import React, {useEffect} from "react";
import {connect} from "react-redux";
import {FormGroup, Grid, FormLabel, Checkbox, FormControlLabel, Typography} from "@mui/material";
import {useTranslation} from "react-i18next";
import {UseAppDispatch, UseAppSelector} from "store/hooks";
import {onChangeTreatmentValue, selectTreatmentValues} from "./treatmentPlanSlice";
import {selectMedicineValues} from "../medicine/medicineSlice";
import {onViewChange, isFormVisible} from "pages/Wizard/wizardSlice";
import {useIsInViewport} from "pages/Wizard/containers/utils";


export const TreatmentPlanForm = (props) => {
  const {t}=useTranslation();

  const isVisibleValues = UseAppSelector(isFormVisible);
  const treatmentPlanValues= UseAppSelector(selectTreatmentValues);
  const medicineValues=UseAppSelector(selectMedicineValues);
  const dispatch = UseAppDispatch();
  const handleValueChange = (field:string, value:any)=>dispatch(onChangeTreatmentValue({field, value}));

  const isVisible=useIsInViewport(props.refProps);
  useEffect(()=>{
    isVisibleValues.treatment!==isVisible&&dispatch(onViewChange({field: "treatment", value: isVisible}));
  }, [isVisible]);
  return (<Grid ref={props.refProps} container direction="column" spacing={3} id="treatment">
    <Grid item>
      <Typography variant="h5" >
        {t(props.label)}
      </Typography>
    </Grid>
    <Grid item xs={12}>
      <FormGroup>
        <FormLabel>{t("wizard.legal.support.text")}</FormLabel>
        <FormControlLabel
          data-cy="specialist_info"
          control={<Checkbox
            checked={treatmentPlanValues.specialist_info}
            onChange={(event)=>handleValueChange("specialist_info", event.target.checked)} />}
          label={t("wizard.specialist.details.checkbox")} />
        <FormControlLabel
          data-cy="limitatio_approval"
          control={<Checkbox
            checked={treatmentPlanValues.limitatio_approval}
            onChange={(event)=>handleValueChange("limitatio_approval", event.target.checked)} />}
          label={t("wizard.limitatio.checkbox")} />
      </FormGroup>
    </Grid>
    <Grid item xs={12} data-cy="request_type">
      <Typography >{`${t("indication.details.type.credit")}`}
        <span style={{color: "#63c2de", textDecoration: "underline"}}>{medicineValues.request_type}</span></Typography>
    </Grid>
  </Grid>
  );
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(TreatmentPlanForm);
