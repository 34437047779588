import {createLogger} from "redux-logger";
import rootReducer from "./reducers/rootReducer";
import {configureStore} from "@reduxjs/toolkit";

/*
declare let process : {
  env: {
    REACT_APP_API_URL: string
  }
};
*/
// const REACT_APP_API_URL: string = (process.env.REACT_APP_API_URL as string);
/*
const isProductionEnviorment = (REACT_APP_API_URL.includes("hin.patient-strength.ch")) ||
                               (REACT_APP_API_URL.includes("kostengutsprache.patient-strength.ch"));
*/
const isProductionEnviorment = false;
const logger = createLogger({
  collapsed: true,
});

let store:any;
if (isProductionEnviorment) {
  store = configureStore({
    reducer: rootReducer,
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware({
        serializableCheck: false,
        immutableCheck: false,
      }),
    devTools: !isProductionEnviorment,
  });
} else {
  store = configureStore({
    reducer: rootReducer,
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware({
        serializableCheck: false,
        immutableCheck: false,
      }).concat(logger),
    devTools: !isProductionEnviorment,
  });
}

export default store;

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;

