import React from "react";
import {Grid, Typography} from "@mui/material";


export const OverviewRow=(props)=>{
  return (
    <Grid item container xs={12} alignItems='center'>
      <Grid item xs={5} paddingRight='12px'>
        <Typography>{props.rowTitle}</Typography>
      </Grid>
      <Grid item xs={7}>
        <Typography>{props.rowContent}</Typography>
      </Grid>
    </Grid>);
};
