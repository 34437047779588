import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {RootState} from "store/rootstate";

export interface LynparzaState {
  formData: {
    variant: string
    default: boolean,
    newlyDiagnosedAdvancedHighGradeSerousOvarianCarcinoma: boolean,
    olympiaGBRCAMHER2FruehesHochrisikoMammakarzinom: boolean,
    solo1BRCAm: boolean,
    paola1BRCAMorHRDInCombinationWithBevacizumab: boolean,
    defaultForm: {
      preTreatment: string
      patientCondition: string,
      prognosis: string,
      reasonsAlternativeTherapiesFailed: string,
    },
  }
}

const initialState:LynparzaState = {
  formData: {
    variant: "",
    default: false,
    newlyDiagnosedAdvancedHighGradeSerousOvarianCarcinoma: false,
    olympiaGBRCAMHER2FruehesHochrisikoMammakarzinom: false,
    solo1BRCAm: false,
    paola1BRCAMorHRDInCombinationWithBevacizumab: false,
    defaultForm: {
      preTreatment: "",
      patientCondition: "",
      prognosis: "",
      reasonsAlternativeTherapiesFailed: "",
    },
  },
};

const LynparzaFormSlice = createSlice({
  name: "Lynparza",
  initialState,
  reducers: {
    onClearLynparzaState: (state)=>{
      state.formData = initialState.formData;
    },
    onReceiveSpecialMedLynparzaEditData: (state, {payload}: PayloadAction<any>) => {
      updatestate(payload, state);
      if (payload.formData?.defaultForm) {
        state.formData.defaultForm.preTreatment =
          payload.formData?.defaultForm?.preTreatment;
        state.formData.defaultForm.patientCondition =
          payload.formData?.defaultForm?.patientCondition;
        state.formData.defaultForm.reasonsAlternativeTherapiesFailed =
          payload.formData?.defaultForm?.reasonsAlternativeTherapiesFailed;
        state.formData.defaultForm.prognosis =
          payload.formData?.defaultForm?.prognosis;
      }
    },
    onChangeLynparzaValue: (state, {payload}: PayloadAction<any>) => {
      if (payload.preTreatment || payload.preTreatment === "") state.formData.defaultForm.preTreatment = payload.preTreatment;
      if (payload.patientCondition || payload.patientCondition === "") state.formData.defaultForm.patientCondition = payload.patientCondition;
      if (payload.reasonsAlternativeTherapiesFailed || payload.reasonsAlternativeTherapiesFailed === "") state.formData.defaultForm.reasonsAlternativeTherapiesFailed = payload.reasonsAlternativeTherapiesFailed;
      if (payload.prognosis || payload.prognosis === "") state.formData.defaultForm.prognosis = payload.prognosis;
      updatestate(payload, state);
    },
  },
});


export const {
  onReceiveSpecialMedLynparzaEditData,
  onClearLynparzaState,
  onChangeLynparzaValue,
} = LynparzaFormSlice.actions;

export default LynparzaFormSlice.reducer;
export const selectLynparzaValues = (state:RootState) =>
  state.wizard.medicine.additionalInformation.lynparzaForm?.formData;


function updatestate(payload: any, state) {
  if (payload.formData?.variant || payload.formData?.variant === "") {
    state.formData.variant = payload.formData?.variant;
  }
  if (payload.formData?.default === true) {
    state.formData.default = true;
    state.formData.newlyDiagnosedAdvancedHighGradeSerousOvarianCarcinoma = false;
    state.formData.olympiaGBRCAMHER2FruehesHochrisikoMammakarzinom = false;
    state.formData.solo1BRCAm = false;
    state.formData.paola1BRCAMorHRDInCombinationWithBevacizumab = false;
  } else if (payload.formData?.newlyDiagnosedAdvancedHighGradeSerousOvarianCarcinoma === true) {
    state.formData.newlyDiagnosedAdvancedHighGradeSerousOvarianCarcinoma = true;
    state.formData.default = false;
    state.formData.olympiaGBRCAMHER2FruehesHochrisikoMammakarzinom = false;
    state.formData.defaultForm.preTreatment = "";
    state.formData.defaultForm.patientCondition = "";
    state.formData.defaultForm.reasonsAlternativeTherapiesFailed = "";
    state.formData.defaultForm.prognosis = "";
  } else if (payload.formData?.olympiaGBRCAMHER2FruehesHochrisikoMammakarzinom === true) {
    state.formData.olympiaGBRCAMHER2FruehesHochrisikoMammakarzinom = true;
    state.formData.newlyDiagnosedAdvancedHighGradeSerousOvarianCarcinoma = false;
    state.formData.default = false;
    state.formData.defaultForm.preTreatment = "";
    state.formData.defaultForm.patientCondition = "";
    state.formData.defaultForm.reasonsAlternativeTherapiesFailed = "";
    state.formData.defaultForm.prognosis = "";
    state.formData.solo1BRCAm = false;
    state.formData.paola1BRCAMorHRDInCombinationWithBevacizumab = false;
  }
  if (payload.formData?.solo1BRCAm === true) {
    state.formData.solo1BRCAm = true;
    state.formData.paola1BRCAMorHRDInCombinationWithBevacizumab = false;
  } else if (payload.formData?.paola1BRCAMorHRDInCombinationWithBevacizumab === true) {
    state.formData.paola1BRCAMorHRDInCombinationWithBevacizumab = true;
    state.formData.solo1BRCAm = false;
  }
}

