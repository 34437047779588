import React from "react";
import CheckIcon from "@mui/icons-material/Check";
import ClearIcon from "@mui/icons-material/Clear";

import makeStyles from "@mui/styles/makeStyles";
const useStyles = makeStyles(()=>({
  iconStyleAccept: {
    "position": "relative",
    "top": "-18px",
    "right": "-30px",
    "borderRadius": "10px",
    "backgroundColor": "rgb(77, 189, 116)",
    "color": "white",
    "width": "15px",
    "height": "15px",
  },
  iconStyleDecline: {
    "position": "relative",
    "top": "-18px",
    "right": "-30px",
    "borderRadius": "10px",
    "backgroundColor": "darkred",
    "color": "white",
    "width": "15px",
    "height": "15px",
  },
}));
const InsuranceStatusIcon=(props)=>{
  const classes=useStyles();
  return (
        props.status==="APPROVED"?
            <CheckIcon className={classes.iconStyleAccept}
              data-toggle="popover"
              data-content="Disabled popover"
              fontSize="small"/>:
             <ClearIcon className={classes.iconStyleDecline}
               data-toggle="popover"
               data-content="Disabled popover"
               fontSize="small" />
  );
};

export default InsuranceStatusIcon;
