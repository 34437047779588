import React from "react";
import DoctorForm from "./forms/doctor/DoctorForm";
import Overview from "./forms/overview/Overview";
import {AuthenticatedTemplate} from "@azure/msal-react";
import {PatientForm} from "./forms/patient/PatientForm";
import UploadForm from "./forms/upload/UploadForm";
import UploadStudy from "./forms/upload/UploadStudy";
import MedicineForm from "./forms/med/medicine/MedicineForm";
import KNForm from "./forms/med/medicine/KNForm";
import DiagnosisForm from "./forms/med/Diagnosis/DiagnosisForm";
import DosisPlanForm from "./forms/med/Dosis/DosisPlanForm";
import TreatmentPlanForm from "./forms/med/Treatment/TreatmentPlanForm";
import AdditionalInformationForm from "./forms/med/AdditionalInformation/AdditionalInformationForm";

export const renderWizardForms=(formName:string, props, refProps)=>{
  switch (formName) {
    case "patient":
      return <PatientForm refProps={refProps} {...props}></PatientForm>;
    case "doctor":
      return <DoctorForm refProps={refProps} {...props}></DoctorForm>;
    case "overview":
      return <Overview refProps={refProps} {...props}></Overview>;
    case "uploadStudy":
      return <UploadStudy refProps={refProps} {...props}></UploadStudy>;
    case "uploadDocument":
      return <AuthenticatedTemplate><UploadForm refProps={refProps} {...props}></UploadForm></AuthenticatedTemplate>;
    case "diagnosis":
      return <DiagnosisForm refProps={refProps} {...props}></DiagnosisForm>;
    case "medicine":
      return <MedicineForm refProps={refProps} {...props}></MedicineForm>;
    case "dosis":
      return <DosisPlanForm refProps={refProps} {...props}></DosisPlanForm>;
    case "treatment":
      return <TreatmentPlanForm refProps={refProps} {...props}></TreatmentPlanForm>;
    case "kn":
      return <KNForm refProps={refProps} {...props}></KNForm>;
    case "additionalInformation":
      return <AdditionalInformationForm refProps={refProps} {...props}></AdditionalInformationForm>;
  }
};

