import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {RootState} from "store/rootstate";

export interface ICalquenceState {
  formData: {
    ccl: boolean,
    ibrutinib: boolean,
    older_65: boolean,
    komorbid: boolean,
    mutations: boolean,
    kombinationtherapie: boolean,
    monotherapie: boolean,
    bonelliform: {
      dosis: string,
      start: string,
      end: string,
      effect: string,
    }
    variant: number,
  }
}

const initialState:ICalquenceState = {
  formData: {
    ccl: false,
    ibrutinib: false,
    older_65: false,
    komorbid: false,
    mutations: false,
    kombinationtherapie: false,
    monotherapie: false,
    bonelliform: {
      dosis: "",
      start: "",
      end: "",
      effect: "",
    },
    variant: 0,
  },
};


const CalquenceFormSlice = createSlice({
  name: "Calquence",
  initialState,
  reducers: {
    onReceiveSpecialMedCalquenceEditData: (state, {payload}: PayloadAction<any>) => {
      console.debug("onReceiveSpecialMedCalquenceEditData", payload.formData);

      state.formData.ccl = payload?.formData?.ccl;
      state.formData.ibrutinib = payload?.formData?.ibrutinib;
      state.formData.older_65 = payload?.formData?.older_65;
      state.formData.komorbid = payload?.formData?.komorbid;
      state.formData.mutations = payload?.formData?.mutations;
      state.formData.kombinationtherapie = payload?.formData?.kombinationtherapie;
      state.formData.monotherapie = payload?.formData?.monotherapie;
      state.formData.variant = payload?.formData?.variant;

      if (payload?.formData?.bonelliform) {
        state.formData.bonelliform.dosis = payload?.formData?.bonelliform?.dosis;
        state.formData.bonelliform.start = payload?.formData?.bonelliform?.start;
        state.formData.bonelliform.end = payload?.formData?.bonelliform?.end;
        state.formData.bonelliform.effect = payload?.formData?.bonelliform?.effect;
      }
    },


    onClearCalquenceState: (state)=>{
      state.formData=initialState.formData;
    },
    onChangeCalquenceValue: (state, {payload}:PayloadAction<any>)=>{
      console.debug("onChangeCalquenceValue", payload);
      console.debug("onChangeCalquenceValue", state.formData);
      if (payload.ccl === true || payload.ccl === false) {
        state.formData.ccl = payload.ccl;
        state.formData.ibrutinib = false;
        state.formData.older_65 = false;
        state.formData.komorbid = false;
        state.formData.mutations = false;
        state.formData.kombinationtherapie = false;
        state.formData.monotherapie = false;
      }
      if (payload.ibrutinib === true || payload.ibrutinib === false) {
        state.formData.ibrutinib = payload.ibrutinib;
        state.formData.older_65 = false;
        state.formData.komorbid = false;
        state.formData.mutations = false;
        state.formData.kombinationtherapie = false;
        state.formData.monotherapie = false;
      }
      if (payload.older_65 === true || payload.older_65 === false) {
        state.formData.older_65 = payload.older_65;
        state.formData.komorbid = false;
        state.formData.mutations = false;
        state.formData.kombinationtherapie = false;
        state.formData.monotherapie = false;
      }
      if (payload.komorbid === true || payload.komorbid === false) {
        state.formData.komorbid = payload.komorbid;
        state.formData.mutations = false;
        state.formData.kombinationtherapie = false;
        state.formData.monotherapie = false;
      }
      if (payload.mutations === true || payload.mutations === false) {
        state.formData.mutations = payload.mutations;
        state.formData.kombinationtherapie = false;
        state.formData.monotherapie = false;
      }
      if (payload.kombinationtherapie === true || payload.kombinationtherapie === false) {
        state.formData.kombinationtherapie = payload.kombinationtherapie;
        state.formData.monotherapie = false;
      }
      if (payload.monotherapie === true || payload.monotherapie === false) {
        state.formData.monotherapie = payload.monotherapie;
        state.formData.kombinationtherapie = false;
      }
      if (payload.dosis || payload.dosis === "") state.formData.bonelliform.dosis = payload.dosis;
      if (payload.start || payload.start === "") state.formData.bonelliform.start = payload.start;
      if (payload.end || payload.end === "") state.formData.bonelliform.end = payload.end;
      if (payload.effect || payload.effect === "") state.formData.bonelliform.effect = payload.effect;

      if (
        state.formData.ccl === true &&
        state.formData.ibrutinib === true
      ) {
        state.formData.variant = 1;
      } else if (
        state.formData.ccl === false &&
        state.formData.older_65 === false &&
        state.formData.komorbid === false
      ) {
        state.formData.variant = 3;
      } else if (
        state.formData.ccl === true &&
        state.formData.ibrutinib === false
      ) {
        state.formData.variant = 2;
      } else if (
        state.formData.ccl === false &&
        state.formData.komorbid === true &&
        state.formData.mutations === true &&
        state.formData.kombinationtherapie === false &&
        state.formData.monotherapie === false
      ) {
        state.formData.variant = 2;
      } else if (
        state.formData.ccl === false &&
        state.formData.komorbid === true &&
        state.formData.mutations === true &&
        state.formData.kombinationtherapie === true
      ) {
        state.formData.variant = 4;
      } else if (
        state.formData.ccl === false &&
        state.formData.komorbid === true &&
        state.formData.mutations === false &&
        state.formData.kombinationtherapie === true &&
        state.formData.monotherapie === false
      ) {
        state.formData.variant = 4;
      } else if (
        state.formData.ccl === false &&
        state.formData.komorbid === false &&
        state.formData.older_65 === true &&
        state.formData.mutations === false &&
        state.formData.kombinationtherapie === false &&
        state.formData.monotherapie === true
      ) {
        state.formData.variant = 5;
      } else if (
        state.formData.ccl === false &&
              state.formData.komorbid === false &&
              state.formData.older_65 === true &&
              state.formData.mutations === false &&
              state.formData.kombinationtherapie === true &&
              state.formData.monotherapie === false
      ) {
        state.formData.variant = 6;
      } else if (
        state.formData.ccl === false &&
        state.formData.ibrutinib === false &&
        state.formData.kombinationtherapie === true &&
        state.formData.komorbid === false &&
        state.formData.monotherapie === false &&
        state.formData.mutations === true &&
        state.formData.older_65 === true
      ) {
        state.formData.variant = 4;
      } else if (
        state.formData.ccl === false &&
        state.formData.ibrutinib === false &&
        state.formData.kombinationtherapie === false &&
        state.formData.komorbid === false &&
        state.formData.monotherapie === true &&
        state.formData.mutations === true &&
        state.formData.older_65 === true
      ) {
        state.formData.variant = 8;
      } else if (
        state.formData.ccl === false &&
      state.formData.ibrutinib === false &&
      state.formData.kombinationtherapie === false &&
      state.formData.komorbid === true &&
      state.formData.monotherapie === true &&
      state.formData.mutations === false &&
      state.formData.older_65 === false
      ) {
        state.formData.variant = 7;
      } else {
        state.formData.variant = 0;
      }
      console.debug("onChangeCalquenceValue Variant", state.formData.variant);
    },
  },
});

export const {
  onReceiveSpecialMedCalquenceEditData,
  onClearCalquenceState,
  onChangeCalquenceValue,
} = CalquenceFormSlice.actions;

export default CalquenceFormSlice.reducer;
export const selectCalquenceValues = (state:RootState) =>
  state.wizard.medicine.additionalInformation.calquenceForm.formData;

