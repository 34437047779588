import React from "react";
import {
  Card,
  CardContent,
  CardActions,
  Typography,
  Button,
} from "@mui/material";


import makeStyles from "@mui/styles/makeStyles";

type ActionCardProps={
    title:string,
    description:string,
    onClick:any,
    dataCy:string,
    actionLabel:string,
    disableButton:boolean,
}

export const ActionCardComponent=({
  title,
  description,
  onClick,
  dataCy,
  actionLabel,
  disableButton,
}:ActionCardProps)=>{
  const useStyles = makeStyles({
    root: {
      width: 345,
      margin: 10,
    },
    body: {
      minHeight: 200,
      width: "100%",
    },
  });
  const classes=useStyles();
  return (
    <Card className={classes.root}>
      <CardContent className={classes.body}>
        <Typography gutterBottom variant="h5" component="h2" textAlign='center'>
          {title}
        </Typography>
        <Typography variant="body2" color="textSecondary" component="p" textAlign='center'>
          {description}
        </Typography>
      </CardContent>
      <CardActions style={{justifyContent: "center"}}>
        <Button
          color="primary"
          size="small"
          data-cy={dataCy}
          id={dataCy}
          disabled={disableButton}
          onClick={onClick}>
          {actionLabel}
        </Button>
      </CardActions>
    </Card>
  );
};
