import React, {useEffect} from "react";
import {connect} from "react-redux";
import {
  Grid,
  TextField,
  Autocomplete,
  CircularProgress,
  Chip,
  Typography,
} from "@mui/material";
import {checkIfMinDateNeeded, correctDateFormat, datepickerLimitations, delay} from "components/formUtils";
import {LocalizationProvider, DatePicker} from "@mui/x-date-pickers/";
import {AdapterDateFns} from "@mui/x-date-pickers/AdapterDateFns";
import {useTranslation} from "react-i18next";
import {UseAppDispatch, UseAppSelector} from "store/hooks";
import {onChangeDosisValue, selectDosisValues} from "./dosisPlanSlice";
import {selectCombinedDrugOptions} from "store/features/masterdataSlice";
import {fetchBagDataCombinedDrug} from "store/middleware/masterdataThunk";
import {selectLoadingState} from "store/features/loadingSlice";
import {calendarLanguage, useIsInViewport, calendarMask} from "pages/Wizard/containers/utils";
import {onViewChange, isFormVisible} from "pages/Wizard/wizardSlice";
import {useLocation, useParams} from "react-router-dom";


export const DosisPlanForm = (props) => {
  const {t, i18n}=useTranslation();
  const params=useLocation();
  const currentLang=i18n.language.toUpperCase();
  const dosisPlanValues= UseAppSelector(selectDosisValues);
  const dispatch = UseAppDispatch();
  const isVisibleValues = UseAppSelector(isFormVisible);
  const handleValueChange = (field:string, value:any)=>dispatch(onChangeDosisValue({field, value}));
  const requiredDrugs= UseAppSelector(selectCombinedDrugOptions);
  const loading=UseAppSelector(selectLoadingState);
  const {requestId} = useParams<{requestId:string}>();
  const isCopy=params?.pathname?.includes("copy_request");

  const removeItem=( value:string)=>{
    const index = dosisPlanValues.combined_drug.indexOf(value);
    const array=[...dosisPlanValues.combined_drug];
    if (index > -1) {
      array.splice(index, 1);
    }
    handleValueChange("combined_drug", array);
  };

  const isVisible=useIsInViewport(props.refProps);
  useEffect(()=>{
    isVisibleValues.dosis!==isVisible&&dispatch(onViewChange({field: "dosis", value: isVisible}));
  }, [isVisible]);

  return (
    <Grid ref={props.refProps} container direction="column" spacing={3} id="dosis">
      <Grid item container xs={12} alignItems='center' spacing={3}>
        <Grid item xs={12}>
          <Typography variant="h5" >
            {t(props.label)}
          </Typography>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Grid item xs={12} data-cy="patient_treatment_startdate">
          <LocalizationProvider dateAdapter={AdapterDateFns} locale={calendarLanguage(currentLang)}>
            <DatePicker
              label={t("patient.startdate.treatment")}
              value={dosisPlanValues.patient_treatment_startdate}
              mask={calendarMask(currentLang)}
              maxDate={datepickerLimitations.med.patient_treatment_startdate.maxDate}
              minDate={checkIfMinDateNeeded(isCopy, requestId, datepickerLimitations.med.patient_treatment_startdate.minDate)}
              onChange={(newValue) => {
                handleValueChange("patient_treatment_startdate", correctDateFormat( newValue));
              }}
              renderInput={(params) => <TextField {...params} />}
            />
          </LocalizationProvider>
        </Grid>
      </Grid>
      <Grid item xs={12} data-cy="patient_details_dosis">
        <TextField
          margin="dense"
          label={t("indication.details.dosis")}
          fullWidth
          value={dosisPlanValues.dosis || ""}
          onChange={(event)=>handleValueChange("dosis", event.target.value)}
        />
      </Grid>
      <Grid item xs={12} data-cy="combined_drug">
        <Autocomplete
          fullWidth
          disableClearable
          multiple={true}
          options={requiredDrugs??[]}
          value={dosisPlanValues.combined_drug || ""}
          getOptionLabel={(option:any) => {
            let label = "";
            if (option?.article) {
              if (option.substances && Array.isArray(option.substances)) {
                let substances = "";
                option.substances.forEach((element) => {
                  if (substances.length > 0) substances = substances + " ";

                  if (currentLang == "FR") {
                    substances = substances + element.NAMF;
                  } else {
                    substances = substances + element.NAMD;
                  }
                });
                if (substances?.length > 30 ) {
                  substances = substances + " ...";
                }
                label = option.article + "\u200C (" + substances + ")";
              } else {
                label = option.article;
              }
            } else if (option.NAME) {
              label = option.NAME;
            } else {
              label= option; // edit & custom med flow
            }
            return label;
          }}
          onInputChange={(event, newInputValue) => {
            if (event?.type === "change") {
              delay(async () => {
                await dispatch(fetchBagDataCombinedDrug(newInputValue, currentLang));
              }, 1000);
            }
          }}
          autoSelect={true}
          freeSolo
          renderTags={(value: string[], getTagProps) =>
            value.map((option: string, index: number) => (
              <Chip
                variant="outlined"
                data-cy={`remove_combinedDrug_${index}`}
                label={option}
                {...getTagProps({index})}
                key={index}
                onDelete={()=>{
                  removeItem(option);
                }}
              />
            ))
          }
          onChange={(e, v, r, selectedDrug)=>{
            if (selectedDrug) {
              if (selectedDrug.option.article === undefined) {
                // do nothing
              } else {
                handleValueChange("combined_drug", [...dosisPlanValues.combined_drug, selectedDrug.option.article]);
              }
            } else {
              handleValueChange("combined_drug", "");
            }
          }}
          renderInput={(params) => <TextField
            {...params}
            variant="outlined"
            // label={t("indication.details.required.drug")}
            label={t("indication.details.combined.drug")}
            placeholder={t("indication.details.combined.drug")}
            InputProps={{...params.InputProps,
              readOnly: loading.bagCombined,
              endAdornment: (
                <React.Fragment>
                  {(loading?.bagCombined) ?
                      <CircularProgress color="inherit" size={20} /> :
                      null}
                  {params.InputProps.endAdornment}
                </React.Fragment>
              ),
            }}
          />}/>

      </Grid>
    </Grid>
  );
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(DosisPlanForm);
