import React, {useEffect} from "react";
import {connect} from "react-redux";
import {useTranslation} from "react-i18next";
import {Grid, Typography} from "@mui/material";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import Checkbox from "@mui/material/Checkbox";
import {setCombinedMedication} from "pages/Wizard/wizardSlice";
import Box from "@mui/material/Box";
import WarningIcon from "@mui/icons-material/Warning";
import Stack from "@mui/material/Stack";
import {setStudyById, removeStudy} from "pages/Wizard/wizardSlice";
import {
  useLocation,
} from "react-router-dom";

import {
  onChangeKVVLegalState,
} from "../../../medicine/medicineSlice";
import {
  onChangeSpecialMedValue,
  SpecialMedAdditionalInformation,
} from "./../../specialMedSlice";

import {onChangeDosisValue} from "../../../Dosis/dosisPlanSlice";

import {
  UseAppDispatch,
  UseAppSelector,
} from "store/hooks";

import {
  onChangeImjudoValue,
  selectImjudoValues,
} from "./ImjudoSlice";

import {
  onChangeTreatmentValue,
} from "../../../Treatment/treatmentPlanSlice";

const ImjudoForm = (props) => {
  const {t} = useTranslation();
  const dispatch = UseAppDispatch();
  const ImjudoValues = UseAppSelector(selectImjudoValues);
  const params=useLocation();
  useEffect(() => {
    const regex = /\d+$/;

    const isEditId = regex.test(params.pathname);
    console.debug("isEditId", isEditId);
    if (!isEditId && ImjudoValues.poseidon !== true) {
      dispatch(setCombinedMedication({medication: ""}));
    }
    if (
      !isEditId &&
      ImjudoValues.poseidon === true &&
      ImjudoValues.inCombinationWithImfinzi === true
    ) {
      dispatch(setCombinedMedication({medication: t("imjudo.inCombinationWithImfinzi.medication")}));
    }
  }, []);

  useEffect(() => {
    if (ImjudoValues.poseidon === true) {
      setPoseidon(dispatch, t);
    } else if (ImjudoValues.inCombinationWithImfinzi === true) {
      dispatch(setCombinedMedication({medication: t("imjudo.inCombinationWithImfinzi.medication")}));
    } else if (ImjudoValues.inCombinationWithImfinzi === false) {
      dispatch(setCombinedMedication({medication: ""}));
    }
  }, [ImjudoValues]);

  return <>
    <Grid item xs={12}>
      <FormControl>
        <RadioGroup
          aria-labelledby="imjudo-radio-buttons-group-form-type"
          name="imjudo-radio-buttons-group-form-type"
          onChange={(event, value) => {
            dispatch(onChangeImjudoValue({choice: value}));
            if (value === "himalaya") {
              Promise.all([
                dispatch(onChangeImjudoValue({
                  formData: {inCombinationWithImfinzi: true},
                })),
                dispatch(setCombinedMedication({medication: t("imjudo.inCombinationWithImfinzi.medication")})),
                dispatch(onChangeKVVLegalState(
                    {
                      limitatio_approval: false,
                      specialist_info: true,
                    },
                )),
                dispatch(onChangeDosisValue({field: "dosis", value: t("imjudo.dose")})),
                dispatch(onChangeSpecialMedValue({field: "special_med_additional_information", value: SpecialMedAdditionalInformation.NONE})),
                dispatch(onChangeTreatmentValue({field: "specialist_info", value: true})),
                dispatch(onChangeTreatmentValue({field: "limitatio_approval", value: false})),
                dispatch(setStudyById({studyId: "38319892"})),
              ]).then((values) => {
                console.debug("hymalaya");
              });
            } else if (value === "poseidon") {
              setPoseidon(dispatch, t);
              dispatch(setCombinedMedication({medication: t("imjudo.inCombinationWithImfinzi.medication")})),

              dispatch(onChangeImjudoValue({formData: {inCombinationWithImfinzi: true}}));
            } else {
              Promise.all([
                dispatch(onChangeImjudoValue({
                  formData: {inCombinationWithImfinzi: false},
                })),
                dispatch(setCombinedMedication({medication: ""})),
                dispatch(onChangeKVVLegalState(
                    {
                      limitatio_approval: false,
                      specialist_info: false,
                    },
                )),
                dispatch(onChangeTreatmentValue({field: "specialist_info", value: false})),
                dispatch(onChangeTreatmentValue({field: "limitatio_approval", value: false})),
                dispatch(onChangeSpecialMedValue({field: "special_med_additional_information", value: SpecialMedAdditionalInformation.NONE})),
                dispatch(onChangeDosisValue({field: "dosis", value: ""})),
                dispatch(removeStudy({})),
              ]).then((values) => {
                console.debug("other");
              });
            }
          }}
        >
          <FormControlLabel
            value="himalaya"
            control={<Radio data-cy="himalaya" checked={ImjudoValues.himalaya} />} label={t("imjudo.himalaya")} />

          <FormControlLabel
            value="poseidon"
            data-cy="poseidon"
            control={<Radio checked={ImjudoValues.poseidon} />} label={t("imjudo.poseidon")} />

          <FormControlLabel
            value="default"
            control={<Radio checked={ImjudoValues.default} data-cy="default"/>}
            label={t("imjudo.default")}
          />
        </RadioGroup>
      </FormControl>

      <Box sx={{m: 1}} />
      <div hidden={

        (!ImjudoValues.himalaya === true) && (!ImjudoValues.poseidon === true)
      }


      >
        <FormControl fullWidth
          hidden={(ImjudoValues.poseidon === true)}
        >
          <FormControlLabel
            control=
              {
                <Checkbox
                  checked={ImjudoValues.bleedingRisk}
                  data-cy="bleedingRisk"
                  onChange={(event, checked) => {
                    dispatch(onChangeImjudoValue({
                      formData: {bleedingRisk: checked},
                    }));
                    if (checked === true) {
                      dispatch(onChangeSpecialMedValue({field: "special_med_additional_information", value: SpecialMedAdditionalInformation.NONE}));
                    } else {
                      dispatch(onChangeSpecialMedValue({field: "special_med_additional_information", value: SpecialMedAdditionalInformation.NONE}));
                    }
                  }
                  }
                />
              }
            label={t("imjudo.bleedingRisk")} />
        </FormControl>
        <FormControl fullWidth>
          <FormControlLabel
            control=
              {
                <Checkbox
                  checked={ImjudoValues.inCombinationWithImfinzi}
                  data-cy="inCombinationWithImfinzi"
                  onChange={(event, checked) => {
                    dispatch(onChangeImjudoValue({
                      formData: {inCombinationWithImfinzi: checked},
                    }));
                    if (checked === true) {
                      dispatch(setCombinedMedication(
                          {
                            medication: t("imjudo.inCombinationWithImfinzi.medication"),
                          }));
                    } else {
                      dispatch(setCombinedMedication({medication: ""}));
                    }
                  }}
                />
              }
            label={t("imjudo.inCombinationWithImfinzi")} />
        </FormControl>
      </div>
      <Grid item xs={12}>
        <Stack
          direction="row"
          alignItems="left"
          gap={1}
          hidden={true}
        >
          <WarningIcon color={"primary"}></WarningIcon>
          <Typography>{t("imjudo.warning.limitatio")}</Typography>

        </Stack>
      </Grid>
    </Grid>
  </>;
};

const mapStateToProps=(state) => {
  return {
  };
};

const mapDispatchToProps=(dispatch) => ({

});

export default connect(mapStateToProps, mapDispatchToProps)(ImjudoForm);


function setPoseidon(dispatch: any, t) {
  Promise.all([
    dispatch(onChangeDosisValue({field: "dosis", value: t("imjudo.dose")})),
    dispatch(onChangeSpecialMedValue({field: "special_med_additional_information", value: SpecialMedAdditionalInformation.IMJUDO_POSEIDON})),
    dispatch(onChangeKVVLegalState(
        {
          limitatio_approval: false,
          specialist_info: false,
        },
    )),
    dispatch(onChangeTreatmentValue({field: "specialist_info", value: false})),
    dispatch(onChangeTreatmentValue({field: "limitatio_approval", value: false})),
    dispatch(setStudyById({studyId: "36327426"})),
  ]).then((values) => {
    console.debug("poseidon");
  });
}

