import React from "react";
import {Box,
  Button,
  Card,
  CardContent,
  Grid,
  Link,
  Typography,
  IconButton,
} from "@mui/material";
import {MoreVert} from "@mui/icons-material";
import {formatDateGerman} from "components/formatting";

import ErrorBoundary from "../../../layout/ErrorBoundary";

import {useTranslation} from "react-i18next";


const RequestCollapseComponent=({row, actions, setSelectedRow, verified})=>{
  const {t}=useTranslation();


  const patientAddress=`${row?.patient_street||"-"}, ${row?.patient_postalcode||"-"}, ${row?.patient_country||"-"}, ${row?.patient_city||"-"}`;

  const noInsuranceSet=row?.patient_insurance_company === "" || row?.patient_insurance_company === null||
              row?.patient_insurance_company_gln === "" ||row?.patient_insurance_company_gln=== null;

  let insurancePhoneNo = "";
  if (row?.patient_insurance_phone && row?.patient_insurance_phone[0]) {
    const insurance_phonenumber_first_element = row?.patient_insurance_phone[0];
    let insurance_phonenumber_country = insurance_phonenumber_first_element?.PRECOUNTR || "";
    if (insurance_phonenumber_country.startsWith("00")) {
      insurance_phonenumber_country = insurance_phonenumber_country.replace("00", "+");
    }
    let insurance_phonenumber_prefix = insurance_phonenumber_first_element?.PREFIX || "";
    if (insurance_phonenumber_prefix.length > 0) {
      insurance_phonenumber_prefix = parseInt(insurance_phonenumber_prefix, 10);
    }

    let insurance_phonenumber_number = insurance_phonenumber_first_element?.NB || "";
    if (insurance_phonenumber_number && insurance_phonenumber_number.length > 0) {
      insurance_phonenumber_number = [insurance_phonenumber_number.slice(0, 4), " ", insurance_phonenumber_number.slice(4)].join("");
    }
    insurancePhoneNo = insurance_phonenumber_country + " " +insurance_phonenumber_prefix+ " " +insurance_phonenumber_number+ " ";

    console.debug("patient_insurance_phone", insurance_phonenumber_first_element);
  }

  let patient_phone_no = row.patient_phone_no || "";
  if (patient_phone_no.startsWith("++")) {
    patient_phone_no = patient_phone_no.replace("++", "+");
  }

  const fieldsAreComplete=row?.fields_are_complete===null?"":row?.fields_are_complete?t("fields.are.complete"):t("fields.are.not.complete");
  return (
    <ErrorBoundary>
      <Box sx={{flexGrow: 1}}>
        <Grid container direction='row' >
          <Grid item container xs={12} sx={{display: {xs: "flex", sm: "none"}}}>
            <Grid item xs={12}>
              <Card variant="outlined" sx={{minHeight: "200px"}}>
                <CardContent>
                  <Typography fontSize='16px' fontWeight='bold' marginBottom='20px'>
                    {t("request.details")}
                  </Typography>
                  <Grid item container xs={12}>
                    <Grid item xs={6}>
                      <Typography fontSize='14px' >
                        {t("requestlist.headers.request.type")}:
                      </Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Typography fontSize='14px' >
                        {row?.request_type}
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid item container xs={12}>
                    <Grid item xs={6}>
                      <Typography fontSize='14px' >
                        {t("requestlist.headers.status")}:
                      </Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Typography fontSize='14px' >
                        {row?.request_status}
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid item container xs={12}>
                    <Grid item xs={6}>
                      <Typography fontSize='14px' >
                        {t("Letze")}:
                      </Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Typography fontSize='14px' >
                        {formatDateGerman(row?.request_date) }
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid item container xs={12}>
                    <Grid item xs={6}>
                      <Typography fontSize='14px' >
                        {t("newrequest.label.doctor.details")}:
                      </Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Typography fontSize='14px' >
                        {"Dr. "+row?.request_owner_last_name+" "+row?.request_owner_first_name}
                      </Typography>
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
          <Grid item container xs={12} sm={4}>
            <Grid item xs={12}>
              <Card variant="outlined" sx={{minHeight: "200px"}}>
                <CardContent>
                  <Typography fontSize='16px' fontWeight='bold' marginBottom='20px'>
                    {t("patient.data")}
                  </Typography>
                  <Grid item container xs={12}>
                    <Grid item xs={6}>
                      <Typography fontSize='14px' >
                        {t("patient.label.dob")}:
                      </Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Typography fontSize='14px' >
                        {formatDateGerman(row?.patient_date_of_birth)||t("dob.missing")}
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid item container xs={12}>
                    <Grid item xs={6}>
                      <Typography fontSize='14px' >
                        {t("patient.label.addresse")}:
                      </Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Typography fontSize='14px' >
                        {patientAddress}
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid item container xs={12}>
                    <Grid item xs={6}>
                      <Typography fontSize='14px' >
                        {t("patient.label.insurance.phonenumber")}
                      </Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Typography fontSize='14px' >
                        {patient_phone_no||"-"}
                      </Typography>
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
          <Grid item container xs={12} sm={4}>
            <Grid item xs={12}>
              <Card variant="outlined" sx={{minHeight: "200px"}}>
                <CardContent>
                  <Typography fontSize='16px' fontWeight='bold' marginBottom='20px'>
                    {t("diagnosis.data")}
                  </Typography>
                  <Grid item container xs={12}>
                    <Grid item xs={6}>
                      <Typography fontSize='14px' >
                        {t( "indication.details.diagnosis")}:
                      </Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Typography fontSize='14px' sx={{
                        display: "-webkit-box",
                        overflow: "hidden",
                        WebkitBoxOrient: "vertical",
                        WebkitLineClamp: 3,
                      }} >
                        {row?.diagnosis||"-"}
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid item container xs={12}>
                    <Grid item xs={6}>
                      <Typography fontSize='14px' >
                        {t("collapsed.medicine")}:
                      </Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Typography fontSize='14px' sx={{
                        display: "-webkit-box",
                        overflow: "hidden",
                        WebkitBoxOrient: "vertical",
                        WebkitLineClamp: 3,
                      }}>
                        {row?.required_drug || row?.breathing_device_data?.model || row?.migel_data?.required_migel || "-"}
                      </Typography>
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
          <Grid item container xs={12} sm={4}>
            <Grid item xs={12}>
              <Card variant="outlined" sx={{minHeight: "200px"}}>
                <CardContent>
                  <Typography fontSize='16px' fontWeight='bold' marginBottom='20px'>
                    {t("insurance.data")}
                  </Typography>
                  <Grid item container xs={12}>
                    <Grid item xs={6}>
                      <Typography fontSize='14px' >
                        {t("patient.label.insurance.company")}:
                      </Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Typography fontSize='14px' >
                        {row?.patient_insurance_company||"-"}
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid item container xs={12}>
                    <Grid item xs={6}>
                      <Typography fontSize='14px' >
                        {t("patient.label.insurance.phonenumber")}
                      </Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Typography fontSize='14px' >
                        {insurancePhoneNo||"-"}
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid item container xs={12}>
                    <Grid item xs={6}>
                      <Typography fontSize='14px' >
                        {t("patient.label.patient.insuranceNo")}
                      </Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Typography fontSize='14px' >
                        {row?.patient_insurance_no||"-"}
                      </Typography>
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </Grid>
        <Card>
          <CardContent>
            <Grid item container xs={12}>
              <Grid item container xs={12} sm={8}>
                <Grid item container xs={12} justifyContent={{xs: "center", sm: "flex-start"}}>
                  <Link component="button" variant="body2"
                    data-cy={"printstudy_"+row?.request_id}
                    onClick={()=>actions.printStudy(row?.request_id, row?.kogu_type)} fontSize='16px' >
                    {`${t("studies.attached")}(${row?.studies_attached || "0"})`}
                  </Link>
                </Grid>
                <Grid item container xs={12} justifyContent={{xs: "center", sm: "flex-start"}}>
                  <Link component="button"
                    variant="body2"
                    onClick={()=>actions.printStudy(row?.request_id, row?.kogu_type)} fontSize='16px' >
                    {`${t("documents.attached")}(${row?.attachments_internal+row?.attachments_external || "0"})`}
                  </Link>
                </Grid>
              </Grid>
              <Grid item container xs={12} sm={3} >
                <Grid item container xs={12} textAlign={{xs: "center", sm: "end"}} justifyContent={{xs: "center", sm: "end"}}>
                  <Typography fontSize='16px'>{fieldsAreComplete}</Typography>
                </Grid>
                <Grid item container xs={12} justifyContent={{xs: "center", sm: "flex-end"}}>
                  <Button variant="contained"
                    size="small"
                    disabled={(!verified) || (noInsuranceSet)}
                    data-cy={"insurance_"+row?.request_id}
                    onClick={()=>actions.insuranceDialog(row)}>{t("send.to.insurance")}</Button>
                </Grid>
              </Grid>
              <Grid container item xs={12} sm={1} justifyContent={{xs: "center", sm: "flex-end"}} >
                <IconButton
                  id="actionsButton"
                  aria-label="more"
                  aria-controls="long-menu"
                  aria-haspopup="true"
                  data-cy={"menuiconbutton_"+row?.request_id}
                  onClick={(event)=>{
                    setSelectedRow({row: row, isOpenMenuButton: event.currentTarget});
                  }}
                >
                  <MoreVert />
                </IconButton>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Box>
    </ErrorBoundary>
  );
};

export default RequestCollapseComponent;
