import React, {useState, useEffect} from "react";
import {
  FaUpload,
  FaTrash,
  AiOutlineEye,
  InsertLink,
  PostAdd,
} from "components/icons";
import {connect, useDispatch} from "react-redux";
import {
  uploadAndParseLocalFiles,
  uploadLocalFiles,
} from "store/middleware/newRequestThunk";
import PropTypes from "prop-types";
import Overlay from "react-overlay-component";
import PDFViewer from "components/PDFViewer";
import TextareaAutosize from "react-textarea-autosize";
import InfoIcon from "@mui/icons-material/Info";


import {
  Grid,
  Button,
  MenuItem,
  TextField,
  Typography,
  TableContainer,
  Table,
  TableHead,
  TableCell,
  TableBody,
  TableRow,
  IconButton,
  Link,
  TableFooter,
  CardContent,
  Box,
  CircularProgress,
} from "@mui/material";
import {
  ButtonUnstyled,
} from "@mui/base";
import {useTranslation} from "react-i18next";
import {updateLocalFile,
  updateDownloadedFile,
  removeLocalFilesAction,
  markFilesForRemoval,
  onAddOrRemoveStudy,
} from "store/features/newRequestSlice";
import {useIsInViewport} from "pages/Wizard/containers/utils";
import {onViewChange, isFormVisible} from "pages/Wizard/wizardSlice";
import {UseAppSelector} from "store/hooks";
import {useLocation} from "react-router-dom";
// import {useTheme} from "@mui/styles";

const UploadDocument = (props) => {
  const {downloadedFiles, allFiles, files} = props;
  const {t} = useTranslation();
  const params=useLocation();
  const isCopy=params?.pathname?.includes("copy_request");

  const [inputRef] = useState<any>(React.createRef());
  const isVisibleValues = UseAppSelector(isFormVisible);

  const dispatch = useDispatch();
  const handleFileRemove = (fileToRemove) => {
    dispatch(removeLocalFilesAction(allFiles.filter((file) => file !== fileToRemove)));
    inputRef.current.value = "";
  };

  // API request for deleting a file
  const handleFileRemoveAPI = (fileToRemove) => {
    dispatch(markFilesForRemoval(fileToRemove));
    handleFileRemove(fileToRemove);
  };

  const handleFileAdd = (theFiles) => {
    const renameFile = (originalFile) => {
      const newName = (downloadedFiles.some((el) => el.name === originalFile.name.replace(".pdf", " - Copy.pdf"))) ?
      originalFile.name.replace(".pdf", " - Copy - Copy.pdf") :
      originalFile.name.replace(".pdf", " - Copy.pdf");
      return new File([originalFile], newName, {
        type: originalFile.type,
        lastModified: originalFile.lastModified,
      });
    };

    const renameLocalFile = (originalFile) => {
      const newName = (allFiles.some((el) => el.name === originalFile.name.replace(".pdf", " - Copy.pdf"))) ?
      originalFile.name.replace(".pdf", " - Copy - Copy.pdf") :
      originalFile.name.replace(".pdf", " - Copy.pdf");
      return new File([originalFile], newName, {
        type: originalFile.type,
        lastModified: originalFile.lastModified,
      });
    };

    for (let i = 0; i < theFiles.length; i++ ) {
      const found = downloadedFiles.some((el) => el.name === theFiles[i].name);
      if (found) {
        theFiles.splice(i, 1, renameFile(theFiles[i]));
      }
    }

    for (let i = 0; i < theFiles.length; i++ ) {
      const found = allFiles.some((el) => el.name === theFiles[i].name);
      if (found) {
        theFiles.splice(i, 1, renameLocalFile(theFiles[i]));
      }
    }

    if (theFiles && theFiles.map !== undefined && Array.isArray(theFiles)) {
      const nonStudyFiles=theFiles.map((e)=> Object.assign(e, {uploadtype: "patient"}));
      dispatch(uploadAndParseLocalFiles(Object.values(nonStudyFiles)));
    }
  };


  const handleAddStudyLinkFromFile = (fileMetaDataToAddAsLink) => {
    dispatch(markFilesForRemoval(fileMetaDataToAddAsLink));
    handleFileRemove(fileMetaDataToAddAsLink);

    // add the link
    dispatch(onAddOrRemoveStudy({
      pmid: fileMetaDataToAddAsLink?.pmid,
      doi: fileMetaDataToAddAsLink?.doi,
      title: fileMetaDataToAddAsLink?.title,
      link: fileMetaDataToAddAsLink?.link,
      pubtype: fileMetaDataToAddAsLink?.pubtype,
      authors: fileMetaDataToAddAsLink?.authors,
    }));
  };

  useEffect(() => {
  }, [allFiles, downloadedFiles]);

  const verifyIfDownloadedFileIsRemoved=(file)=>{
    return props.filesMarkedForRemoval.filter(
        (markedFile)=>Object.values(markedFile)?.includes(file.fileURL)).length>0;
  };
  const handleDropzoneClick = () => inputRef.current.click();

  const carouselView = (file) => {
    const pdfFile=file.fileURL?[file]:[
      {fileURL: window.URL.createObjectURL(
          new Blob(
              [file],
              {type: "application/pdf"},
          ),
      ),
      name: file.name},
    ];
    setCarouselPDFs(pdfFile);
    setOverlay(true);
  };

  const [isOpen, setOverlay] = useState(false);
  const [carouselPDFs, setCarouselPDFs] = useState<any>([]);
  const closeOverlay = () => setOverlay(false);
  // const theme = useTheme<any>();
  const displayDocument=(file, idx, handleFileRemove, updateFile)=>{
    return (
      <Grid key={idx} container className="pt-2" alignItems='center'>
        <Grid item sm={1} className="pt-2"><Button color="info" onClick={() => carouselView(file)}><AiOutlineEye size={28} /></Button></Grid>
        <Grid item xs={10} className="pt-2"><Typography >{file.name}</Typography></Grid>
        <Grid item xs={1} className="pt-2"><Button color="info" onClick={() => handleFileRemove(file)} data-cy={"delete-upload-" + idx} ><FaTrash /></Button></Grid>
        <Grid item xs={12} className="pt-2">
          <TextField
            margin="dense"
            select
            id={`select_document_type_${idx}`}
            label={t("pubmed.label.type")}
            inputProps={{id: `select_document_type_input_${idx}`}}
            fullWidth
            value={file.uploadtype}
            onChange= {(e) => dispatch(updateFile({idx: idx, uploadtype: e.target.value}))}>
            <MenuItem data-cy={"select_option_patient"}
              key={"upload.document.patient"} value={"patient"}>
              {t("upload.document.patient")}
            </MenuItem>
            <MenuItem data-cy={"select_option_case"}
              key={"upload.document.case"} value={"case"}>
              {t("upload.document.case")}
            </MenuItem>
            <MenuItem data-cy={"select_option_study"}
              key={"upload.document.study"} value={"study"}>
              {t("upload.document.study")}
            </MenuItem>
          </TextField>
        </Grid>
        <Grid item xs={12} className="pt-2" data-cy={`uploaded_file_remarks_${idx}`}>
          <TextareaAutosize
            style={{
              width: "100%",
              textAlign: "justify"?"justify":"left",
              textJustify: "justify"?"inter-word":undefined,
            }}
            className="form-control"
            defaultValue={file.remarks}
            onChange= {(e) => dispatch(updateFile({idx: idx, remarks: e.target.value}))}
            minRows={3}
            placeholder={t("upload.document.remarks")}
          />
        </Grid>
        <Grid item sm={1} xs={0}></Grid>
        <Grid item xs={12} className="pt-2"><hr/></Grid>
      </Grid>
    );
  };
  const isVisible=useIsInViewport(props.refProps);
  useEffect(()=>{
    isVisibleValues.uploadDocument!==isVisible&&dispatch(onViewChange({field: "uploadDocument", value: isVisible}));
  }, [isVisible]);

  return <Grid container ref={props.refProps} spacing={3} id="uploadDocument">
    <Grid item container direction="row" justifyContent="space-between" spacing={2} xs={12}>
      <Grid item xs={12}>
        <Typography variant="h5" >
          {t(props.label)}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography >{t("upload.other.text")}</Typography>
      </Grid>
      <Grid item container justifyContent='center' xs={12}>
        <Grid item container zIndex={10000}>
          <Overlay configs={{animate: true}} isOpen={isOpen} closeOverlay={closeOverlay}>
            {Array.isArray(
                carouselPDFs)&& carouselPDFs.map((file:any, idx) =>
              <PDFViewer key={idx} src={file.fileURL}/>)}
          </Overlay>
        </Grid>
        <ButtonUnstyled
          data-cy="dropzone"
          style={{"borderWidth": "0px",
            "backgroundColor": "white"}}

          onClick={handleDropzoneClick}
        >
          <input ref={inputRef} hidden={true}
            type="file"
            id="file"
            data-cy="dropzone_input"
            accept=".pdf"
            name="file"
            onChange={(e:any) => handleFileAdd([...e.target.files])}
          />
          <Grid container spacing={3} padding={3} justifyContent="center" >
            <Grid item xs={12}>
              <FaUpload size='60px' color="#2f353a"/>
            </Grid>
            <Grid item xs={12}>
              <Typography >{t("upload.warning")}</Typography>
            </Grid>

          </Grid>
        </ButtonUnstyled >
      </Grid>
      <Grid item container xs={12} justifyContent='center'>
        <CardContent>
          <Box hidden={!props.documentparsing} justifyContent="center" alignItems="center" display="flex">
            <CircularProgress color="info" size={50} />
          </Box>
          <TableContainer>
            <Table sx={{tableLayout: "fixed"}} hidden={(files?.length === 0) || (!files?.[0]?.pmid) || (files?.[0]?.pmid?.length === 0) || props.documentparsing}>
              <TableHead>
                <TableRow>
                  <TableCell colSpan={2} align="left">
                    {t("pubmed.label.title")}
                  </TableCell>
                  <TableCell align="left">
                    {t("pubmed.label.type")}
                  </TableCell>
                  <TableCell align="left">
                    {t("pubmed.label.link")}
                  </TableCell>
                  <TableCell align="left">
                    {t("pubmed.label.use")}
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow className="pt-3 pb-3" key={1}>
                  <TableCell colSpan={2} align="left" className="col-9"><span>{files?.[0]?.title}</span></TableCell>
                  <TableCell align="left"><span>{files?.[0]?.pubtype?.toString()}</span></TableCell>
                  <TableCell align="left">
                    <Link color="#0288d1" href={`https://pubmed.ncbi.nlm.nih.gov/${files?.[0]?.pmid}`} target="_blank" underline="none">
                      <InsertLink/>
                    </Link>
                  </TableCell>
                  <TableCell align="left">
                    <IconButton
                      data-cy="btParseAddAsStudy"
                      color="info"
                      onClick={() => handleAddStudyLinkFromFile(files?.[0])}
                    >
                      <PostAdd />
                    </IconButton>
                  </TableCell>
                </TableRow>
              </TableBody>
              <TableFooter>
                <TableRow>
                  <TableCell colSpan={5} align="left">
                    <div style={{display: "flex"}}>
                      <InfoIcon color="warning"/>&nbsp;
                      <Typography sx={{FontWeight: "bold"}} >
                        {t("pubmed.label.foundstudy")}
                      </Typography>
                    </div>
                  </TableCell>
                </TableRow>
              </TableFooter>
            </Table>
          </TableContainer>
        </CardContent>
      </Grid>
      <Grid item container>

        {allFiles[0] && allFiles.length > 0 ? allFiles.map((file, idx) => {
          return displayDocument(file, idx, handleFileRemove, updateLocalFile);
        }) : null}

        {/* List from BE (EDIT Case) */}
        {!isCopy&&downloadedFiles[0] && downloadedFiles.length > 0 ?
         downloadedFiles.filter((file)=>
           !verifyIfDownloadedFileIsRemoved(file)).map((file, idx) => {
           return displayDocument(file, idx, handleFileRemoveAPI, updateDownloadedFile);
         }) : null}

      </Grid>
    </Grid>
  </Grid>;
};


const mapStateToProps = (state) => ({
  downloadedFiles: state.singleRequest.downloadedFiles,
  files: state.singleRequest.localFiles?.filter((e)=>e.pmid ),
  documentparsing: state.loading.documentparsing === true,
  allFiles: state.singleRequest.localFiles,
  filesMarkedForRemoval: state.singleRequest.filesMarkedForRemoval,
});

UploadDocument.propTypes = {
  uploadLocalFiles: PropTypes.func.isRequired,
};

export default connect(mapStateToProps, {uploadLocalFiles})(UploadDocument);
