import React, {useEffect, useState} from "react";
import {connect, ConnectedProps} from "react-redux";

import {RootState, studyRowType} from "store/rootstate";
import {UseAppDispatch} from "store/hooks";
import {useTranslation} from "react-i18next";
import Overlay from "react-overlay-component";
import PDFViewer from "components/PDFViewer";
import TextareaAutosize from "react-textarea-autosize";
import {
  useParams,
} from "react-router-dom";
import {
  fetchPubmedAbstract,
} from "store/middleware/masterdataThunk";
// @ts-ignore
import jwtDecode from "jwt-decode";
import {
  Card,
  CardContent,
  CardActions,
  Grid,
  Typography,
  Button,
  Table,
  TableBody,
  TableRow,
  TableCell,
  IconButton,
} from "@mui/material";
import {
  AiOutlineEye,
} from "components/icons";
import {
  addCommentForStudies, uploadFiles,
} from "../../store/middleware/uploadThunk";
import {
  markStudiesForRemoval,
  onAddOrRemoveStudy,
} from "store/features/newRequestSlice";
import {
  processFilesAndPubmed,
  getAvailableDocuments,
  getAvailableStudies,
} from "store/middleware/newRequestThunk";
import {FaUpload, FaTrash} from "react-icons/fa";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";

const getRequestDataFromToken=()=>{
  const {token} = useParams<{token:string}>();
  let decodedTokenResult;

  try {
    decodedTokenResult = jwtDecode(token);
  } catch (ex) {
    console.error("[JWT] [DECODE ERROR]", ex, token);
    decodedTokenResult = {
      request_id: 0,
      requested: false,
    };
  }
  return {token, decodedToken: decodedTokenResult};
};
import Backdrop from "components/Backdrop";
import PubMedComponent from "components/PubMed";
import {PubMedCard} from "components/PubMedCard";
const PharmaResponseJWT=({
  currentStudy,
  abstract,
  documentSettings,
  files,
}:Props) => {
  const tableCellStyle={"overflow": "auto", "overflowWrap": "break-word"};

  const {token, decodedToken}=getRequestDataFromToken();
  const {request_id: requestId}=decodedToken;
  const dispatch = UseAppDispatch();
  useEffect(() => {
    Promise.all([
      dispatch(getAvailableStudies(requestId, token)),
      dispatch(getAvailableDocuments(requestId, token)),
    ]);
  }, [requestId]);
  const [comment, setComment] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isFeedback, setIsFeedback] = useState(false);
  const [isOpen, setOverlay] = useState(false);
  const [carouselPDFs, setCarouselPDFs] = useState([]);
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [inputRef] = useState<any>(React.createRef());
  const handleDropzoneClick = () => inputRef.current.click();


  const closeOverlay = () => setOverlay(false);

  const handleAddFile=(value)=>setUploadedFiles(uploadedFiles.concat(value));
  const handleFileRemove=(file)=> setUploadedFiles(uploadedFiles.filter((e:any) =>e.name !== file));

  const carouselView = (file:any) => {
    const pdfFile:any=file.fileURL?[file]:[
      {fileURL: window.URL.createObjectURL(
          new Blob(
              [file],
              {type: "application/pdf"},
          ),
      ),
      name: file.name},
    ];
    setCarouselPDFs(pdfFile);
    setOverlay(true);
  };

  const {t} = useTranslation();
  const handleFetchAbstract = (pmid:number) => {
    dispatch(fetchPubmedAbstract(pmid, token));
  };
  const handleRemoveStudy = (studyForRemoval:studyRowType) => {
    Boolean(studyForRemoval?.study_id)&&dispatch(markStudiesForRemoval(studyForRemoval.study_id));
    dispatch(onAddOrRemoveStudy(studyForRemoval));
  };

  const handlePharmaResponse = () => {
    setIsLoading(true);
    dispatch(processFilesAndPubmed(requestId, documentSettings, token))
        .then((promiseAllResponse:[any, any, any, any, any])=> {
          Boolean(promiseAllResponse?.[4]?.[0]?.newStudyId)&&
          dispatch(addCommentForStudies(t("pharma.added.study.from.mail"), token));

          comment.length>0&&
          dispatch(addCommentForStudies(comment, token));

          uploadedFiles.length>0&&
          dispatch(uploadFiles( uploadedFiles, token));
        },
        ).finally(()=>{
          setIsLoading(false);
          setIsFeedback(true);
        });
  };
  const study=files.filter((file)=>file.uploadtype==="study");
  return (isFeedback?
    <Grid container sx={{background: "white", height: "100%", padding: "20px"}} justifyContent='center'>
      <Typography >{t("pharma.response.feedback")}</Typography>
    </Grid>:
    <Grid container sx={{marginBottom: "1em"}} spacing={3}>
      <Backdrop open={isLoading}></Backdrop>
      <Grid item xs={12}>
        {Boolean(currentStudy?.pmid)&&<PubMedCard
          title={t("upload.title.current")}
          rowData={currentStudy}
          onRemoveStudy={(rowData:studyRowType)=>handleRemoveStudy(rowData)}
          // addedStudyId={addedStudyId}
          handleFetchAbstract={handleFetchAbstract}
          abstractContent={abstract.content?.[currentStudy?.pmid]}
          isLoading={abstract.isLoading}
        />}
      </Grid>


      <Grid item xs={12}>
        <Card sx={{paddingBottom: "16px"}}>
          <Grid item>
            <CardContent>
              <PubMedComponent JWTToken={token}/>
            </CardContent>
          </Grid>

        </Card>
        <Card>
          <Grid item xs={12} paddingY='25px'>
            <div className="Dropzone shadow" data-cy='ins_upload_dropzone' onClick={handleDropzoneClick}>
              <input
                ref={inputRef}
                hidden={true}
                type="file"
                id="file"
                accept=".pdf"
                multiple
                name="file"
                data-cy="dropzone_input"
                onChange={(e:any) => handleAddFile([...e.target.files])}

              />
              <span>{t("upload.instruction")}</span>
              <FaUpload />
            </div>
          </Grid>
          <Grid item xs={12} style={{borderRadius: "5px"}}>
            <Table>
              <TableBody>
                {uploadedFiles.length > 0 && uploadedFiles.map((file:any, idx) =>
                  <TableRow className="table table-borderless table-hover  mb-0 pb-0 d-flex align-items-center" key={idx}>
                    <TableCell style={{"verticalAlign": "middle"}} className="col-1"><PictureAsPdfIcon color="primary"/></TableCell>
                    <TableCell align="left" sx={tableCellStyle} className="col-7"><span >{file.name}</span></TableCell>
                    <TableCell align="right" style={{"verticalAlign": "middle"}} className="col-2"><span >{((file.size) / 1000000).toFixed(2)} MB</span></TableCell>
                    <TableCell align="right" className="col-2">
                      <IconButton color="primary" onClick={()=>handleFileRemove(file.name)}><FaTrash/></IconButton>
                    </TableCell>
                  </TableRow>,
                )}
              </TableBody>
            </Table>
          </Grid>
        </Card>
        <Card>
          <TextareaAutosize
            className="form-control"
            placeholder={t("textarea.placeholder")}
            minRows={3}
            value={comment}
            onChange = {(event)=>setComment(event.target.value)}
            data-cy="textareaaddcomment"
          />
        </Card>

        {study.length>0?
        <Card>
          <Table >
            <TableBody>
              <TableRow className="table table-borderless table-hover  mb-0 pb-0 d-flex align-items-center">
                <TableCell align="left"><Button color="info" onClick={() => carouselView(study[0])}><AiOutlineEye size={28} /></Button></TableCell>
                <TableCell align="left" sx={tableCellStyle} className="col-7 animated fadeIn"><span >{study[0]?.name}</span></TableCell>
                <TableCell align="right" sx={tableCellStyle} className="col-4 animated fadeIn">
                  <Typography style={{color: "#4dbd74"}}>{t("study.uplaoded")}</Typography>
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </Card>:null}
        <Card>
          <Grid item>
            <CardActions>
              <Grid sx={{textAlign: "center"}} item xs={12}>
                <Button
                  data-cy="pharmaAddPubMed"
                  variant="contained"
                  color="primary"
                  disabled={!Boolean(currentStudy?.pmid)}
                  onClick={handlePharmaResponse}>{t("pharma.response.submit")}</Button>
              </Grid>
            </CardActions>
          </Grid>
        </Card>
      </Grid>
      <Overlay configs={{animate: true}} isOpen={isOpen} closeOverlay={closeOverlay}>
        {Array.isArray(carouselPDFs) &&
      carouselPDFs.map((file:any, idx) =><PDFViewer key={idx} src={file.fileURL}/>)}
      </Overlay>
    </Grid>
  );
};


const mapStateToProps = (state:RootState) => ({
  currentStudy: state.singleRequest.pubmedStudy,
  abstract: {
    isLoading: Boolean(state.loading.pubmedAbstract),
    content: state.masterdata.pubMedAbstracts,
  },
  files: state.singleRequest.downloadedFiles,
  documentSettings: {
    studiesMarkedForRemoval: state.singleRequest.studiesMarkedForRemoval,
    filesMarkedForRemoval: state.singleRequest.filesMarkedForRemoval,
    localFiles: state.singleRequest.localFiles,
    pubmedStudy: state.singleRequest.pubmedStudy,
  },
});


const connector = connect(mapStateToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

interface Props extends PropsFromRedux {
}

// @ts-ignore
export default connector(PharmaResponseJWT);
