import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {RootState} from "store/rootstate";

const fieldNames = [
  // Options
  "options_isIv",
  "options_isIc",

  // Indications
  "indications_isMultiplesMyelom",
  "indications_untreatedAlAmyloidosis",
  "indications_otherIndicationToBeTreated",

  // Prescriptions
  "prescriptions_combinationTherapyBCD",
  "prescriptions_firstPrescription",
  "prescriptions_isTrainingCenter",

  // Therapy
  "therapy_isMonotherapy",
  "therapy_isCombinationTherapy",

  // Therapielinie if monotherapy
  "monotherapy_Patient_4",
  "monotherapy_Patient_1_3",

  // Therapielinies if combination therapy
  "combinationTherapy_Patient_1",
  "combinationTherapy_Patient_2",

  // Line 1
  "line1_notEligibleForAutologousStemCellTransplant",
  "line1_justification",
  "line1_bortezomibMelphalanPrednisone",
  "line1_lenalidomideDexamethasone",

  // Line 2
  "line2_nonRefractoryNonRelapsedCD38Treatment",

  // Medications
  "medications",

  // Darzalex Combination Selection
  "combination_lenalidomideDexamethasoneDRd",
  "combination_bortezomibDexamethasoneDVd",
  "combination_pomalidomideDexamethasoneDPd",
  "combination_carfilzomibDexamethasoneDKd",
  "combination_otherCombination",

  // Pomalidomid & Dexamethason (DPd)
  "combination_pomalidomid_noPriorPomalidomideTreatment",
  "combination_pomalidomid_priorPILenalidomideTherapyRefractory",
  "combination_pomalidomid_twoPriorTherapiesWithPILenalidomide",

  // Other Combination
  "combination_otherCombinationRemarks",

  // Comments on therapy
  "comments",

  // default stuff
  "preTreatment",
  "patientCondition",
  "prognosis",
  "reasonsAlternativeTherapiesFailed",

];

export interface DarzalexState {
  formData: {
    // default stuff
    preTreatment: string
    patientCondition: string,
    prognosis: string,
    reasonsAlternativeTherapiesFailed: string,

    // options
    options_isIv: boolean,
    options_isIc: boolean,

    // Indications
    indications_isMultiplesMyelom: boolean,
    indications_untreatedAlAmyloidosis: boolean,
    indications_otherIndicationToBeTreated: boolean,

    // Prescriptions
    prescriptions_combinationTherapyBCD: boolean,
    prescriptions_firstPrescription: boolean,
    prescriptions_isTrainingCenter: boolean,

    // Therapy
    therapy_isMonotherapy: boolean,
    therapy_isCombinationTherapy: boolean,

    // Therapielinie if monotherapy
    monotherapy_Patient_4: boolean,
    monotherapy_Patient_1_3: boolean,

    // Therapielinies if combination therapy
    combinationTherapy_Patient_1: boolean,
    combinationTherapy_Patient_2: boolean,

    // Line 1
    line1_notEligibleForAutologousStemCellTransplant: boolean,
    line1_justification: string,
    line1_bortezomibMelphalanPrednisone: boolean,
    line1_lenalidomideDexamethasone: boolean,

    // Line 2
    line2_nonRefractoryNonRelapsedCD38Treatment: boolean,

    // Darzalex Combination Selection
    combination_lenalidomideDexamethasoneDRd: boolean,
    combination_bortezomibDexamethasoneDVd: boolean,
    combination_pomalidomideDexamethasoneDPd: boolean,
    combination_carfilzomibDexamethasoneDKd: boolean,
    combination_otherCombination: boolean,

    // Pomalidomid & Dexamethason (DPd)
    combination_pomalidomid_noPriorPomalidomideTreatment: boolean,
    combination_pomalidomid_priorPILenalidomideTherapyRefractory: boolean,
    combination_pomalidomid_twoPriorTherapiesWithPILenalidomide: boolean,

    // Other Combination
    combination_otherCombinationRemarks: string,

    // Medications
    medications: any[],

    // Comments on therapy
    comments: string,

  }
}

const initialState:DarzalexState = {
  formData: {
  // default stuff
    preTreatment: "",
    patientCondition: "",
    prognosis: "",
    reasonsAlternativeTherapiesFailed: "",

    // options
    options_isIv: false,
    options_isIc: false,

    // Indications
    indications_isMultiplesMyelom: false,
    indications_untreatedAlAmyloidosis: false,
    indications_otherIndicationToBeTreated: false,

    // Prescriptions
    prescriptions_combinationTherapyBCD: false,
    prescriptions_firstPrescription: false,
    prescriptions_isTrainingCenter: false,

    // Therapy
    therapy_isMonotherapy: false,
    therapy_isCombinationTherapy: false,

    // Therapielinie if monotherapy
    monotherapy_Patient_4: false,
    monotherapy_Patient_1_3: false,

    // Therapielinies if combination therapy
    combinationTherapy_Patient_1: false,
    combinationTherapy_Patient_2: false,

    // Line 1
    line1_notEligibleForAutologousStemCellTransplant: false, // option
    line1_justification: "",
    line1_bortezomibMelphalanPrednisone: false,
    line1_lenalidomideDexamethasone: false,

    // Line 2
    line2_nonRefractoryNonRelapsedCD38Treatment: false,

    // Darzalex Combination Selection
    combination_lenalidomideDexamethasoneDRd: false, // option
    combination_bortezomibDexamethasoneDVd: false, // option
    combination_pomalidomideDexamethasoneDPd: false, // option
    combination_carfilzomibDexamethasoneDKd: false, // option
    combination_otherCombination: false, // option

    // Pomalidomid & Dexamethason (DPd)
    combination_pomalidomid_noPriorPomalidomideTreatment: false, // checkbox
    combination_pomalidomid_priorPILenalidomideTherapyRefractory: false, // option
    combination_pomalidomid_twoPriorTherapiesWithPILenalidomide: false, // option

    // Other Combination
    combination_otherCombinationRemarks: "",

    // Medications
    medications: [],

    // Comments on therapy
    comments: "",
  },
};

const DarzalexFormSlice = createSlice({
  name: "Darzalex",
  initialState,
  reducers: {
    onClearDarzalexState: (state)=>{
      state.formData = initialState.formData;
    },
    onReceiveSpecialMedDarzalexEditData: (state, {payload}: PayloadAction<any>) => {
      updatestate(payload, state, true);
      if (payload.formData) {
        state.formData.preTreatment =
          payload.formData?.preTreatment;
        state.formData.patientCondition =
          payload.formData?.patientCondition;
        state.formData.reasonsAlternativeTherapiesFailed =
          payload.formData?.reasonsAlternativeTherapiesFailed;
        state.formData.prognosis =
          payload.formData?.prognosis;
        state.formData.line1_justification =
          payload.formData?.line1_justification;
        state.formData.combination_otherCombinationRemarks =
          payload.formData?.combination_otherCombinationRemarks;
        state.formData.comments =
          payload.formData?.comments;
      }
    },
    onChangeDarzalexValue: (state, {payload}: PayloadAction<any>) => {
      console.debug("onChangeDarzalexValue", payload);
      if (payload.preTreatment || payload.preTreatment === "") state.formData.preTreatment = payload.preTreatment;
      if (payload.patientCondition || payload.patientCondition === "") state.formData.patientCondition = payload.patientCondition;
      if (payload.reasonsAlternativeTherapiesFailed || payload.reasonsAlternativeTherapiesFailed === "") state.formData.reasonsAlternativeTherapiesFailed = payload.reasonsAlternativeTherapiesFailed;
      if (payload.prognosis || payload.prognosis === "") state.formData.prognosis = payload.prognosis;

      if (payload.line1_justification || payload.line1_justification === "") state.formData.line1_justification = payload.line1_justification;
      if (payload.combination_otherCombinationRemarks || payload.combination_otherCombinationRemarks === "") state.formData.combination_otherCombinationRemarks = payload.combination_otherCombinationRemarks;

      updatestate(payload, state, false);
    },
    onAddMedication: (state, action) => {
      state.formData.medications.push(action.payload); // Add new medication
    },
    onRemoveMedication: (state, action) => {
      state.formData.medications =
        state.formData.medications.filter((_, index) => index !== action.payload); // Remove medication by index
    },
  },
});


export const {
  onReceiveSpecialMedDarzalexEditData,
  onClearDarzalexState,
  onChangeDarzalexValue,
  onAddMedication,
  onRemoveMedication,
} = DarzalexFormSlice.actions;

export default DarzalexFormSlice.reducer;
export const selectDarzalexValues = (state:RootState) =>
  state.wizard.medicine.additionalInformation.darzalexForm?.formData;


function updatestate(payload: any, state, isEdit: boolean) {
  const data = payload.formData;
  console.debug("data", JSON.stringify(data));
  if (!data) return;

  if (data.medications) {
    state.formData.medications = data.medications;
  }

  // Update specific options only if they are provided in data
  if ("options_isIv" in data) {
    state.formData.options_isIv = !!data.options_isIv;
    !isEdit && resetBelow(fieldNames, "options_isIv", data, state);
  }
  if ("options_isIc" in data) {
    state.formData.options_isIc = !!data.options_isIc && !data.options_isIv;
    !isEdit && resetBelow(fieldNames, "options_isIc", data, state);
  }

  // List of possible indications
  const indications = [
    "indications_isMultiplesMyelom",
    "indications_untreatedAlAmyloidosis",
    "indications_otherIndicationToBeTreated",
  ];
  // Loop through each indication and update the state only if present in data
  indications.forEach((key) => {
    if (key in data) {
      state.formData[key] = data[key] === true;
      !isEdit && resetBelow(fieldNames, key, data, state);
    }
  });

  // List of possible prescriptions
  const prescriptions = [
    "prescriptions_combinationTherapyBCD",
    "prescriptions_firstPrescription",
    "prescriptions_isTrainingCenter",
  ];
  // Update each prescription option only if present in data
  prescriptions.forEach((key) => {
    if (key in data) {
      state.formData[key] = data[key] === true;
      !isEdit && resetBelow(fieldNames, key, data, state);
    }
  });

  // Therapy options list
  const therapyOptions = [
    "therapy_isMonotherapy",
    "therapy_isCombinationTherapy",
  ];
  // Update each therapy option only if present in data
  therapyOptions.forEach((key) => {
    if (key in data) {
      state.formData[key] = data[key] === true;
      !isEdit && resetBelow(fieldNames, key, data, state);
    }
  });

  // Monotherapy options list
  const monotherapyOptions = [
    "monotherapy_Patient_4",
    "monotherapy_Patient_1_3",
  ];
  // Update each monotherapy option only if present in data
  monotherapyOptions.forEach((key) => {
    if (key in data) {
      state.formData[key] = data[key] === true;
      !isEdit && resetBelow(fieldNames, key, data, state);
    }
  });

  // Combination therapy options list
  const combinationTherapyOptions = [
    "combinationTherapy_Patient_1",
    "combinationTherapy_Patient_2",
  ];
  // Update each combination therapy option only if present in data
  combinationTherapyOptions.forEach((key) => {
    if (key in data) {
      state.formData[key] = data[key] === true;
      !isEdit && resetBelow(fieldNames, key, data, state);
    }
  });

  // Update checkbox and text field directly only if present in data
  if ("line1_notEligibleForAutologousStemCellTransplant" in data) {
    state.formData.line1_notEligibleForAutologousStemCellTransplant = data.line1_notEligibleForAutologousStemCellTransplant;
    !isEdit && resetBelow(fieldNames, "line1_notEligibleForAutologousStemCellTransplant", data, state);
  }
  if ("line1_justification" in data) {
    state.formData.line1_justification = data.line1_justification;
    !isEdit && resetBelow(fieldNames, "line1_justification", data, state);
  }

  // Radio options for Line 1
  const line1Options = [
    "line1_bortezomibMelphalanPrednisone",
    "line1_lenalidomideDexamethasone",
  ];
  // Update each line1 option only if present in data
  line1Options.forEach((key) => {
    if (key in data) {
      state.formData[key] = data[key] === true;
      !isEdit && resetBelow(fieldNames, key, data, state);
    }
  });


  // Combination therapy options list
  const combinationOptions = [
    "combination_lenalidomideDexamethasoneDRd",
    "combination_bortezomibDexamethasoneDVd",
    "combination_pomalidomideDexamethasoneDPd",
    "combination_carfilzomibDexamethasoneDKd",
    "combination_otherCombination",
  ];

  // Update only fields provided in data
  combinationOptions.forEach((key) => {
    if (key in data) {
      state.formData[key] = data[key] === true;
      !isEdit && resetBelow(fieldNames, key, data, state);
    }
  });

  // DPd therapy fields list
  const dpdFields = [
    "combination_pomalidomid_noPriorPomalidomideTreatment",
    "combination_pomalidomid_priorPILenalidomideTherapyRefractory",
    "combination_pomalidomid_twoPriorTherapiesWithPILenalidomide",
  ];

  // Update only fields provided in data
  dpdFields.forEach((key) => {
    if (key in data) {
      state.formData[key] = data[key] === true;
      !isEdit && resetBelow(fieldNames, key, data, state);
    }
  });

  // Update only fields provided in data
  if ("comments" in data) {
    state.formData.comments = data.comments;
  }

  // Update only fields provided in data
  if ("combination_otherCombinationRemarks" in data) {
    state.formData.combination_otherCombinationRemarks = data.combination_otherCombinationRemarks;
  }

  // Update only fields provided in data
  if ("line2_nonRefractoryNonRelapsedCD38Treatment" in data) {
    state.formData.line2_nonRefractoryNonRelapsedCD38Treatment = data.line2_nonRefractoryNonRelapsedCD38Treatment === true;
    !isEdit && resetBelow(fieldNames, "line2_nonRefractoryNonRelapsedCD38Treatment", data, state);
  }
}

function resetBelow(fieldNames, changedField, data, state) {
  let reset = false;

  fieldNames.forEach((field) => {
    if (reset) {
      // Reset boolean fields to false
      if (typeof state.formData[field] === "boolean") {
        state.formData[field] = false;
      }
      // Reset string fields to empty string
      if (typeof state.formData[field] === "string") {
        state.formData[field] = "";
      }
      // Reset fields that can be null
      if (state.formData[field] === null || typeof state.formData[field] === "undefined") {
        state.formData[field] = null;
      }
      // Rest array fields to empty array
      if (Array.isArray(state.formData[field])) {
        state.formData[field] = [];
      }
    }

    // If this is the changed field, start resetting subsequent fields
    if (field === changedField) {
      reset = true;

      // Apply the change for the current field
      state.formData[field] = data[field];
    }
  });
}
