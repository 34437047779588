/* eslint-disable react/jsx-key */
import React from "react";
import {connect} from "react-redux";
import {
  Grid,
  TextField,
  Autocomplete,
  CircularProgress,
} from "@mui/material";
import {useTranslation} from "react-i18next";
import {UseAppDispatch, UseAppSelector} from "store/hooks";
import {
  selectCombinedDrugOptions,
} from "store/features/masterdataSlice";
import {fetchBagDataCombinedDrug} from "store/middleware/masterdataThunk";
import {delay} from "components/formUtils";
import {selectLoadingState} from "store/features/loadingSlice";

const DrugPlanForm = ({onChange, value, label}) => {
  const {t, i18n} = useTranslation();
  const dispatch = UseAppDispatch();

  const currentLang = i18n.language.toUpperCase();
  const requiredDrugs = UseAppSelector(selectCombinedDrugOptions);
  const loading = UseAppSelector(selectLoadingState);

  const handleInputChange = (event, newInputValue) => {
    if (newInputValue.length < 3) return;
    if (event?.type === "change") {
      delay(() => {
        dispatch(fetchBagDataCombinedDrug(newInputValue, currentLang));
      }, 1000);
    }
  };

  const handleOptionLabel = (option) => {
    if (option?.article) {
      let substances = option.substances?.map((substance) =>
        currentLang === "FR" ? substance.NAMF : substance.NAMD,
      ).join(" ") || "";

      if (substances.length > 30) substances += " ...";
      if (substances.length > 0) {
        console.debug("substances", substances);
        return `${option.article} \u200C (${substances})`;
      } else {
        return `${option.article}`;
      }
    }
    return option.article || option;
  };

  const handleAutocompleteChange = (e, v) => {
    console.debug("DrugPlanForm: handleAutocompleteChange", v);
    onChange(v?.article || v);
  };

  return (
    <Grid container direction="column" spacing={3} id="dosis">
      <Grid item xs={12} data-cy={label}>
        <Autocomplete
          fullWidth
          disableClearable
          options={
            requiredDrugs ?
              requiredDrugs.filter(
                  (drug, index, self) =>
                    index === self.findIndex((d) => d.article === drug.article),
              ) :
              []
          }
          value={value}
          getOptionLabel={handleOptionLabel}
          onInputChange={handleInputChange}
          autoSelect
          freeSolo
          onChange={handleAutocompleteChange}
          renderInput={(params) => (
            <TextField
              margin="normal"
              {...params}
              variant="outlined"
              label={t(label)}
              placeholder={t(label)}
              InputProps={{
                ...params.InputProps,
                readOnly: loading.bagCombined,
                endAdornment: (
                  <>
                    {loading?.bagCombined && (
                      <CircularProgress color="inherit" size={20} />
                    )}
                    {params.InputProps.endAdornment}
                  </>
                ),
              }}
            />
          )}
        />
      </Grid>
    </Grid>
  );
};

export default connect(null, null)(DrugPlanForm);
