import React from "react";

import {Select, FormControl, InputLabel, MenuItem, FormHelperText} from "@mui/material";
import {useTranslation} from "react-i18next";

type variant="standard"|"outlined"|"filled";

type SelectDropdownProps={
    options:Array<any>,
    label:string,
    input:any,
    hidden:boolean,
    touched:boolean,
    handleChange:Function,
    variant:variant,
    defaultValue:any,
    renderOption:Function,
    multiple:boolean,
    checkboxes:boolean,
    meta: {error:string,
      visited:boolean}}

export const SelectDropdown=({
  options,
  label,
  input,
  hidden,
  touched,
  handleChange,
  variant,
  defaultValue,
  renderOption,
  multiple,
  checkboxes,
  meta: {error,
    visited}}:SelectDropdownProps) => {
  const {t}=useTranslation();
  const dropdownOptions=options?.map((e, i)=>
    <MenuItem key={i} value={e.value?e.value:e}>
      {e.label?e.label:e}
    </MenuItem>);

  return <div
    hidden={hidden}
  >
    <FormControl data-cy={input.name} fullWidth variant={variant?variant:"standard" } size="small" error={(touched||visited)&&error? true:false}>
      <InputLabel>{label}</InputLabel>
      <Select
        multiple={multiple}
        MenuProps={{
          disableScrollLock: true,
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "left",
          },
          transformOrigin: {
            vertical: "top",
            horizontal: "left",
          },
          sx: {maxHeight: "50%"},
        }}
        labelId={label}
        // renderValue={(selected) => checkboxes?selected.join(", "):selected}
        value={multiple?input.value.split(","):input.value}
        label={label}
        onChange={typeof handleChange==="function"?(e)=>handleChange(e.target.value): (event, value)=>input.onChange(event.target.value)}
      >
        {dropdownOptions}
      </Select>
      {(touched||visited)&&error?<FormHelperText>{t(error)}</FormHelperText>:null}
    </FormControl>
  </div>;
};
