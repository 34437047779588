import React from "react";
import {
  IconButton,
  styled,
  IconButtonProps,
} from "@mui/material";
interface ExpandMoreProps extends IconButtonProps {
    expand: boolean;
  }

export const ExpandMore = styled((props: ExpandMoreProps) => {
  // eslint-disable-next-line no-unused-vars
  const {expand, ...other} = props;
  return <IconButton {...other} />;
})(({theme, expand}) => ({
  transform: !expand ? "rotate(0deg)" : "rotate(180deg)",
  marginLeft: "auto",
  transition: theme.transitions.create("transform", {
    duration: theme.transitions.duration.shortest,
  }),
}));
