import React from "react";
import {IMaskInput} from "react-imask";

interface CustomProps {
    onChange: (event: { target: { name: string; value: string } }) => void;
    name: string;
  }
export const InsuranceNoComponent = React.forwardRef<HTMLElement, CustomProps>(
    function InsuranceNoComponent(props, ref:any) {
      const {onChange, ...other} = props;
      return (
        <IMaskInput
          {...other}
          mask="000.0000.0000.00"
          inputRef={ref}
          definitions={{
            "#": /[1-9]/,
          }}
          onAccept={(value: any) => onChange({target: {name: props.name, value}})}
          overwrite
        />
      );
    },
);
