// Mantelzelllymphom
import React, {useEffect} from "react";
import {connect} from "react-redux";
import {useTranslation} from "react-i18next";
import {Grid, Typography} from "@mui/material";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import Checkbox from "@mui/material/Checkbox";
import Box from "@mui/material/Box";
import FormLabel from "@mui/material/FormLabel";
import {UseAppDispatch, UseAppSelector} from "store/hooks";
import {onChangeImbruvicaValue, selectImbruvicaValues} from "./ImbruvicaSlice";
import {onChangeKVVLegalState, onChangeMedicineValue} from "../../../medicine/medicineSlice";
import {onChangeTreatmentValue} from "../../../Treatment/treatmentPlanSlice";
import {removeStudy} from "pages/Wizard/wizardSlice";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import WarningIcon from "@mui/icons-material/Warning";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";


const imbruvicaForm = (props) => {
  const {t}=useTranslation();
  const dispatch = UseAppDispatch();
  const imbruvicaValues=UseAppSelector(selectImbruvicaValues);
  const handleTreatmentValue = (field:string, value:any)=>dispatch(onChangeTreatmentValue({field, value}));

  useEffect(() => {
    switch (imbruvicaValues.formMCL.variant) {
      case 0:
        handleTreatmentValue("limitatio_approval", false);
        handleTreatmentValue("specialist_info", false);
        dispatch(onChangeKVVLegalState(
            {
              limitatio_approval: false,
              specialist_info: false,
            },
        ));
        dispatch(onChangeMedicineValue({field: "kn_id", value: ""}));
        dispatch(removeStudy({}));
        break;
      case 1:
        dispatch(onChangeMedicineValue({field: "kn_id", value: ""}));
        dispatch(removeStudy({}));
        handleTreatmentValue("limitatio_approval", false);
        handleTreatmentValue("specialist_info", false);
        dispatch(onChangeKVVLegalState(
            {
              limitatio_approval: false,
              specialist_info: false,
            },
        ));
        break;
      case 2:
        dispatch(removeStudy({}));
        dispatch(onChangeMedicineValue({field: "kn_id", value: "21011.07"}));
        handleTreatmentValue("limitatio_approval", true);
        handleTreatmentValue("specialist_info", true);
        dispatch(onChangeKVVLegalState(
            {
              limitatio_approval: true,
              specialist_info: true,
            },
        ));
        break;
      case 3:
        dispatch(onChangeMedicineValue({field: "kn_id", value: ""}));
        dispatch(removeStudy({}));
        handleTreatmentValue("limitatio_approval", false);
        handleTreatmentValue("specialist_info", false);
        dispatch(onChangeKVVLegalState(
            {
              limitatio_approval: false,
              specialist_info: false,
            },
        ));
        break;
      case 4:
        dispatch(onChangeMedicineValue({field: "kn_id", value: ""}));
        dispatch(removeStudy({}));
        handleTreatmentValue("limitatio_approval", false);
        handleTreatmentValue("specialist_info", false);
        dispatch(onChangeKVVLegalState(
            {
              limitatio_approval: false,
              specialist_info: false,
            },
        ));
        break;
    }
  }, [imbruvicaValues.formMCL.variant]);


  return <>
    <Grid item xs={12}>
      <FormControl fullWidth>
        <RadioGroup
          hidden={false}
          aria-labelledby="imbruvica-radio-buttons-group-monotherapie"
          name="imbruvica-radio-buttons-group-monotherapie"
          onChange={(event, value) => {
            if (value == "mono") {
              dispatch(onChangeImbruvicaValue({
                formMCL: {monotherapie: true},
              }));
              dispatch(onChangeImbruvicaValue({
                formMCL: {combotherapie: false},
              }));
            } else if (value == "kombi") {
              dispatch(onChangeImbruvicaValue({
                formMCL: {combotherapie: true},
              }));
              dispatch(onChangeImbruvicaValue({
                formMCL: {monotherapie: false},
              }));
            }
          }
          }
        >
          <FormControlLabel
            data-cy="monotherapie" value="mono" control={
              <Radio checked={imbruvicaValues.formMCL.monotherapie} />} label={t("imbruvica.mcl.monotherapie")} />
          <FormControlLabel
            data-cy="combotherapie" value="kombi" control={
              <Radio checked={imbruvicaValues.formMCL.combotherapie} />} label={t("imbruvica.mcl.combotherapie")} />
        </RadioGroup>
      </FormControl>


      <FormControl
        hidden={ !(imbruvicaValues.formMCL.monotherapie === true)}
        fullWidth
      >
        <RadioGroup

          aria-labelledby="imbruvica-radio-buttons-group-l1l2"
          defaultValue="default"
          name="imbruvica-radio-buttons-group-l1l2"
          onChange={(event, value) => {
            if (value == "L1") {
              dispatch(onChangeImbruvicaValue({
                formMCL: {L2: false},
              }));
              dispatch(onChangeImbruvicaValue({
                formMCL: {L1: true},
              }));
            } else if (value == "L2") {
              dispatch(onChangeImbruvicaValue({
                formMCL: {L2: true},
              }));
              dispatch(onChangeImbruvicaValue({
                formMCL: {L1: false},
              }));
            }
          }
          }
        >
          <FormControlLabel data-cy="L1" value="L1" control={
            <Radio checked={imbruvicaValues.formMCL.L1}/>} label={t("imbruvica.mcl.l1")} />
          <FormControlLabel data-cy="L2" value="L2" control={
            <Radio checked={imbruvicaValues.formMCL.L2} />} label={t("imbruvica.mcl.l2")} />
        </RadioGroup>
      </FormControl>


      <FormControl fullWidth>
        <FormControlLabel
          hidden={
            imbruvicaValues.formMCL.monotherapie === true
          }
          value="combiRituximab"
          control=
            {
              <Checkbox
                checked={imbruvicaValues.formMCL.combiRituximab}
                data-cy="combiRituximab"
                onChange={(event, checked) =>
                  dispatch(onChangeImbruvicaValue({
                    formMCL: {combiRituximab: checked},
                  }))
                }
              />
            }
          label={t("imbruvica.combiRituximab")} />
      </FormControl>
    </Grid>

    <Grid item xs={12}
      hidden={
        (imbruvicaValues.formMCL.variant !== 1) &&
        (imbruvicaValues.formMCL.variant !== 2) &&
        (imbruvicaValues.formMCL.variant !== 3) &&
        (imbruvicaValues.formMCL.variant !== 4)
      }
    >
      <Stack
        direction="row"
        alignItems="left"
        gap={1}
      >
        <WarningIcon color={"primary"}
        />

        <Typography hidden={imbruvicaValues.formMCL.variant !== 1}>{t("imbruvica.mcl.text.1")}</Typography>
        <Typography hidden={imbruvicaValues.formMCL.variant !== 2}>{t("imbruvica.mcl.text.2")}</Typography>
        <Typography hidden={imbruvicaValues.formMCL.variant !== 3}>{t("imbruvica.mcl.text.3")}</Typography>
        <Typography hidden={imbruvicaValues.formMCL.variant !== 4}>{t("imbruvica.mcl.text.4")}</Typography>
      </Stack>
    </Grid>
    <Grid item xs={12}
      hidden={imbruvicaValues.formMCL.L2 !== true}
    >
      <FormControl fullWidth>
        <FormControlLabel
          value="translokation"
          control=
            {
              <Checkbox
                checked={imbruvicaValues.formMCL.translokation}
                data-cy="translokation"
                onChange={(event, checked) =>
                  dispatch(onChangeImbruvicaValue({
                    formMCL: {translokation: checked},
                  }))
                }
              />
            }
          label={t("imbruvica.mcl.translokation")} />
      </FormControl>

      <FormControl fullWidth>
        <FormControlLabel
          value="partialResponseNotAchieved"
          control=
            {
              <Checkbox
                checked={imbruvicaValues.formMCL.partialResponseNotAchieved}
                data-cy="partialResponseNotAchieved"
                onChange={(event, checked) =>
                  dispatch(onChangeImbruvicaValue({
                    formMCL: {partialResponseNotAchieved: checked},
                  }))
                }
              />
            }
          label={t("imbruvica.mcl.partialResponseNotAchieved")} />
      </FormControl>

      <FormControl fullWidth>
        <FormControlLabel
          value="progressionAfterPreviousTherapy"
          control=
            {
              <Checkbox
                checked={imbruvicaValues.formMCL.progressionAfterPreviousTherapy}
                data-cy="progressionAfterPreviousTherapy"
                onChange={(event, checked) =>
                  dispatch(onChangeImbruvicaValue({
                    formMCL: {progressionAfterPreviousTherapy: checked},
                  }))
                }
              />
            }
          label={t("imbruvica.mcl.progressionAfterPreviousTherapy")} />
      </FormControl>
    </Grid>
    <Box sx={{p: 1}} />
    <Grid item xs={8} >
      <TextField
        fullWidth
        InputLabelProps={{shrink: true}}
        data-cy="imbruvica.mcl.textfield"
        label={t("imbruvica.mcl.textfield")}
        value={imbruvicaValues.formMCL?.textfield}
        multiline
        maxRows={4}
        minRows={4}
        variant="outlined"
        onChange = {(event ) =>
          dispatch(onChangeImbruvicaValue({
            formMCL: {textfield: (event.target as HTMLInputElement).value},
          }),
          )
        }
      />
    </Grid>


    <Grid item xs={12}>
      {
        imbruvicaValues.formMCL.variant !== 0 ?
        <FormLabel>
          {t("imbruvica.variant")}&nbsp;{imbruvicaValues.formMCL.variant}&nbsp;
        </FormLabel> :
        <></>
      }
    </Grid>
  </>;
};

const mapStateToProps=(state) => {
  return {
  };
};

const mapDispatchToProps=(dispatch) => ({

});

export default connect(mapStateToProps, mapDispatchToProps)(imbruvicaForm);


