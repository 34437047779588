import {Card,
  IconButton,
} from "@mui/material";
import React from "react";
import {Link} from "react-router-dom";
import makeStyles from "@mui/styles/makeStyles";
import CardHeader from "@mui/material/CardHeader";
export const HomeCardTile=(props)=>{
  const useStyle=makeStyles(()=>({
    card: {
      "display": "grid",
      "width": "100%",
      "height": "100%",
      "&:focus, &:hover, &:visited, &:link, &:active": {
        textDecoration: "none",
        color: "black",
      },
    },
    cardMedia: {
      "height": "100px",
    },
  }));
  const classes=useStyle();
  return (
    <Card className={classes.card} component={Link} to={props.to} >
      <CardHeader className={classes.cardMedia}
        avatar={<IconButton color="primary">{props.icon}</IconButton>}
        title={props.title}
      />
    </Card>
  );
};

