import React from "react";
import withStyles from "@mui/styles/withStyles";
import {
  Switch,
  FormLabel,
  Typography,
  Grid,
} from "@mui/material";
import {useTranslation} from "react-i18next";

const IOSSwitch=withStyles((theme) => ({
  root: {
    width: 42,
    height: 26,
    padding: 0,
    margin: theme.spacing(1),
  },
  switchBase: {
    "padding": 1,
    "&$checked": {
      "transform": "translateX(16px)",
      "color": theme.palette.common.white,
      "& + $track": {
        backgroundColor: "#56b472",
        opacity: 1,
        border: "none",
      },
    },
    "&$focusVisible $thumb": {
      color: "#4dbd74",
      border: "6px solid #fff",
    },
  },
  thumb: {
    width: 24,
    height: 24,
  },
  track: {
    borderRadius: 26/2,
    border: `1px solid ${theme.palette.grey[400]}`,
    backgroundColor: "#63c2de",
    opacity: 1,
    transition: theme.transitions.create(["backgroundColor", "border"]),
  },
  checked: {},
  focusVisible: {},
}))(({classes, ...props}) => {
  return (
    <Switch
      focusVisibleClassName={classes.focusVisible}
      disableRipple
      classes={{
        root: classes.root,
        switchBase: classes.switchBase,
        thumb: classes.thumb,
        track: classes.track,
        checked: classes.checked,
      }}
      {...props}
    />
  );
});

export const IosSwitch=({
  input,
  touched,
  options,
  clicked,
  checked,
  onChangeAction,
  disabled,
  name,
  required,
  label,
  additionalInfo,
  meta: {error}}) => {
  const {t}=useTranslation();

  return <div>
    <FormLabel
      component="legend" required={true}>{label}</FormLabel>
    <Typography component="span">
      <Grid component="label" container alignItems="center" spacing={1}>
        <Grid item>{options.n}</Grid>
        <Grid item>
          <IOSSwitch
            {...input}
            checked={Boolean(input.value)}
            disabled={disabled}
            id={name}
            data-cy={input.name}
            // onChange={onChangeAction}
            color="secondary"
          />
        </Grid>
        <Grid item>{options.y}</Grid>
      </Grid>
    </Typography>
    < textarea
      {...input}
      hidden={clicked!==false? true:false}
      className="form-control"
      value={additionalInfo}
      required={required}
      placeholder={label}
      rows='1'
    ></textarea>
    <label
      htmlFor="user-message"
      className="col-lg pl-0 text-danger"
    >
      {error&&touched? t(error):null}
    </label>
  </div >;
};
