import {
  get,
  postNoBearer,
  postFilesPharmaCompany,
} from "store/api";
import {displayToastSuccess, displayToastError} from "../features/errorSlice";
import i18n from "./../../i18n";

export const verifyToken=(token)=>(dispatch)=> {
  return get(`documents/jwt/isTokenValid/${token}`, "json", token).then((json) =>{
    if (json === false) {
      dispatch(displayToastError("jwtTokenExpired"));
    } else {
      dispatch(displayToastSuccess("jwtTokenOk"));
    }
    return json;
  }).catch((error) => {
    console.error("[JWT] [verifyToken]", error);
    return error;
  });
};

export const verifyNrOfFiles=(token)=>(dispatch)=> {
  return get(`documents/jwt/getNumberOfFilesLeftToUpload/${token}`, "json", token).then((json) =>{
    if (json && json.Count < 1 ) {
      dispatch(displayToastError("MAX_FILES_EXCEEDED"));
    }
    return json;
  }).catch((error) => {
    dispatch(displayToastError("verifyNrOfFilesError"));
    console.error("[JWT] [verifyNrOfFiles]", error);
    return error;
  });
};

export const addCommentForStudies=(comment, token, insuranceMessage)=>(dispatch)=> {
  return postNoBearer("communications/jwt", {message: comment}, token)
      .then((res) =>{
        if (res.status === 200 ) {
          !insuranceMessage&&dispatch(displayToastSuccess("COMMENT_SUCCESSFUL"));
        } else if (res.status === 404) {
          dispatch(displayToastError("COMMENT_UNSUCCESSFUL"));
        } else {
          dispatch(displayToastError("COMMENT_UNSUCCESSFUL"));
        }
      }).catch((error) => {
        dispatch(displayToastError("LINK_EXPIRED"));
        return error;
      });
};


export const getDocumentsMetaData=(token)=>(dispatch)=>{
  return get(`studies/jwt/token/${token}`, "json", token)
      .then((json)=>json)
      .catch((error)=>{
        dispatch(displayToastError("GET_DOCUMENTS_METADATA_UNSUCCESSFUL"));
        console.error("[JWT] [getDocumentsMetaData]", error);
        return error;
      });
};

export const getRequestStatus=(token)=>(dispatch)=>{
  return get(`studies/jwt/requestStatus/${token}`, "json", token).then((res)=>{
    return res;
  }).catch((error)=>{
    dispatch(displayToastError("GET_REQUEST_STATUS_UNSUCCESSFUL"));
    console.error("[JWT] [getRequestStatus]", error);
    return error;
  });
};

export const postStudyRating=(token, rating, studyId)=>(dispatch)=>{
  return postNoBearer("studies/jwt/rating", {rating: rating, studyId: studyId}, token)
      .then((res)=>{
        if (res.status !== 200) {
          dispatch(displayToastError("POST_RATING_ERROR"));
        } else {
          dispatch(displayToastSuccess("POST_RATING"));
        }
      })
      .catch((error)=>{
        dispatch(displayToastError("postStudiesRatingError"));
        return error;
      });
};

export const uploadFiles = (files, token)=>(dispatch)=> {
  const formData = new FormData();
  files.map(function(i) {
    return formData.append("file", i);
  });
  return postFilesPharmaCompany("documents/jwt/upload", formData, token)
      .then((json) =>{
        if (json !== 200) {
          dispatch(displayToastError("UPLOAD_ERROR"));
        } else {
          // dispatch(displayToastSuccess("FILES_SUCCESSFUL"));
          files.forEach((file)=>{
            dispatch(addCommentForStudies(`fileName:${file.name}`, token));
          });
        }
      }).catch((error) => {
        dispatch(displayToastError(error));
        console.error("[uploadFiles]", error);
        return error;
      });
};

export const acceptInsuranceRequest=(insuranceParams)=>(dispatch)=>
  postNoBearer("documents/jwt/insuranceAcceptance",
      {insuranceStatus: insuranceParams.status}, insuranceParams.token)
      .then((response)=> {
        const insuranceStatusText = ((status)=>{
          switch (status) {
            case "APPROVED":
              return i18n.t("Insurance approved");
            case "DECLINED":
              return i18n.t("Insurance declined");
            default:
              return "";
          }
        })(insuranceParams.status);
        response.status === 404&&dispatch(displayToastError("INSURANCE_CHANGE_FAILED"));
        if (response.status === 200) {
          dispatch(displayToastSuccess("INSURANCE_STATUS_CHANGED"));
          insuranceParams.comment.length>0 &&
           dispatch(addCommentForStudies(insuranceParams.comment, insuranceParams.token));
          dispatch(addCommentForStudies(insuranceStatusText, insuranceParams.token, true));

          insuranceParams.files &&
            dispatch(uploadFiles( insuranceParams.files, insuranceParams.token));

          insuranceParams.studyId &&
           dispatch(postStudyRating(
               insuranceParams.token,
               insuranceParams.rating,
               insuranceParams.studyId),
           );
        }
        return response.json();
      }).catch((error) => {
        dispatch(displayToastError("INSURANCE_CHANGE_FAILED"));
        return error;
      });

export const addComment=(params)=>(dispatch)=> {
  return postNoBearer("communications/jwt", {message: params.comment}, params.token)
      .then((res) =>{
        if (res.status === 200) {
          dispatch(displayToastSuccess("COMMENT_SUCCESSFUL"));
          params.files &&
           dispatch(uploadFiles( params.files, params.token));
        } else if (res.status === 404) {
          dispatch(displayToastError("COMMENT_UNSUCCESSFUL"));
        } else {
          dispatch(displayToastError("COMMENT_UNSUCCESSFUL"));
        }
        return res.status;
      }).catch((error) => {
        dispatch(displayToastError("COMMENT_UNSUCCESSFUL"));
        return error;
      });
};

