import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {RootState} from "store/rootstate";

export interface IDefaultMedicineState {
  formData:{
    pretreatment: string,
    patient_condition:string,
    disease_prognosis: string,
    therapy_alternatives: string,
  };
}
const initialState:IDefaultMedicineState = {
  formData: {
    pretreatment: "",
    patient_condition: "",
    disease_prognosis: "",
    therapy_alternatives: "",

  },
};

const defaultMedicineSlice = createSlice({
  name: "defaultMedicine",
  initialState,
  reducers: {
    onChangeMoreInformationValue: (state, {payload}:PayloadAction<{field:string;value:any;}>)=>{
      state.formData[payload.field] = payload.value;
    },
    onClearAdditionalInfoState: (state)=>{
      state.formData=initialState.formData;
    },
    onReceiveDefaultMedicineEditData: (state, {payload}:PayloadAction<any>)=>{
      state.formData ={...state.formData, ...payload};
    },
  },
});

export const {
  onClearAdditionalInfoState,
  onChangeMoreInformationValue,
  onReceiveDefaultMedicineEditData,
} = defaultMedicineSlice.actions;

export default defaultMedicineSlice.reducer;
export const selectMoreInformationValues = (state:RootState) =>
  state.wizard.medicine.additionalInformation.defaultMedicineForm.formData;
