import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Slide,
  TextField,
} from "@mui/material";
import React, {useState} from "react";
import {useDispatch} from "react-redux";
import {sendRequestToInsurance} from "store/middleware/requestListThunk";
import {useTranslation} from "react-i18next";

const Transition = React.forwardRef(function Transition(props:any, ref:any) {
  return <Slide direction="up" ref={ref} {...props} />;
});
interface PendingInsuranceDialogProps {
  isOpen:boolean,
  handleClose:any,
  requestId?:string,
  status?:string,
  requestData:any,
  handleSend:any,
}

const PendingInsuranceDialog = ({
  isOpen,
  handleClose,
  requestId,
  status,
  requestData,
  handleSend,
}:PendingInsuranceDialogProps) => {
  const {t, i18n}=useTranslation();
  const currentLang=i18n.language.toUpperCase();
  const dispatch=useDispatch();
  const [comment, setComment] = useState("");

  const handleRequestToInsurance=() => {
    handleSend?handleSend(comment):dispatch(sendRequestToInsurance(requestId, status === "PENDING_INSURANCE" || status === "PENDING_INSURANCE_RESEND" ? true: false, comment, currentLang));
    setComment("");
    handleClose();
  };

  const handleCloseDialog=()=> {
    setComment("");
    handleClose();
  };
  return (
    <Dialog
      open={isOpen}
      TransitionComponent={Transition}
      keepMounted
      onClose={handleCloseDialog}
      aria-labelledby="alert-dialog-slide-title"
      aria-describedby="alert-dialog-slide-description"
    >
      <DialogTitle>{t("insurance.dialog.action.title")}</DialogTitle>
      <DialogContent>
        <DialogContentText>
          <span>{t("insurance.dialog.action.content")}&nbsp;<br></br></span>
          <span><b>{requestData?.patient_insurance_company}</b></span>
          {requestData?.reha_data?.additional_insurance_company?
                <span>
                  <span><br></br>{t("insurance.dialog.action.content.additional")}<br></br></span>
                  <span><b>{requestData?.reha_data?.additional_insurance_company}</b></span>
                </span>:
                null}
        </DialogContentText>
        <TextField
          multiline
          // rows={2}
          inputProps={{"data-cy": "insuranceCommentField"}}
          autoFocus
          margin="dense"
          label={t("insurance.dialog.action.label")}
          type="text"
          fullWidth
          onChange={(e)=>{
            setComment(e.target.value);
          }}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleCloseDialog} color="primary" data-cy={"sendToInsuranceCancel"}>
          {t("insurance.dialog.action.cancel")}
        </Button>
        <Button onClick={handleRequestToInsurance} color="primary" data-cy={"sendToInsuranceConfirm"}>
          {t("insurance.dialog.action.confirm")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default PendingInsuranceDialog;
