/* istanbul ignore file */
import React from "react";
import {connect} from "react-redux";
import IDEAutocomplete from "./ideAutocomplete";

const AI=(props) => {
  return (
    <IDEAutocomplete/>
  );
};

const mapStateToProps=(state) => ({
  verified: state.userinformation.user?.groups?.includes("verified"),
  demo: state.userinformation.user?.groups?.includes("demo"),
});

const mapDispatchToProps=(dispatch) => ({
});
export default connect(mapStateToProps, mapDispatchToProps)(AI);
