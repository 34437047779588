import React, {useEffect} from "react";
import {connect} from "react-redux";
import {Field, reduxForm, formValueSelector, change, getFormSyncErrors} from "redux-form";
import {useTranslation} from "react-i18next";
import {FaUserMd} from "react-icons/fa";
import {CardHeader,
  Grid,
  Avatar,
} from "@mui/material";

import {AutocompleteField} from "components/formFields/AutocompleteField";
import {TextFieldComponent} from "components/formFields/TextFieldComponent";
import {CountryDropdown} from "components/formFields/CountryDropdown";
import {PhoneComponent} from "components/formFields/PhoneComponent";

import {
  onSelectDoctorObject,
} from "../../../store/features/newRequestSlice";
import {
  fieldNames,
  fieldLevelValidator,
  getCountryCode,
  formInitialValues,
} from "components/formUtils";


import "react-phone-input-2/lib/bootstrap.css";

const {required, lengthLessThanTwo, validEmail, validPhoneNo, validPostalCode}=fieldLevelValidator;

const ReduxFormComponentDoctor=(props) => {
  const {t,
    i18n}=useTranslation();
  const currentLang=i18n.language.toUpperCase();
  const {doctorCountry,
    syncErrors,
    needsValidation,
    doctor,
    doctorProps,
    isEdit,
    doctorData,
    changeValue,
  } = props;
  useEffect(() => {
    fieldNames.doctor.forEach((e)=>changeValue(e, doctorData?.[e]??formInitialValues.doctor?.[e]??""));
    changeValue("doctor_country", getCountryCode(doctorData?.doctor_country)??doctorData?.doctor_country);
  }, [doctorData]);


  return <div className="">
    <Grid container spacing={3} justifyContent="center">
      <Grid item xs={12} sm={4} md={3} lg={2}>
        <CardHeader
          className="p-0 pb-3 pt-3"
          avatar={
            <Avatar aria-label="recipe" style={{
              color: "#63c2de",
            }}>
              <FaUserMd data-toggle="popover" data-content="Disabled popover" />
            </Avatar>
          }
          title={t("newrequest.label.step.doctor")}
        />
      </Grid>
      <Grid item xs={12} sm={8} md={9} lg={10} >
        <Field name="doctorSubstitute"
          cy_data="doctorSubstitute"
          hidden={!doctorProps.options||doctorProps.options.length === 1}
          component={AutocompleteField}
          autoFocus={true}
          options={doctorProps.options}
          getOptionLabel={(item) => item.givenName?`${item.givenName} ${item.surname}`:item}
          touched={needsValidation}
          onSelectObject={doctor.select}
          required={false}
          label={t("doctor.details.selectDoctor")}
        />
      </Grid>
      <Grid item xs={12} sm={4}>
        <Field name="doctor_title"
          required={false}
          component={TextFieldComponent}
          type="text"
          label={t("doctor.details.title")} />
      </Grid>
      <Grid item xs={12} sm={4}>
        <Field name="doctor_surename"
          required={true}
          touched={needsValidation}
          component={TextFieldComponent}
          type="text"
          validate={[required, lengthLessThanTwo]}
          label={t("doctor.details.surname")} />
      </Grid>
      <Grid item xs={12} sm={4}>
        <Field name="doctor_lastname"
          required={true}
          touched={needsValidation}
          component={TextFieldComponent}
          type="text"
          validate={[required, lengthLessThanTwo]}
          label={t("doctor.details.lastname")} />
      </Grid>
      <Grid item xs={12} sm={8}>
        <Field name="doctor_specialty"
          pristine={false}
          required={true}
          touched={needsValidation}
          component={TextFieldComponent}
          type="text"
          validate={[required, lengthLessThanTwo]}
          label={t("doctor.details.specialty")} />
      </Grid>

      <Grid item xs={12} sm={4}>
        <Field name="doctor_center_name"
          pristine={false}
          required={true}
          touched={needsValidation}
          component={TextFieldComponent}
          type="text"
          validate={[required, lengthLessThanTwo]}
          label={t("doctor.details.hospital")} />
      </Grid>


      <Grid item xs={12} sm={12}>
        <Field name="doctor_email_addr"
          required={true}
          validate={[required, validEmail]}
          touched={needsValidation}
          component={TextFieldComponent}
          type="email"
          label={t("doctor.details.email")} />
      </Grid>
      <Grid item xs={12} sm={4}>
        <Field name="doctor_street"
          required={true}
          touched={needsValidation}
          component={TextFieldComponent}
          type="text"
          validate={[required, lengthLessThanTwo]}
          label={t("doctor.details.street")} />
      </Grid>
      <Grid item xs={12} sm={3}>
        <Field name="doctor_postalcode"
          required={true}
          touched={needsValidation}
          component={TextFieldComponent}
          type="number"
          validate={[required, validPostalCode]}
          label={t("doctor.details.zip")} />
      </Grid>
      <Grid item xs={12} sm={3}>
        <Field name="doctor_city"
          required={true}
          touched={needsValidation}
          component={TextFieldComponent}
          type="text"
          validate={[required, lengthLessThanTwo]}
          label={t("doctor.details.city")} />
      </Grid>
      <Grid item xs={12} sm={2}>
        <Field name="doctor_country"
          touched={needsValidation}
          language={currentLang}
          component={CountryDropdown}
          type="text"
          required={true}
          validate={[required]}
          label={t("doctor.details.country")} />
      </Grid>
      <Grid item className="pt-1" xs={12} sm={6}>
        <Field name="doctor_phone_no"
          doctorCountry={doctorCountry}
          isEdit={isEdit}
          validate={[validPhoneNo]}
          touched={needsValidation}
          component={PhoneComponent}
          id="doctor_phone_no"
          type="text"
          placeholder={t("doctor.details.phone.no")} />
      </Grid>
      <Grid item className="pt-1" xs={12} sm={6}>
        <Field name="doctor_fax_no"
          doctorCountry={doctorCountry}
          isEdit={isEdit}
          component={PhoneComponent}
          validate={[validPhoneNo]}
          id="doctor_fax_no"
          placeholder={t("doctor.details.fax.no")}
        />
      </Grid>
      {syncErrors}
    </Grid>
  </div>;
};

const mapStateToProps=(state) => {
  const doctorCountry=selector(state, "doctor_country");
  return {
    editData: state.singleRequest.editData,
    needsValidation: state.singleRequest.needsValidation.doctor,
    syncErrors: getFormSyncErrors("doctorContact")(state),
    doctorData: state.singleRequest.doctorData,
    doctorProps: {
      options: state.masterdata?.autocompleteOptions?.doctor,
    },

    doctorCountry,
  };
};
const selector=formValueSelector("doctorContact");

const mapDispatchToProps=(dispatch) => ({
  doctor: {
    select: (doctor) => dispatch(onSelectDoctorObject(doctor)),
  },
  changeValue: (fieldName, value)=>dispatch(change("doctorContact", fieldName, value)),
});

export default connect(mapStateToProps, mapDispatchToProps)(reduxForm({
  enableReinitialize: false,
  form: "doctorContact",
})(ReduxFormComponentDoctor));

