import i18n from "i18next";
import {
  initReactI18next,
} from "react-i18next";
import Backend from "i18next-http-backend";

let language = navigator.language;
let loadPath = "";
try {
  loadPath = process.env.REACT_APP_API_URL + "nexus/{{lng}}";
  if ( sessionStorage.getItem("ui_locales")) {
    language = sessionStorage.getItem("ui_locales");
  } else {
    let navigatorlang = navigatorlang.substring(0, 2);
    navigatorlang = ["de", "fr", "it"].includes(navigatorlang) ? navigatorlang.toUpperCase() : "DE";
    language = navigatorlang;
  }
} catch (ex) {
  console.error("[I18n]", ex);
}

i18n
    .use(initReactI18next)
    .use(Backend)
    .init({
      cleanCode: true,
      lng: language,
      fallbackLng: "de",
      debug: false,
      ns: ["Standard"],
      defaultNS: "Standard",
      keySeparator: false,
      interpolation: {
        escapeValue: false,
        formatSeparator: ",",
      },
      react: {
        withRef: false,
        bindI18n: "languageChanged loaded",
        bindStore: "added removed",
        nsMode: "default",
      },
      supportedLngs: ["de", "fr", "it"],
      backend: {
        loadPath: loadPath,
      },
      // Custom exception handler for missing keys
      missingKeyHandler: (lngs, ns, key, defaultValue, missingKeyOptions) => {
        console.error(`Missing translation key: ${key}`);
        location.reload();
        return defaultValue;
      },
    }, (err, t) => {
      if (err) {
        console.error("[I18n]", err);
        window.location.href = window.location.href;
      }
    });

export default i18n;

export const i18_instance = {
  i18n: i18n,
  language: i18n.language,
};


