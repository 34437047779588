import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {RootState} from "store/rootstate";

export interface IQuviviqState {
  formData:{
    justification: {
      thereIsNoAlternativeDrug: boolean,
      patientRespondedToTherapy: boolean,
      furtherJustification: string
    },
    clinicalcondition: {
      decreasedPerformance: boolean,
      decreasedCognitiveFunctions: boolean,
      previousFalls: boolean,
      increasedRiskOfCardiovascularDisease: boolean,
      furtherJustification: string
    },
    achieveIfSubscribed: {
      improveSleepQuality: boolean,
      improvingCognitiveFunctions: boolean,
      reductionOfRiskOfFalls: boolean,
      reductionOfRiskOfCardiovascularDisease: boolean,
      reductionOfSideEffectsOfNarcoticsConsume: boolean
      furtherJustification: string
    }
  }
}

const initialState:IQuviviqState = {
  formData: {
    justification: {
      thereIsNoAlternativeDrug: false,
      patientRespondedToTherapy: false,
      furtherJustification: "",
    },
    clinicalcondition: {
      decreasedPerformance: false,
      decreasedCognitiveFunctions: false,
      previousFalls: false,
      increasedRiskOfCardiovascularDisease: false,
      furtherJustification: "",
    },
    achieveIfSubscribed: {
      improveSleepQuality: false,
      improvingCognitiveFunctions: false,
      reductionOfRiskOfFalls: false,
      reductionOfRiskOfCardiovascularDisease: false,
      reductionOfSideEffectsOfNarcoticsConsume: false,
      furtherJustification: "",
    },
  },
};


const QuviviqFormSlice = createSlice({
  name: "Quviviq",
  initialState,
  reducers: {
    onReceiveSpecialMedQuviviqEditData: (state, {payload}: PayloadAction<any>) => {
      console.debug("onReceiveSpecialMedQuviviqEditData", payload.formData);
      const justification = payload.formData?.justification;
      if (justification) {
        state.formData.justification.thereIsNoAlternativeDrug = justification.thereIsNoAlternativeDrug;
        state.formData.justification.patientRespondedToTherapy = justification.patientRespondedToTherapy;
        state.formData.justification.furtherJustification = justification.furtherJustification;
      }


      const clinicalcondition = payload.formData?.clinicalcondition;
      if (clinicalcondition) {
        state.formData.clinicalcondition.decreasedPerformance = clinicalcondition.decreasedPerformance;
        state.formData.clinicalcondition.decreasedCognitiveFunctions = clinicalcondition.decreasedCognitiveFunctions;
        state.formData.clinicalcondition.previousFalls = clinicalcondition.previousFalls;
        state.formData.clinicalcondition.increasedRiskOfCardiovascularDisease =
          clinicalcondition.increasedRiskOfCardiovascularDisease;
        state.formData.clinicalcondition.furtherJustification = clinicalcondition.furtherJustification;
      }

      const achieveIfSubscribed = payload.formData?.achieveIfSubscribed;
      if (achieveIfSubscribed) {
        console.debug("achieveIfSubscribed.improveSleepQuality", achieveIfSubscribed.improveSleepQuality);
        state.formData.achieveIfSubscribed.improveSleepQuality = achieveIfSubscribed.improveSleepQuality;
        state.formData.achieveIfSubscribed.improvingCognitiveFunctions = achieveIfSubscribed.improvingCognitiveFunctions;
        state.formData.achieveIfSubscribed.reductionOfRiskOfFalls = achieveIfSubscribed.reductionOfRiskOfFalls;
        state.formData.achieveIfSubscribed.reductionOfRiskOfCardiovascularDisease =
          achieveIfSubscribed.reductionOfRiskOfCardiovascularDisease;
        state.formData.achieveIfSubscribed.reductionOfSideEffectsOfNarcoticsConsume =
          achieveIfSubscribed.reductionOfSideEffectsOfNarcoticsConsume;
        state.formData.achieveIfSubscribed.furtherJustification = achieveIfSubscribed.furtherJustification;
      }
    },


    onClearQuviviqState: (state)=>{
      state.formData=initialState.formData;
    },
    onChangeQuiviqValue: (state, {payload}:PayloadAction<any>)=>{
      console.debug("onChangeQuiviqValue", payload);
      if (payload.type === "justification") {
        if (payload.selectedOption === "thereIsNoAlternativeDrug") {
          state.formData.justification.thereIsNoAlternativeDrug = payload.value;
        }
        if (payload.selectedOption === "patientRespondedToTherapy") {
          state.formData.justification.patientRespondedToTherapy = payload.value;
        }
        if (payload.selectedOption === "furtherJustification") {
          state.formData.justification.furtherJustification = payload.value;
        }
      }
      if (payload.type === "clinicalcondition") {
        if (payload.selectedOption === "decreasedPerformance") {
          state.formData.clinicalcondition.decreasedPerformance = payload.value;
        }
        if (payload.selectedOption === "decreasedCognitiveFunctions") {
          state.formData.clinicalcondition.decreasedCognitiveFunctions = payload.value;
        }
        if (payload.selectedOption === "previousFalls") {
          state.formData.clinicalcondition.previousFalls = payload.value;
        }
        if (payload.selectedOption === "increasedRiskOfCardiovascularDisease") {
          state.formData.clinicalcondition.increasedRiskOfCardiovascularDisease = payload.value;
        }
        if (payload.selectedOption === "furtherJustification") {
          state.formData.clinicalcondition.furtherJustification = payload.value;
        }
      }

      if (payload.type === "achieveIfSubscribed") {
        if (payload.selectedOption === "improveSleepQuality") {
          state.formData.achieveIfSubscribed.improveSleepQuality = payload.value;
        }
        if (payload.selectedOption === "improvingCognitiveFunctions") {
          state.formData.achieveIfSubscribed.improvingCognitiveFunctions = payload.value;
        }
        if (payload.selectedOption === "reductionOfRiskOfFalls") {
          state.formData.achieveIfSubscribed.reductionOfRiskOfFalls = payload.value;
        }
        if (payload.selectedOption === "reductionOfRiskOfCardiovascularDisease") {
          state.formData.achieveIfSubscribed.reductionOfRiskOfCardiovascularDisease = payload.value;
        }
        if (payload.selectedOption === "reductionOfSideEffectsOfNarcoticsConsume") {
          state.formData.achieveIfSubscribed.reductionOfSideEffectsOfNarcoticsConsume = payload.value;
        }
        if (payload.selectedOption === "furtherJustification") {
          state.formData.achieveIfSubscribed.furtherJustification = payload.value;
        }
      }
    },
  },
});

export const {
  onReceiveSpecialMedQuviviqEditData,
  onClearQuviviqState,
  onChangeQuiviqValue,
} = QuviviqFormSlice.actions;

export default QuviviqFormSlice.reducer;
export const selectQuviviqValues = (state:RootState) =>
  state.wizard.medicine.additionalInformation.quviviqForm.formData;

