import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {RootState} from "store/rootstate";


export interface TecvayliState {
  formData: {
    // default stuff
    preTreatment: string
    patientCondition: string,
    prognosis: string,
    reasonsAlternativeTherapiesFailed: string,

    // options
    isMonotherapy: boolean,
    isOtherIndication: boolean,
    medications: any[],
    comments: string,
  }
}

const initialState:TecvayliState = {
  formData: {
    isMonotherapy: false,
    isOtherIndication: false,
    medications: [],
    comments: "",
    preTreatment: "",
    patientCondition: "",
    prognosis: "",
    reasonsAlternativeTherapiesFailed: "",
  },
};

const TecvayliFormSlice = createSlice({
  name: "Tecvayli",
  initialState,
  reducers: {
    onClearTecvayliState: (state)=>{
      state.formData = initialState.formData;
    },
    onReceiveSpecialMedTecvayliEditData: (state, {payload}: PayloadAction<any>) => {
      updatestate(payload, state);
      if (payload.formData) {
        state.formData.preTreatment =
          payload.formData?.preTreatment;
        state.formData.patientCondition =
          payload.formData?.patientCondition;
        state.formData.reasonsAlternativeTherapiesFailed =
          payload.formData?.reasonsAlternativeTherapiesFailed;
        state.formData.prognosis =
          payload.formData?.prognosis;
      }
    },
    onChangeTecvayliValue: (state, {payload}: PayloadAction<any>) => {
      console.debug("onChangeTecvayliValue", payload);
      if (payload.preTreatment || payload.preTreatment === "") state.formData.preTreatment = payload.preTreatment;
      if (payload.patientCondition || payload.patientCondition === "") state.formData.patientCondition = payload.patientCondition;
      if (payload.reasonsAlternativeTherapiesFailed || payload.reasonsAlternativeTherapiesFailed === "") state.formData.reasonsAlternativeTherapiesFailed = payload.reasonsAlternativeTherapiesFailed;
      if (payload.prognosis || payload.prognosis === "") state.formData.prognosis = payload.prognosis;

      updatestate(payload, state);
    },
    onAddMedication: (state, action) => {
      state.formData.medications.push(action.payload); // Add new medication
    },
    onRemoveMedication: (state, action) => {
      state.formData.medications =
        state.formData.medications.filter((_, index) => index !== action.payload); // Remove medication by index
    },
  },
});


export const {
  onReceiveSpecialMedTecvayliEditData,
  onClearTecvayliState,
  onChangeTecvayliValue,
  onAddMedication,
  onRemoveMedication,
} = TecvayliFormSlice.actions;

export default TecvayliFormSlice.reducer;
export const selectTecvayliValues = (state:RootState) =>
  state.wizard.medicine.additionalInformation.tecvayliForm?.formData;


function updatestate(payload: any, state) {
  const data = payload.formData;
  console.debug("data", JSON.stringify(data));
  if (!data) return;

  if (data.isMonotherapy && data.isMonotherapy === true) {
    state.formData.isOtherIndication = false;
    state.formData.isMonotherapy = true;
    state.formData.preTreatment = "";
    state.formData.patientCondition = "";
    state.formData.prognosis = "";
    state.formData.reasonsAlternativeTherapiesFailed = "";
  }
  if (data.isOtherIndication && data.isOtherIndication === true) {
    state.formData.isOtherIndication = true;
    state.formData.isMonotherapy = false;
    state.formData.medications = [];
    state.formData.comments = "";
  }

  if (data.medications) {
    state.formData.medications = data.medications;
  }
  if (data.comments || data.comments === "") {
    state.formData.comments = data.comments;
  }
}

