import Select, {components} from "react-select";
import React from "react";
import {useTranslation} from "react-i18next";
import Avatar from "@mui/material/Avatar";

import {allKoguTypes} from "components/koguTypes";


export const KoguTypeDropdown=(props) => {
  const {t}=useTranslation();

  const {
    input: {onChange},
  }=props;

  const selectedValue=props.input.value;
  const {Option}=components;
  const CustomSelectOption=(props) => (
    <Option {...props}>
      <Avatar aria-label="recipe" style={{
        color: "#63c2de",
        backgroundColor: "#fff",
        display: "inline",
        marginRight: "5px",
      }}>
        {props.data.icon}
      </Avatar>
      {t("kogu_type_"+props.data.value)}
    </Option>
  );

  const CustomSelectValue=(props) => (
    <div>
      <Avatar aria-label="recipe" style={{
        color: "#63c2de",
        backgroundColor: "#fff",
        display: "inline",
        marginRight: "5px",
      }}>
        {props.data.icon}
      </Avatar>
      {t("kogu_type_"+props.data.value)}
    </div>
  );

  const handleChange=(selectedOption) => {
    onChange(selectedOption.value);
  };
  const kogutyes = allKoguTypes.filter((koguType) => koguType.value !== "med");
  // const kogutyes = allKoguTypes;

  return (
    <Select
      inputId='kogu_type'
      data-cy="kogu_type"
      isSearchable={false}
      styles={{
        control: (provided, state) => ({
          ...provided,
          boxShadow: "none",
          border: "none",
          maxWidth: "400px",
          width: "auto",
          height: "40px",
        }),
        option: (provided, state) => ({
          ...provided,
          backgroundColor: "white",
          color: "black",
        }),
      }
      }
      // name={name}
      // data-cy="diagnosis-input"
      value={kogutyes.find((k) => k.value==selectedValue)}
      onChange={handleChange}
      options={kogutyes}
      components={{Option: CustomSelectOption, SingleValue: CustomSelectValue}
      }
    />
  );
};


