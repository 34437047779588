import React from "react";
import {connect} from "react-redux";
import {useTranslation} from "react-i18next";
import {
  Grid,
  FormControlLabel,
  Checkbox,
  TextField,
  Typography,
} from "@mui/material";

import {UseAppDispatch, UseAppSelector} from "store/hooks";
import {onChangeBrukinsaValue, selectBrukinsaValues} from "./BrukinsaSlice";
import {LocalizationProvider, DatePicker} from "@mui/x-date-pickers/";
import {AdapterDateFns} from "@mui/x-date-pickers/AdapterDateFns";
import {TextArea} from "pages/Wizard/components/TextAreaComponent";
import {calendarLanguage, calendarMask} from "pages/Wizard/containers/utils";


const BrukinsaForm=(props) => {
  const {t, i18n}=useTranslation();
  const currentLang=i18n.language.toUpperCase();
  const brukinsaValues=UseAppSelector(selectBrukinsaValues);
  const dispatch=UseAppDispatch();
  const handleValueChange = (field:string, value:any)=>dispatch(onChangeBrukinsaValue({field, value}));


  return <>
    <Grid item container xs={12}>
      <Grid item container xs={12} direction="row" className="p-3">
        <Grid item xs={12} data-cy="chk_brukinsa_wanlenstrom">
          <FormControlLabel
            control={
              <Checkbox
                name="wanlenstrom"
                checked={brukinsaValues?.wanlenstrom}
                onChange={(event, newValue)=>{
                  handleValueChange("wanlenstrom", event.target.checked);
                }}/>
            }
            label={t("brukinsa.wanlenstrom")}
          />
        </Grid>
        <Grid item xs={12} data-cy="chk_brukinsa_rituximab">
          <FormControlLabel
            control={
              <Checkbox
                name="rituximab"
                checked={brukinsaValues?.rituximab}
                onChange={(event, newValue)=>{
                  handleValueChange("rituximab", event.target.checked);
                }}/>
            }
            label={t("brukinsa.rituximab")}
          />
        </Grid>
        <Grid item xs={12}>
          <TextArea
            data-cy="txt_brukinsa_rituximabReason"
            value={brukinsaValues?.rituximabReason||""}
            placeholder={t("brukinsa.rituximabReason")}
            style={{width: "100%"}}
            onChange={(event)=>handleValueChange("rituximabReason", event.target.value)}
          />
        </Grid>
        <Grid item xs={12} data-cy="chk_brukinsa_hasTreatment">
          <FormControlLabel
            control={
              <Checkbox
                name="hasTreatment"
                checked={brukinsaValues?.hasTreatment}
                onChange={(event, newValue)=>{
                  handleValueChange("hasTreatment", event.target.checked);
                }}/>
            }
            label={t("brukinsa.hasTreatment")}
          />
        </Grid>
        <Grid item container >
          <Grid item xs={4} alignSelf={"center"}>
            <Typography >{t("brukinsa.treatment_label")}</Typography>
          </Grid>
          <Grid item xs={4} data-cy="date_brukinsa_treatmentStart">
            <LocalizationProvider dateAdapter={AdapterDateFns} locale={calendarLanguage(currentLang)}>
              <DatePicker
                label={t("brukinsa.treatmentStart")}
                value={brukinsaValues?.treatmentStart}
                mask={calendarMask(currentLang)}
                onChange={(newValue) => {
                  handleValueChange("treatmentStart", newValue);
                }}
                renderInput={(params) => <TextField {...params} />}
              />
            </LocalizationProvider>
          </Grid>
          <Grid item xs={4} data-cy="date_brukinsa_treatmentEnd">
            <LocalizationProvider dateAdapter={AdapterDateFns} locale={calendarLanguage(currentLang)}>
              <DatePicker
                label={t("brukinsa.treatmentEnd")}
                value={brukinsaValues?.treatmentEnd}
                mask={calendarMask(currentLang)}
                minDate={brukinsaValues.treatmentStart}
                onChange={(newValue) => {
                  handleValueChange("treatmentEnd", newValue);
                }}
                renderInput={(params) => <TextField {...params} />}
              />
            </LocalizationProvider>
          </Grid>
        </Grid>

      </Grid>
    </Grid>
  </>;
};

const mapStateToProps=(state) => {
  return {

  };
};

const mapDispatchToProps=(dispatch) => ({

});

export default connect(mapStateToProps, mapDispatchToProps)(BrukinsaForm);


