import {
  CardContent,
  Grid,
  Link,
  IconButton,
  Typography,
  Collapse,
  CircularProgress,
  Card,
  CardHeader,
} from "@mui/material";
import React, {useState} from "react";
import {studyRowType} from "../store/rootstate";
import i18n from "../i18n";
import {
  InsertLink,
  PostAdd,
  FaTrash,
  ExpandMore as ExpandMoreIcon,
} from "components/icons";
import {ExpandMore} from "components/ExpandMore";
import {useDispatch} from "react-redux";
import {onReceivedPubMedData} from "store/features/masterdataSlice";

export const PubMedCard=(props:{
  handleFetchAbstract:(id: number) =>void,
  abstractContent:string[],
  rowData: studyRowType,
  isLoading:boolean,
  onAddOrRemoveStudy?:(studyData: studyRowType) => { payload: studyRowType; type: string; },
  onRemoveStudy?:(studyData: studyRowType) => void,
  addedStudyId?:number,
  title?:string
})=> {
  const {
    rowData,
    onAddOrRemoveStudy,
    onRemoveStudy,
    addedStudyId,
    handleFetchAbstract,
    abstractContent,
    isLoading,
    title,
  }=props;

  const [expanded, setExpanded] = useState(false);
  const dispatch=useDispatch();
  const handleExpandClick = () => {
    abstractContent??handleFetchAbstract(rowData.pmid);
    setExpanded(!expanded);
  };

  const handleStudyAttach=()=>{
    onAddOrRemoveStudy&&onAddOrRemoveStudy(rowData);
    dispatch(onReceivedPubMedData([]));
  };
  const handleStudyDetach=()=>onRemoveStudy&&onRemoveStudy(rowData);
  return <Card >
    {title&&<CardHeader title= {title}/>}
    <CardContent>
      <Grid container direction="row" spacing={2}>
        <Grid item xs={1} alignSelf="center">
          <ExpandMore
            data-cy="pubmed_expand"
            expand={expanded}
            onClick={handleExpandClick}
          >
            <ExpandMoreIcon />
          </ExpandMore>
        </Grid>
        <Grid item container direction="column" xs={2} sx={{overflowWrap: "anywhere"}}>
          <Grid item>
            <Typography sx={{fontWeight: "bold"}}>
              {i18n.t("pubmed.label.type")}
            </Typography>
          </Grid>
          <Grid item >
            <Typography >
              {rowData?.document_type??rowData?.pubtype?.join(", ")??""}
            </Typography>
          </Grid>
        </Grid>
        <Grid item container direction="column" xs={4}>
          <Grid item>
            <Typography sx={{fontWeight: "bold"}}>
              {i18n.t("pubmed.label.title")}
            </Typography>
          </Grid>
          <Grid item >
            {rowData.title}
          </Grid>
        </Grid>
        <Grid item xs={3}>
          <Grid item>
            <Typography sx={{fontWeight: "bold"}}>
              {i18n.t("pubmed.label.author")}
            </Typography>
          </Grid>
          <Grid item >
            {Array.isArray(rowData.authors)?
            rowData.authors
                ?.map((e)=>e.name)
                .join(", "):rowData.authors}
          </Grid>
        </Grid>
        <Grid item xs={1} alignSelf="center">
          <Link href={`https://pubmed.ncbi.nlm.nih.gov/${rowData.pmid}`} target="_blank" underline="none">
            <InsertLink/>
          </Link>
        </Grid>
        <Grid item xs={1} alignSelf="center">
          {typeof onAddOrRemoveStudy === "function"&&
          <IconButton onClick={handleStudyAttach} data-cy={`addStudy_${rowData.pmid}`}>
            <PostAdd color={addedStudyId===rowData.pmid?"secondary":"primary"} />
          </IconButton>
          }
          {typeof onRemoveStudy === "function"&&
          <IconButton onClick={handleStudyDetach} data-cy={`removeStudy_${rowData.pmid}`}>
            <FaTrash color="#63c2de" />
          </IconButton>
          }
        </Grid>
      </Grid>
    </CardContent>
    <Collapse in={expanded} timeout="auto" unmountOnExit>
      <CardContent>
        <Grid container spacing={3}>
          <Grid item xs={1}></Grid>
          <Grid item container xs={2} spacing={2} direction="column">
            <Grid item container direction="column">
              <Grid item>
                {i18n.t("pubmed.pubmedid")}
              </Grid>
              <Grid item>
                {rowData.pmid}
              </Grid>
            </Grid>
            {rowData.doi?<Grid item container direction="column">
              <Grid item >
                {i18n.t("pubmed.doi")}
              </Grid>
              <Grid item>
                <Typography style={{wordBreak: "break-word"}}>
                  {rowData?.doi}
                </Typography>
              </Grid>
            </Grid>:<></>
            }
            {rowData?.rating&&(<Grid item container direction="column">
              <Grid item data-cy="pubmed_rating">
                <Typography sx={{fontWeight: "bold"}}>
                  {i18n.t("pubmed.rating")}&nbsp;{rowData?.rating}
                </Typography>
              </Grid>
            </Grid>)}
          </Grid>
          <Grid item xs={9} alignSelf="center">
            {isLoading&&!Boolean(abstractContent)?
            <Grid item alignSelf="center" xs={12}>
              <CircularProgress color="primary" size={50} />
            </Grid>:
            abstractContent?.map((e:string, idx:number)=>
              <Typography key={`${rowData.pmid}_${idx}`} paragraph>{e}</Typography>)
            }
          </Grid>
        </Grid>
      </CardContent>
    </Collapse>
  </Card>;
};
