import React from "react";
import {connect, useDispatch} from "react-redux";
import {Field, reduxForm} from "redux-form";
import {KoguTypeDropdown} from "components/formFields/KoguTypeDropdown";

import {
  onKoguTypeChanged,
} from "../../../store/features/newRequestSlice";

import MigelForm from "./MigelForm";
import AmbulantForm from "./AmbulantForm";
import RehaForm from "./RehaForm";
import TransportForm from "./TransportForm.jsx";
import BreathingDeviceForm from "./BreathingDeviceForm.jsx";


const ReduxFormComponentIndication=(props) => {
  const {
    editData,
  }=props;
  const {kogu_type}=props.initialValues;
  const getIndicationForm=(kogu_type)=>{
    switch (kogu_type) {
      case "migel":
        return <MigelForm
          isEdit={props.id}
          onRequiredDrugEvents={props.onRequiredDrugEvents}
          // bagData={props.bagData}
          editData={editData}
        />;
      case "ambstat":
        return <AmbulantForm />;
      case "reha":
        return <RehaForm />;
      case "transport":
        return <TransportForm />;
      case "breathing_device":
        return <BreathingDeviceForm/>;
      default:
        return <MigelForm
          isEdit={props.id}
          onRequiredDrugEvents={props.onRequiredDrugEvents}
          // bagData={props.bagData}
          editData={editData}
        />;
    }
  };
  const dispatch=useDispatch();
  return <div className="w-auto">
    <div>
      <Field name="kogu_type"
        value={kogu_type}
        onChange={(event, value) => dispatch(onKoguTypeChanged(value))}
        component={KoguTypeDropdown}
        defaultValue={kogu_type}
        data-cy="diagnosis-input"
      />
      {getIndicationForm(kogu_type)}
    </div>
  </div>;
};
const mapStateToProps=(state) => {
  return {
    state,
    initialValues: {kogu_type: state.singleRequest?.kogu_type},
  };
};

const mapDispatchToProps=(dispatch) => ({
});

const IndicationDetailsForm=reduxForm({
  enableReinitialize: true,
  destroyOnUnmount: false,
  form: "indication_wrapper",
})(ReduxFormComponentIndication);

export default connect(mapStateToProps, mapDispatchToProps)(IndicationDetailsForm);
