
import {createSlice} from "@reduxjs/toolkit";
import {RootState} from "store/rootstate";

export interface IUserInformationState {
  openVerifiedDialog:boolean;
  user:{
    "@odata.context"?: string;
    Base64EncodedSignaturePicture: string;
    city?: string;
    country?: string;
    createdDateTime?: string;
    creationType?: string;
    deletedDateTime?: string;
    extension_Profession?: string;
    extension_center_name?: string;
    extension_fax_number?: string;
    extension_phone_number?: string;
    extension_title?: string;
    givenName?: string;
    groups?: string[];
    id: string;
    identities?: any[];
    isNewUser?: boolean;
    oids?: string[];
    postalCode?: string;
    streetAddress?: string;
    surname?: string;
    tfp?: string;
  }
}

const UserReducerSlices = createSlice({
  name: "UserReducer",
  initialState: <IUserInformationState>{
    user: {
      groups: ["patientstrenght"],
    },
    openVerifiedDialog: false,
  },
  reducers: {

    isSavingSignature(state, {payload}) {
      return {
        ...state,
        isSavingSignature: payload,
      };
    },

    onReceivedUserData(state, action) {
      return {
        ...state,
        user: action.payload,
        openVerifiedDialog: ! action.payload?.groups?.includes("verified"),
      };
    },

    closeVerifiedDialog(state) {
      return {
        ...state,
        openVerifiedDialog: false,
      };
    },
  },


});


export const {
  onReceivedUserData,
  closeVerifiedDialog,
  isSavingSignature,
} = UserReducerSlices.actions;

export default UserReducerSlices.reducer;
export const selectIsAuthenticated = (state:RootState) => Boolean(state.userinformation.user.oids);
export const selectUser = (state:RootState) => state.userinformation.user;

