import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {RootState} from "store/rootstate";

export interface IBrukinsaState {
  formData:{
    wanlenstrom : boolean,
    rituximab: boolean,
    rituximabReason: string,
    hasTreatment: boolean,
    treatmentStart: Date,
    treatmentEnd: Date
  };
}
const initialState:IBrukinsaState = {
  formData: {
    wanlenstrom: false,
    rituximab: false,
    rituximabReason: "",
    hasTreatment: false,
    treatmentStart: new Date(),
    treatmentEnd: new Date(),
  },
};

const BrukinsaFormSlice = createSlice({
  name: "Brukinsa",
  initialState,
  reducers: {
    onReceiveSpecialMedBrukinsaEditData: (state, {payload}:PayloadAction<any>)=>{
      state.formData ={...state.formData, ...payload};
    },
    onChangeBrukinsaValue: (state, {payload}:PayloadAction<{field:string;value:any;}>)=>{
      state.formData[payload.field] = payload.value;
    },
    onClearBrukinsaState: (state)=>{
      state.formData=initialState.formData;
    },
  },
});

export const {
  onChangeBrukinsaValue,
  onReceiveSpecialMedBrukinsaEditData,
  onClearBrukinsaState,
} = BrukinsaFormSlice.actions;

export default BrukinsaFormSlice.reducer;
export const selectBrukinsaValues = (state:RootState) =>
  state.wizard.medicine.additionalInformation.brukinsaForm.formData;
export const selectActiveBrukinsaValues=(state:RootState)=>{
  return {
    wanlenstrom: state.wizard.medicine.additionalInformation.brukinsaForm.formData.wanlenstrom,
    rituximab: state.wizard.medicine.additionalInformation.brukinsaForm.formData.wanlenstrom,
    rituximabReason: state.wizard.medicine.additionalInformation.brukinsaForm.formData.rituximabReason,
    hasTreatment: state.wizard.medicine.additionalInformation.brukinsaForm.formData.hasTreatment,
    treatmentStart: state.wizard.medicine.additionalInformation.brukinsaForm.formData.treatmentStart,
    treatmentEnd: state.wizard.medicine.additionalInformation.brukinsaForm.formData.treatmentEnd,
  };
};
