import React, {useEffect} from "react";
import {connect} from "react-redux";
import {Field, reduxForm, formValueSelector, change} from "redux-form";
import {useTranslation} from "react-i18next";
import {Box, Grid, Typography} from "@mui/material";
import {
  fetchRehaData,
  fetchICD10Data,
} from "store/middleware/masterdataThunk";
import {onSelectClinicObject} from "../../../store/features/newRequestSlice";
import {AutocompleteField} from "components/formFields/AutocompleteField";
import {TextArea} from "components/formFields/TextArea";
import {TextFieldComponent} from "components/formFields/TextFieldComponent";
import {Calendar} from "components/formFields/Calendar";
import {PhoneComponentPatient} from "components/formFields/PhoneComponentPatient";
import {CountryDropdown} from "components/formFields/CountryDropdown";
import {CheckboxComponent} from "components/formFields/CheckboxComponent";


import {
  fieldNames,
  fieldLevelValidator,
  options,
  datepickerLimitations,
  formInitialValues,
  correctDateFormat,
} from "components/formUtils";

const {validDate}=fieldLevelValidator;

const ReduxFormComponentIndication=(props) => {
  const {t, i18n}=useTranslation();
  const currentLang=i18n.language.toUpperCase();
  const {
    needsValidation,
    insuranceCompanyProps,
    formValues,
    changeValue,
    diagnosis,
    requiredReha,
    diagnosisProps,
    requiredRehaData,
    requiredRehaProps,
    editData,
    firstInsuranceCompany,
  }=props;
  useEffect(()=>{
    fieldNames.reha.forEach((e)=>{
      changeValue(e, editData?.[e]??formInitialValues.reha?.[e]??"");
    });
  }, [editData]);

  useEffect(() => {
    changeValue("additional_insurance_company_gln", insuranceCompanyProps?.options?.find((e)=>formValues?.additional_insurance_company?.includes(e.DESCR1))?.GLN);
  }, [formValues.additional_insurance_company]);
  useEffect(()=>{
    changeValue("city", requiredRehaData?.city??editData?.city);
    changeValue("email", requiredRehaData?.email??editData?.email);
    changeValue("phone_no", requiredRehaData?.phone_no??editData?.phone_no);
    changeValue("postalcode", requiredRehaData?.postalcode??editData?.postalcode);
    changeValue("street_no", requiredRehaData?.street_no??editData?.street_no);
    changeValue("country", requiredRehaData?.country??editData?.country);
  }, [requiredRehaData]);
  return <Grid container spacing={3}>
    <Grid item container spacing={3}>
      <Grid item xs={12} sm={12} component={Box} alignSelf='flex-start' className="mt-3">
        <Field className="w-100" name="clinic"
          needsDelay
          options={requiredRehaProps.options}
          required={true}
          isLoading={requiredRehaProps.isLoading}
          onChange={requiredReha.fetchOptions}
          onSelectObject={requiredReha.select}
          touched={needsValidation}
          component={AutocompleteField}
          pristine={true}
          getOptionLabel={(item) =>
            item?.CENTERNAME?
            `${item.CENTERNAME}, ${item.STREET},  ${item.PLZ},  ${item.CITY}`:
            item.toString()}
          label={t("reha.clinic")}
          onKeyPressAction={(e) => requiredReha.keyPress(e, currentLang)}
        />
      </Grid>
      <Grid item xs={12} sm={4}>
        <Field name="street_no"
          component={TextFieldComponent}
          type="text"
          label={t("patient.label.street")}
        />
      </Grid>
      <Grid item xs={12} sm={3}>
        <Field name="postalcode"
          required={true}
          touched={needsValidation}
          component={TextFieldComponent}
          type="number"
          label={t("patient.label.zip")}
        />
      </Grid>
      <Grid item xs={12} sm={3}>
        <Field name="city"
          required={true}
          touched={needsValidation}
          component={TextFieldComponent}
          type="text"
          label={t("patient.details.city")}
        />
      </Grid>
      <Grid item xs={12} sm={2}>
        <Field name="country"
          required={true}
          language={currentLang}
          touched={needsValidation}
          component={CountryDropdown}
          type="text"
          label={t("patient.label.country")}
        />
      </Grid>
      <Grid item className="pt-1" xs={12} sm={4}>
        <Field name="phone_no"
          component={PhoneComponentPatient}
          type="text"
          placeholder={t("patient.label.phone.no")}
        />
      </Grid>
      <Grid item className="pt-3" xs={12} sm={8}>
        <Field name="email"
          id="email"
          required={false}
          component={TextFieldComponent}
          type="email"
          label={t("patient.label.email")}
        />
      </Grid>
    </Grid>
    <Grid item xs={12} sm={12} >
      <Field name="grund"
        options={options.reha.reasons.map((e)=>t(e))}
        required={true}
        touched={needsValidation}
        component={AutocompleteField}
        label={t("reha.grund")}
      />
    </Grid>

    <Grid item container className="mt-3">
      <Grid item xs={12} sm={12}>
        <Typography className="MuiFormLabel-root" >
          {t("reha.ursache")}
        </Typography>
      </Grid>
      <Grid item container xs={3} sm={3}>
        <Grid item container xs={3} sm={3} >
          <Grid item xs={12} sm={12} className="p-3">
            <Field name="krankheit"
              className="MuiFormLabel-root"
              required={true}
              onChange={(event, newValue)=> {
                changeValue("unfall", !newValue);
                changeValue("nabendiagnosen", "");
              }}
              component={CheckboxComponent}
              radio
              label={t("reha.krankheit")}
            />
          </Grid>
          <Grid item xs={12} sm={12} className={"p-3"}>
            <Field name="unfall"
              required={true}
              onChange={(event, newValue)=>{
                changeValue("krankheit", !newValue);
                changeValue("nabendiagnosen", "Unfalldatum:\nArbeitgeber:");
              }}
              touched={needsValidation}
              component={CheckboxComponent}
              radio
              type="text"
              label={t("reha.unfall")}
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid container item xs={9} sm={9} spacing={0}>
        <Grid item xs={12} sm={12}>
          <Field name="diagnosis"
            needsDelay
            component={AutocompleteField}
            onChange={(e)=>diagnosis.fetchOptions(e, currentLang)}
            options={diagnosisProps.options}
            isLoading={diagnosisProps.isLoading}
            getOptionLabel={(item) =>
              item?.code?
              `${item.code} ${item.description}`:
              item.toString()}
            touched={needsValidation}
            required={true}
            validate={fieldLevelValidator.required}
            label={t("indication.details.diagnosis")}
          />

        </Grid>
        <Grid container justifycontent="space-between" spacing={1}>
          <Grid item xs={12} sm={6} >
            <Field name="diagnosis_date"
              required={true}
              validate={[validDate]}
              touched={needsValidation}
              component={Calendar}
              language={currentLang}
              normalize={correctDateFormat}
              type="text"
              label={t("reha.diagnosis_date")}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Field name="operation_date"
              required={true}
              validate={[validDate]}
              touched={needsValidation}
              language={currentLang}
              component={Calendar}
              normalize={correctDateFormat}
              type="text"
              label={t("reha.operation_date")}
            />
          </Grid>
        </Grid>
      </Grid>

    </Grid>

    <Grid item xs={12} >
      <Field name="nabendiagnosen"
        minRows={3}
        required={true}
        touched={needsValidation}
        component={TextArea}
        type="text"
        label={t("reha.nabendiagnosen")}
      />
    </Grid>
    <Grid item xs={12} >
      <Field name="begrundung"
        minRows={3}
        required={true}
        touched={needsValidation}
        component={TextArea}
        type="text"
        label={t("reha.begrundung")}
      />
    </Grid>

    <Grid item xs={12} >
      <Field name="return"
        required={true}
        touched={needsValidation}
        component={CheckboxComponent}
        type="text"
        label={t("reha.return")}
      />
    </Grid>
    <Grid item xs={12} >
      <Typography component={"span"} variant="h6">
        {t("reha.insurance_status")}
      </Typography>
    </Grid>
    <Grid item container>
      <Grid container item xs={3} >
        <Grid item xs={12}>
          <Field name="insurance_general"
            required={true}
            onChange={(event, newValue)=>{
              changeValue("insurance_half", false);
              changeValue("insurance_private", false);
            }}
            touched={needsValidation}
            radio
            component={CheckboxComponent}
            type="text"
            label={t("reha.insurance_general")}
          />
        </Grid>
        <Grid item xs={12}>
          <Field name="insurance_half"
            required={true}
            radio
            onChange={(event, newValue)=>{
              changeValue("insurance_general", false);
              changeValue("insurance_private", false);
            }}
            touched={needsValidation}
            component={CheckboxComponent}
            type="text"
            label={t("reha.insurance_half")}
          />
        </Grid>
        <Grid item xs={12}>
          <Field name="insurance_private"
            required={true}
            radio
            onChange={(event, newValue)=>{
              changeValue("insurance_general", false);
              changeValue("insurance_half", false);
            }}
            touched={needsValidation}
            component={CheckboxComponent}
            type="text"
            label={t("reha.insurance_private")}
          />
        </Grid>
      </Grid>
      <Grid container item xs={9} >
        <Grid item xs={12}>
          <Field name="addition"
            required={true}
            touched={needsValidation}
            component={CheckboxComponent}
            type="text"
            label={t("reha.addition")}
          />
        </Grid>

        <Grid item xs={12} >
          <Field name="additional_insurance"
            required={true}
            touched={needsValidation}
            component={CheckboxComponent}
            type="text"
            label={t("reha.additional_insurance")}
          />
        </Grid>
        <Grid item xs={12} >
          <Field name="room_upgrade"
            required={true}
            touched={needsValidation}
            component={CheckboxComponent}
            type="text"
            label={t("reha.room_upgrade")}
          />
        </Grid>
      </Grid>
      {/* {formValues.additional_insurance===true? */}
      <Grid item className="m-1" xs={12}>
        <Grid item xs={12}
          hidden={formValues.additional_insurance!==true ||
          firstInsuranceCompany!==undefined} >
          <Typography sx={{color: "#ffc107"}}>
            {t("reha_insurance_missing_warning")}
          </Typography>
        </Grid>
        <Field name="additional_insurance_company_gln" hidden component={TextArea}/>
        <Field name="additional_insurance_company"
          hidden={formValues.additional_insurance!==true}
          component={AutocompleteField}
          filterCallback={(option, value)=>
            option.DESCR1?.toLowerCase().includes(value?.toLowerCase())}
          getOptionLabel={(e) => e?.DESCR1?`${e.DESCR1}`:e}
          options={insuranceCompanyProps.options}
          required={true}
          touched={needsValidation}
          label={t("patient.label.insurance.company")}
        />
      </Grid>
      {/* :null */}
      {/* } */}
    </Grid>

    <Grid item container spacing={1}>
      <Grid item xs={6} md={3}>
        <Field name="entry_date"
          required={true}
          validate={[validDate]}
          touched={needsValidation}
          component={Calendar}
          language={currentLang}
          normalize={correctDateFormat}
          minDate={datepickerLimitations.reha.entry_date.minDate}
          type="text"
          label={t("reha.entry_date")}
        />
      </Grid>
      <Grid item xs={6} md={3}>
        <Field name="rsvp_deadline"
          required={true}
          validate={[validDate]}
          touched={needsValidation}
          component={Calendar}
          language={currentLang}
          normalize={correctDateFormat}
          type="text"
          label={t("reha.rsvp_deadline")}
        />
      </Grid>
      <Grid className="p-3" item xs={12} md={6} >
        <Field name="rejoin"
          component={CheckboxComponent}
          type="text"
          label={t("reha.rejoin")}
        />
      </Grid>
    </Grid>
    <Grid item container>
      <Grid container spacing={3} >
        <Grid item xs={12} sm={12} className="m-1">
          <Field name="personal_reference"
            component={TextFieldComponent}
            type="text"
            label={t("reha.personal_reference")}
          />
        </Grid>
        <Grid item xs={12} sm={12} className="m-1">
          <Field name="religion"
            component={TextFieldComponent}
            type="text"
            label={t("reha.religion")}
          />
        </Grid>
      </Grid>
      <Grid container spacing={3} sm={6}>
        <Grid item xs={12} sm={12} className="m-1">
          <Field name="family_doctor"
            component={TextFieldComponent}
            type="text"
            label={t("reha.family_doctor")}
          />
        </Grid>
      </Grid>
    </Grid>
    <Grid item xs={12} sm={12}>
      <Field name="comments"
        minRows={3}
        component={TextArea}
        type="text"
        label={t("reha.comments")}
      />
    </Grid>
  </Grid>;
};

const mapStateToProps=(state) => {
  return {
    editData: state.singleRequest?.editData?.reha_data,
    diagnosisProps: {
      isLoading: state.loading.icd10,
      options: state.masterdata?.autocompleteOptions?.icd10,
    },
    insuranceCompanyProps: {
      options: state.masterdata?.autocompleteOptions?.insuranceCompany,
    },
    // insuranceCompanyData: state.singleRequest.insuranceCompanyData,
    formValues: formValueSelector("reha_data")(
        state, "additional_insurance", "unfall", "krankheit", "additional_insurance_company"),
    firstInsuranceCompany: formValueSelector("patientContact")(state, "patient_insurance_company"),

    needsValidation: state.singleRequest.needsValidation.form==="reha",
    requiredRehaData: state.singleRequest.requiredRehaData,
    requiredRehaProps: {
      isLoading: state.loading.reha,
      options: state.masterdata?.autocompleteOptions?.reha,
    },

  };
};


const mapDispatchToProps=(dispatch) => ({
  changeValue: (fieldName, value)=>dispatch(change("reha_data", fieldName, value)),

  requiredReha: {
    select: (object) =>dispatch(onSelectClinicObject(object)),
    keyPress: (event, diagnosis, currentLang) => {
      // dispatch(onSelectMigel(event.target.value? event.target.value:""));
    },
    fetchOptions: (event) => dispatch(fetchRehaData(event.target.value)),
  },
  diagnosis: {
    // select: (event) => dispatch(onSelectICD10Object(event.target.value? event.target.value:"")),
    // keyPress: (event) =>
    // dispatch(onSelectICD10Object(event.target.value? event.target.value:"")),
    fetchOptions: (event, currentLang) =>dispatch(fetchICD10Data(event.target.value, currentLang)),
  },
});
const RehaForm=reduxForm({
  enableReinitialize: false,
  destroyOnUnmount: false,
  form: "reha_data",
})(ReduxFormComponentIndication);

export default connect(mapStateToProps, mapDispatchToProps)(RehaForm);
