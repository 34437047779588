import React, {useEffect} from "react";
import {connect} from "react-redux";
import {useTranslation} from "react-i18next";
import {Grid, Typography} from "@mui/material";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import Checkbox from "@mui/material/Checkbox";
import FormLabel from "@mui/material/FormLabel";
import {setStudyById, removeStudy} from "pages/Wizard/wizardSlice";
import Box from "@mui/material/Box";
import {onChangeSpecialMedValue, SpecialMedAdditionalInformation} from "../../specialMedSlice";
import TextField from "@mui/material/TextField";

import {
  onChangeKVVLegalState,
  onChangeMedicineValue,
} from "../../../medicine/medicineSlice";


import {
  UseAppDispatch,
  UseAppSelector,
} from "store/hooks";

import {
  onChangeTagrissoValue,
  selectTagrissoValues,
} from "./TagrissoSlice";

import {
  onChangeTreatmentValue,
} from "../../../Treatment/treatmentPlanSlice";

import {
  useLocation,
} from "react-router-dom";

const TagrissoForm = (props) => {
  const {t} = useTranslation();
  const dispatch = UseAppDispatch();
  const TagrissoValues = UseAppSelector(selectTagrissoValues);
  const handleValueChangeKVV = (field:string, value:any)=>dispatch(onChangeTreatmentValue({field, value}));
  const handleSpecialMedValueChange = (field:string, value:any)=>dispatch(onChangeSpecialMedValue({field, value}));
  const params=useLocation();

  function clearDefaultFormValues() {
    dispatch(onChangeTagrissoValue({preTreatment: ""}));
    dispatch(onChangeTagrissoValue({patientCondition: ""}));
    dispatch(onChangeTagrissoValue({reasonsAlternativeTherapiesFailed: ""}));
  }

  useEffect(() => {
    const regex = /\d+$/;
    const isEditId = regex.test(params.pathname);
    console.debug("EnhertuForm isEditId", isEditId);
    if (!isEditId) {
      dispatch(onChangeKVVLegalState(
          {
            limitatio_approval: false,
            specialist_info: false,
          },
      ));
      handleValueChangeKVV("limitatio_approval", false);
      handleValueChangeKVV("specialist_info", false);
    }
  }, []);

  useEffect(() => {
    if (TagrissoValues.default === false) {
      clearDefaultFormValues();
    }
    dispatch(onChangeTagrissoValue({formData: {variant: "  "}}));

    if (
      TagrissoValues.default === false &&
      TagrissoValues.nonSmallCellLungCarcinomaWithCommonEGFRmIsPresent === true &&
      TagrissoValues.theTumorIsResectable === true &&
      TagrissoValues.theTumorIsAdvancedWithoutPriorTherapy === false &&
      TagrissoValues.tagrissoIsUsedInCombinationWithChemotherapy === false &&
      TagrissoValues.tagrissoIsUsedInMonoTherapy === false &&
      TagrissoValues.brainMetastasesPresent === false
    ) {
      dispatch(onChangeTagrissoValue({formData: {variant: "ADAURA"}}));
      dispatch(setStudyById({studyId: "37272535"}));
      dispatch(onChangeKVVLegalState(
          {
            limitatio_approval: false,
            specialist_info: true,
          },
      ));
      handleValueChangeKVV("limitatio_approval", false);
      handleValueChangeKVV("specialist_info", true);
      dispatch(onChangeMedicineValue({field: "kn_id", value: ""}));
      handleSpecialMedValueChange("special_med_additional_information", SpecialMedAdditionalInformation.TAGRISSO_ADAURA);
    } else if (
      TagrissoValues.default === false &&
      TagrissoValues.nonSmallCellLungCarcinomaWithCommonEGFRmIsPresent === true &&
      TagrissoValues.theTumorIsResectable === false &&
      TagrissoValues.theTumorIsAdvancedWithoutPriorTherapy === true &&
      TagrissoValues.tagrissoIsUsedInCombinationWithChemotherapy === false &&
      TagrissoValues.tagrissoIsUsedInMonoTherapy === true
    ) {
      dispatch(onChangeKVVLegalState(
          {
            limitatio_approval: true,
            specialist_info: true,
          },
      ));
      handleValueChangeKVV("limitatio_approval", true);
      handleValueChangeKVV("specialist_info", true);
      dispatch(onChangeTagrissoValue({formData: {variant: "FLAURA"}}));
      dispatch(onChangeMedicineValue({field: "kn_id", value: "20518.01"}));
      handleSpecialMedValueChange("special_med_additional_information", SpecialMedAdditionalInformation.TAGRISSO_FLAURA);
      dispatch(removeStudy({}));
    } else if (
      TagrissoValues.default === false &&
      TagrissoValues.nonSmallCellLungCarcinomaWithCommonEGFRmIsPresent === true &&
      TagrissoValues.theTumorIsResectable === false &&
      TagrissoValues.theTumorIsAdvancedWithoutPriorTherapy === true &&
      TagrissoValues.tagrissoIsUsedInCombinationWithChemotherapy === true
    ) {
      dispatch(onChangeKVVLegalState(
          {
            limitatio_approval: false,
            specialist_info: true,
          },
      ));
      handleValueChangeKVV("limitatio_approval", false);
      handleValueChangeKVV("specialist_info", true);
      dispatch(onChangeTagrissoValue({formData: {variant: "FLAURA2"}}));
      dispatch(setStudyById({studyId: "37937763"}));
      dispatch(onChangeMedicineValue({field: "kn_id", value: ""}));
      handleSpecialMedValueChange("special_med_additional_information", SpecialMedAdditionalInformation.TAGRISSO_FLAURA2);
    } else {
      dispatch(onChangeTagrissoValue({formData: {variant: "  "}}));
      dispatch(removeStudy({}));
      dispatch(onChangeMedicineValue({field: "kn_id", value: ""}));
      handleSpecialMedValueChange("special_med_additional_information", SpecialMedAdditionalInformation.NONE);
      dispatch(onChangeKVVLegalState(
          {
            limitatio_approval: false,
            specialist_info: false,
          },
      ));
      handleValueChangeKVV("limitatio_approval", false);
      handleValueChangeKVV("specialist_info", false);
    }
    if (TagrissoValues.default === true) {
      dispatch(removeStudy({}));
      dispatch(onChangeTagrissoValue({formData: {nonSmallCellLungCarcinomaWithCommonEGFRmIsPresent: false}}));
      dispatch(onChangeTagrissoValue({formData: {theTumorIsResectable: false}}));
      dispatch(onChangeTagrissoValue({formData: {theTumorIsAdvancedWithoutPriorTherapy: false}}));
      dispatch(onChangeTagrissoValue({formData: {tagrissoIsUsedInCombinationWithChemotherapy: false}}));
      dispatch(onChangeTagrissoValue({formData: {brainMetastasesPresent: false}}));
      dispatch(onChangeTagrissoValue({formData: {tagrissoIsUsedInMonoTherapy: false}}));
    }
  },
  [
    TagrissoValues.default,
    TagrissoValues.nonSmallCellLungCarcinomaWithCommonEGFRmIsPresent,
    TagrissoValues.theTumorIsResectable,
    TagrissoValues.theTumorIsAdvancedWithoutPriorTherapy,
    TagrissoValues.tagrissoIsUsedInCombinationWithChemotherapy,
    TagrissoValues.brainMetastasesPresent,
    TagrissoValues.tagrissoIsUsedInMonoTherapy,
    TagrissoValues.variant,
  ]);


  /*
      Der Tumor ist resezierbar. ODER
      Der Tumor ist fortgeschritten ohne Vortherapie (1L).
  */

  return <>

    <Grid item xs={12}>
      <FormControl>
        <FormLabel>{t("tagrisso.isNonSmallCellLungCarcinomaWithCommonEGFRmIsPresent")}</FormLabel>
        <RadioGroup
          aria-labelledby="tagrisso-radio-buttons-group-form-type"
          name="tagrisso-radio-buttons-group-form-type"
          onChange={(event, value) => {
            if (value === "default") {
              dispatch(onChangeTagrissoValue({formData: {default: true}}));
              dispatch(onChangeTagrissoValue({formData: {nonSmallCellLungCarcinomaWithCommonEGFRmIsPresent: false}}));
            } else {
              dispatch(onChangeTagrissoValue({formData: {nonSmallCellLungCarcinomaWithCommonEGFRmIsPresent: true}}));
              dispatch(onChangeTagrissoValue({formData: {default: false}}));
            }
          }}>
          <FormControlLabel
            value="nonSmallCellLungCarcinomaWithCommonEGFRmIsPresent"
            control={<Radio checked={TagrissoValues.nonSmallCellLungCarcinomaWithCommonEGFRmIsPresent}
              data-cy="nonSmallCellLungCarcinomaWithCommonEGFRmIsPresent" />}
            label={t("tagrisso.nonSmallCellLungCarcinomaWithCommonEGFRmIsPresent")}
          />
          <FormControlLabel
            value="default"
            control={<Radio checked={TagrissoValues.default}
              data-cy="default" />}
            label={t("tagrisso.default")}
          />

        </RadioGroup>
      </FormControl>
      <div hidden={
        !TagrissoValues.nonSmallCellLungCarcinomaWithCommonEGFRmIsPresent
      }>
        <FormLabel>{t("tagrisso.theTumorIsResectableOrNot")}</FormLabel>
        <RadioGroup
          aria-labelledby="tagrisso-radio-buttons-group-form-type"
          name="tagrisso-radio-buttons-group-form-type"
          onChange={(event, value) => {
            if (value === "theTumorIsResectable") {
              dispatch(onChangeTagrissoValue({formData: {theTumorIsResectable: true}}));
              dispatch(onChangeTagrissoValue({formData: {theTumorIsAdvancedWithoutPriorTherapy: false}}));
              dispatch(onChangeTagrissoValue({formData: {tagrissoIsUsedInCombinationWithChemotherapy: false}}));
              dispatch(onChangeTagrissoValue({formData: {tagrissoIsUsedInMonoTherapy: false}}));
              dispatch(onChangeTagrissoValue({formData: {brainMetastasesPresent: false}}));
            } else {
              dispatch(onChangeTagrissoValue({formData: {theTumorIsResectable: false}}));
              dispatch(onChangeTagrissoValue({formData: {theTumorIsAdvancedWithoutPriorTherapy: true}}));
            }
          }}>
          <FormControlLabel
            value="theTumorIsAdvancedWithoutPriorTherapy"
            control={<Radio checked={TagrissoValues.theTumorIsAdvancedWithoutPriorTherapy}
              data-cy="theTumorIsAdvancedWithoutPriorTherapy" />}
            label={t("tagrisso.theTumorIsAdvancedWithoutPriorTherapy")}
          />
          <FormControlLabel
            value="theTumorIsResectable"
            control={<Radio checked={TagrissoValues.theTumorIsResectable}
              data-cy="theTumorIsResectable" />}
            label={t("tagrisso.theTumorIsResectable")}
          />

        </RadioGroup>

        {
          // add options with this value
        }
        <div hidden={
          TagrissoValues.theTumorIsResectable ||
          TagrissoValues.theTumorIsAdvancedWithoutPriorTherapy === false
        }>
          <FormLabel>{t("tagrisso.IsUsedInCombinationWithOrWithoutChemotherapy")}</FormLabel>
          <RadioGroup
            aria-labelledby="tagrisso-radio-buttons-group-form-type"
            name="tagrisso-radio-buttons-group-form-type"
            onChange={(event, value) => {
              if (value === "tagrissoIsUsedInCombinationWithChemotherapy") {
                dispatch(onChangeTagrissoValue({formData: {tagrissoIsUsedInCombinationWithChemotherapy: true}}));
                dispatch(onChangeTagrissoValue({formData: {tagrissoIsUsedInMonoTherapy: false}}));
              } else {
                dispatch(onChangeTagrissoValue({formData: {tagrissoIsUsedInMonoTherapy: true}}));
                dispatch(onChangeTagrissoValue({formData: {tagrissoIsUsedInCombinationWithChemotherapy: false}}));
              }
            }}>
            <FormControlLabel
              value="tagrissoIsUsedMonoTherapy"
              control={<Radio checked={TagrissoValues.tagrissoIsUsedInMonoTherapy}
                data-cy="tagrissoIsUsedMonoTherapy" />}
              label={t("tagrisso.tagrissoIsUsedMonoTherapy")}
            />
            <FormControlLabel
              value="tagrissoIsUsedInCombinationWithChemotherapy"
              control={<Radio checked={TagrissoValues.tagrissoIsUsedInCombinationWithChemotherapy}
                data-cy="tagrissoIsUsedInCombinationWithChemotherapy" />}
              label={t("tagrisso.tagrissoIsUsedInCombinationWithChemotherapy")}
            />
          </RadioGroup>
          <FormControl fullWidth>
            <FormControlLabel
              control={
                <Checkbox
                  checked={TagrissoValues.brainMetastasesPresent}
                  data-cy="brainMetastasesPresent"
                  onChange={(event, checked) => {
                    dispatch(onChangeTagrissoValue({formData: {brainMetastasesPresent: checked}}));
                  }}
                />} label={t("tagrisso.brainMetastasesPresent")} />
          </FormControl>
        </div>
      </div>
    </Grid>

    <Grid item xs={12}>
      <div hidden={
        TagrissoValues.default === false
      }>
        <Box sx={{m: 1}}/>
        <Typography >{t("tagrisso.details.text")}</Typography>
        <Box sx={{m: 1}}/>
        <TextField
          style={{width: "100%"}}
          InputLabelProps={{shrink: true}}
          data-cy="preTreatment"
          label={t("tagrisso.defaultForm.preTreatment")}
          value={TagrissoValues.defaultForm?.preTreatment}
          multiline
          maxRows={4}
          minRows={4}
          variant="outlined"
          onChange = {(event ) =>
            dispatch(onChangeTagrissoValue(
                {
                  preTreatment: (event.target as HTMLInputElement).value,
                }),
            )
          }
        />
        <Box sx={{m: 1}}/>
        <TextField
          multiline
          style={{width: "100%"}}
          label={t("tagrisso.defaultForm.patientCondition")}
          InputLabelProps={{shrink: true}}
          value={TagrissoValues.defaultForm?.patientCondition }
          variant="outlined"
          maxRows={4}
          minRows={4}
          onChange = {(event ) =>
            dispatch(onChangeTagrissoValue(
                {
                  patientCondition: (event.target as HTMLInputElement).value,
                }),
            )
          }
        />

        <Box sx={{m: 1}}/>
        <TextField
          style={{width: "100%"}}
          label={t("tagrisso.defaultForm.reasonsAlternativeTherapiesFailed")}
          InputLabelProps={{shrink: true}}
          value={TagrissoValues.defaultForm?.reasonsAlternativeTherapiesFailed }
          variant="outlined"
          maxRows={4}
          minRows={4}
          multiline
          onChange = {(event ) =>
            dispatch(onChangeTagrissoValue(
                {
                  reasonsAlternativeTherapiesFailed: (event.target as HTMLInputElement).value,
                }),
            )
          }
        />
      </div>

    </Grid>
    <Box sx={{p: 1}} />
    <Grid item xs={12}>
      {
        TagrissoValues.variant !=="" && TagrissoValues.variant !=="  " && TagrissoValues.default === false ?
          <FormLabel >{t("tagrisso.variant")}:&nbsp;{TagrissoValues.variant}&nbsp;</FormLabel> :
        <></>
      }
    </Grid>
  </>;
};

const mapStateToProps=(state) => {
  return {
  };
};

const mapDispatchToProps=(dispatch) => ({

});

export default connect(mapStateToProps, mapDispatchToProps)(TagrissoForm);


