import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {RootState} from "store/rootstate";

export interface ImbruvicaState {
  formData: {
    // CLL
    formCLL : {
      notYetCLLTherapy: boolean,
      mutatedIGHVStatus: boolean,
      mutatedTP53Or17p: boolean,
      monotherapie: boolean,
      younger65: boolean,
      noFludarabin: boolean,
      combiRituximab: boolean,
      younger70: boolean,
      variant: number,
      textfield: string,
    }
    // Waldenström
    formMW: {
      variant: number,
      patientDidNotReceiveMWTherapy: boolean,
      patientDidReceiveMWTherapy: boolean,
      // options
      monotherapie: boolean,
      combotherapie: boolean,
      // checkboxes
      imbruvicaInCombinationWithRituximab: boolean,
      rituximabBasedChemoimmunotherapyNotAnOption: boolean,
      patientSufferedRelapseWithin24MonthsOfRituximabBasedTherapy: boolean,
      chemotherapyIsNotAnOption: boolean,
      chemoImmuneTherapyIsNotAnOption: boolean,
      rituximabMonotherapyIsNotAnOption: boolean,
      textfield: string,
    }
    formMCL: {
      variant: number,
      monotherapie: boolean,
      combotherapie: boolean,
      L1: boolean,
      L2: boolean,
      combiRituximab: boolean,
      translokation: boolean,
      partialResponseNotAchieved: boolean,
      progressionAfterPreviousTherapy: boolean,
      textfield: string,
    }
  }
}

const initialState:ImbruvicaState = {
  formData: {
    formCLL: {
      notYetCLLTherapy: false,
      mutatedIGHVStatus: false,
      mutatedTP53Or17p: false,
      monotherapie: false,
      younger65: false,
      noFludarabin: false,
      combiRituximab: false,
      younger70: false,
      variant: 15,
      //
      textfield: "",
    },
    formMW: {
      variant: 0,
      // options
      patientDidNotReceiveMWTherapy: false,
      patientDidReceiveMWTherapy: false,
      // options
      monotherapie: false,
      combotherapie: false,
      // checkboxes
      imbruvicaInCombinationWithRituximab: false,
      rituximabBasedChemoimmunotherapyNotAnOption: false,
      patientSufferedRelapseWithin24MonthsOfRituximabBasedTherapy: false,
      chemotherapyIsNotAnOption: false,
      chemoImmuneTherapyIsNotAnOption: false,
      rituximabMonotherapyIsNotAnOption: false,
      //
      textfield: "",
    },
    formMCL: {
      variant: 1,
      monotherapie: false,
      combotherapie: false,
      L1: false,
      L2: false,
      combiRituximab: false,
      translokation: false,
      partialResponseNotAchieved: false,
      progressionAfterPreviousTherapy: false,
      //
      textfield: "",
    },

  },
};


const imbruvicaFormSlice = createSlice({
  name: "imbruvica",
  initialState,
  reducers: {

    onReceiveSpecialMedImbruvicaEditData: (state, {payload}: PayloadAction<any>) => {
      console.debug("onReceiveSpecialMedimbruvicaEditData", JSON.stringify(payload));
      payload = payload.formData;
      if (payload?.formCLL?.notYetCLLTherapy === true || payload?.formCLL?.notYetCLLTherapy === false) {
        state.formData.formCLL.notYetCLLTherapy = payload?.formCLL?.notYetCLLTherapy;
      }
      if (payload?.formCLL?.mutatedIGHVStatus === true || payload?.formCLL?.mutatedIGHVStatus === false) {
        state.formData.formCLL.mutatedIGHVStatus = payload?.formCLL?.mutatedIGHVStatus;
      }
      if (payload?.formCLL?.mutatedTP53Or17p === true || payload?.formCLL?.mutatedTP53Or17p === false) {
        state.formData.formCLL.mutatedTP53Or17p = payload?.formCLL?.mutatedTP53Or17p;
      }
      if (payload?.formCLL?.monotherapie === true || payload?.formCLL?.monotherapie === false) {
        state.formData.formCLL.monotherapie = payload?.formCLL?.monotherapie;
      }
      if (payload?.formCLL?.younger65 === true || payload?.formCLL?.younger65 === false) {
        state.formData.formCLL.younger65 = payload?.formCLL?.younger65;
      }
      if (payload?.formCLL?.noFludarabin === true || payload?.formCLL?.noFludarabin === false) {
        state.formData.formCLL.noFludarabin = payload?.formCLL?.noFludarabin;
      }
      if (payload?.formCLL?.younger70 === true || payload?.formCLL?.younger70 === false) {
        state.formData.formCLL.younger70 = payload?.formCLL?.younger70;
      }
      if (payload?.formCLL?.combiRituximab === true || payload?.formCLL?.combiRituximab === false) {
        state.formData.formCLL.combiRituximab = payload?.formCLL?.combiRituximab;
      }
      if (payload?.formCLL?.variant > 0) {
        state.formData.formCLL.variant = payload?.formCLL?.variant;
      }
      if (payload?.formCLL?.textfield?.length > 0) {
        state.formData.formCLL.textfield = payload?.formCLL?.textfield;
      }

      // MCL
      if (payload?.formMCL?.variant > 0) {
        state.formData.formMCL.variant = payload?.formMCL?.variant;
      }
      if (payload?.formMCL?.monotherapie === true || payload?.formMCL?.monotherapie === false) {
        state.formData.formMCL.monotherapie = payload?.formMCL?.monotherapie;
      }
      if (payload?.formMCL?.combotherapie === true || payload?.formMCL?.combotherapie === false) {
        state.formData.formMCL.combotherapie = payload?.formMCL?.combotherapie;
      }
      if (payload?.formMCL?.L1 === true || payload?.formMCL?.L1 === false) {
        state.formData.formMCL.L1 = payload?.formMCL?.L1;
      }
      if (payload?.formMCL?.L2 === true || payload?.formMCL?.L2 === false) {
        state.formData.formMCL.L2 = payload?.formMCL?.L2;
      }
      if (payload?.formMCL?.combiRituximab=== true || payload?.formMCL?.combiRituximab === false) {
        state.formData.formMCL.combiRituximab = payload?.formMCL?.combiRituximab;
      }
      if (payload?.formMCL?.translokation === true || payload?.formMCL?.translokation === false) {
        state.formData.formMCL.translokation = payload?.formMCL?.translokation;
      }
      if (payload?.formMCL?.partialResponseNotAchieved === true ||
        payload?.formMCL?.partialResponseNotAchieved === false) {
        state.formData.formMCL.partialResponseNotAchieved = payload?.formMCL?.partialResponseNotAchieved;
      }
      if (payload?.formMCL?.progressionAfterPreviousTherapy === true ||
        payload?.formMCL?.progressionAfterPreviousTherapy === false) {
        state.formData.formMCL.progressionAfterPreviousTherapy = payload?.formMCL?.progressionAfterPreviousTherapy;
      }
      if (payload?.formMCL?.textfield?.length > 0) {
        state.formData.formMCL.textfield = payload?.formMCL?.textfield;
      }

      // MW
      if (payload?.formMW?.variant > 0) {
        state.formData.formMW.variant = payload?.formMW?.variant;
      }
      if (payload?.formMW?.textfield?.length > 0) {
        state.formData.formMW.textfield = payload?.formMW?.textfield;
      }
      if (payload?.formMW?.patientDidNotReceiveMWTherapy === true ) {
        state.formData.formMW.patientDidNotReceiveMWTherapy = true;
      }
      if (payload?.formMW?.patientDidReceiveMWTherapy === true ) {
        state.formData.formMW.patientDidReceiveMWTherapy = true;
      }
      if (payload?.formMW?.monotherapie === true ) {
        state.formData.formMW.monotherapie = true;
      }
      if (payload?.formMW?.combotherapie === true ) {
        state.formData.formMW.combotherapie = true;
      }
      if (payload?.formMW?.imbruvicaInCombinationWithRituximab === true ) {
        state.formData.formMW.imbruvicaInCombinationWithRituximab = true;
      }
      if (payload?.formMW?.rituximabBasedChemoimmunotherapyNotAnOption === true ) {
        state.formData.formMW.rituximabBasedChemoimmunotherapyNotAnOption = true;
      }
      if (payload?.formMW?.patientSufferedRelapseWithin24MonthsOfRituximabBasedTherapy === true ) {
        state.formData.formMW.patientSufferedRelapseWithin24MonthsOfRituximabBasedTherapy = true;
      }
      if (payload?.formMW?.chemotherapyIsNotAnOption === true ) {
        state.formData.formMW.chemotherapyIsNotAnOption = true;
      }
      if (payload?.formMW?.chemoImmuneTherapyIsNotAnOption === true ) {
        state.formData.formMW.chemoImmuneTherapyIsNotAnOption = true;
      }
      if (payload?.formMW?.rituximabMonotherapyIsNotAnOption === true ) {
        state.formData.formMW.rituximabMonotherapyIsNotAnOption = true;
      }
    },

    onClearImbruvicaState: (state) => {
      console.debug("onClearImbruvicaState");
      state.formData=initialState.formData;
    },

    onChangeImbruvicaValue: (state, {payload}: PayloadAction<any>) => {
      console.debug("onChangeimbruvicaValue", payload);
      // CLL
      if (payload?.formCLL?.notYetCLLTherapy === true || payload?.formCLL?.notYetCLLTherapy === false) {
        state.formData.formCLL.notYetCLLTherapy = payload?.formCLL?.notYetCLLTherapy;
      }
      if (payload?.formCLL?.mutatedIGHVStatus === true || payload?.formCLL?.mutatedIGHVStatus === false) {
        state.formData.formCLL.mutatedIGHVStatus = payload?.formCLL?.mutatedIGHVStatus;
      }
      if (payload?.formCLL?.mutatedTP53Or17p === true || payload?.formCLL?.mutatedTP53Or17p === false) {
        state.formData.formCLL.mutatedTP53Or17p = payload?.formCLL?.mutatedTP53Or17p;
      }
      if (payload?.formCLL?.monotherapie === true || payload?.formCLL?.monotherapie === false) {
        state.formData.formCLL.monotherapie = payload?.formCLL?.monotherapie;
      }
      if (payload?.formCLL?.younger65 === true || payload?.formCLL?.younger65 === false) {
        state.formData.formCLL.younger65 = payload?.formCLL?.younger65;
      }
      if (payload?.formCLL?.noFludarabin === true || payload?.formCLL?.noFludarabin === false) {
        state.formData.formCLL.noFludarabin = payload?.formCLL?.noFludarabin;
      }
      if (payload?.formCLL?.younger70 === true || payload?.formCLL?.younger70 === false) {
        state.formData.formCLL.younger70 = payload?.formCLL?.younger70;
      }
      if (payload?.formCLL?.combiRituximab === true || payload?.formCLL?.combiRituximab === false) {
        state.formData.formCLL.combiRituximab = payload?.formCLL?.combiRituximab;
      }
      if (payload?.formCLL?.textfield?.length > 0) {
        state.formData.formCLL.textfield = payload?.formCLL?.textfield;
      }


      // MCL
      if (payload?.formMCL?.monotherapie === true || payload?.formMCL?.monotherapie === false) {
        state.formData.formMCL.monotherapie = payload?.formMCL?.monotherapie;
      }
      if (payload?.formMCL?.combotherapie === true || payload?.formMCL?.combotherapie === false) {
        state.formData.formMCL.combotherapie = payload?.formMCL?.combotherapie;
      }
      if (payload?.formMCL?.L1 === true || payload?.formMCL?.L1 === false) {
        state.formData.formMCL.L1 = payload?.formMCL?.L1;
      }
      if (payload?.formMCL?.L2 === true || payload?.formMCL?.L2 === false) {
        state.formData.formMCL.L2 = payload?.formMCL?.L2;
      }
      if (payload?.formMCL?.combiRituximab === true || payload?.formMCL?.combiRituximab === false) {
        state.formData.formMCL.combiRituximab = payload?.formMCL?.combiRituximab;
      }
      if (payload?.formMCL?.translokation === true || payload?.formMCL?.translokation === false) {
        state.formData.formMCL.translokation = payload?.formMCL?.translokation;
      }
      if (payload?.formMCL?.partialResponseNotAchieved === true || payload?.formMCL?.partialResponseNotAchieved === false) {
        state.formData.formMCL.partialResponseNotAchieved = payload?.formMCL?.partialResponseNotAchieved;
      }
      if (payload?.formMCL?.progressionAfterPreviousTherapy === true ||
        payload?.formMCL?.progressionAfterPreviousTherapy === false) {
        state.formData.formMCL.progressionAfterPreviousTherapy = payload?.formMCL?.progressionAfterPreviousTherapy;
      }
      if (payload?.formMCL?.textfield?.length > 0) {
        state.formData.formMCL.textfield = payload?.formMCL?.textfield;
      }


      // MW
      if (payload?.formMWchoice === "combotherapie" ) {
        state.formData.formMW.combotherapie = true;
        state.formData.formMW.monotherapie = false;
      } else if (payload?.formMWchoice === "monotherapie" ) {
        state.formData.formMW.combotherapie = false;
        state.formData.formMW.monotherapie = true;
      }
      if (payload?.formMWchoice === "patientDidNotReceiveMWTherapy" ) {
        state.formData.formMW.patientDidNotReceiveMWTherapy = true;
        state.formData.formMW.patientDidReceiveMWTherapy = false;
      } else if (payload?.formMWchoice === "patientDidReceiveMWTherapy" ) {
        state.formData.formMW.patientDidNotReceiveMWTherapy = false;
        state.formData.formMW.patientDidReceiveMWTherapy = true;
      }

      if (payload?.formMW?.textfield?.length > 0) {
        state.formData.formMW.textfield = payload?.formMW?.textfield;
      }

      if (
        payload?.formMW?.imbruvicaInCombinationWithRituximab === true ||
        payload?.formMW?.imbruvicaInCombinationWithRituximab === false) {
        state.formData.formMW.imbruvicaInCombinationWithRituximab = payload?.formMW?.imbruvicaInCombinationWithRituximab;
      }
      if (
        payload?.formMW?.rituximabBasedChemoimmunotherapyNotAnOption === true ||
        payload?.formMW?.rituximabBasedChemoimmunotherapyNotAnOption === false) {
        state.formData.formMW.rituximabBasedChemoimmunotherapyNotAnOption =
          payload?.formMW?.rituximabBasedChemoimmunotherapyNotAnOption;
      }
      if (
        payload?.formMW?.patientSufferedRelapseWithin24MonthsOfRituximabBasedTherapy === true ||
        payload?.formMW?.patientSufferedRelapseWithin24MonthsOfRituximabBasedTherapy === false) {
        state.formData.formMW.patientSufferedRelapseWithin24MonthsOfRituximabBasedTherapy =
          payload?.formMW?.patientSufferedRelapseWithin24MonthsOfRituximabBasedTherapy;
      }
      if (
        payload?.formMW?.chemotherapyIsNotAnOption === true ||
        payload?.formMW?.chemotherapyIsNotAnOption === false) {
        state.formData.formMW.chemotherapyIsNotAnOption =
          payload?.formMW?.chemotherapyIsNotAnOption;
      }
      if (
        payload?.formMW?.chemoImmuneTherapyIsNotAnOption === true ||
        payload?.formMW?.chemoImmuneTherapyIsNotAnOption === false) {
        state.formData.formMW.chemoImmuneTherapyIsNotAnOption =
          payload?.formMW?.chemoImmuneTherapyIsNotAnOption;
      }
      if (
        payload?.formMW?.rituximabMonotherapyIsNotAnOption === true ||
        payload?.formMW?.rituximabMonotherapyIsNotAnOption === false) {
        state.formData.formMW.rituximabMonotherapyIsNotAnOption =
          payload?.formMW?.rituximabMonotherapyIsNotAnOption;
      }
      setVariantFormCLL(state);
      setVariantFormMCL(state);
      setVariantFormMW(state);
      setCombinedValuesFormMW(state);
    },
  },
});

export const {
  onReceiveSpecialMedImbruvicaEditData,
  onClearImbruvicaState,
  onChangeImbruvicaValue,
} = imbruvicaFormSlice.actions;

export default imbruvicaFormSlice.reducer;

export const selectImbruvicaValues = (state:RootState) =>
  state.wizard.medicine.additionalInformation.imbruvicaForm.formData;

function setVariantFormMCL(state) {
  if (
    state.formData.formMCL.monotherapie === true
  ) {
    state.formData.formMCL.combiRituximab = false;
  }
  if (
    state.formData.formMCL.combotherapie === true
  ) {
    state.formData.formMCL.L1 = false;
    state.formData.formMCL.L2 = false;
    state.formData.formMCL.translokation = false;
    state.formData.formMCL.partialResponseNotAchieved = false;
    state.formData.formMCL.progressionAfterPreviousTherapy = false;
  }
  if (
    state.formData.formMCL.l1 === true
  ) {
    state.formData.formMCL.translokation = false;
    state.formData.formMCL.partialResponseNotAchieved = false;
    state.formData.formMCL.progressionAfterPreviousTherapy = false;
  }


  if (
    state.formData.formMCL.monotherapie === true &&
    state.formData.formMCL.L1 === true &&
    state.formData.formMCL.L2 === false
  ) {
    console.debug("Variant 1");
    state.formData.formMCL.variant = 1;
  } else if (
    state.formData.formMCL.monotherapie === true &&
    state.formData.formMCL.L1 === false &&
    state.formData.formMCL.L2 === true
  // state.formData.formMCL.translokation === true
  ) {
    console.debug("Variant 2");
    state.formData.formMCL.variant = 2;
  } else if (
    state.formData.formMCL.combotherapie === true &&
    state.formData.formMCL.combiRituximab === true
  ) {
    console.debug("Variant 3");
    state.formData.formMCL.variant = 3;
  } else if (
    state.formData.formMCL.combotherapie === true &&
    state.formData.formMCL.combiRituximab === false
  ) {
    console.debug("Variant 4");
    state.formData.formMCL.variant = 4;
  } else {
    console.debug("Variant 0");
    state.formData.formMCL.variant = 0;
  }

  if (state.formData.formMCL.variant !== 2) {
    state.formData.formMCL.translokation = false;
    state.formData.formMCL.partialResponseNotAchieved = false;
    state.formData.formMCL.progressionAfterPreviousTherapy = false;
  }
}
function setVariantFormMW(state) {
  if (state.formData.formMW.patientDidNotReceiveMWTherapy === true) {
    if (
      state.formData.formMW.monotherapie === true &&
      state.formData.formMW.rituximabBasedChemoimmunotherapyNotAnOption === true
    ) {
      state.formData.formMW.variant = 1;
    } else if (
      state.formData.formMW.monotherapie === true &&
      state.formData.formMW.rituximabBasedChemoimmunotherapyNotAnOption === false
    ) {
      state.formData.formMW.variant = 2;
    } else if (
      state.formData.formMW.combotherapie === true &&
      state.formData.formMW.imbruvicaInCombinationWithRituximab === true
    ) {
      state.formData.formMW.variant = 3;
    } else if (
      state.formData.formMW.combotherapie === true &&
      state.formData.formMW.imbruvicaInCombinationWithRituximab === false
    ) {
      state.formData.formMW.variant = 4;
    }
  } else if (state.formData.formMW.patientDidNotReceiveMWTherapy === false) {
    if (
      state.formData.formMW.monotherapie === true &&
      state.formData.formMW.patientSufferedRelapseWithin24MonthsOfRituximabBasedTherapy === true
    ) {
      state.formData.formMW.variant = 5;
    } else if (
      state.formData.formMW.monotherapie === true &&
      state.formData.formMW.patientSufferedRelapseWithin24MonthsOfRituximabBasedTherapy === false
    ) {
      state.formData.formMW.variant = 6;
    } else if (
      state.formData.formMW.monotherapie === false &&
      state.formData.formMW.combotherapie === true &&
      state.formData.formMW.imbruvicaInCombinationWithRituximab === true
    ) {
      state.formData.formMW.variant = 7;
    } else if (
      state.formData.formMW.monotherapie === false &&
      state.formData.formMW.combotherapie === true &&
      state.formData.formMW.imbruvicaInCombinationWithRituximab === false
    ) {
      state.formData.formMW.variant = 8;
    }
  }
}

function setCombinedValuesFormMW(state) {
  if (state.formData.formMW.monotherapie) {
    state.formData.formMW.imbruvicaInCombinationWithRituximab = false;
  }
  if (state.formData.formMW.combotherapie) {
    state.formData.formMW.patientSufferedRelapseWithin24MonthsOfRituximabBasedTherapy = false;
  }
  if (
    !state.formData.formMW.patientDidNotReceiveMWTherapy &&
    !state.formData.formMW.monotherapie) {
    state.formData.formMW.rituximabBasedChemoimmunotherapyNotAnOption = false;
  }
  if (
    !state.formData.formMW.patientDidReceiveMWTherapy &&
    !state.formData.formMW.monotherapie) {
    state.formData.formMW.patientSufferedRelapseWithin24MonthsOfRituximabBasedTherapy = false;
  }
  if (
    (
      state.formData.formMW.patientDidNotReceiveMWTherapy ||
      state.formData.formMW.monotherapie ||
      !state.formData.formMW.imbruvicaInCombinationWithRituximab
    ) &&
    !(state.formData.formMW.variant === 3)
  ) {
    state.formData.formMW.chemotherapyIsNotAnOption = false;
    state.formData.formMW.chemoImmuneTherapyIsNotAnOption = false;
    state.formData.formMW.rituximabMonotherapyIsNotAnOption = false;
  }
}

function setVariantFormCLL(state) {
  if (state.formData.formCLL.notYetCLLTherapy === true &&
    state.formData.formCLL.mutatedIGHVStatus === false &&
    state.formData.formCLL.mutatedTP53Or17p === true &&
    state.formData.formCLL.monotherapie === false &&
    state.formData.formCLL.younger65 === false &&
    state.formData.formCLL.younger70 === false &&
    state.formData.formCLL.noFludarabin === false &&
    state.formData.formCLL.combiRituximab === false) {
    // Variant 1
    console.debug("Variant 1");
    state.formData.formCLL.variant = 1;
  } else if (state.formData.formCLL.notYetCLLTherapy === true &&
    state.formData.formCLL.mutatedIGHVStatus === true &&
    state.formData.formCLL.mutatedTP53Or17p === false &&
    state.formData.formCLL.monotherapie === true &&
    state.formData.formCLL.younger65 === true &&
    state.formData.formCLL.noFludarabin === false &&
    state.formData.formCLL.combiRituximab === false) {
    // Variant 2
    state.formData.formCLL.variant = 2;
  } else if (state.formData.formCLL.notYetCLLTherapy === true &&
    state.formData.formCLL.mutatedIGHVStatus === true &&
    state.formData.formCLL.mutatedTP53Or17p === false &&
    state.formData.formCLL.monotherapie === true &&
    state.formData.formCLL.younger65 === false &&
    state.formData.formCLL.younger70 === false &&
    state.formData.formCLL.noFludarabin === false &&
    state.formData.formCLL.combiRituximab === false) {
    // Variant 3
    state.formData.formCLL.variant = 3;
  } else if (state.formData.formCLL.notYetCLLTherapy === true &&
    state.formData.formCLL.mutatedIGHVStatus === true &&
    state.formData.formCLL.mutatedTP53Or17p === false &&
    state.formData.formCLL.monotherapie === false &&
    state.formData.formCLL.younger65 === false &&
    state.formData.formCLL.younger70 === true &&
    state.formData.formCLL.noFludarabin === false &&
    state.formData.formCLL.combiRituximab === true) {
    // Variant 4
    state.formData.formCLL.variant = 4;
  } else if (
    state.formData.formCLL.notYetCLLTherapy === true &&
    state.formData.formCLL.mutatedIGHVStatus === true &&
    state.formData.formCLL.mutatedTP53Or17p === false &&
    state.formData.formCLL.monotherapie === false &&
    state.formData.formCLL.younger65 === false &&
    state.formData.formCLL.younger70 === false &&
    state.formData.formCLL.noFludarabin === false &&
    state.formData.formCLL.combiRituximab === true) {
    // Variant 5
    state.formData.formCLL.variant = 5;
  } else if (state.formData.formCLL.notYetCLLTherapy === true &&
    state.formData.formCLL.mutatedIGHVStatus === true &&
    state.formData.formCLL.mutatedTP53Or17p === false &&
    state.formData.formCLL.monotherapie === false &&
    state.formData.formCLL.younger65 === false &&
    state.formData.formCLL.younger70 === false &&
    state.formData.formCLL.noFludarabin === false &&
    state.formData.formCLL.combiRituximab === false) {
    // Variant 6
    state.formData.formCLL.variant = 6;
  } else if (state.formData.formCLL.notYetCLLTherapy === true &&
    state.formData.formCLL.mutatedIGHVStatus === false &&
    state.formData.formCLL.mutatedTP53Or17p === false &&
    state.formData.formCLL.monotherapie === true &&
    state.formData.formCLL.younger65 === true &&
    state.formData.formCLL.noFludarabin === false &&
    state.formData.formCLL.combiRituximab === false) {
    // Variant 7
    state.formData.formCLL.variant = 7;
  } else if (state.formData.formCLL.notYetCLLTherapy === true &&
    state.formData.formCLL.mutatedIGHVStatus === false &&
    state.formData.formCLL.mutatedTP53Or17p === false &&
    state.formData.formCLL.monotherapie === true &&
    state.formData.formCLL.younger65 === false &&
    state.formData.formCLL.younger70 === false &&
    state.formData.formCLL.noFludarabin === true &&
    state.formData.formCLL.combiRituximab === false) {
    // Variant 8
    state.formData.formCLL.variant = 8;
  } else if (state.formData.formCLL.notYetCLLTherapy === true &&
    state.formData.formCLL.mutatedIGHVStatus === false &&
    state.formData.formCLL.mutatedTP53Or17p === false &&
    state.formData.formCLL.monotherapie === false &&
    state.formData.formCLL.younger65 === false &&
    state.formData.formCLL.younger70 === true &&
    state.formData.formCLL.noFludarabin === true &&
    state.formData.formCLL.combiRituximab === true) {
    // Variant 9
    state.formData.formCLL.variant = 9;
  } else if (
    state.formData.formCLL.notYetCLLTherapy === true &&
    state.formData.formCLL.mutatedIGHVStatus === false &&
    state.formData.formCLL.mutatedTP53Or17p === false &&
    state.formData.formCLL.monotherapie === false &&
    state.formData.formCLL.younger65 === true &&
    state.formData.formCLL.noFludarabin === true &&
    state.formData.formCLL.combiRituximab === true) {
    // Variant 9
    state.formData.formCLL.variant = 9;
  } else if (
    state.formData.formCLL.notYetCLLTherapy === true &&
    state.formData.formCLL.mutatedIGHVStatus === false &&
    state.formData.formCLL.mutatedTP53Or17p === false &&
    state.formData.formCLL.monotherapie === false &&
    state.formData.formCLL.younger65 === false &&
    state.formData.formCLL.younger70 === true &&
    state.formData.formCLL.noFludarabin === false &&
    state.formData.formCLL.combiRituximab === true
  ) {
    // Variant 10
    state.formData.formCLL.variant = 10;
  } else if (
    state.formData.formCLL.notYetCLLTherapy === true &&
    state.formData.formCLL.mutatedIGHVStatus === false &&
    state.formData.formCLL.mutatedTP53Or17p === false &&
    state.formData.formCLL.monotherapie === true &&
    state.formData.formCLL.younger65 === false &&
    state.formData.formCLL.younger70 === false &&
    state.formData.formCLL.noFludarabin === false &&
    state.formData.formCLL.combiRituximab === false
  ) {
    // Variant 10
    state.formData.formCLL.variant = 10;
  } else if (state.formData.formCLL.notYetCLLTherapy === true &&
    state.formData.formCLL.mutatedIGHVStatus === false &&
    state.formData.formCLL.mutatedTP53Or17p === false &&
    state.formData.formCLL.monotherapie === false &&
    state.formData.formCLL.younger65 === false &&
    state.formData.formCLL.younger70 === false &&
    state.formData.formCLL.noFludarabin === false &&
    state.formData.formCLL.combiRituximab === true) {
    // Variant 11
    state.formData.formCLL.variant = 11;
  } else if (
    state.formData.formCLL.notYetCLLTherapy === true &&
    state.formData.formCLL.mutatedIGHVStatus === false &&
    state.formData.formCLL.mutatedTP53Or17p === false &&
    state.formData.formCLL.monotherapie === false &&
    state.formData.formCLL.younger65 === false &&
    state.formData.formCLL.younger70 === false &&
    state.formData.formCLL.noFludarabin === false &&
    state.formData.formCLL.combiRituximab === false) {
    // Variant 12
    state.formData.formCLL.variant = 12;
  } else if (
    state.formData.formCLL.notYetCLLTherapy === false &&
    state.formData.formCLL.mutatedIGHVStatus === false &&
    state.formData.formCLL.mutatedTP53Or17p === false &&
    state.formData.formCLL.monotherapie === true &&
    state.formData.formCLL.younger65 === false &&
    state.formData.formCLL.younger70 === false &&
    state.formData.formCLL.noFludarabin === false &&
    state.formData.formCLL.combiRituximab === false) {
    // Variant 13
    state.formData.formCLL.variant = 13;
  } else if (
    state.formData.formCLL.notYetCLLTherapy === false &&
    state.formData.formCLL.mutatedIGHVStatus === false &&
    state.formData.formCLL.mutatedTP53Or17p === false &&
    state.formData.formCLL.monotherapie === false &&
    state.formData.formCLL.younger65 === false &&
    state.formData.formCLL.younger70 === false &&
    state.formData.formCLL.noFludarabin === false &&
    state.formData.formCLL.combiRituximab === true) {
    // Variant 14
    state.formData.formCLL.variant = 14;
  } else if (
    state.formData.formCLL.notYetCLLTherapy === false &&
    state.formData.formCLL.mutatedIGHVStatus === false &&
    state.formData.formCLL.mutatedTP53Or17p === false &&
    state.formData.formCLL.monotherapie === false &&
    state.formData.formCLL.younger65 === false &&
    state.formData.formCLL.younger70 === false &&
    state.formData.formCLL.noFludarabin === false &&
    state.formData.formCLL.combiRituximab === false) {
    // Variant 15
    state.formData.formCLL.variant = 15;
  } else {
    console.debug("Variant 0");
    state.formData.formCLL.variant = 0;
  }
}
