import {createSlice} from "@reduxjs/toolkit";

function strcmp(a, b, asc) {
  if (asc) {
    return (a < b ? -1 : (a > b ? 1 : 0));
  } else {
    return (a < b ? 1 : (a > b ? -1 : 0));
  }
}

function datecmp(a, b, asc) {
  if (asc) {
    return new Date(b) - new Date(a);
  } else {
    return new Date(a) - new Date(b);
  }
};
const initialState = {
  searchQuery: "",
  studiesFiles: [],
  studiesStatus: false,
  itemList: [],
  notificationList: [],
  communicationList: [],
  isLoadingDocument: {},
  filters: [],
};

const RequestListReducerSlice = createSlice({
  name: "RequestListReducer",
  initialState: initialState,
  reducers: {
    receiveRequests(state, action) {
      return {
        ...state,
        isError: false,
        itemList: action.payload || [],
      };
    },
    removeRequest(state, action) {
      const requestId = action.payload;
      const result = state.itemList.filter((request) => request.request_id !== requestId);
      return {
        ...state,
        isError: false,
        itemList: result,
      };
    },
    onDeleteFilter(state, action) {
      const newFilters = {...state.filters};
      delete newFilters[action.payload.filter];
      return {
        ...state,
        filters: newFilters,
      };
    },
    onFilterChange(state, action) {
      return {
        ...state,
        filters: {
          ...state.filters,
          [action.payload.filter]: action.payload.value,
        },
      };
    },
    receiveUpdatedRequest(state, {payload}) {
      if (payload && payload.request_id) {
        return {
          ...state,
          itemList: state.itemList.map(
              (e) => e.request_id === payload.request_id ?
               {...e} :
               e),
          isLoading: false,
        };
      } else {
        return {
          ...state,
          isLoading: false,
        };
      }
    },
    receiveDetails(state, {payload}) {
      if (payload && payload.request_id) {
        return {
          ...state,
          itemList: state.itemList.map(
              (e) => e.request_id === payload.request_id ?
               {...e, ...payload} :
               e),
          isLoading: false,
        };
      } else {
        return {
          ...state,
          isLoading: false,
        };
      }
    },

    receiveChangesRequests(state, action) {
      return {
        ...state,
        notificationList: action.payload || [],
      };
    },

    setPrintStudiesAvailability(state, action) {
      return {
        ...state,
        studiesStatus: action.payload,
      };
    },
    setPrintStudies(state, action) {
      return {
        ...state,
        studiesFiles: action.payload,
      };
    },
    onCheckRequest(state, action) {
      return {
        ...state,
        itemList: state.itemList.map((e) =>
          e.id !== action.payload.id ?
            e : {
              ...e,
              selected: action.payload.isChecked,
            },
        ),
      };
    },
    receiveStatusHistoryForARequest(state, action) {
      return {
        ...state,
        itemList: state.itemList.map((e) =>
          e.request_id !== action.payload.requestId ?
            e : {
              ...e,
              requestHistoryList: action.payload.historyList,
            },
        ),
      };
    },
    onSearchChange(state, action) {
      return {
        ...state,
        searchQuery: action.payload.value ? action.payload.value : "",
      };
    },
    onCheckAllRequests(state, action) {
      return {
        ...state,
        itemList: state.itemList.map((e) => ({
          ...e,
          selected: action.payload.isChecked,
        })),
      };
    },
    errorServiceException(state, action) {
      return {
        ...state,
        isLoadingDocument: {
          ...state.isLoadingDocument,
          ...(action.payload?.documentId ? {
            [action.payload.documentId]: false,
          } : {}),
        },
        isError: true,
      };
    },

    setBanner(state, action) {
      return {
        ...state,
        showBanner: action.payload,
      };
    },

    onClearFilters(state) {
      return {
        ...state,
        filters: [],
        searchQuery: "",
      };
    },

    setSelectedRow(state, action) {
      return {
        ...state,
        selectedRow: action.payload,
      };
    },

    removeReminderDateFromState(state, action) {
      const itemList = Array.from(state.itemList);
      const request_id = action.payload.request_id;
      const reminder_id = action.payload.reminder_id;
      const indexOfRequest = itemList.findIndex(function(item, i) {
        return item.request_id === request_id;
      });

      let reminder_dates = Array.from(itemList[indexOfRequest].reminder_dates);
      reminder_dates = reminder_dates.filter(function(item) {
        return item.ID !== reminder_id;
      });
      itemList[indexOfRequest].reminder_dates = reminder_dates;
    },

    addReminderDateFromState(state, action) {
      const itemList = Array.from(state.itemList);
      const request_id = action.payload.request_id;
      const reminder_id = action.payload.reminder_id;
      const reminder_date = action.payload.reminder_date;

      const indexOfRequest = itemList.findIndex(function(item, i) {
        return item.request_id === request_id;
      });
      if (!itemList[indexOfRequest]) return;

      if (!Array.isArray((itemList[indexOfRequest].reminder_dates))) {
        itemList[indexOfRequest].reminder_dates = [];
      }
      const reminder_dates = Array.from(itemList[indexOfRequest].reminder_dates);
      reminder_dates.push({
        ID: reminder_id,
        REMINDER_DATE: reminder_date,
        REQUEST_ID: request_id,
      });
      if (itemList[indexOfRequest]) {
        itemList[indexOfRequest].reminder_dates = reminder_dates;
      }
    },

    onSort(state, action) {
      const sortBy = action.payload.sortBy;
      const sortAsc = action.payload.sortAsc;
      const items = Array.from(state.itemList);
      switch (sortBy) {
        case ("date"):
          items.sort((a, b) => datecmp(a.request_date, b.request_date, sortAsc));
          break;
        case ("name"):
          items.sort((a, b) => {
            const comp1 = a.patient_lastname ? a.patient_lastname?.toLowerCase() : (a.patient_surename ? a.patient_surename?.toLowerCase() : "");
            const comp2 = b.patient_lastname ? b.patient_lastname?.toLowerCase() : (b.patient_surename ? b.patient_surename?.toLowerCase() : "");
            return strcmp(comp1, comp2, sortAsc);
          });
          break;
        case ("diagnosis"):
          items.sort((a, b) => {
            const comp1 = a.diagnosis ? a.diagnosis?.toLowerCase() : "";
            const comp2 = b.diagnosis ? b.diagnosis?.toLowerCase() : "";
            return strcmp(comp1, comp2, sortAsc);
          });
          break;
        case ("therapy"):
          items.sort((a, b) => {
            const comp1 = a.required_drug ? a.required_drug?.toLowerCase() : "";
            const comp2 = b.required_drug ? b.required_drug?.toLowerCase() : "";
            return strcmp(comp1, comp2, sortAsc);
          });
          break;
        case ("type"):
          items.sort((a, b) => {
            const comp1 = a.request_type ? a.request_type?.toLowerCase() : "";
            const comp2 = b.request_type ? b.request_type?.toLowerCase() : "";
            return strcmp(comp1, comp2, sortAsc);
          });
          break;
        case ("status"):
          items.sort((a, b) => {
            const comp1 = a.request_status ? a.request_status?.toLowerCase() : "";
            const comp2 = b.request_status ? b.request_status?.toLowerCase() : "";
            return strcmp(comp1, comp2, sortAsc);
          });
          break;
        case ("dokument"):
          items.sort((a, b) => {
            const comp1 = Math.max(a.attachments_external, a.attachments_internal);
            const comp2 = Math.max(b.attachments_external, b.attachments_internal);
            return strcmp(comp1, comp2, sortAsc);
          });
          break;
        default:
          break;
      }
      return {
        ...state,
        itemList: items,
      };
    },
  },
});


export const {
  receiveRequests,
  receiveChangesRequests,
  setPrintStudiesAvailability,
  setPrintStudies,
  onCheckRequest,
  onSearchChange,
  receiveStatusHistoryForARequest,
  onCheckAllRequests,
  errorServiceException,
  receiveUpdatedRequest,
  receiveDetails,
  setBanner,
  onSort,
  setSelectedRow,
  onFilterChange,
  onDeleteFilter,
  onClearFilters,
  removeReminderDateFromState,
  addReminderDateFromState,
  removeRequest,
} = RequestListReducerSlice.actions;

export default RequestListReducerSlice.reducer;


