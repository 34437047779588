import React from "react";
import {Card, CardContent, CardHeader} from "@mui/material";
import {useTranslation} from "react-i18next";
import {connect} from "react-redux";
import {Field, reduxForm} from "redux-form";
import {CheckboxComponent} from "components/formFields/CheckboxComponent";

const ReduxFormComponentIndication=(props) => {
  const {t}=useTranslation();
  const getAmbulantCard=(item) => <Card>
    <CardHeader title={t("ambulant."+item.key)} />
    <CardContent>
      {getAmbulantCardButtons(item.key, item.count)}
    </CardContent>
  </Card>;

  const getAmbulantCardButtons=(key, count) => count?
    [getAmbulantCardButtons(key, count-1),
      <Field name={key+count}
        key={key+count}
        component={CheckboxComponent}
        type="checkbox"
        required={false}
        label={t("ambulant."+key+count)}
      />,
    ]:
    null;

  return (<div>{props.ambulant_keys.map((e) => getAmbulantCard(e))}
    <Field name="urgent"
      component={CheckboxComponent}
      type="checkbox"
      label={t("indication.details.urgent")}
    />
  </div>);
};

const mapStateToProps=(state) => {
  const ambulant_keys=[
    {key: "A", count: 4},
    {key: "B", count: 7},
    {key: "C", count: 2},
    {key: "D", count: 4},
    {key: "E", count: 7},
  ];

  return {
    initialValues: {
      ...ambulant_keys
          .reduce((a, e) => {
            let counter=e.count;
            while (counter>0) {
              if (state.singleRequest.editData) {
                a[e.key+counter]=state.singleRequest?.editData?.ambstat_data?.[e.key+counter]?
                 true:
                 false;
              } else {
                a[e.key+counter]=false;
              }
              counter-=1;
            }
            return a;
          }, {}),
      urgent: state.singleRequest?.editData?.urgent===true},
    ambulant_keys,
  };
};

export default connect(mapStateToProps, {})(reduxForm({
  enableReinitialize: true,
  destroyOnUnmount: false,
  form: "ambstat_data",
})(ReduxFormComponentIndication));
