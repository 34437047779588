import React from "react";
import {useTranslation} from "react-i18next";
import {default as imprintandprotection} from "./imprintandprotection.json";
const htmlDataProtectionText = imprintandprotection.htmlDataProtectionText;
const htmlImprintText = imprintandprotection.htmlImprintText;

import {connect} from "react-redux";
import {Parser} from "html-to-react";
const HtmlToReactParser = Parser;

const htmlToReactParser = new HtmlToReactParser();
const htmlImprintInput = htmlImprintText;
const reactImprintElement = htmlToReactParser.parse(htmlImprintInput);
const htmlProtectionInput = htmlDataProtectionText;
const reactProtectionElement = htmlToReactParser.parse(htmlProtectionInput);

class ImprintHtml extends React.Component {
  render() {
    return reactImprintElement;
  }
}
class DataprotectionHtml extends React.Component {
  render() {
    return reactProtectionElement;
  }
}

function handleDontAcceptDataProtection(e) {
  document.cookie.split(";").forEach(function(c) {
    document.cookie = c.trim().split("=")[0] + "=; expires=Thu, 01 Jan 1970 00:00:00 UTC;";
  });
  window.cookieConsent();
}


const Imprint = (props) => {
  const {t}=useTranslation();
  const currentLocation = window.location.origin;
  return (
    <div className="">
      <div className="main">
        <div className="row">
          <div className="col">
            <div className="card" >
              <div className="card-body ">
                <h1 className="card-title">{t("imprint.title")}</h1>
                <div className="card-text" >
                  <ImprintHtml />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col">
            <div className="card">
              <div className="card-body  ">
                <h1 className="card-title">{t("imprint.data.protection.title")}</h1>
                <div className="card-text">
                  <DataprotectionHtml />
                  <br></br>
                  <button className="btn btn-success float-right ml-3" onClick={()=>window.location.replace(currentLocation)}>{t("imprint.data.protection.accept")}</button>
                  <button className="btn btn-danger float-right" onClick={handleDontAcceptDataProtection}>{t("imprint.data.protection.notaccept")}</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div >
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
  };
};

const mapDispatchToProps = (dispatch) => ({
  changeLang: (newLang) => dispatch(onLangChange(newLang)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Imprint);
