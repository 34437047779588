import React from "react";
import {Redirect} from "react-router-dom";
import {trackError} from "AppInsights";
export default class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = {hasError: false};
  }
  static getDerivedStateFromError(error) {
    return {hasError: true};
  }
  componentDidCatch(error, errorInfo) {
    console.error("[REACT] error was thrown", error, errorInfo);
    trackError(error);
  }
  render() {
    if (this.state.hasError) {
      console.error("[REACT] error was thrown");
      return <Redirect to="/wizard" />;
    }
    return this.props.children;
  }
}
