import React from "react";
import {connect} from "react-redux";
import {FormControl, Grid, InputLabel, OutlinedInput, Typography} from "@mui/material";
import {TextArea} from "pages/Wizard/components/TextAreaComponent";

import {useTranslation} from "react-i18next";
import {selectMoreInformationValues, onChangeMoreInformationValue} from "./defaultMedicineSlice";
import {UseAppDispatch, UseAppSelector} from "store/hooks";


export const DefaultMedicineForm = (props) => {
  const {t}=useTranslation();
  const moreInformationValues= UseAppSelector(selectMoreInformationValues);
  const dispatch = UseAppDispatch();
  const handleValueChange = (field:string, value:any)=>dispatch(onChangeMoreInformationValue({field, value}));
  return (
    <>
      <Grid item>
        <Typography variant="h5" >
          {t(props.label)}
        </Typography>
      </Grid>
      <Grid item xs={12} data-cy='txt_defaultMedicine_pretreatment'>
        <FormControl variant="outlined" fullWidth>
          <InputLabel htmlFor="preatreatment">{t("indication.details.pretreatment")} </InputLabel>
          <OutlinedInput
            label={t("indication.details.pretreatment")}
            value={moreInformationValues.pretreatment||""}
            onChange={(event)=>handleValueChange("pretreatment", event.target.value)} style={{width: "100%"}}
            inputProps={{minRows: 2}}
            id="preatreatment"
            inputComponent={TextArea as any}
          />
        </FormControl>

      </Grid>
      <Grid item xs={12} data-cy='txt_defaultMedicine_patient_condition'>
        <FormControl variant="outlined" fullWidth >
          <InputLabel htmlFor="patient_condition">{t("indication.details.patient.condition")} </InputLabel>
          <OutlinedInput
            label={t("indication.details.patient.condition")}
            value={moreInformationValues.patient_condition||""}
            onChange={(event)=>handleValueChange("patient_condition", event.target.value)}
            style={{width: "100%"}}
            inputProps={{minRows: 2}}
            id="patient_condition"
            inputComponent={TextArea as any}
          />
        </FormControl>

      </Grid>
      <Grid item xs={12} data-cy='txt_defaultMedicine_disease_prognosis'>
        <FormControl variant="outlined" fullWidth >
          <InputLabel htmlFor="disease_prognosis">{t("indication.details.disease.prognosis")} </InputLabel>
          <OutlinedInput
            label={t("indication.details.disease.prognosis")}
            value={moreInformationValues.disease_prognosis||""}
            onChange={(event)=>handleValueChange("disease_prognosis", event.target.value)}
            style={{width: "100%"}}
            inputProps={{minRows: 2}}
            id="disease_prognosis"
            inputComponent={TextArea as any}
          />
        </FormControl>
      </Grid>
      <Grid item xs={12} data-cy='txt_defaultMedicine_therapy_alternatives'>
        <FormControl variant="outlined" fullWidth >
          <InputLabel htmlFor="therapy_alternatives">{t("indication.details.therapy.alternatives")} </InputLabel>
          <OutlinedInput
            label={t("indication.details.therapy.alternatives")}
            value={moreInformationValues.therapy_alternatives||""}
            onChange={(event)=>handleValueChange("therapy_alternatives", event.target.value)}
            style={{width: "100%"}}
            inputProps={{minRows: 2}}
            id="therapy_alternatives"
            inputComponent={TextArea as any}
          />
        </FormControl>

      </Grid>
    </>
  );
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(DefaultMedicineForm);
