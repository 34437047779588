import {
  onReceivedInsuranceCompanyData,
  onReceivedBagData,
  onReceivedICD10Data,
  onReceivedDoctorData,
  onReceivedBagDataCombined,
  onReceivedRehaData,
  onReceivedMigelData,
  onReceivedPatientFreeTextSearch,
  onReceivedBreathingDeviceModesData,
  onReceivedAdditionalDrugInformation,
  onReceivedBreathingDeviceData,
  onReceivedPubMedData,
  onReceivedPubMedAbstract,
  onReceivedCompendiumData,
} from "store/features/masterdataSlice";
import {displayToastError} from "store/features/errorSlice";
import {
  onReceivedDoctorDataPrefill,
} from "store/features/newRequestSlice";
import {doctorProfileComplete} from "../../components/formUtils";
import {
  get,
  post,
} from "store/api";
import {trackEvent, trackError} from "AppInsights";
import {startLoading, endLoading} from "store/features/loadingSlice";


export const fetchICD10Data=(searchString)=>(dispatch)=> {
  dispatch(startLoading("icd10"));
  trackEvent("masterdata/ICD10/"+encodeURIComponent(searchString));
  return get("masterdata/ICD10/"+encodeURIComponent(searchString))
      .then((jsonResponse) =>{
        dispatch(onReceivedICD10Data(jsonResponse));
      }).catch((error) => {
        trackError(error);
        console.error(error);
        dispatch(displayToastError("ICD10 data couldnt be loaded."));
      })
      .finally(()=>dispatch(endLoading("icd10")));
};

export const fetchCompediumData=(searchString)=>(dispatch)=> {
  dispatch(startLoading("CompediumData"));
  const encodedSearchString = btoa(searchString);
  trackEvent("masterdata/compendium/"+encodeURIComponent(encodedSearchString));
  return get("masterdata/compendium/"+encodeURIComponent(searchString))
      .then((jsonResponse) =>{
        dispatch(onReceivedCompendiumData({data: jsonResponse}));
      }).catch((error) => {
        trackError(error);
        console.error(error);
        dispatch(displayToastError("CompediumData data couldnt be loaded."));
      })
      .finally(()=>dispatch(endLoading("CompediumData")));
};

export const fetchBreathingDevicesData=() => (dispatch) => {
  dispatch(startLoading("breathingDevices"));
  trackEvent("masterdata/breathing_devices/123456");
  return get("masterdata/breathing_devices/123456")
      .then((jsonResponse) =>{
        dispatch(onReceivedBreathingDeviceData(jsonResponse));
      }).catch((error) => {
        trackError(error);
        console.error(error);
        dispatch(displayToastError("BREATHING_DEVICES.LOAD.FAILED"));
      })
      .finally(()=>dispatch(endLoading("breathingDevices")));
};

export const fetchBreathingDeviceModesData=() => (dispatch) => {
  dispatch(startLoading("breathingModes"));
  trackEvent("masterdata/breathing_device_modes/");
  return get("masterdata/breathing_device_modes/")
      .then((jsonResponse) =>{
        dispatch(onReceivedBreathingDeviceModesData(jsonResponse));
      }).catch((error) => {
        trackError(error);
        console.error(error);
        dispatch(displayToastError("BREATHING_MODES.LOAD.FAILED"));
      })
      .finally(()=>dispatch(endLoading("breathingModes")));
};

export const fetchRehaData=(searchString)=>(dispatch)=> {
  dispatch(startLoading("reha"));
  trackEvent("masterdata/rehab/"+encodeURIComponent(searchString));
  return get("masterdata/rehab/"+encodeURIComponent(searchString))
      .then((json) =>{
        dispatch(onReceivedRehaData({data: json, selected: searchString}));
      })
      .catch((error) => {
        trackError(error);
        dispatch(displayToastError("REHA.LOAD.FAILED"));
      })
      .finally(()=> dispatch(endLoading("reha")));
};

export const fetchMigelData=(searchString, lang)=>(dispatch)=> {
  dispatch(startLoading("migel"));
  console.debug("fetchMigelData", searchString);
  trackEvent("masterdata/search/"+encodeURIComponent(searchString)+""+"/migel");
  return get("masterdata/search/"+btoa(encodeURIComponent(searchString))+"/migel")
      .then((json) =>{
        dispatch(onReceivedMigelData({data: json, selected: searchString}));
      }).catch((error) => {
        trackError(error);
        dispatch(displayToastError("MIGEL.LOAD.FAILED"));
      })
      .finally(()=>dispatch(endLoading("migel")));
};

export const fetchDoctorData=()=> (dispatch) => {
  dispatch(startLoading("doctor"));
  trackEvent("substitution/getAllDoctors/");
  return get("substitution/getAllDoctors")
      .then((json) =>{
        dispatch(onReceivedDoctorData(json));
        dispatch(onReceivedDoctorDataPrefill(json));
      },
      ).catch((error) => {
        trackError(error);
        console.error(error);
      })
      .finally(()=> dispatch(endLoading("doctor")));
};

export const fetchBagDataCombinedDrug=(searchString, lang)=>(dispatch)=> {
  dispatch(startLoading("bagCombined"));
  console.debug("fetchBagDataCombinedDrug", searchString);
  trackEvent("masterdata/searcharticle/" + searchString );
  return get("masterdata/searcharticle/" + btoa(encodeURIComponent(searchString)))
      .then((json) =>{
        dispatch(onReceivedBagDataCombined(json));
      }).catch((error) => {
        trackError(error);
        dispatch(displayToastError("BAG_DATA.LOAD.FAILED"));
      })
      .finally(()=>dispatch(endLoading("bagCombined")));
};

export const fetchBagData = (searchString) => (dispatch) => {
  console.debug("fetchBagData", searchString);
  trackEvent("masterdata/searcharticle/" + encodeURIComponent(searchString));
  dispatch(startLoading("bagData"));
  return get("masterdata/searcharticle/" + btoa(encodeURIComponent(searchString)) )
      .then(async (json) =>{
        console.debug("fetchBagData", json);
        dispatch(onReceivedBagData({data: json}));
      })
      .catch((error) => {
        trackError(error);
        dispatch(displayToastError("BAG_DATA.LOAD.FAILED"));
      })
      .finally(()=> dispatch(endLoading("bagData")));
};


export const fetchBagDataByArticleName = (articleName) => (dispatch) => {
  console.debug("fetchBagDataByArticleName", articleName);
  trackEvent("masterdata/search/" + encodeURIComponent(articleName));
  dispatch(startLoading("bagData"));
  return get("masterdata/search/" + btoa(encodeURIComponent(articleName)) )
      .then(async (json) =>{
        return json;
      })
      .catch((error) => {
        trackError(error);
        dispatch(displayToastError("BAG_DATA.LOAD.FAILED"));
      })
      .finally(()=> dispatch(endLoading("bagData")));
};


export const fetchInsuranceCompaniesData=() =>(dispatch) => {
  dispatch(startLoading("insurance"));
  trackEvent("masterdata/insurances");
  get("masterdata/insurances")
      .then((json) =>{
        dispatch(onReceivedInsuranceCompanyData(json));
      }).catch((error) => {
        trackError(error);
        dispatch(displayToastError("INSURANCE_DATA.LOAD.FAILED"));
        dispatch(endLoading("insurance"));
      })
      .finally(()=> dispatch(endLoading("insurance")));
  ;
};

export const fetchPatientFreeTextSearch=(searchString, includephonebook)=>(dispatch)=> {
  dispatch(startLoading("patientSearch"));
  trackEvent("swisscom/");
  if (!searchString || searchString.length === 0) {
    dispatch(endLoading("patientSearch"));
  } else {
    return get(`swisscom/${encodeURIComponent(searchString)}/${includephonebook}`)
        .then((json) => {
          dispatch(onReceivedPatientFreeTextSearch(json));
        }).catch((error) => {
          trackError(error);
          dispatch(displayToastError("FREETEXT_SEARCH_ERROR"));
        })
        .finally(()=>dispatch(endLoading("patientSearch")));
  }
};

export const fetchAdditionalDrugInfo=(drug, indication, lang)=> (dispatch) => {
  dispatch(startLoading("additionalDrugInfo"));
  trackEvent("masterdata/additionalInformation");
  try {
    if (drug === undefined || drug === "") {
      dispatch(endLoading("additionalDrugInfo"));
    } else {
      post("masterdata/additionalInformation",
          {
            drug: encodeURIComponent(drug),
            indication: encodeURIComponent(indication),
          })
          .then((json) =>{
            dispatch(onReceivedAdditionalDrugInformation(json.reduce((a, e)=>a+=e.comment_on_treatment+"\n\n", "")));
          })
          .catch((error) => {
            trackError(error);
            dispatch(endLoading("additionalDrugInfo"));
            dispatch(displayToastError("ADDITIONAL_INFO_ERROR"));
          })
          .finally(()=>dispatch(endLoading("additionalDrugInfo")));
    }
  } catch (exception) {
    trackError(exception);
    dispatch(endLoading("additionalDrugInfo"));
  }
};

export const fetchPubMedData=(searchString, JWTToken)=>(dispatch)=> {
  if (searchString !== undefined) {
    searchString = searchString.toLowerCase();
    searchString = searchString.replaceAll("and", "");
  }
  try {
    const url= `pubMed/${JWTToken?"/":""}${encodeURIComponent(searchString)}`;
    dispatch(startLoading("pubMed"));
    trackEvent(url);
    get(url, null, JWTToken).then((json) => {
      if (json.success === false) {
        dispatch(displayToastError("PUBMED_SEARCH_FAILED"));
        dispatch(onReceivedPubMedData([]));
      } else {
        dispatch(onReceivedPubMedData(json.searchresult));
        if (json.searchresult.length >= 200) {
          dispatch(displayToastError("PUBMED_SEARCH_MAX_RESULTS"));
        }
      }
    }).catch((error) => {
      trackError(error);
      dispatch(displayToastError("PUBMED_SEARCH_ERROR"));
    }).finally(()=>dispatch(endLoading("pubMed")));
  } catch (error) {
    trackError(error);
  }
};

export const fetchPubmedAbstract = (id, JWTToken) => async (dispatch) => {
  dispatch(startLoading("pubmedAbstract"));
  const url= `pubMed/${JWTToken?"/":""}abstract/${id}`;
  trackEvent(url);
  return get(url, null, JWTToken)
      .then((json) =>{
        if (json.success === false) {
          dispatch(displayToastError("PUBMED_ABSTRACT_FAILED"));
          dispatch(onReceivedPubMedAbstract({studyId: id, data: ""}));
        } else {
          dispatch(onReceivedPubMedAbstract({studyId: id, data: json.abstract}));
        }
      },
      ).catch((error) => {
        trackError(error);
        dispatch(displayToastError("PUBMED_ABSTRACT_ERROR"));
      })
      .finally(() => dispatch(endLoading("pubmedAbstract")));
};
