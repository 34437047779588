import {MenuItem,
  Card,
  ListItemIcon,
  Menu,
} from "@mui/material";
import React from "react";
import {useTranslation} from "react-i18next";
import {useDispatch} from "react-redux";
import {allStates} from "../../../components/states.js";
import {sendSetStatus} from "store/middleware/requestListThunk";

const StateDropdown=({requestId, anchorEl, status, kogu_type, handleClose}) => {
  const {t}=useTranslation();
  const dispatch=useDispatch();

  const send =(status) => {
    dispatch(sendSetStatus(requestId, status));
    handleClose();
  };

  const open=Boolean(anchorEl);
  return (
    <Menu
      id="long-menu"
      anchorEl={anchorEl}
      keepMounted
      open={open}
      onClose={handleClose}
    >
      <Card>
        <MenuItem key='title' disabled>
          {t("SET_STATUS")}
        </MenuItem>
        {
          allStates.map((state) => !excludedStates[kogu_type] ||
           excludedStates[kogu_type]?.indexOf(state.status)===-1?
        <MenuItem key={state.status} onClick={() => send(state.status)}>
          <ListItemIcon style={{color: state.color, fontSize: "xx-large"}}>
            {state.icon}
          </ListItemIcon>
          {t("STATUS_" + state.status)}
        </MenuItem>:
        null)
        }
      </Card>
    </Menu>

  );
};
const excludedStates={
  "ambstat": ["WAITING_FOR_STUDIES", "STUDIES_RECEIVED", "ORDERED"],
};
export default StateDropdown;
