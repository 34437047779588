import React, {useEffect} from "react";
import {connect} from "react-redux";
import {useTranslation} from "react-i18next";
import {Grid, Typography} from "@mui/material";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import Radio from "@mui/material/Radio";
import Checkbox from "@mui/material/Checkbox";
import RadioGroup from "@mui/material/RadioGroup";
import FormLabel from "@mui/material/FormLabel";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import {onChangeDosisValue} from "../../../Dosis/dosisPlanSlice";

import {
  useLocation,
} from "react-router-dom";

import {
  UseAppDispatch,
  UseAppSelector,
} from "store/hooks";

import {
  onChangeSomatulineValue,
  selectSomatulineValues,
} from "./SomatulineSlice";
import {
  onChangeKVVLegalState,
  onChangeMedicineValue,
} from "../../../medicine/medicineSlice";
import {
  setStudyById,
  removeStudy,
} from "pages/Wizard/wizardSlice";
import {
  onChangeTreatmentValue,
} from "../../../Treatment/treatmentPlanSlice";
import {
  onChangeSpecialMedValue,
  SpecialMedAdditionalInformation,
} from "../../specialMedSlice";

const SomatulineForm = (props) => {
  const handleDosisValueChange = (field:string, value:any)=>dispatch(onChangeDosisValue({field, value}));
  const {t} = useTranslation();
  const dispatch = UseAppDispatch();
  const SomatulineValues = UseAppSelector(selectSomatulineValues);
  const handleValueChangeKVV = (field:string, value:any)=>dispatch(onChangeTreatmentValue({field, value}));
  const handleSpecialMedValueChange = (field:string, value:any)=>dispatch(onChangeSpecialMedValue({field, value}));
  const params=useLocation();
  useEffect(() => {
    const regex = /\d+$/;
    const isEditId = regex.test(params.pathname);
    console.debug("isEditId", isEditId);
    if (!isEditId) {
      dispatch(onChangeKVVLegalState(
          {
            limitatio_approval: false,
            specialist_info: false,
          },
      ));
      handleValueChangeKVV("limitatio_approval", false);
      handleValueChangeKVV("specialist_info", false);
    }
  }, []);


  useEffect(() => {
    onChangeSomatulineValue({formData: {variant: ""}});
    if (
      SomatulineValues.tumor_control_gep_net_midgut_pancreas_unknown === true &&
      SomatulineValues.grade_of_differentiation_g2 === true &&
      SomatulineValues.ki67_index_up_to_10_percent === true
    ) {
      // OPTION G2
      dispatch(setStudyById({studyId: "25014687"}));
      dispatch(onChangeSomatulineValue({formData: {variant: "G2"}}));
      dispatch(onChangeKVVLegalState(
          {
            limitatio_approval: false,
            specialist_info: true,
          },
      ));
      handleValueChangeKVV("limitatio_approval", false);
      handleValueChangeKVV("specialist_info", true);
      dispatch(onChangeMedicineValue({field: "kn_id", value: ""}));
      handleDosisValueChange("dosis", "");
      handleSpecialMedValueChange("special_med_additional_information", SpecialMedAdditionalInformation.SOMATULINE_CLARINET);
    } else if (
      // OPTION G1
      SomatulineValues.tumor_control_gep_net_midgut_pancreas_unknown === true &&
      SomatulineValues.grade_of_differentiation_g2 === false &&
      SomatulineValues.grade_of_differentiation_g1 === true &&
      SomatulineValues.ki67_index_up_to_10_percent === false
    ) {
      dispatch(onChangeSomatulineValue({formData: {variant: "G1"}}));
      dispatch(setStudyById({studyId: "25014687"}));
      dispatch(onChangeKVVLegalState(
          {
            limitatio_approval: false,
            specialist_info: true,
          },
      ));
      handleValueChangeKVV("limitatio_approval", false);
      handleValueChangeKVV("specialist_info", true);
      handleDosisValueChange("dosis", "");
      dispatch(onChangeMedicineValue({field: "kn_id", value: ""}));
      handleSpecialMedValueChange("special_med_additional_information", SpecialMedAdditionalInformation.SOMATULINE_CLARINET);
    } else if (
      // OPTION CLARINET
      SomatulineValues.tumor_control_gep_net_midgut_pancreas_unknown === true &&
      SomatulineValues.grade_of_differentiation_g2 === true &&
      SomatulineValues.grade_of_differentiation_g1 === false &&
      SomatulineValues.ki67_index_up_to_10_percent === false
    ) {
      dispatch(onChangeSomatulineValue({formData: {variant: "G2B"}}));
      dispatch(onChangeKVVLegalState(
          {
            limitatio_approval: false,
            specialist_info: true,
          },
      ));
      handleValueChangeKVV("limitatio_approval", false);
      handleValueChangeKVV("specialist_info", false);
      handleDosisValueChange("dosis", t(""));
      dispatch(setStudyById({studyId: "25014687"}));
      handleSpecialMedValueChange("special_med_additional_information", SpecialMedAdditionalInformation.SOMATULINE_CLARINET);
    } else {
      dispatch(onChangeSomatulineValue({formData: {variant: undefined}}));
      dispatch(removeStudy({}));
      handleSpecialMedValueChange("special_med_additional_information", SpecialMedAdditionalInformation.NONE);
      dispatch(onChangeKVVLegalState(
          {
            limitatio_approval: false,
            specialist_info: false,
          },
      ));
      handleValueChangeKVV("limitatio_approval", false);
      handleValueChangeKVV("specialist_info", false);
      handleDosisValueChange("dosis", t(""));
      dispatch(onChangeMedicineValue({field: "kn_id", value: ""}));
    }
  },
  [
    SomatulineValues.default,
    SomatulineValues.tumor_control_gep_net_midgut_pancreas_unknown,
    SomatulineValues.grade_of_differentiation_g1,
    SomatulineValues.grade_of_differentiation_g2,
    SomatulineValues.ki67_index_up_to_10_percent,
  ]);


  return <>

    <Grid item xs={12}>
      <FormControl>
        <FormLabel>{t("somatuline.options")}</FormLabel>
        <RadioGroup
          onChange={(event, value) => {
            if (value === "default") {
              dispatch(onChangeSomatulineValue({formData: {default: true}}));
              dispatch(onChangeSomatulineValue({formData: {tumor_control_gep_net_midgut_pancreas_unknown: false}}));
              dispatch(onChangeSomatulineValue({formData: {grade_of_differentiation_g1: false}}));
              dispatch(onChangeSomatulineValue({formData: {grade_of_differentiation_g2: false}}));
              dispatch(onChangeSomatulineValue({formData: {ki67_index_up_to_10_percent: false}}));
            } else if (value === "tumor_control_gep_net_midgut_pancreas_unknown") {
              dispatch(onChangeSomatulineValue({formData: {default: false}}));
              dispatch(onChangeSomatulineValue({formData: {tumor_control_gep_net_midgut_pancreas_unknown: true}}));
              dispatch(onChangeSomatulineValue({formData: {grade_of_differentiation_g1: false}}));
              dispatch(onChangeSomatulineValue({formData: {grade_of_differentiation_g2: false}}));
              dispatch(onChangeSomatulineValue({formData: {ki67_index_up_to_10_percent: false}}));
            }
          }}>
          <FormControlLabel
            value="tumor_control_gep_net_midgut_pancreas_unknown"
            control={<Radio
              checked={SomatulineValues.tumor_control_gep_net_midgut_pancreas_unknown}
              data-cy="tumor_control_gep_net_midgut_pancreas_unknown" />}
            label={t("somatuline.tumor_control_gep_net_midgut_pancreas_unknown")}
          />
          <FormControlLabel
            value="default"
            control={<Radio
              checked={SomatulineValues.default}
              data-cy="default" />}
            label={t("somatuline.default")}
          />
        </RadioGroup>
      </FormControl>
      <div
        hidden={
          !(SomatulineValues.tumor_control_gep_net_midgut_pancreas_unknown === true)}>

        <FormLabel>{t("somatuline.grade_of_differentiation")}</FormLabel>
        <RadioGroup
          onChange={(event, value) => {
            if (value === "grade_of_differentiation_g1") {
              dispatch(onChangeSomatulineValue({formData: {grade_of_differentiation_g1: true}}));
              dispatch(onChangeSomatulineValue({formData: {grade_of_differentiation_g2: false}}));
              dispatch(onChangeSomatulineValue({formData: {ki67_index_up_to_10_percent: false}}));
            } else if (value === "grade_of_differentiation_g2") {
              dispatch(onChangeSomatulineValue({formData: {grade_of_differentiation_g1: false}}));
              dispatch(onChangeSomatulineValue({formData: {grade_of_differentiation_g2: true}}));
            }
          }}>
          <FormControlLabel
            value="grade_of_differentiation_g1"
            control={<Radio
              checked={SomatulineValues.grade_of_differentiation_g1}
              data-cy="grade_of_differentiation_g1" />}
            label={t("somatuline.grade_of_differentiation_g1")}
          />
          <FormControlLabel
            value="grade_of_differentiation_g2"
            control={<Radio
              checked={SomatulineValues.grade_of_differentiation_g2}
              data-cy="grade_of_differentiation_g2" />}
            label={t("somatuline.grade_of_differentiation_g2")}
          />
        </RadioGroup>
        <div hidden={!(
          SomatulineValues.grade_of_differentiation_g2 === true
        )
        }>
          <Box sx={{m: 1}}/>
          <Grid item xs={12}>
            <FormControlLabel
              control={
                <Checkbox
                  data-cy="ki67_index_up_to_10_percent"
                  name="ki67_index_up_to_10_percent"
                  checked={SomatulineValues?.ki67_index_up_to_10_percent}
                  onChange={(event, newValue)=>{
                    dispatch(onChangeSomatulineValue({formData: {ki67_index_up_to_10_percent: event.target.checked}}));
                  }}/>
              }
              label={t("somatuline.ki67_index_up_to_10_percent")}
            />
          </Grid>
        </div>

      </div>
      <div
        hidden={
          !(SomatulineValues.default === true)}>

        <Box sx={{m: 1}}/>
        <Typography >{t("somatuline.details.text")}</Typography>
        <Box sx={{m: 1}}/>
        <TextField
          style={{width: "100%"}}
          InputLabelProps={{shrink: true}}
          data-cy="preTreatment"
          label={t("somatuline.defaultForm.preTreatment")}
          value={SomatulineValues.defaultForm?.preTreatment}
          multiline
          maxRows={4}
          minRows={4}
          variant="outlined"
          onChange = {(event ) =>
            dispatch(onChangeSomatulineValue(
                {
                  preTreatment: (event.target as HTMLInputElement).value,
                }),
            )
          }
        />
        <Box sx={{m: 1}}/>
        <TextField
          multiline
          style={{width: "100%"}}
          label={t("somatuline.defaultForm.patientCondition")}
          InputLabelProps={{shrink: true}}
          value={SomatulineValues.defaultForm?.patientCondition }
          variant="outlined"
          maxRows={4}
          minRows={4}
          onChange = {(event ) =>
            dispatch(onChangeSomatulineValue(
                {
                  patientCondition: (event.target as HTMLInputElement).value,
                }),
            )
          }
        />

        <Box sx={{m: 1}}/>
        <TextField
          style={{width: "100%"}}
          label={t("somatuline.defaultForm.reasonsAlternativeTherapiesFailed")}
          InputLabelProps={{shrink: true}}
          value={SomatulineValues.defaultForm?.reasonsAlternativeTherapiesFailed }
          variant="outlined"
          maxRows={4}
          minRows={4}
          multiline
          onChange = {(event ) =>
            dispatch(onChangeSomatulineValue(
                {
                  reasonsAlternativeTherapiesFailed: (event.target as HTMLInputElement).value,
                }),
            )
          }
        />
        <Box sx={{m: 1}}/>
        <TextField
          style={{width: "100%"}}
          hidden={true}
          label={t("somatuline.defaultForm.prognosis")}
          InputLabelProps={{shrink: true}}
          value={SomatulineValues.defaultForm?.prognosis }
          variant="outlined"
          maxRows={4}
          minRows={4}
          multiline
          onChange = {(event ) =>
            dispatch(onChangeSomatulineValue(
                {
                  prognosis: (event.target as HTMLInputElement).value,
                }),
            )
          }
        />

      </div>
    </Grid>
    <Grid item xs={12}>
      {
        SomatulineValues.variant !=="" && SomatulineValues.variant !=="  " && SomatulineValues.default === false ?
          <FormLabel >{t("variante")}:&nbsp;{SomatulineValues.variant}&nbsp;</FormLabel> :
        <></>
      }
    </Grid>

  </>;
};


const mapStateToProps=(state) => {
  return {
  };
};

const mapDispatchToProps=(dispatch) => ({

});

export default connect(mapStateToProps, mapDispatchToProps)(SomatulineForm);


