import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {RootState} from "store/rootstate";

export interface IDiagnosisState {
  formData:{
    diagnosis:string;
    remarks_diagnosis:string,
    urgent:boolean,
  };
}
const initialState:IDiagnosisState = {
  formData: {
    diagnosis: "",
    remarks_diagnosis: "",
    urgent: false,

  },
};

const diagnosisSlice = createSlice({
  name: "diagnosis",
  initialState,
  reducers: {
    onReceiveDiagnosisEditData: (state, {payload}:PayloadAction<any>)=>{
      state.formData ={...state.formData, ...payload};
    },
    onChangeDiagnosisValue: (state, {payload}:PayloadAction<{field:string;value:any;}>)=>{
      state.formData[payload.field] = payload.value;
    },
    onClearDiagnosisState: (state)=>{
      state.formData=initialState.formData;
    },
  },
});

export const {
  onChangeDiagnosisValue,
  onReceiveDiagnosisEditData,
  onClearDiagnosisState,
} = diagnosisSlice.actions;

export default diagnosisSlice.reducer;
export const selectDiagnosisValues = (state:RootState) => state.wizard.medicine.diagnosisForm.formData;
