import React, {useState, useEffect} from "react";
import {
  FaUpload,
  FaTrash,
  AiOutlineEye,
} from "components/icons";
import {connect, useDispatch} from "react-redux";
import {
  uploadLocalFiles,
} from "store/middleware/newRequestThunk";
import {updateLocalFile, updateDownloadedFile, removeLocalFilesAction, markFilesForRemoval} from "../../../store/features/newRequestSlice";
import PropTypes from "prop-types";
import Grid from "@mui/material/Grid";
import Overlay from "react-overlay-component";
import PDFViewer from "components/PDFViewer";
import {TextArea} from "components/formFields/TextArea";
import {SegmentedButton} from "components/formFields/SegmentedButton";


import {
  Button,
} from "@mui/material";
import {useTranslation} from "react-i18next";

/**
 * Component for uploading files.
 *
 * @component
 * @example
 * return (
 *   <Upload  />
 * )
 */

const UploadDocument = (props) => {
  const {uploadLocalFiles, localFiles, downloadedFiles, allFiles} = props;
  const {t} = useTranslation();

  const [inputRef] = useState(React.createRef(0));

  const dispatch = useDispatch();
  const handleFileRemove = (fileToRemove) => {
    dispatch(removeLocalFilesAction(allFiles.filter((file) => file !== fileToRemove), "REMOVE"));
    inputRef.current.value = "";
  };

  // API request for deleting a file
  const handleFileRemoveAPI = (fileToRemove) => {
    dispatch(markFilesForRemoval(fileToRemove));
    handleFileRemove(fileToRemove);
  };

  const handleFileAdd = (theFiles) => {
    console.log("handleFileAdd", theFiles);
    const renameFile = (originalFile) => {
      const newName = (downloadedFiles.some((el) => el.name === originalFile.name.replace(".pdf", " - Copy.pdf"))) ?
      originalFile.name.replace(".pdf", " - Copy - Copy.pdf") :
      originalFile.name.replace(".pdf", " - Copy.pdf");
      return new File([originalFile], newName, {
        type: originalFile.type,
        lastModified: originalFile.lastModified,
      });
    };

    const renameLocalFile = (originalFile) => {
      const newName = (allFiles.some((el) => el.name === originalFile.name.replace(".pdf", " - Copy.pdf"))) ?
      originalFile.name.replace(".pdf", " - Copy - Copy.pdf") :
      originalFile.name.replace(".pdf", " - Copy.pdf");
      return new File([originalFile], newName, {
        type: originalFile.type,
        lastModified: originalFile.lastModified,
      });
    };

    for (let i = 0; i < theFiles.length; i++ ) {
      const found = downloadedFiles.some((el) => el.name === theFiles[i].name);
      if (found) {
        theFiles.splice(i, 1, renameFile(theFiles[i]));
      }
    }

    for (let i = 0; i < theFiles.length; i++ ) {
      const found = allFiles.some((el) => el.name === theFiles[i].name);
      if (found) {
        theFiles.splice(i, 1, renameLocalFile(theFiles[i]));
      }
    }

    if (theFiles && theFiles.map !== undefined && Array.isArray(theFiles)) {
      const nonStudyFiles = theFiles.map((e) => Object.assign(e, {uploadtype: ""}));
      uploadLocalFiles(Object.values(nonStudyFiles));
    }
  };

  useEffect(() => {
  }, [localFiles, downloadedFiles]);

  const verifyIfDownloadedFileIsRemoved=(file)=>{
    return props.filesMarkedForRemoval.filter(
        (markedFile)=>Object.values(markedFile)?.includes(file.fileURL)).length>0;
  };
  const handleDropzoneClick = () => inputRef.current.click();

  const carouselView = (file) => {
    const pdfFile=file.fileURL?[file]:[
      {fileURL: window.URL.createObjectURL(
          new Blob(
              [file],
              {type: "application/pdf"},
          ),
      ),
      name: file.name},
    ];
    setCarouselPDFs(pdfFile);
    setOverlay(true);
  };

  const [isOpen, setOverlay] = useState(false);
  const [carouselPDFs, setCarouselPDFs] = useState([]);
  const closeOverlay = () => setOverlay(false);

  const displayDocument=(file, idx, handleFileRemove, updateFile)=>{
    return (
      <Grid key={idx} container className="pt-2">
        <Grid item xs={12} sm={1} className="pt-2"><Button color="info" onClick={() => carouselView(file)}><AiOutlineEye size={28} /></Button></Grid>
        <Grid item xs={12} sm={4} lg={6} className="pt-2"><span className="Filename">{file.name}</span></Grid>
        <Grid item xs={12} sm={6} lg={4} className="pt-2">
          <SegmentedButton
            input ={{
              value: file.uploadtype,
              onChange: (e) => dispatch(updateFile({idx: idx, uploadtype: e})),
            }}
            buttons={[
              {"key": "upload.document.patient", "label": t("upload.document.patient"), "value": "patient", "data-cy": "upload_document_patient"},
              {key: "upload.document.case", label: t("upload.document.case"), value: "case"},
            ]}
          />
        </Grid>
        <Grid item xs={12} sm={1} className="pt-2"><Button color="info" onClick={() => handleFileRemove(file)} data-cy={"delete-upload-" + idx} ><FaTrash /></Button></Grid>
        <Grid item sm={1} xs={0}></Grid>
        <Grid item sm={10} xs={12} className="pt-2" data-cy={"txt_document_remarks"}>
          <TextArea
            style={{width: "100%"}}
            input ={{
              value: file.remarks,
              onChange: (e) => dispatch(updateFile({idx: idx, remarks: e.target.value})),
            }}
            minRows={3}
            meta={{}}
            label={t("upload.document.remarks")}
          />
        </Grid>
        <Grid item sm={1} xs={0}></Grid>
        <Grid item xs={12} className="pt-2"><hr/></Grid>

      </Grid>

    );
  };

  return <div
    id="dropzone"
    className="card  pb-4" style={{border: "none", width: "100%", margin: "inherit"}}>
    <p className="pt-2 pb-4">{t("upload.other.text")}</p>
    <div className="card-bodyDropzone" style={{alignSelf: "center"}}>


      <div className="Dropzone shadow" onClick={handleDropzoneClick}>
        <Overlay configs={{animate: true}} isOpen={isOpen} closeOverlay={closeOverlay}>
          {Array.isArray(
              carouselPDFs)&& carouselPDFs.map((file, idx) =>
            <PDFViewer key={idx} src={file.fileURL}/>)}
        </Overlay>
        <input
          ref={inputRef}
          hidden={true} type="file"
          id="file"
          accept=".pdf"
          multiple
          name="file"
          data-cy="dropzone_input"
          onChange={(e) => {
            console.log("dropzone onChange", e.target);
            handleFileAdd([...e.target.files]);
          } }

        />
        <span>{t("upload.warning")}</span>
        <FaUpload />
      </div>
      <div className="pt-2">

        {allFiles[0] && allFiles.length > 0 ? allFiles.map((file, idx) => {
          if (file.uploadtype!=="study") {
            return displayDocument(file, idx, handleFileRemove, updateLocalFile);
          }
          return null;
        }) : null}

        {/* List from BE (EDIT Case) */}
        {downloadedFiles[0] && downloadedFiles.length > 0 ?
         downloadedFiles.filter((file)=>
           !verifyIfDownloadedFileIsRemoved(file)).map((file, idx) => {
           return displayDocument(file, idx, handleFileRemoveAPI, updateDownloadedFile);
         }) : null}

      </div>

    </div>
  </div>;
};


const mapStateToProps = (state) => ({
  localFiles: state.singleRequest.localFiles?.filter((e)=>e.uploadtype!=="study"),
  downloadedFiles: state.singleRequest.downloadedFiles?.filter((e)=>e.uploadtype!=="study"),
  allFiles: state.singleRequest.localFiles,
  filesMarkedForRemoval: state.singleRequest.filesMarkedForRemoval,
});

UploadDocument.propTypes = {
  uploadLocalFiles: PropTypes.func.isRequired,
};

export default connect(mapStateToProps, {uploadLocalFiles})(UploadDocument);
