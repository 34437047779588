import React, {useEffect} from "react";
import {useTranslation} from "react-i18next";
import {connect, ConnectedProps} from "react-redux";
import {UseAppDispatch} from "../../store/hooks";
import {RootState} from "../../store/rootstate";
import makeStyles from "@mui/styles/makeStyles";
import SignaturePad from "react-signature-canvas";
import SaveIcon from "@mui/icons-material/Save";
import DeleteIcon from "@mui/icons-material/Delete";
import {setSignaturePicture} from "store/middleware/userThunk";
import {
  Button,
  CircularProgress,
} from "@mui/material";
const Signature = (props: Props) => {
  const {t} = useTranslation();
  const dispatch = UseAppDispatch();
  const useStyles = makeStyles(() => ({
    canvas: {
      background: "#FAFBFC",
      border: "1px solid #D4E1F2",
      borderRadius: "3px",
      width: "650px",
      height: "150px",
    },
  }));

  useEffect(() => {
    if (props.Base64EncodedSignaturePicture) {
      if (sigCanvas && sigCanvas.current && sigCanvas.current.fromDataURL instanceof Function) {
        sigCanvas.current.fromDataURL(props.Base64EncodedSignaturePicture);
      }
    }
  }, [props.Base64EncodedSignaturePicture, props.isLoadingUserData, props.isSavingSignature]);
  const classes = useStyles();
  const sigCanvas: React.MutableRefObject<any> = React.useRef();
  const clear = () => {
    sigCanvas.current.clear();
  };
  const save = async () => {
    if (sigCanvas && sigCanvas.current && sigCanvas.current.toDataURL instanceof Function) {
      const base64EncodedSignaturePicture = sigCanvas.current.toDataURL("image/png"); // getTrimmedCanvas()
      dispatch(setSignaturePicture(base64EncodedSignaturePicture));
    }
  };
  return (
    <div className="text-center signature_container">
      <div className="">
        <div className="col-sm-12">
          <div className="card signature_card">
            <div className="card-header container-fluid">
              <div className="d-xl-flex flex-xl-nowrap d-lg-flex flex-lg-nowrap w-100 ">
                <h3 className="w-100 p-2 centred">{t("signature.heading")}</h3>
              </div>
            </div>
            <div className="card-body signature_pad" id="signature-pad">
              {(props.isSavingSignature || props.isLoadingUserData) &&
                <CircularProgress color="inherit" size={20} />
              }
              {!props.isSavingSignature && !props.isLoadingUserData &&
                <div>
                  <SignaturePad penColor='blue' canvasProps={{className: classes.canvas}} ref={sigCanvas}/>
                  <div>
                    <p>{t("signature.longtext2describethefunction")}</p>
                  </div>
                </div>
              }
            </div>
            <div className="card-body signature_label_screen_to_small">
              <p>{t("signature.display.to.small")}</p>
            </div>
            <div className="card-footer">
              <Button className="float-left" size="medium" startIcon={<DeleteIcon />} variant="contained" color="primary" id="signature_clear" onClick={clear} disabled={props.isSavingSignature || props.isLoadingUserData} >{t("signature.clear")}</Button>
              <Button className="float-right" size="medium" startIcon={<SaveIcon />} variant="contained" color="primary" id="signature_save" onClick={save} disabled={props.isSavingSignature || props.isLoadingUserData} >{t("signature.save")}</Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};


const mapStateToProps = (state: RootState) => ({
  Base64EncodedSignaturePicture: state.userinformation.user.Base64EncodedSignaturePicture,
  isSavingSignature: state.loading.signature === true,
  isLoadingUserData: state.loading.userGroups === true,
});

const mapDispatchToProps = {
};

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>

interface Props extends PropsFromRedux {
  Base64EncodedSignaturePicture: string
}

export default connector(Signature);
