import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {RootState} from "store/rootstate";

export interface TagrissoState {
  formData: {
    variant: string
    default: boolean,
    nonSmallCellLungCarcinomaWithCommonEGFRmIsPresent: boolean, // Nicht- kleinzelliges Lungenkarzinom
    theTumorIsResectable: boolean, // Der Tumor ist resezierbar
    theTumorIsAdvancedWithoutPriorTherapy: boolean, // Der Tumor ist fortgeschritten ohne Vortherapie (1L)
    tagrissoIsUsedInCombinationWithChemotherapy: boolean, // Tagrisso wird in Kombination mit einer Chemotherapie eingesetzt
    brainMetastasesPresent: boolean, // Hirnmetastasen vorhanden
    tagrissoIsUsedInMonoTherapy: boolean,
    defaultForm: {
      preTreatment: string
      patientCondition: string,
      reasonsAlternativeTherapiesFailed: string,
    },
  }
}

const initialState:TagrissoState = {
  formData: {
    variant: "",
    default: false, // Eine andere Indikation soll behandelt werden
    nonSmallCellLungCarcinomaWithCommonEGFRmIsPresent: false, // Nicht- kleinzelliges Lungenkarzinom
    theTumorIsResectable: false, // Der Tumor ist resezierbar
    theTumorIsAdvancedWithoutPriorTherapy: false, // Der Tumor ist fortgeschritten ohne Vortherapie (1L)
    tagrissoIsUsedInCombinationWithChemotherapy: false, // Tagrisso wird in Kombination mit einer Chemotherapie eingesetzt
    tagrissoIsUsedInMonoTherapy: false,
    brainMetastasesPresent: false, // Hirnmetastasen vorhanden
    defaultForm: {
      preTreatment: "",
      patientCondition: "",
      reasonsAlternativeTherapiesFailed: "",
    },
  },
};

const TagrissoFormSlice = createSlice({
  name: "Tagrisso",
  initialState,
  reducers: {
    onClearTagrissoState: (state)=>{
      state.formData = initialState.formData;
    },
    onReceiveSpecialMedTagrissoEditData: (state, {payload}: PayloadAction<any>) => {
      if (state.formData.defaultForm === undefined) {
        state.formData.defaultForm = {
          preTreatment: "",
          patientCondition: "",
          reasonsAlternativeTherapiesFailed: "",
        };
      }

      if (payload.formData?.defaultForm) {
        state.formData.defaultForm.preTreatment =
          payload.formData?.defaultForm?.preTreatment;
        state.formData.defaultForm.patientCondition =
          payload.formData?.defaultForm?.patientCondition;
        state.formData.defaultForm.reasonsAlternativeTherapiesFailed =
          payload.formData?.defaultForm?.reasonsAlternativeTherapiesFailed;
      }
      if (payload.formData?.default === true) {
        state.formData.default = true;
      } else if (payload.formData?.nonSmallCellLungCarcinomaWithCommonEGFRmIsPresent === true) {
        state.formData.nonSmallCellLungCarcinomaWithCommonEGFRmIsPresent = true;
      } else {
        state.formData.default = true;
      }
      if (payload.formData?.theTumorIsResectable === true) {
        state.formData.theTumorIsResectable = true;
      }
      if (payload.formData?.theTumorIsAdvancedWithoutPriorTherapy === true) {
        state.formData.theTumorIsAdvancedWithoutPriorTherapy = true;
      }
      if (payload.formData?.tagrissoIsUsedInCombinationWithChemotherapy === true) {
        state.formData.tagrissoIsUsedInCombinationWithChemotherapy = true;
      }
      if (payload.formData?.tagrissoIsUsedInMonoTherapy === true) {
        state.formData.tagrissoIsUsedInMonoTherapy = true;
      }
      if (payload.formData?.brainMetastasesPresent === true) {
        state.formData.brainMetastasesPresent = true;
      }
      if (payload.formData?.variant ) {
        state.formData.variant = payload.variant;
      }
    },
    onChangeTagrissoValue: (state, {payload}: PayloadAction<any>) => {
      if (payload.preTreatment || payload.preTreatment === "") state.formData.defaultForm.preTreatment = payload.preTreatment;
      if (payload.patientCondition || payload.patientCondition === "") state.formData.defaultForm.patientCondition = payload.patientCondition;
      if (payload.reasonsAlternativeTherapiesFailed || payload.reasonsAlternativeTherapiesFailed === "") state.formData.defaultForm.reasonsAlternativeTherapiesFailed = payload.reasonsAlternativeTherapiesFailed;


      if (payload.formData?.default === true ||
        payload.formData?.default === false) {
        state.formData.default = payload.formData?.default;
      }
      if (payload.formData?.nonSmallCellLungCarcinomaWithCommonEGFRmIsPresent === true ||
        payload.formData?.nonSmallCellLungCarcinomaWithCommonEGFRmIsPresent === false) {
        state.formData.nonSmallCellLungCarcinomaWithCommonEGFRmIsPresent =
          payload.formData?.nonSmallCellLungCarcinomaWithCommonEGFRmIsPresent;
      }

      if (payload.choice === "nonSmallCellLungCarcinomaWithCommonEGFRmIsPresent") {
        state.formData.nonSmallCellLungCarcinomaWithCommonEGFRmIsPresent = true;
        state.formData.default = false;
      }
      if (payload.formData?.theTumorIsResectable === true ||
        payload.formData?.theTumorIsResectable === false) {
        state.formData.theTumorIsResectable = payload.formData?.theTumorIsResectable;
      }
      if (payload.formData?.theTumorIsAdvancedWithoutPriorTherapy === true ||
        payload.formData?.theTumorIsAdvancedWithoutPriorTherapy === false) {
        state.formData.theTumorIsAdvancedWithoutPriorTherapy = payload.formData?.theTumorIsAdvancedWithoutPriorTherapy;
      }
      if (payload.formData?.tagrissoIsUsedInCombinationWithChemotherapy === true ||
        payload.formData?.tagrissoIsUsedInCombinationWithChemotherapy === false) {
        state.formData.tagrissoIsUsedInCombinationWithChemotherapy =
          payload.formData?.tagrissoIsUsedInCombinationWithChemotherapy;
      }
      if (payload.formData?.tagrissoIsUsedInMonoTherapy === true ||
        payload.formData?.tagrissoIsUsedInMonoTherapy === false) {
        state.formData.tagrissoIsUsedInMonoTherapy = payload.formData?.tagrissoIsUsedInMonoTherapy;
      }
      if (payload.formData?.brainMetastasesPresent === true ||
        payload.formData?.brainMetastasesPresent === false) {
        state.formData.brainMetastasesPresent = payload.formData?.brainMetastasesPresent;
      }
      if (payload.formData?.variant) {
        state.formData.variant = payload.formData?.variant;
      }

      console.debug("tagrisso formData", {
        variant: state.formData.variant,
        default: state.formData.default,
        nonSmallCellLungCarcinomaWithCommonEGFRmIsPresent: state.formData.nonSmallCellLungCarcinomaWithCommonEGFRmIsPresent,
        theTumorIsResectable: state.formData.theTumorIsResectable,
        theTumorIsAdvancedWithoutPriorTherapy: state.formData.theTumorIsAdvancedWithoutPriorTherapy,
        tagrissoIsUsedInCombinationWithChemotherapy: state.formData.tagrissoIsUsedInCombinationWithChemotherapy,
        brainMetastasesPresent: state.formData.brainMetastasesPresent,
        tagrissoIsUsedInMonoTherapy: state.formData.tagrissoIsUsedInMonoTherapy,
      });
    },
  },
});

export const {
  onReceiveSpecialMedTagrissoEditData,
  onClearTagrissoState,
  onChangeTagrissoValue,
} = TagrissoFormSlice.actions;

export default TagrissoFormSlice.reducer;
export const selectTagrissoValues = (state:RootState) =>
  state.wizard.medicine.additionalInformation.tagrissoForm?.formData;


