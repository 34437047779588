import React from "react";
import {
  FaQuestion,

} from "react-icons/fa";

import {IconContext} from "react-icons";
import {
  Button,
} from "@mui/material";
import {allStates} from "components/states.js";

export const statusIcons=(status, handleClickStatus, t) => {
  const state=allStates.find((s)=>s.status===status);
  if (state) {
    return <Button onClick={handleClickStatus} cy-data={"button_" + state.status}>
      <IconContext.Provider value={{className: "global-class-name pb-1", color: state.color, size: "1.5em", title: state.status}}>
        {state.icon}
      </IconContext.Provider >
    </Button>;
  } else {
    return <Button>
      <IconContext.Provider value={{className: "global-class-name", color: "#c8ced3", size: "1.5em", title: "Sent print."}}>
        <FaQuestion />
      </IconContext.Provider>
    </Button>;
  }
};


