import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {findCountryObjectByCode} from "pages/Wizard/utils";
import {ICountry, RootState} from "store/rootstate";
export interface IDoctorState {
  formData: {
    doctor_title:string;
    doctor_surename:string;
    doctor_lastname:string;
    doctor_phone_no:string;
    doctor_fax_no:string;
    doctor_email_addr:string,
    doctor_center_name:string;
    doctor_specialty:string;
    doctor_street:string;
    doctor_postalcode:string;
    doctor_city:string;
    doctor_country:ICountry;
  };
}

export const initialState:IDoctorState = {
  formData: {
    doctor_title: "",
    doctor_surename: "",
    doctor_lastname: "",
    doctor_phone_no: "",
    doctor_fax_no: "",
    doctor_email_addr: "",
    doctor_center_name: "",
    doctor_specialty: "",
    doctor_street: "",
    doctor_postalcode: "",
    doctor_city: "",
    doctor_country: {
      lang: "",
      code: "",
      label: "",
      phone: "",
    },
  },

};

const doctorSlice = createSlice({
  name: "doctor",
  initialState,
  reducers: {
    onReceiveDoctorEditData: (state, {payload}:PayloadAction<any>)=>{
      state.formData ={...state.formData, ...payload};
    },
    onChangeDoctorValue: (state, {payload}: PayloadAction<{ field: keyof IDoctorState["formData"]; value: any; }>) => {
      state.formData[payload.field] = payload.value;
    },
    onClearDoctorState: (state)=>{
      state.formData=initialState.formData;
    },
    onSelectDoctor: (state, {payload}: PayloadAction<any>) => {
      let mail = payload?.email || "";
      if (payload.identities &&
        Array.isArray(payload.identities) &&
        payload.identities.length > 0) {
        mail = payload?.identities[0]?.issuerAssignedId;
      }

      state.formData.doctor_title=payload?.extension_title;
      state.formData.doctor_surename=payload?.givenName;
      state.formData.doctor_lastname=payload?.surname;
      state.formData.doctor_phone_no=payload?.extension_phone_number;
      state.formData.doctor_fax_no=payload?.extension_fax_number;
      state.formData.doctor_email_addr=mail;
      state.formData.doctor_center_name=payload?.extension_center_name;
      state.formData.doctor_specialty=payload?.extension_Profession;
      state.formData.doctor_street=payload?.streetAddress;
      state.formData.doctor_postalcode=payload?.postalCode;
      state.formData.doctor_city=payload?.city;
      state.formData.doctor_country=findCountryObjectByCode(payload?.country);
    },
    clearSpecificDoctortState: (state, {payload}:PayloadAction<{field:any;}>)=>{
      state.formData[payload.field]=initialState.formData[payload.field];
    },
  },
});

export const {
  onChangeDoctorValue,
  clearSpecificDoctortState,
  onReceiveDoctorEditData,
  onClearDoctorState,
  onSelectDoctor,
} = doctorSlice.actions;

export default doctorSlice.reducer;
export const selectDoctorValues = (state: RootState) => state.wizard.doctor?.formData;
