import React from "react";
import {connect} from "react-redux";
import {useTranslation} from "react-i18next";
import {Grid} from "@mui/material";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import TextField from "@mui/material/TextField";
import Checkbox from "@mui/material/Checkbox";
import Box from "@mui/material/Box";

import {
  UseAppDispatch,
  UseAppSelector,
} from "store/hooks";

import {
  onChangeQuiviqValue,
  selectQuviviqValues,
} from "./QuviviqSlice";
import {selectMedicineValues} from "../../../medicine/medicineSlice";

const QuviviqForm = (props) => {
  const isString = (value) => {
    return (value?.constructor === String) && value.length > 0;
  };
  const {t}=useTranslation();
  const dispatch = UseAppDispatch();
  const quviviqValues=UseAppSelector(selectQuviviqValues);
  const medicineValues = UseAppSelector(selectMedicineValues);

  // Justification
  const justification = (event: React.ChangeEvent<HTMLInputElement>) => {
    const selectedOption = (event.target as HTMLInputElement).value;
    const value = (event.target as HTMLInputElement).checked;
    console.debug("justification", event);
    if (value === undefined) {
      dispatch(onChangeQuiviqValue(
          {
            type: "justification",
            selectedOption: "furtherJustification",
            value: selectedOption,
          }));
    } else {
      dispatch(onChangeQuiviqValue(
          {
            type: "justification",
            selectedOption: selectedOption,
            value: value,
          }));
    }
  };


  // clinicalcondition
  const clinicalcondition = (event: React.ChangeEvent<HTMLInputElement>) => {
    const selectedOption = (event.target as HTMLInputElement).value;
    const value = (event.target as HTMLInputElement).checked;
    console.debug("clinicalcondition", event);
    if (value === undefined) {
      dispatch(onChangeQuiviqValue(
          {
            type: "clinicalcondition",
            selectedOption: "furtherJustification",
            value: selectedOption,
          }));
    } else {
      dispatch(onChangeQuiviqValue(
          {
            type: "clinicalcondition",
            selectedOption: selectedOption,
            value: value,
          }));
    }
  };


  // achieveIfSubscribed
  const achieveIfSubscribed = (event: React.ChangeEvent<HTMLInputElement>) => {
    const selectedOption = (event.target as HTMLInputElement).value;
    const value = (event.target as HTMLInputElement).checked;
    console.debug("achieveIfSubscribed", event);
    if (value === undefined) {
      dispatch(onChangeQuiviqValue(
          {
            type: "achieveIfSubscribed",
            selectedOption: "furtherJustification",
            value: selectedOption,
          }));
    } else {
      dispatch(onChangeQuiviqValue(
          {
            type: "achieveIfSubscribed",
            selectedOption: selectedOption,
            value: value,
          }));
    }
  };


  return <>
    {
      medicineValues.required_drug?.toLowerCase().includes("25") ?
        <div>
          <Grid item xs={12} >
            <Box sx={{p: 2}}>
              {t("indication.details.quiviq.recomendation.dosis")}
            </Box>

          </Grid>


        </div> :
        <div></div>
    }
    {
    // Justification
    }
    <Grid item xs={12}>
      <FormControl fullWidth>
        <FormLabel id="quiviq-justification-radio-buttons-group-label">{t("indication.details.quiviq_justification")}</FormLabel>
        <FormControlLabel value="thereIsNoAlternativeDrug" control={<Checkbox checked={quviviqValues.justification.thereIsNoAlternativeDrug} onChange={justification}/>} label={t("indication.details.quiviq_thereIsNoAlternativeDrug")} />
        <FormControlLabel value="patientRespondedToTherapy" control={<Checkbox checked={quviviqValues.justification.patientRespondedToTherapy} onChange={justification}/>} label={t("indication.details.quiviq_patientRespondedToTherapy")} />
        <FormControlLabel value="furtherJustification" control={<Checkbox checked={isString(quviviqValues.justification.furtherJustification)} />} data-cy="quiviq_furtherJustification" label={t("indication.details.quiviq.furtherJustification")} />
        {
          <TextField
            InputLabelProps={{shrink: false}}
            id="outlined-multiline-static"
            data-cy="txt_quiviq_furtherJustification"
            multiline
            fullWidth
            maxRows={4}
            minRows={4}
            onChange={justification}
            value={quviviqValues.justification.furtherJustification }
            variant="outlined"
          />
        }
      </FormControl>
    </Grid>


    {
    // clinicalcondition
    }
    <Grid item xs={12}>
      <FormControl fullWidth>
        <FormLabel id="quiviq-clinicalcondition-radio-buttons-group-label">{t("indication.details.quiviq.clinicalcondition")}</FormLabel>
        <FormControlLabel value="decreasedPerformance" control={<Checkbox checked={quviviqValues.clinicalcondition.decreasedPerformance} onChange={clinicalcondition} />} label={t("indication.details.quiviq.decreasedPerformance")} />
        <FormControlLabel value="decreasedCognitiveFunctions" control={<Checkbox checked={quviviqValues.clinicalcondition.decreasedCognitiveFunctions} onChange={clinicalcondition}/>} label={t("indication.details.quiviq.decreasedCognitiveFunction")} />
        <FormControlLabel value="previousFalls" control={<Checkbox checked={quviviqValues.clinicalcondition.previousFalls} onChange={clinicalcondition}/>} data-cy="quiviq_previousFalls" label={t("indication.details.quiviq.previousFalls")} />
        <FormControlLabel value="increasedRiskOfCardiovascularDisease" control={<Checkbox checked={quviviqValues.clinicalcondition.increasedRiskOfCardiovascularDisease} onChange={clinicalcondition}/>} label={t("indication.details.quiviq.increasedRiskOfCardiovascularDisease")} />
        <FormControlLabel value="furtherJustification" control={<Checkbox checked={isString(quviviqValues.clinicalcondition.furtherJustification)}/>} label={t("indication.details.quiviq.furtherJustification")} />
        {
          <TextField
            InputLabelProps={{shrink: false}}
            id="outlined-multiline-static"
            multiline
            fullWidth
            maxRows={4}
            minRows={4}
            value={quviviqValues.clinicalcondition.furtherJustification}
            onChange={clinicalcondition}
            variant="outlined"
          />
        }
      </FormControl>
    </Grid>


    {
    // achieveIfSubscribed
    }

    <Grid item xs={12}>
      <FormControl fullWidth>
        <FormLabel id="quiviq-achieveIfSubscribed-radio-buttons-group-label">{t("indication.details.quiviq.achieveIfSubscribed")}</FormLabel>
        <FormControlLabel value="improveSleepQuality" control={<Checkbox checked={quviviqValues.achieveIfSubscribed.improveSleepQuality} onChange={achieveIfSubscribed}/>} label={t("indication.details.quiviq.improveSleepQuality")} />
        <FormControlLabel value="improvingCognitiveFunctions" control={<Checkbox checked={quviviqValues.achieveIfSubscribed.improvingCognitiveFunctions} onChange={achieveIfSubscribed}/>} label={t("indication.details.quiviq.improvingCognitiveFunctions")} />
        <FormControlLabel data-cy="reductionOfRiskOfFalls" value="reductionOfRiskOfFalls" control={<Checkbox checked={quviviqValues.achieveIfSubscribed.reductionOfRiskOfFalls} onChange={achieveIfSubscribed} />} label={t("indication.details.quiviq.reductionOfRiskOfFalls")} />
        <FormControlLabel value="reductionOfRiskOfCardiovascularDisease" control={<Checkbox checked={quviviqValues.achieveIfSubscribed.reductionOfRiskOfCardiovascularDisease} onChange={achieveIfSubscribed}/>} label={t("indication.details.quiviq.reductionOfRiskOfCardiovascularDisease")} />
        <FormControlLabel value="reductionOfSideEffectsOfNarcoticsConsume" control={<Checkbox checked={quviviqValues.achieveIfSubscribed.reductionOfSideEffectsOfNarcoticsConsume} onChange={achieveIfSubscribed} />} label={t("indication.details.quiviq.reductionOfSideEffectsOfNarcoticsConsume")} />
        <FormControlLabel value="furtherJustification" control={<Checkbox checked={isString(quviviqValues.achieveIfSubscribed.furtherJustification)}/>} label={t("indication.details.quiviq.furtherJustification")} />
        {
          <TextField
            InputLabelProps={{shrink: false}}
            id="outlined-multiline-static"
            multiline
            fullWidth
            maxRows={4}
            minRows={4}
            value={quviviqValues.achieveIfSubscribed.furtherJustification}
            onChange={achieveIfSubscribed}
            variant="outlined"
          />
        }
      </FormControl>
    </Grid>


    {
    // code
    }

    <Box sx={{p: 1}}>
    </Box>
    <Grid item xs={12} sx={{fontSize: 10}} >
      {t("indication.details.quiviq.code")}
    </Grid>
  </>;
};

const mapStateToProps=(state) => {
  return {
  };
};

const mapDispatchToProps=(dispatch) => ({

});

export default connect(mapStateToProps, mapDispatchToProps)(QuviviqForm);


