
import React, {useEffect} from "react";
import {connect} from "react-redux";
import {
  Autocomplete,
  Grid,
  TextField,
  Typography,
  FormControl,

} from "@mui/material";
import {useTranslation} from "react-i18next";
import {UseAppSelector, UseAppDispatch} from "store/hooks";
import {
  onChangeMedicineValue,
  selectMedicineValues,

} from "./medicineSlice";


import {useIsInViewport} from "pages/Wizard/containers/utils";
import {
  onViewChange,
  isFormVisible,
} from "pages/Wizard/wizardSlice";

export const KNForm = (props) => {
  if (props === undefined) {
    props = {
      standalone: true,
    };
  }

  const {t}=useTranslation();
  const medicineValues=UseAppSelector(selectMedicineValues);
  const isVisibleValues = UseAppSelector(isFormVisible);
  const dispatch = UseAppDispatch();
  const isVisible=useIsInViewport(props.refProps);


  useEffect(()=>{
    if (props.loading.editRequestLoad === true) {
      return;
    } else {
      isVisibleValues.medicine!==isVisible&&dispatch(onViewChange({field: "medicine", value: isVisible}));
    }
  }, [isVisible]);


  const displayKnValue=medicineValues.kn_id? medicineValues.kn_id + "" :"";
  return (
    <Grid ref={props.refProps} container direction="column" spacing={3} id="kn">
      <Grid item>
        <Typography variant="h5" >
          {t(props.label)}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <FormControl fullWidth >
          <Autocomplete
            freeSolo
            data-cy="indicationcode"
            disableClearable
            options={
              medicineValues.indicationcodes || [""]
            }
            onInputChange={(event, newInputValue) => {
              console.debug("[MedicineForm] onInputChange, value is: ", newInputValue, " for event: ", event?.type);
              dispatch(onChangeMedicineValue({field: "kn_id", value: newInputValue}));
            }}
            value={displayKnValue}
            renderInput={(params) => (
              <TextField
                {...params}
                label={t("indication.details.type.limitationId")}
                InputProps={{
                  ...params.InputProps,
                  type: "...",
                }}
              />
            )}
          />
        </FormControl>
      </Grid>
    </Grid>
  );
};


const mapStateToProps = (state) => {
  return {
    loading: state.wizard.wizardModel.loading,
  };
};


const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(KNForm);

