import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {VALIDATIONERROR} from "pages/Wizard/interfaces";
import {findCountryObjectByCode} from "pages/Wizard/utils";
import {ICountry, IInsuranceCompany, RootState} from "store/rootstate";
import {validateField} from "./validations";

export interface IPatientState {
  formData: {
    patient_surename:string;
    patient_lastname:string;
    patient_gender:string;
    patient_date_of_birth:Date;
    patient_phone_no:string;
    patient_email_addr:string;
    patient_insurance:IInsuranceCompany;
    patient_insurance_no:string;
    patient_street:string;
    patient_postalcode:string;
    patient_city:string;
    patient_country:ICountry;
    patient_agreement:boolean;

  };
  validationData:{
    patient_email_addr:VALIDATIONERROR;
    patient_surename:VALIDATIONERROR;
    patient_lastname:VALIDATIONERROR;

  }

}
export const initialState: IPatientState = {
  formData: {
    patient_surename: "",
    patient_lastname: "",
    patient_gender: "",
    patient_date_of_birth: new Date("01/01/1970"),
    patient_phone_no: "",
    patient_email_addr: "",
    patient_insurance: {
      COM: "",
      DEL: "",
      DESCR1: "",
      DESCR2: "",
      GLN: "",
      LANG: "",
      ROLE: "",
      email: "",
      email_med: "",
      email_vent: "",
    },
    patient_insurance_no: "",
    patient_street: "",
    patient_postalcode: "",
    patient_city: "",
    patient_country: {
      lang: "",
      code: "",
      label: "",
      phone: "",
    },
    patient_agreement: false,
  },
  validationData: {
    patient_email_addr: VALIDATIONERROR.NONE,
    patient_surename: VALIDATIONERROR.NONE,
    patient_lastname: VALIDATIONERROR.NONE,

  },

};

const patientSlice = createSlice({
  name: "patient",
  initialState,
  reducers: {
    onReceivePatientEditData: (state, {payload}:PayloadAction<any>)=>{
      state.formData ={...state.formData, ...payload};
      state.validationData = initialState.validationData;
    },
    onChangePatientValue: (state, {payload}:PayloadAction<{field:string;value:any;}>)=>{
      state.formData[payload.field] = payload.value;
      state.validationData[payload.field] = validateField(payload.field, payload.value);
    },
    onClearPatientState: (state)=>{
      state.formData=initialState.formData;
    },
    onSelectSearchedPatient: (state, {payload}:PayloadAction<any>)=>{
      state.formData.patient_surename= payload.firstname;
      state.formData.patient_lastname= payload.name;
      state.formData.patient_gender= payload.gender?.toString();
      state.formData.patient_date_of_birth= new Date(payload.date_of_birth);
      state.formData.patient_phone_no= payload.phone;
      state.formData.patient_email_addr= payload.email_addr;
      state.formData.patient_insurance_no=payload.insurance_no;
      state.formData.patient_street=payload.street;
      state.formData.patient_postalcode=payload.zip;
      state.formData.patient_city=payload.city;
      state.formData.patient_country=findCountryObjectByCode(payload.country);
      state.formData.patient_insurance.DESCR1=payload.insurance_company;
      state.formData.patient_insurance.GLN=payload.insurance_company_gln;
    },
    clearSpecificPatientState: (state, {payload}:PayloadAction<{field:any;}>)=>{
      state.formData[payload.field]=initialState.formData[payload.field];
    },
  },
});

export const {
  clearSpecificPatientState,
  onClearPatientState,
  onChangePatientValue,
  onReceivePatientEditData,
  onSelectSearchedPatient,
} = patientSlice.actions;

export default patientSlice.reducer;
export const selectPatientValues = (state: RootState) => state.wizard.patient.formData;
export const selectPatientValidationErrors = (state: RootState) => state.wizard.patient.validationData;
