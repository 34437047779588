/* eslint react/prop-types: 0 */
import React from "react";
import {useDispatch} from "react-redux";
import {IconButton} from "@mui/material";
import {useTranslation} from "react-i18next";
import makeStyles from "@mui/styles/makeStyles";
import {FaUserMd, FaTrash} from "react-icons/fa";
import {
  Avatar,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";
import {removeDoctorFromSubstitutions, fetchSubstitutionDoctors, fetchSubstitutions} from "../../../store/middleware/substitutionThunk";


const useRowStyles = makeStyles({
  root: {
    "& > *": {
      borderBottom: "unset",
    },
  },
});

function Row(props) {
  const classes = useRowStyles();
  const dispatch = useDispatch();
  return (
    <React.Fragment>
      <TableRow data-cy={"row_"+ props.row.email} className={classes.root}>
        <TableCell>
          <Avatar aria-label="recipe" style={{
            color: "#63c2de",
            backgroundColor: "#f0f3f5",
          }}>
            <FaUserMd data-toggle="popover" data-content="Disabled popover" />
          </Avatar>
        </TableCell>
        <TableCell className="pt-3 pb-3" align="left">{props.row.givenName + " " + props.row.family_name}</TableCell>
        <TableCell className="pt-3 pb-3" align="left">{props.row.email}</TableCell>
        <TableCell>
          <IconButton data-cy={"delete_button_" + props.row.email} variant="contained" size="small" color="primary" onClick={() => dispatch(removeDoctorFromSubstitutions(props.row.email, props.doctors, props.lang)).then(() => {
            if (props.substitutors) {
              return dispatch(fetchSubstitutions());
            } else {
              return dispatch(fetchSubstitutionDoctors());
            }
          })}>
            <FaTrash />
          </IconButton>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}


const renderTableData = (props) => {
  if (props.substitutions) {
    return props.substitutions.map((row, index) => {
      return <Row
        key={index}
        lang={props.lang}
        substitutors={props.substitutors}
        actions={props}
        row={row}
        doctors={props.doctors} />;
    });
  } else {
    return null;
  }
};

const SubstitutionBody = (props) => {
  const {t} = useTranslation();
  if (props.substitutions && (props.substitutions).length > 0) {
    return (
      <Table data-cy="table_substitution" className="table table-hover table-sm table-responsive table-borderless">
        <TableHead>
          <TableRow>
            <TableCell />
            <TableCell align="left" className="text-nowrap" scope="col">{t("substitution.name")}</TableCell>
            <TableCell align="left" className="text-nowrap" scope="col">{t("substitution.email")}</TableCell>
            <TableCell />
          </TableRow>
        </TableHead>
        <TableBody>
          {renderTableData(props)}
        </TableBody>
      </Table>
    );
  } else {
    return (
      <div data-cy="no_substitution" style={{textAlign: "center"}}>
        {props.noPersonsAddedMessage}
      </div>
    );
  }
};

export default SubstitutionBody;
