import {
  get,
} from "../api";
import {
  receiveglobalstatistics,
} from "../features/statisticsSlice";
import {Dispatch} from "redux";

import {startLoading, endLoading} from "../features/loadingSlice";
import {trackEvent, trackError} from "../../AppInsights";
import {displayToastError} from "../features/errorSlice";

export const fetchGlobalStatistics = () => (dispatch: Dispatch) => {
  trackEvent("requests/getStatistics/");
  dispatch(startLoading("statistics"));
  return get("requests/globalstatistics")
      .then((json) => {
        dispatch(receiveglobalstatistics(json));
      })
      .catch((error) => {
        dispatch(displayToastError("STATISTICS_RETRIVE_UNSUCCESSFUL"));
        trackError(error);
      })
      .finally(() => dispatch(endLoading("statistics")));
};
