import React, {useRef, useEffect} from "react";
import {connect, useDispatch} from "react-redux";
import {Paper, Typography, Box, Grid} from "@mui/material";
import {UseAppSelector} from "store/hooks";
import {
  fetchRequestData,
  getAllWizardForms,
  selectIsLoading,
} from "../wizardSlice";
import {useTranslation} from "react-i18next";
import {renderWizardForms} from "../wizardSteps";
import MedicineInfo from "../forms/med/AdditionalInformation/MedicineInfo";
import {useParams} from "react-router-dom";
import Backdrop from "components/Backdrop";
import {getPatientDiary} from "store/middleware/newRequestThunk";
import {onSelectDoctor} from "../forms/doctor/doctorSlice";

import {fetchDoctorData} from "store/middleware/masterdataThunk";
import {selectIsAuthenticated, selectUser} from "store/features/userSlice";
import {selectLoadingState} from "store/features/loadingSlice";

export const WizardForm = (props) => {
  const allWizardForms = UseAppSelector(getAllWizardForms);


  let refList={};
  allWizardForms.forEach((form)=>{
    refList={...refList, [form.id]: useRef()};// NOSONAR
  });

  const {t} = useTranslation();
  const isLoading = UseAppSelector(selectIsLoading);
  const isAuthenticated=UseAppSelector(selectIsAuthenticated);
  const doctor=UseAppSelector(selectUser);
  const loadingReduxData=UseAppSelector(selectLoadingState);
  const {requestId} = useParams<{requestId:string}>();
  const dispatch = useDispatch();


  useEffect(()=>{
    isAuthenticated&&dispatch(fetchDoctorData());
    isAuthenticated&&dispatch(onSelectDoctor(doctor));
  }, [isAuthenticated]);

  /*
  * check if the mobile app is used
  */
  useEffect( () => {
    if (requestId === undefined || window.location.href.indexOf("/app/") > -1) {
      // do nothing
    } else {
      Promise.all([
        dispatch(fetchRequestData({id: requestId})), // <-- async dispatch chaining in action
        dispatch(getPatientDiary(requestId)),
      ]).then(() => {
        console.debug("request data loaded");
      }).catch((err) => {
        console.error(err);
      });
    }
  }, [requestId]);

  const isLoadingForm = loadingReduxData.doctor === true ||
                        isLoading.getRequest === true ||
                        isLoading.postRequest === true ||
                        isLoading.sendToInsurance === true ||
                        loadingReduxData.studies === true ||
                        isLoading.editRequestLoad;

  return (
    <Grid container>
      <Backdrop open={isLoadingForm}></Backdrop>
      <Grid item xs={12} md={8}>
        <Paper sx={{padding: "1em", width: "100%", minHeight: "100%", marginBottom: "1em"}}>
          <Grid item xs={12}>
            <Typography variant="h5" marginBottom='20px'>
              {t("med.wizzard.title")}
            </Typography>
          </Grid>
          {allWizardForms?.map((e) =>
            <Box key={e.id} paddingY={2}>
              {renderWizardForms(e.id, {label: e.label}, refList[e.id])}
            </Box>,
          )}
        </Paper>
      </Grid>
      <Grid container item md={4} display={{xs: "none", md: "flex"}} >
        <Paper sx={{backgroundColor: "#e8f4ec", padding: "1em", paddingRight: "2em", minHeight: "100%", marginBottom: "1em", width: "-webkit-fill-available"}}>
          <Grid container spacing={3}>
            <MedicineInfo/>
          </Grid>
        </Paper>
      </Grid>
    </Grid>
  );
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(WizardForm);
