import React from "react";
class PDFJs {
  init = (source, element) => {
    if (element.hasChildNodes()) {
      element.innerHTML = "";
    }
    const iframe = document.createElement("iframe");
    iframe.src = `/pdfjs-2.5.207-es5-dist/web/viewer.html?file=${source}`;
    iframe.width = "100%";
    iframe.height = "100%";
    element.appendChild(iframe);
  };
}
export default class PDFViewer extends React.Component {
  constructor(props) {
    super(props);
    this.viewerRef = React.createRef();
    this.backend = new PDFJs();
  }

  componentDidMount() {
    const {src} = this.props;
    const element = this.viewerRef.current;
    this.backend.init(src, element);
  }

  shouldComponentUpdate(nextProps) {
    return nextProps.src !== this.props.src;
  }

  componentDidUpdate() {
    this.backend.init(this.props.src, this.viewerRef.current);
  }

  render() {
    return (
      <div ref={this.viewerRef} id='viewer' style={{width: "100%", height: "100%"}}>
      </div>
    );
  }
}
