import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {RootState} from "store/rootstate";

export interface IBrukinsaCllState {
  formData:{
    previously_treated : boolean,
  };
}
const initialState:IBrukinsaCllState = {
  formData: {
    previously_treated: false,
  },
};

const BrukinsaCllFormSlice = createSlice({
  name: "BrukinsaCll",
  initialState,
  reducers: {
    onChangeBrukinsaCllValue: (state, {payload}:PayloadAction<any>)=>{
      state.formData[payload.field] = payload.value;
    },
    onClearBrukinsaCllState: (state)=>{
      state.formData=initialState.formData;
    },
  },
});

export const {
  onChangeBrukinsaCllValue,
  onClearBrukinsaCllState,
} = BrukinsaCllFormSlice.actions;

export default BrukinsaCllFormSlice.reducer;
export const selectBrukinsaCllValues = (state:RootState) =>
  state.wizard.medicine.additionalInformation.brukinsaCllForm.formData;
export const selectActiveBrukinsaCllValues=(state:RootState)=>{
  return {
    previously_treated: state.wizard.medicine.additionalInformation.brukinsaCllForm.formData.previously_treated,
  };
};
