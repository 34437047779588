import React, {useEffect} from "react";
import {connect} from "react-redux";
import {Field, reduxForm, formValueSelector, change} from "redux-form";
import {useTranslation} from "react-i18next";
import {Box,
  Grid,
} from "@mui/material";
import {
  fetchMigelData,
  fetchICD10Data,
} from "store/middleware/masterdataThunk";
import {
  onSelectMigelObject,
} from "../../../store/features/newRequestSlice";

import {AutocompleteField} from "components/formFields/AutocompleteField";
import {TextArea} from "components/formFields/TextArea";
import {IosSwitch} from "components/formFields/IOSSwitch";


import {fieldNames, fieldLevelValidator} from "components/formUtils";
const ReduxFormComponentIndication=(props) => {
  const {t, i18n}=useTranslation();
  const currentLang=i18n.language.toUpperCase();
  const {
    needsValidation,
    requiredMigel,
    diagnosis,
    diagnosisProps,
    requiredMigelProps,
    requiredMigelData,
    changeValue,
    editData,
  }=props;
  useEffect(()=>{
    fieldNames.migel.forEach((e)=>changeValue(e, editData?.migel_data?.[e]));
  }, [editData]);
  useEffect(()=>{
    requiredMigelData&&changeValue("limitatio_details", requiredMigelData?.limitatio_details);
    if (requiredMigelData && !requiredMigelData?.limitatio_details) {
      changeValue("limitatio_approval", "true");
    } else {
      changeValue("limitatio_approval", "false");
    }
  }, [requiredMigelData]);

  const {required, lengthLessThanTwo}=fieldLevelValidator;
  return <Grid container spacing={3} justifyContent="center">
    <Grid container item justifyContent="center">
      <Grid item xs={12} sm={12} component={Box} alignSelf='flex-start' className="mt-3">
        <Field name="diagnosis"
          needsDelay
          component={AutocompleteField}
          onChange={(e)=>diagnosis.fetchOptions(e, currentLang)}
          options={diagnosisProps.options}
          isLoading={diagnosisProps.isLoading}
          getOptionLabel={(item) => item?.description? `${item.code} ${item.description}`: item.toString()}
          touched={needsValidation}
          required={true}
          validate={[required, lengthLessThanTwo]}
          label={t("indication.details.diagnosis")}
        />
      </Grid>
      <Grid item xs={12} sm={12} className="mt-3">
        <Field name="remarks_diagnosis"
          minRows={3}
          component={TextArea}
          type="text"
          label={t("indication.details.remarks.diagnosis")}
        />
      </Grid>
      <Grid item xs={12} sm={12}>
        <Field name="required_migel"
          needsDelay
          component={AutocompleteField}
          onSelectObject={requiredMigel.select}
          onChange={(e)=>requiredMigel.fetchOptions(e, currentLang)}
          options={requiredMigelProps.options}
          isLoading={requiredMigelProps.isLoading}
          getOptionLabel={(e) => e.article? e.article.toString():e.toString()}
          touched={needsValidation}
          required={true}
          validate={[required]}
          label={t("indication.details.required.migel")}
        />
      </Grid>
    </Grid>
    <Grid container item spacing={3}>
      <Grid item xs={12} sm={6}>
        <Field name="limitatio_details"
          defaultValue={t("no.limitations")}
          required={true}
          touched={needsValidation}
          component={TextArea}
          title={t("newrequest.limitation.title")}
          type="text"
          label={t("no.limitations")}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <Field name="limitatio_approval"
          options={{y: t("indication.yes"), n: t("indication.no")}}
          option_name="limitatio_approval"
          disabled={requiredMigelData && !requiredMigelData?.limitatio_details}
          touched={needsValidation}
          component={IosSwitch}
          normalize={(v) => v ? "true" : "false"}
          format={(v) => v === "true"}
          type="text"
          label={t("indication.details.limitatio.approval")}
        />
      </Grid>
    </Grid>
  </Grid>;
};

const mapStateToProps=(state) => {
  return {
    requiredMigelData: state.singleRequest.requiredMigelData,
    editData: state.singleRequest.editData,
    needsValidation: state.singleRequest.needsValidation.form==="migel",
    formValues: formValueSelector("migel_data")(state, "diagnosis", "required_migel", "limitatio_approval"),
    diagnosisProps: {
      options: state.masterdata?.autocompleteOptions?.icd10,
      isLoading: state.loading.icd10,
    },
    requiredMigelProps: {
      isLoading: state.loading.migel,
      options: state.masterdata?.autocompleteOptions?.migel,
    },
  };
};

const mapDispatchToProps=(dispatch) => ({
  changeValue: (fieldName, value)=>dispatch(change("migel_data", fieldName, value)),
  requiredMigel: {
    select: (object) => dispatch(onSelectMigelObject(object)),
    fetchOptions: (event, currentLang) => dispatch(fetchMigelData(event.target.value, currentLang)),
  },
  diagnosis: {
    fetchOptions: (event, currentLang) => {
      dispatch(fetchICD10Data(event.target.value, currentLang));
    },
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(reduxForm({
  destroyOnUnmount: false,
  enableReinitialize: false,
  form: "migel_data",
})(ReduxFormComponentIndication));


