import React, {useEffect} from "react";
import {useHistory} from "react-router-dom";

import {
  Grid,
  Typography,
  Box,
} from "@mui/material";
import {Statistics} from "./components/statistics";
import {AdminStatistics} from "./components/adminstatistics";
import {HomeCardTile} from "./components/HomeCardTile";
import {AuthenticatedTemplate, useIsAuthenticated} from "@azure/msal-react";
import {fetchGlobalStatistics} from "store/middleware/statisticsThunk.tsx";
import {saveRequestBodyFromSession} from "pages/Wizard/wizardSlice";
import {
  FaCapsules,
  FaCrutch,
  FaSyncAlt,
  FaAmbulance,
  FaStethoscope,

} from "react-icons/fa";

import {
  MdSpa,
} from "react-icons/md";

import {useTranslation} from "react-i18next";

import {
  connect,
  useDispatch,
} from "react-redux";

const Home = (props) => {
  const dispatch = useDispatch();
  const isAuthenticated = useIsAuthenticated();
  const {t} = useTranslation();
  const history=useHistory();

  useEffect(async () => {
    try {
      if (isAuthenticated === true) {
        Promise.all([
          dispatch(fetchGlobalStatistics()),
        ]);
      }
    } catch (ex) {
      console.error(ex);
    }


    try {
      const session = JSON.parse(sessionStorage.getItem("requestBody"));
      if (session) {
        Promise.all([
          dispatch(saveRequestBodyFromSession())]).then(() => {
          history.push("/requests");
        });
      }
    } catch (ex) {
      console.error(ex);
    }
  }, []);

  try {
    return (
      <Box className="text-center" >
        <AuthenticatedTemplate className="row wrap">
          <Box sx={{m: 2}}>
            <Typography variant="h4" component="h4" align="left" sx={{color: "white.main"}}>
              {t("home.header.buttons")}
            </Typography>
          </Box>
          <Grid container spacing={2} alignItems="stretch">
            <Grid item xs={12} sm={6} md={4} key="med">
              <HomeCardTile to={"/wizard"}
                title={t("home.new.med")}
                icon={<FaCapsules />}
              >
              </HomeCardTile>
            </Grid>
            <Grid item xs={12} sm={6} md={4} key="migel">
              <HomeCardTile to={"/new_request?kogu_type=migel"}
                title={t("home.new.migel")}
                icon={<FaCrutch />}
                data-cy="btn_migel"
              >
              </HomeCardTile>
            </Grid>
            <Grid item xs={12} sm={6} md={4} key="3cKLV">
              <HomeCardTile to={"/new_request?kogu_type=ambstat"}
                title={t("home.new.ambstat")}
                icon={<FaSyncAlt />}
              >
              </HomeCardTile>
            </Grid>
            <Grid item xs={12} sm={6} md={4} key="Reha">
              <HomeCardTile to={"/new_request?kogu_type=Reha"}
                title={t("home.new.reha")}
                icon={<MdSpa />}
              >
              </HomeCardTile>
            </Grid>
            <Grid item xs={12} sm={6} md={4} key="Transport">
              <HomeCardTile to={"/new_request?kogu_type=transport"}
                title={t("home.new.transport")}
                icon={<FaAmbulance />}
              >
              </HomeCardTile>
            </Grid>
            <Grid item xs={12} sm={6} md={4} key="breathing_device">
              <HomeCardTile to={"/new_request?kogu_type=breathing_device"}
                title={t("home.new.breathing_device")}
                icon={<FaStethoscope />}
              >
              </HomeCardTile>
            </Grid>
          </Grid>
          <Box sx={{m: 2}}>
            {props.statistics?.PersonalStatistics ? (
              <Typography variant="h4" component="h4" align="left" sx={{color: "white.main"}}>
                {t("home.header.statistics")}
              </Typography>
            ): (<div></div>)}
          </Box>
          {Statistics(props)}
        </AuthenticatedTemplate>
        {/* {Tweets(props)} */}
        <Box sx={{m: 2}} />
        <AuthenticatedTemplate>

          {AdminStatistics(props)}
          <Box sx={{m: 2}} />
        </AuthenticatedTemplate>
      </Box>
    );
  } catch (ex) {
    console.error(ex);
    return <div></div>;
  }
};

const mapStateToProps = (state) => ({
  statistics: state.statistics.statistics,
  statistics_domains: flattenDomainStatistics(state.statistics.statistics),
  statistics_comms: flattenCommunicationStatistics(state.statistics.statistics),
  statistics_pharma: flattenPharmaStatistics(state.statistics.statistics),
});

function flattenPharmaStatistics(statistics) {
  try {
    const result = [];
    statistics?.StatisticsByDomain?.pharma?.forEach( (element) => {
      result.push({
        request_kw: element?.request_kw,
        DRUG: element?.DRUG,
        PRTNO: element?.PRTNO,
        DIAGNOSIS: element?.DIAGNOSIS,
        id: Math.random()*100,
        NAMS: element?.NAMS,
        ARTICLE_PRDNO: element?.ARTICLE_PRDNO,
        REQUEST_TYPE: element?.REQUEST_TYPE,
        ANONYM: element?.ANONYM,
      });
    });
    return result.sort((a, b) => a.request_kw < b.request_kw ? 1 : -1);
  } catch (ex) {
    console.error(ex);
  }
}
function flattenDomainStatistics(statistics) {
  const result = [];
  statistics?.StatisticsByDomain?.statistics?.forEach( (element) => {
    if (element?.domain) {
      try {
        element.domain.forEach( (domainElement) => {
          result.push({
            request_kw: domainElement?.request_kw,
            com_doc_insurance: domainElement?.com_doc_insurance,
            com_doc_pharma: domainElement?.com_doc_pharma,
            diagnoses: domainElement?.diagnoses,
            maildomain: domainElement?.maildomain,
            requests: domainElement?.requests,
            studies: domainElement?.studies,
            type: domainElement?.type,
            id: Math.random()*100,
          });
        });
      } catch (ex) {
        console.error(ex);
      }
    }
  });
  return result.sort((a, b) => a.request_kw < b.request_kw ? 1 : -1);
}

function flattenCommunicationStatistics(statistics) {
  const result = [];
  statistics?.StatisticsByDomain?.statistics?.forEach( (element) => {
    if (element?.comms) {
      try {
        element.comms.forEach( (commsElement) => {
          result.push({
            id: Math.random()*100,
            request_kw: commsElement.request_kw,
            maildomain: commsElement.maildomain,
            partner: commsElement.coms_partner,
            avg_time_div_in_minutes: commsElement.avg_time_div_in_minutes,
            max_time_div_in_minutes: commsElement.max_time_div_in_minutes,
            count_approved: commsElement.count_approved,
            count_conversations: commsElement.count_conversations,
            count_declined: commsElement.count_declined,
            count_messages: commsElement.count_messages,
          });
        });
      } catch (ex) {
        console.error(ex);
      }
    }
  });
  return result.sort((a, b) => a.request_kw < b.request_kw ? 1 : -1);
}

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(Home, Statistics);
