import React from "react";


import ChatList from "./components/ChatList";

const CommunicationView = (props) => {
  return (
    <div className="animated fadeIn">
      <ChatList/>
    </div>
  );
};


export default CommunicationView;
