import {
  get,
  post,
} from "../api";
import {
  receiveRequests,
  receiveChangesRequests,
  setPrintStudiesAvailability,
  receiveStatusHistoryForARequest,
  setPrintStudies,
  errorServiceException,
  setBanner,
  receiveUpdatedRequest,
  receiveDetails,
  removeReminderDateFromState,
  addReminderDateFromState,
  removeRequest,
} from "../features/requestListSlice";
import {startLoading, endLoading} from "store/features/loadingSlice";
import {trackEvent, trackError} from "AppInsights";
import {displayToastError, displayToastSuccess} from "../features/errorSlice";
import {
  fetchCommunication,
} from "./communicationThunk";
import {uploadLocalFiles} from "store/middleware/newRequestThunk";

function downloadObjectAsJson(exportObj, exportName) {
  const dataStr = "data:text/json;charset=utf-8," + encodeURIComponent(JSON.stringify(exportObj));
  const downloadAnchorNode = document.createElement("a");
  downloadAnchorNode.setAttribute("href", dataStr);
  downloadAnchorNode.setAttribute("download", exportName + ".json");
  document.body.appendChild(downloadAnchorNode); // required for firefox
  downloadAnchorNode.click();
  downloadAnchorNode.remove();
}

export const fetchRequests = () => (dispatch) => {
  trackEvent("requests/");
  dispatch(startLoading("requestList"));
  get("requests/")
      .then((json) => {
        dispatch(receiveRequests(json));
      },
      ).catch((error) => {
        trackError(error);
        dispatch(displayToastError("REQUESTS_LOADING_ERROR"));
        dispatch(receiveRequests([]));
      })
      .finally(() => dispatch(endLoading("requestList")));
};

export const fetchRequestsForExport = () => (dispatch) => {
  trackEvent("requests/");
  dispatch(startLoading("requestList"));
  get("requests/")
      .then((json) => {
        downloadObjectAsJson(json, "KoguExport_" + Date.now());
      }).catch((error) => {
        trackError(error);
        dispatch(displayToastError("REQUESTS_LOADING_ERROR"));
      })
      .finally(() => dispatch(endLoading("requestList")));
};

export const handleDeleteRequest = (id) => (dispatch) => {
  dispatch(startLoading("requestList"));
  post("requests/delete", {id: id}).then((res) => {
    dispatch(removeRequest(id));
    dispatch(displayToastSuccess("DELETION_SUCCESSFUL"));
  }).catch((error) => {
    dispatch(displayToastError("DELETION_UNSUCCESSFUL"));
    console.error(error);
  }).finally(() => dispatch(endLoading("requestList")));
};

export const handlePrintRequest = (id, lang) => (dispatch) => {
  dispatch(startLoading("requestList"));
  return get(`requests/pdf/${id}/print`, "blob").then((json) => {
    const blob = [{file: window.URL.createObjectURL(new Blob([json], {type: "application/pdf", DocumentTitle: "testpdf"})), fileName: "Druckvorschau"}];
    dispatch(setPrintStudiesAvailability(true));
    dispatch(setPrintStudies(blob));
  },
  ).catch((error) => {
    console.error("error @ printing");
    dispatch(displayToastError("PRINTING_UNSUCCESSFUL"));
    dispatch(errorServiceException(`requests/${error}`, id));
  })
      .finally(() => dispatch(endLoading("requestList")));
};

export const handleAddPrintoutToPraluentCopy = (id, lang) => (dispatch) => {
  return get(`requests/pdf/${id}/print`, "blob").then((json) => {
    const blob = [{fileURL: window.URL.createObjectURL(new Blob([json], {type: "application/pdf", DocumentTitle: "testpdf"})), name: "Druckvorschau", uploadType: ""}];
    if (blob) {
      dispatch(uploadLocalFiles(blob));
    }
  },
  ).catch((error) => {
    dispatch(displayToastError("SHOW_PRINTOUT_UNSUCESSFUL"));
    dispatch(errorServiceException(`requests/${error}`, id));
  });
};

export const handleDownloadAll = (id, lang, containsDocuments) => async (dispatch) => {
  dispatch(startLoading("requestList"));
  const createDownloadLink = (blob, fileName) => {
    const link = document.createElement("a");
    // create a blobURI pointing to our Blob
    link.href = window.URL.createObjectURL(blob);
    link.download = fileName;
    // some browser needs the anchor to be in the doc
    document.body.append(link);
    link.click();
    link.remove();
  };

  await get(`requests/pdf/${id}/`, "blob").then((json) => {
    createDownloadLink(new Blob([json]), "Druckvorschau.pdf");
  });

  if (containsDocuments) {
    trackEvent(`documents/availableDocuments/${id}/`);
    await get(`documents/availableDocuments/${id}/`).then((res) => {
      const promises = [];
      res.forEach((file) =>
        promises.push(get(`documents/download/${id}/${encodeURIComponent(file.name)}/${ file.fromExternalSource}`, "blob")
            .then((blob) => ({blob: blob, name: file.name}))),
      );
      if (promises.length > 0) {
        Promise.all(promises).then((res) => res.forEach((file) =>
          createDownloadLink(new Blob([file.blob]), `${file.name}`)));
      } else {
        dispatch(displayToastSuccess("NO_FILES_TO_PRINT"));
      }
    }).catch((error) => {
      dispatch(displayToastError("DOWNLOAD_FILES_UNSUCCESSFUL"));
    });
  }
  dispatch(endLoading("requestList"));
};

export const handlePrintAll = (id, lang, containsDocuments) => async (dispatch) => {
  dispatch(startLoading("requestList"));
  const printFile = async (blob) => {
    const iframe = document.createElement("iframe");
    document.body.appendChild(iframe);
    iframe.style.display = "none";
    iframe.src = window.URL.createObjectURL(blob);
    iframe.onload = () => {
      setTimeout(() => {
        iframe.focus();
        iframe.contentWindow.print();
      }, 1);
    };
  };
  await get(`requests/pdf/${id}`, "blob").then((json) => {
    printFile(new Blob([json], {type: "application/pdf"}));
  });

  if (containsDocuments) {
    trackEvent(`documents/availableDocuments/${id}/`);
    await get(`documents/availableDocuments/${id}/`).then((res) => {
      const promises = [];
      res.forEach((file) =>
        promises.push(get(`documents/download/${id}/${encodeURIComponent(file.name)}/${ file.fromExternalSource}`, "blob")
            .then((blob) => blob)));
      if (promises.length > 0) {
        Promise.all(promises)
            .then((res) =>
              res.forEach(async (file) =>
                await printFile(new Blob([file], {type: "application/pdf"}))));
      } else {
        dispatch(displayToastSuccess("NO_FILES_TO_PRINT"));
      }
    }).catch((error) => {
      trackError(error);
      dispatch(displayToastError("Data couldnt be loaded. Please try again later."));
      dispatch(endLoading("requestList"));
    });
  }
  dispatch(endLoading("requestList"));
};


export const handlePrintStudiesRequest = (id) => (dispatch) => {
  dispatch(startLoading("requestList"));
  return get(`documents/availableDocuments/${id}/`).then((res) => {
    if (res.StatusText !== undefined) {
      dispatch(endLoading("requestList"));
      dispatch(setBanner(true));
    } else {
      const promises = [];
      res.forEach((file) => {
        promises.push(get(`documents/download/${id}/${encodeURIComponent(file.name)}/${ file.fromExternalSource}`, "blob").then((blob) => {
          return {file: window.URL.createObjectURL(blob), fileName: file.name};
        }));
      });
      if (promises.length > 0) {
        Promise.all(promises).then((res) => {
          dispatch(setPrintStudiesAvailability(true));
          dispatch(setPrintStudies(res));
        });
      } else {
        dispatch(displayToastSuccess("NO_FILES_TO_PRINT"));
      }
    }
  }).catch((error) => {
    dispatch(displayToastError("PRINTING_UNSUCCESSFUL"));
    dispatch(errorServiceException(`requests/${error}`, id));
  })
      .finally(() => dispatch(endLoading("requestList")));
};

export const handleDrugOrderRequest = (request_id, lang) => (dispatch) => {
  dispatch(startLoading("requestList"));
  return get(`requests/pdf/${request_id}/recipe`, "blob").then((json) => {
    const blob = [{file: window.URL.createObjectURL(new Blob([json], {type: "application/pdf", DocumentTitle: "PDF"})), fileName: "Medikament bestellen"}];
    dispatch(setPrintStudiesAvailability(true));
    dispatch(setPrintStudies(blob));
  },
  ).catch((error) => {
    console.error("error @ printing");
    dispatch(displayToastError("ORDER_DRUGS_UNSUCCESSFUL"));
    dispatch(errorServiceException(`requests/${error}`, request_id));
  })
      .finally(() => dispatch(endLoading("requestList")));
};

export const requestStudiesReq = (request_id) => (dispatch) => {
  trackEvent("studies/createStudieEmail/");
  dispatch(startLoading("requestList"));
  return post("studies/createStudieEmail/", {request_id})
      .then((json) => {
        if (json.success) {
          dispatch(displayToastSuccess("SENT_EMAIL_TO_PHARMA"));
          dispatch(fetchCommunication());
        } else {
          dispatch(displayToastError("SENT_EMAIL_TO_PHARMA_UNSUCCESSFUL"));
        }
      })
      .catch((error) => {
        if (error.status !== 400) {
          trackError(error);
        }
        dispatch(displayToastError("SENT_EMAIL_TO_PHARMA_UNSUCCESSFUL"));
      })
      .finally(() => dispatch(endLoading("requestList")));
};

export const fetchStatusChangesForARequest = (requestId) => (dispatch) => {
  get(`requests/history/${requestId}`).then((json) => {
    dispatch(receiveStatusHistoryForARequest(
        {historyList: json, requestId: requestId}),
    );
  }).catch((error) => {
    dispatch(errorServiceException(`requests/history${error}`));
    dispatch(displayToastError("NOTIFICATION_LOAD_UNSUCCESSFUL"));
  });
};

export const fetchStatusChangesRequests = () => (dispatch) => {
  dispatch(startLoading("notificationHistory"));
  get("requests/history").then((json) => {
    let today = new Date();
    const dd = String(today.getDate()).padStart(2, "0");
    const mm = String(today.getMonth() + 1).padStart(2, "0"); // January is 0!
    const yyyy = today.getFullYear();
    today = `${yyyy}-${mm}-${dd}`;
    const filteredHistory = json.filter((history) => {
      return history.event_id === "SUBTITUTE_ADDED_BY" ||
             history.event_id === "INSURANCE_RESPONSE_RECEIVED" ||
             history.event_id === "SUBTITUTE_REMOVED_BY" ||
             history.event_id === "STUDIES_RECEIVED" ||
            (history.event_id === "REMINDER_SET" && history.event_data <= today);
    });
    dispatch(receiveChangesRequests(filteredHistory));
    dispatch(endLoading("notificationHistory"));
  }).catch((error) => {
    dispatch(endLoading("notificationHistory"));
    dispatch(errorServiceException(`requests/history${error}`));
    dispatch(displayToastError("FETCH_NOTIFICATION_UNSUCCESSFUL"));
  });
};

export const sendRequestToInsurance = (request_id, resend, comment, lang) =>
  (dispatch) => {
    dispatch(startLoading("requestList"));
    if (request_id && request_id !== undefined && request_id !== "undefined") {
      return post(`requests/${request_id}/sendToInsurance`, {resend, comment})
          .then((json) => {
            if (json.success) {
              dispatch(receiveUpdatedRequest(json));
              dispatch(fetchRequests());
              dispatch(displayToastSuccess("SENT_TO_INSURANCE_SUCCESSFULLY"));
            } else {
              dispatch(displayToastError("SEND_REQUEST_TO_INSURANCE_UNSUCCESSFUL"));
            }
          })
          .catch((error) => {
            if (error.status !== 400) {
              trackError(error);
            }
            dispatch(displayToastError("SEND_REQUEST_TO_INSURANCE_UNSUCCESSFUL"));
          })
          .finally(() => dispatch(endLoading("requestList")));
    } else {
      dispatch(displayToastError("SEND_REQUEST_TO_INSURANCE_UNSUCCESSFUL"));
    }
  };

export const sendSetStatus = (requestId, status) => (dispatch) => {
  trackEvent(`requests/${requestId}/${status}`);
  post(`requests/${requestId}/${status}`).then((json) => {
    dispatch(receiveUpdatedRequest(json[0]));
    dispatch(displayToastSuccess("STATUS_CHANGE_SUCCESSFUL"));
  }).catch((error) => {
    trackError(error);
    dispatch(displayToastError("STATUS_CHANGE_UNSUCCESSFUL"));
  });
};

export const sendSetReminder = (request_id, reminder_date, remindermsg) => (dispatch) => {
  trackEvent(`requests/reminder/${request_id}/${reminder_date}`);
  post(`requests/reminder/${request_id}/` + (reminder_date ? encodeURIComponent(reminder_date) : ""), {msg: remindermsg}).then((json) => {
    dispatch(addReminderDateFromState({
      reminder_id: json.ID,
      request_id: request_id,
      reminder_date: reminder_date,
      remindermsg: remindermsg,
    }));
    dispatch(displayToastSuccess("REMINDER_SET_SUCCESSFUL"));
  }).catch((error) => {
    trackError(error);
    console.error(error);
    dispatch(displayToastError("REMINDER_SET_UNSUCCESSFUL"));
  });
};

export const deleteReminder = (reminder_id, request_id) => (dispatch) => {
  trackEvent(`requests/reminder/delete/${reminder_id}`);
  get("requests/reminder/delete/" + reminder_id).then((json) => {
    dispatch(displayToastSuccess("REMINDER_CANCELED_SUCCESSFUL"));
    dispatch(removeReminderDateFromState({
      reminder_id: reminder_id,
      request_id: request_id,
    }));
  }).catch((error) => {
    trackError(error);
    dispatch(displayToastError("REMINDER_DELETED_FAILED"));
  });
};

export const setHistoryAsRead = (request_history_id) => (dispatch) => {
  dispatch(startLoading("notificationDismiss"));
  trackEvent(`requests/historyAsRead/${request_history_id}`);
  return post(`requests/historyAsRead/${request_history_id}`)
      .then(() => dispatch(fetchStatusChangesRequests()))
      .catch((error) => {
        dispatch(errorServiceException(`requests/historyAsRead${error}`));
        dispatch(displayToastError("SET_HISTORY_AS_READ_UNSUCCESSFUL"));
      })
      .finally(() => dispatch(endLoading("notificationDismiss"))); ;
};

export const deleteAllRequests = () => (dispatch) => {
  dispatch(startLoading("requestList"));
  get("requests/")
      .then((itemList) => {
        itemList.forEach(async (request) => {
          try {
            request.request_status = "DELETED";
            await post("requests/edit/", request).catch((error) => {
              console.error("deleteAllRequests", error);
              dispatch(displayToastError("DELETE_ALL_REQUESTS_UNSUCCESSFUL"));
            });
          } catch (ex) {
            dispatch(displayToastError("DELETE_ALL_REQUESTS_UNSUCCESSFUL"));
            console.error("deleteAllRequests", ex);
          } finally {
          // nothing
          }
        });
      }).catch((error) => {
        trackError(error);
        console.error("deleteAllRequests", error);
      }).finally(() => dispatch(endLoading("requestList")));
};

export const fetchDetails = (id) => async (dispatch) => {
  trackEvent("requests/" + id);
  dispatch(startLoading("fetchDetails"));
  try {
    await Promise.all([
      // dispatch(cleanData()),
      get("requests/" + id)
          .then(async (json) => {
            dispatch(receiveDetails(json[0]));
          }).catch((error) => {
            console.error(error);
            trackError(error);
            dispatch(displayToastError(i18n.t("error.edit.request")));
          }),
    ]);
  } catch (ex) {
    console.error("LOADING REQUEST DETAILS", ex);
    dispatch(endLoading("fetchDetails"));
  }
  return dispatch(endLoading("fetchDetails"));
};
