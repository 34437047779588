//  Morbus Waldenström
import React, {useEffect} from "react";
import {connect} from "react-redux";
import {useTranslation} from "react-i18next";
import {Grid, Typography} from "@mui/material";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import Checkbox from "@mui/material/Checkbox";
import Box from "@mui/material/Box";
import FormLabel from "@mui/material/FormLabel";
import {UseAppDispatch, UseAppSelector} from "store/hooks";
import {onChangeImbruvicaValue, selectImbruvicaValues} from "./ImbruvicaSlice";
import {onChangeKVVLegalState, onChangeMedicineValue} from "../../../medicine/medicineSlice";
import {onChangeTreatmentValue} from "../../../Treatment/treatmentPlanSlice";
import {onChangeSpecialMedValue} from "../../specialMedSlice";
import {getPreselectedStudy, removeStudy, setStudyById} from "pages/Wizard/wizardSlice";
import WarningIcon from "@mui/icons-material/Warning";
import Stack from "@mui/material/Stack";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import TextField from "@mui/material/TextField";

const imbruvicaForm = (props) => {
  const {t}=useTranslation();
  const dispatch = UseAppDispatch();
  const imbruvicaValues=UseAppSelector(selectImbruvicaValues);
  const handleTreatmentValue = (field:string, value:any)=>dispatch(onChangeTreatmentValue({field, value}));

  useEffect(() => {
    switch (imbruvicaValues.formMW.variant) {
      case 0:
        handleTreatmentValue("limitatio_approval", false);
        handleTreatmentValue("specialist_info", false);
        dispatch(onChangeKVVLegalState(
            {
              limitatio_approval: false,
              specialist_info: false,
            },
        ));
        dispatch(onChangeMedicineValue({field: "kn_id", value: ""}));
        dispatch(removeStudy({}));
        break;
      case 1:
        dispatch(setStudyById({studyId: "PMC8683240"}));
        handleTreatmentValue("limitatio_approval", true);
        handleTreatmentValue("specialist_info", true);
        dispatch(onChangeMedicineValue({field: "kn_id", value: "21011.05"}));
        dispatch(onChangeKVVLegalState(
            {
              limitatio_approval: true,
              specialist_info: true,
            },
        ));
        break;
      case 2:
        dispatch(removeStudy({}));
        dispatch(onChangeMedicineValue({field: "kn_id", value: ""}));
        handleTreatmentValue("limitatio_approval", false);
        handleTreatmentValue("specialist_info", false);
        dispatch(onChangeKVVLegalState(
            {
              limitatio_approval: false,
              specialist_info: false,
            },
        ));
        break;
      case 3:
        dispatch(removeStudy({}));
        dispatch(onChangeMedicineValue({field: "kn_id", value: "21011.06"}));
        handleTreatmentValue("limitatio_approval", true);
        handleTreatmentValue("specialist_info", true);
        dispatch(onChangeKVVLegalState(
            {
              limitatio_approval: true,
              specialist_info: true,
            },
        ));
        break;
      case 4:
        dispatch(removeStudy({}));
        dispatch(onChangeMedicineValue({field: "kn_id", value: ""}));
        handleTreatmentValue("limitatio_approval", false);
        handleTreatmentValue("specialist_info", false);
        dispatch(onChangeKVVLegalState(
            {
              limitatio_approval: false,
              specialist_info: false,
            },
        ));
        break;
      case 5:
        dispatch(removeStudy({}));
        dispatch(onChangeMedicineValue({field: "kn_id", value: "21011.05"}));
        handleTreatmentValue("limitatio_approval", true);
        handleTreatmentValue("specialist_info", true);
        dispatch(onChangeKVVLegalState(
            {
              limitatio_approval: true,
              specialist_info: true,
            },
        ));
        break;
      case 6:
        dispatch(removeStudy({}));
        dispatch(onChangeMedicineValue({field: "kn_id", value: ""}));
        handleTreatmentValue("limitatio_approval", false);
        handleTreatmentValue("specialist_info", false);
        dispatch(onChangeKVVLegalState(
            {
              limitatio_approval: false,
              specialist_info: false,
            },
        ));
        break;
      case 7:
        dispatch(onChangeMedicineValue({field: "kn_id", value: ""}));
        dispatch(onChangeSpecialMedValue({"field": "special_med_additional_information", "value": 1}));
        dispatch(getPreselectedStudy({}));
        handleTreatmentValue("limitatio_approval", false);
        handleTreatmentValue("specialist_info", false);
        dispatch(onChangeKVVLegalState(
            {
              limitatio_approval: false,
              specialist_info: false,
            },
        ));
        break;
      case 8:
        dispatch(removeStudy({}));
        dispatch(onChangeMedicineValue({field: "kn_id", value: ""}));
        handleTreatmentValue("limitatio_approval", false);
        handleTreatmentValue("specialist_info", false);
        dispatch(onChangeKVVLegalState(
            {
              limitatio_approval: false,
              specialist_info: false,
            },
        ));
        break;
    }
  }, [imbruvicaValues.formMW.variant]);


  return <>

    <Grid item xs={12}>

      <FormControl fullWidth>
        <RadioGroup aria-labelledby="imbruvica-radio-buttons-group-form-patientDidNotReceiveMWTherapy" name="imbruvica-radio-buttons-group-form-patientDidNotReceiveMWTherapy"
          onChange = {(event, value) =>
            dispatch(onChangeImbruvicaValue({formMWchoice: value}))
          }
        >
          <FormControlLabel
            value="patientDidNotReceiveMWTherapy"
            control={<Radio checked={imbruvicaValues.formMW.patientDidNotReceiveMWTherapy} />} label={t("imbruvica.mw.patientDidNotReceiveMWTherapy")} />
          <FormControlLabel
            value="patientDidReceiveMWTherapy"
            control={<Radio checked={imbruvicaValues.formMW.patientDidReceiveMWTherapy} />} label={t("imbruvica.mw.patientDidReceiveMWTherapy")} />
        </RadioGroup>
      </FormControl>

      <FormControl fullWidth>
        <RadioGroup aria-labelledby="imbruvica-radio-buttons-group-form-mono" name="imbruvica-radio-buttons-group-form-mono"
          onChange = {(event, value) =>
            dispatch(onChangeImbruvicaValue({formMWchoice: value}))
          }
        >
          <FormControlLabel value="monotherapie"
            control={<Radio checked={ imbruvicaValues.formMW.monotherapie}/>} label={t("imbruvica.mw.monotherapie")} />
          <FormControlLabel value="combotherapie" data-cy="combotherapie"
            control={<Radio checked={imbruvicaValues.formMW.combotherapie} />} label={t("imbruvica.mw.combotherapie")} />
        </RadioGroup>
      </FormControl>

      <FormControl
        hidden={!imbruvicaValues.formMW.combotherapie}
        fullWidth>
        <FormControlLabel
          label={t("imbruvica.mw.imbruvicaInCombinationWithRituximab")}
          value="imbruvicaInCombinationWithRituximab"
          control=
            {
              <Checkbox checked={imbruvicaValues.formMW.imbruvicaInCombinationWithRituximab} data-cy="imbruvicaInCombinationWithRituximab"
                onChange={(event, checked) =>
                  dispatch(onChangeImbruvicaValue({
                    formMW: {imbruvicaInCombinationWithRituximab: checked},
                  }))
                }
              />
            }
        />
      </FormControl>
      <FormControl
        fullWidth
        hidden={
          !(
            imbruvicaValues.formMW.patientDidNotReceiveMWTherapy &&
            imbruvicaValues.formMW.monotherapie
          )
        }
      >
        <FormControlLabel
          label={t("imbruvica.mw.rituximabBasedChemoimmunotherapyNotAnOption")}
          control=
            {
              <Checkbox checked={imbruvicaValues.formMW.rituximabBasedChemoimmunotherapyNotAnOption}
                data-cy="rituximabBasedChemoimmunotherapyNotAnOption"
                onChange={(event, checked) =>
                  dispatch(onChangeImbruvicaValue({
                    formMW: {rituximabBasedChemoimmunotherapyNotAnOption: checked},
                  }))
                }
              />
            }
        />
      </FormControl>

      <FormControl
        hidden={
          !(
            imbruvicaValues.formMW.patientDidReceiveMWTherapy &&
            imbruvicaValues.formMW.monotherapie
          )
        }
      >
        <FormControlLabel
          label={t("imbruvica.mw.patientSufferedRelapseWithin24MonthsOfRituximabBasedTherapy")}
          control=
            {
              <Checkbox checked={imbruvicaValues.formMW.patientSufferedRelapseWithin24MonthsOfRituximabBasedTherapy}
                data-cy="patientSufferedRelapseWithin24MonthsOfRituximabBasedTherapy"
                onChange={(event, checked) =>
                  dispatch(onChangeImbruvicaValue({
                    formMW: {patientSufferedRelapseWithin24MonthsOfRituximabBasedTherapy: checked},
                  }))
                }
              />
            }
        />
      </FormControl>

      <div
        hidden={
          (
            imbruvicaValues.formMW.patientDidNotReceiveMWTherapy ||
            imbruvicaValues.formMW.monotherapie ||
            imbruvicaValues.formMW.imbruvicaInCombinationWithRituximab === false
          ) && imbruvicaValues.formMW.variant !== 3
        }
      >
        <FormControl
          fullWidth
        >
          <FormControlLabel
            label={t("imbruvica.mw.chemotherapyIsNotAnOption")}
            control=
              {
                <Checkbox checked={imbruvicaValues.formMW.chemotherapyIsNotAnOption}
                  data-cy="chemotherapyIsNotAnOption"
                  onChange={(event, checked) =>
                    dispatch(onChangeImbruvicaValue({
                      formMW: {chemotherapyIsNotAnOption: checked},
                    }))
                  }
                />
              }
          />
        </FormControl>

        <FormControl
          fullWidth
        >
          <FormControlLabel
            label={t("imbruvica.mw.chemoImmuneTherapyIsNotAnOption")}
            control=
              {
                <Checkbox checked={imbruvicaValues.formMW.chemoImmuneTherapyIsNotAnOption}
                  data-cy="chemoImmuneTherapyIsNotAnOption"
                  onChange={(event, checked) =>
                    dispatch(onChangeImbruvicaValue({
                      formMW: {chemoImmuneTherapyIsNotAnOption: checked},
                    }))
                  }
                />
              }
          />
        </FormControl>

        <FormControl
          fullWidth
        >
          <FormControlLabel
            label={t("imbruvica.mw.rituximabMonotherapyIsNotAnOption")}
            control=
              {
                <Checkbox checked={imbruvicaValues.formMW.rituximabMonotherapyIsNotAnOption}
                  data-cy="rituximabMonotherapyIsNotAnOption"
                  onChange={(event, checked) =>
                    dispatch(onChangeImbruvicaValue({
                      formMW: {rituximabMonotherapyIsNotAnOption: checked},
                    }))
                  }
                />
              }
          />
        </FormControl>
      </div>

    </Grid>
    <Grid item xs={12}
      hidden={
        (imbruvicaValues.formMW.variant !== 1) &&
        (imbruvicaValues.formMW.variant !== 2) &&
        (imbruvicaValues.formMW.variant !== 3) &&
        (imbruvicaValues.formMW.variant !== 4) &&
        (imbruvicaValues.formMW.variant !== 5) &&
        (imbruvicaValues.formMW.variant !== 6) &&
        (imbruvicaValues.formMW.variant !== 7) &&
        (imbruvicaValues.formMW.variant !== 8)
      }
    >
      <Stack
        direction="row"
        alignItems="left"
        gap={1}
      >
        <WarningIcon color={"primary"}
        />
        <Typography hidden={(imbruvicaValues.formMW.variant !== 1)}>{t("imbruvica.mw.text.1")}</Typography>
        <Typography hidden={(imbruvicaValues.formMW.variant !== 2)}>{t("imbruvica.mw.text.2")}</Typography>
        <Typography hidden={(imbruvicaValues.formMW.variant !== 3) }>{t("imbruvica.mw.text.3")}</Typography>
        <Typography hidden={(imbruvicaValues.formMW.variant !== 4) }>{t("imbruvica.mw.text.4")}</Typography>
        <Typography hidden={(imbruvicaValues.formMW.variant !== 5) }>{t("imbruvica.mw.text.5")}</Typography>
        <Typography hidden={(imbruvicaValues.formMW.variant !== 6)}>{t("imbruvica.mw.text.6")}</Typography>
        <Typography hidden={(imbruvicaValues.formMW.variant !== 7)}>{t("imbruvica.mw.text.7")}</Typography>
        <Typography hidden={(imbruvicaValues.formMW.variant !== 8)}>{t("imbruvica.mw.text.8")}</Typography>
      </Stack>
      <Box sx={{p: 1}} />
    </Grid>
    <Grid item xs={8} >
      <TextField
        fullWidth
        hidden={
          (imbruvicaValues.formMW.variant !== 1) &&
          (imbruvicaValues.formMW.variant !== 2) &&
          (imbruvicaValues.formMW.variant !== 3) &&
          (imbruvicaValues.formMW.variant !== 4) &&
          (imbruvicaValues.formMW.variant !== 5) &&
          (imbruvicaValues.formMW.variant !== 6) &&
          (imbruvicaValues.formMW.variant !== 7) &&
          (imbruvicaValues.formMW.variant !== 8)
        }
        InputLabelProps={{shrink: true}}
        data-cy="imbruvica.mw.textfield"
        label={t("imbruvica.mw.textfield")}
        value={imbruvicaValues.formMW?.textfield}
        multiline
        maxRows={4}
        minRows={4}
        variant="outlined"
        onChange = {(event ) =>
          dispatch(onChangeImbruvicaValue({
            formMW: {textfield: (event.target as HTMLInputElement).value},
          }),
          )
        }
      />
    </Grid>
    <Box sx={{p: 1}}/>
    <Grid item xs={12}
      hidden={
        (imbruvicaValues.formMW.variant !== 3)
      }
    >
    </Grid>
    <Grid item xs={12}>
      {
        imbruvicaValues.formMW.variant !== 0 ?
          <FormLabel >{t("imbruvica.variant")}&nbsp;{imbruvicaValues.formMW.variant}&nbsp;</FormLabel> :
        <></>
      }
    </Grid>
  </>;
};

const mapStateToProps=(state) => {
  return {
  };
};

const mapDispatchToProps=(dispatch) => ({

});

export default connect(mapStateToProps, mapDispatchToProps)(imbruvicaForm);


