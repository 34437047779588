import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {RootState} from "store/rootstate";

export interface ImjudoState {
  formData: {
    default: boolean,
    himalaya: boolean,
    poseidon: boolean,
    bleedingRisk: boolean,
    inCombinationWithImfinzi: boolean,
  }
}

const initialState:ImjudoState = {
  formData: {
    default: false,
    himalaya: false,
    poseidon: false,
    bleedingRisk: false,
    inCombinationWithImfinzi: false,
  },
};

const ImjudoFormSlice = createSlice({
  name: "Imjudo",
  initialState,
  reducers: {


    onReceiveSpecialMedImjudoEditData: (state, {payload}: PayloadAction<any>) => {
      if (payload.formData?.himalaya === true) {
        state.formData.himalaya = true;
      } else if (payload.formData?.poseidon === true) {
        state.formData.poseidon = true;
      } else {
        state.formData.default = true;
      }

      if (payload.formData?.bleedingRisk === true) {
        state.formData.bleedingRisk = true;
      }
      if (payload.formData?.inCombinationWithImfinzi === true) {
        state.formData.inCombinationWithImfinzi = true;
      }
    },

    onClearImjudoState: (state)=>{
      state.formData=initialState.formData;
    },
    onChangeImjudoValue: (state, {payload}:PayloadAction<any>)=>{
      if (payload.choice === "default") {
        state.formData.default = true;
        state.formData.himalaya = false;
        state.formData.poseidon = false;
        state.formData.inCombinationWithImfinzi = false;
        state.formData.bleedingRisk = false;
      } else if (payload.choice === "himalaya") {
        state.formData.default = false;
        state.formData.himalaya = true;
        state.formData.poseidon = false;
      } else if (payload.choice === "poseidon") {
        state.formData.default = false;
        state.formData.himalaya = false;
        state.formData.poseidon = true;
        state.formData.bleedingRisk = false;
      }
      if (payload.formData?.bleedingRisk === true ||
        payload.formData?.bleedingRisk === false) {
        state.formData.bleedingRisk = payload.formData?.bleedingRisk;
      }
      if (payload.formData?.inCombinationWithImfinzi === true ||
        payload.formData?.inCombinationWithImfinzi === false) {
        state.formData.inCombinationWithImfinzi =
          payload.formData?.inCombinationWithImfinzi;
      }
    },
  },
});

export const {
  onReceiveSpecialMedImjudoEditData,
  onClearImjudoState,
  onChangeImjudoValue,
} = ImjudoFormSlice.actions;

export default ImjudoFormSlice.reducer;
export const selectImjudoValues = (state:RootState) =>
  state.wizard.medicine.additionalInformation.imjudoForm?.formData;


