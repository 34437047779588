import {createSlice} from "@reduxjs/toolkit";


const LoadingReducerSlice = createSlice({
  name: "LoadingReducer",
  initialState: {},
  reducers: {
    startLoading: (state, {payload}) =>({
      ...state,
      [payload]: true,
    }),
    endLoading: (state, {payload}) =>({
      ...state,
      [payload]: false,
    }),
  },

});

export const {
  startLoading,
  endLoading,

} = LoadingReducerSlice.actions;

export const selectLoadingState=(state) =>state?.loading;

export default LoadingReducerSlice.reducer;
