import React from "react";
import {useTranslation} from "react-i18next";

import {
  Card,
  CardContent,
  Grid,
  Typography,
} from "@mui/material";


const HeaderCard = (props) => {
  const {t} = useTranslation();

  return (
    <Card variant="outlined" >
      <CardContent sx={{padding: "8px"}}>
        <Grid container alignContent='center'>
          <Grid container item alignItems="center" >
            <Grid item container xs={2} sm={1} alignItems="center" >
            </Grid>
            <Grid item xs={6} sm={3}>
              <Typography component={"span"} fontSize='16px' >
                {t("requestlist.headers.name")}
              </Typography>
            </Grid>
            <Grid item xs={2}>
              <Typography component={"span"} fontSize='16px' sx={{display: {xs: "none", sm: "flex"}}}>
                {t("requestlist.headers.request.type")}
              </Typography>
            </Grid>
            <Grid item container xs={4} alignItems="center" sx={{display: {xs: "none", sm: "flex"}}}>
              <Grid item container justifyContent='center' xs={4} >
                <Typography component={"span"} fontSize='16px' sx={{display: {xs: "none", sm: "flex"}}}>
                  {t("requestlist.headers.status")}
                </Typography>
              </Grid>
              <Grid item xs={8}>
              </Grid>
            </Grid>
            <Grid item container xs={4} sm={2}>
            </Grid>


          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

export default HeaderCard;

