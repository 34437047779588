import React, {useEffect} from "react";
import {connect} from "react-redux";
import {useTranslation} from "react-i18next";
import {Grid, Typography} from "@mui/material";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormLabel from "@mui/material/FormLabel";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import {
  useLocation,
} from "react-router-dom";

import {
  UseAppDispatch,
  UseAppSelector,
} from "store/hooks";

import {
  onChangeIbranceValue,
  selectIbranceValues,
} from "./IbranceSlice";
import {
  onChangeKVVLegalState,
} from "../../../medicine/medicineSlice";
import {onChangeDiagnosisValue} from "../../../Diagnosis/diagnosisSlice";
import {
  onChangeTreatmentValue,
} from "../../../Treatment/treatmentPlanSlice";


const IbranceForm = (props) => {
  const {t} = useTranslation();
  const dispatch = UseAppDispatch();
  const IbranceValues = UseAppSelector(selectIbranceValues);
  const handleValueChangeKVV = (field:string, value:any)=>dispatch(onChangeTreatmentValue({field, value}));
  const params=useLocation();
  const handleValueChangeDiagnosis = (field:string, value:any)=>dispatch(onChangeDiagnosisValue({field, value}));

  useEffect(() => {
    const regex = /\d+$/;
    const isEditId = regex.test(params.pathname);
    console.debug("isEditId", isEditId);
  }, []);


  return <>

    <Grid item xs={12}>
      <FormControl>
        <FormLabel>{t("Ibrance.options")}</FormLabel>
        <RadioGroup
          onChange={(event, value) => {
            if (value === "default") {
              dispatch(onChangeIbranceValue({formData: {default: true}}));
              dispatch(onChangeKVVLegalState(
                  {
                    limitatio_approval: false,
                    specialist_info: false,
                  },
              ));
              handleValueChangeKVV("limitatio_approval", false);
              handleValueChangeKVV("specialist_info", false);
            } else if (value === "aromataseCombi") {
              dispatch(onChangeIbranceValue({formData: {aromataseCombi: true}}));
              dispatch(onChangeIbranceValue({preTreatment: ""}));
              dispatch(onChangeIbranceValue({patientCondition: ""}));
              dispatch(onChangeIbranceValue({reasonsAlternativeTherapiesFailed: ""}));
              dispatch(onChangeIbranceValue({prognosis: ""}));
              handleValueChangeDiagnosis("remarks_diagnosis", t("ibrance.diagnosis"));
            } else if (value === "fulvestrantCombi") {
              dispatch(onChangeIbranceValue({formData: {fulvestrantCombi: true}}));
              dispatch(onChangeKVVLegalState(
                  {
                    limitatio_approval: false,
                    specialist_info: false,
                  },
              ));
              dispatch(onChangeIbranceValue({preTreatment: ""}));
              dispatch(onChangeIbranceValue({patientCondition: ""}));
              dispatch(onChangeIbranceValue({reasonsAlternativeTherapiesFailed: ""}));
              dispatch(onChangeIbranceValue({prognosis: ""}));
              handleValueChangeKVV("limitatio_approval", false);
              handleValueChangeKVV("specialist_info", false);
            }
          }}>
          <FormControlLabel
            value="aromataseCombi"
            control={<Radio
              checked={IbranceValues.aromataseCombi}
              data-cy="aromataseCombi" />}
            label={t("ibrance.aromataseCombi")}
          />
          <FormControlLabel
            value="fulvestrantCombi"
            control={<Radio
              checked={IbranceValues.fulvestrantCombi}
              data-cy="fulvestrantCombi" />}
            label={t("ibrance.fulvestrantCombi")}
          />
          <FormControlLabel
            value="default"
            control={<Radio
              checked={IbranceValues.default}
              data-cy="default" />}
            label={t("ibrance.default")}
          />
        </RadioGroup>
      </FormControl>
      <Box sx={{m: 1}}/>
      <div
        hidden={
          !(IbranceValues.aromataseCombi === true)}>
        <FormControl>
          <FormLabel>{t("ibrance.aromataseCombi.options")}</FormLabel>
          <RadioGroup
            onChange={(event, value) => {
              if (value === "aromataseCombi_Letrozol") {
                dispatch(onChangeIbranceValue({formData: {aromataseCombi_Letrozol: true}}));
                dispatch(onChangeKVVLegalState(
                    {
                      limitatio_approval: true,
                      specialist_info: true,
                    },
                ));
                handleValueChangeKVV("limitatio_approval", true);
                handleValueChangeKVV("specialist_info", true);
              } else if (value === "aromataseCombi_2LOther") {
                dispatch(onChangeIbranceValue({formData: {aromataseCombi_2LOther: true}}));
                dispatch(onChangeKVVLegalState(
                    {
                      limitatio_approval: true,
                      specialist_info: true,
                    },
                ));
                handleValueChangeKVV("limitatio_approval", true);
                handleValueChangeKVV("specialist_info", true);
              }
            }}>
            <FormControlLabel
              value="aromataseCombi_Letrozol"
              control={<Radio
                checked={IbranceValues.aromataseCombi_Letrozol}
                data-cy="default" />}
              label={t("ibrance.aromataseCombi_Letrozol")}
            />
            <FormControlLabel
              value="aromataseCombi_2LOther"
              control={<Radio
                checked={IbranceValues.aromataseCombi_2LOther}
                data-cy="aromataseCombi_2LOther" />}
              label={t("ibrance.aromataseCombi_2LOther")}
            />

          </RadioGroup>
        </FormControl>
        <Box sx={{m: 1}}/>
        <TextField
          hidden= {(IbranceValues.aromataseCombi_2LOther === false ) }
          style={{width: "100%"}}
          InputLabelProps={{shrink: true}}
          data-cy="aromataseCombi_2LOtherText"
          label={t("ibrance.aromataseCombi_2LOtherText")}
          value={IbranceValues.aromataseCombi_2LOtherText}
          multiline
          maxRows={4}
          minRows={4}
          variant="outlined"
          onChange = {(event ) =>
            dispatch(onChangeIbranceValue(
                {
                  aromataseCombi_2LOtherText: (event.target as HTMLInputElement).value,
                }),
            )
          }
        />


      </div>
      <div
        hidden={
          !(IbranceValues.fulvestrantCombi === true)}>

        <FormControl>
          <FormLabel>{t("ibrance.aromataseCombi.fulvestrantCombi")}</FormLabel>
          <RadioGroup
            onChange={(event, value) => {
              if (value === "fulvestrantCombi_1l") {
                dispatch(onChangeIbranceValue({formData: {fulvestrantCombi_1l: true}}));
                dispatch(onChangeIbranceValue({formData: {aromataseCombi_2LOther: true}}));
                dispatch(onChangeKVVLegalState(
                    {
                      limitatio_approval: true,
                      specialist_info: true,
                    },
                ));
                handleValueChangeKVV("limitatio_approval", true);
                handleValueChangeKVV("specialist_info", true);
              } else if (value === "fulvestrantCombi_2l") {
                dispatch(onChangeIbranceValue({formData: {fulvestrantCombi_2l: true}}));
                dispatch(onChangeIbranceValue({formData: {aromataseCombi_2LOther: true}}));
                dispatch(onChangeKVVLegalState(
                    {
                      limitatio_approval: true,
                      specialist_info: true,
                    },
                ));
                handleValueChangeKVV("limitatio_approval", true);
                handleValueChangeKVV("specialist_info", true);
              }
            }}>
            <FormControlLabel
              value="fulvestrantCombi_1l"
              control={<Radio
                checked={IbranceValues.fulvestrantCombi_1l}
                data-cy="fulvestrantCombi_1l" />}
              label={t("ibrance.fulvestrantCombi_1l")}
            />
            <FormControlLabel
              value="fulvestrantCombi_2l"
              control={<Radio
                checked={IbranceValues.fulvestrantCombi_2l}
                data-cy="fulvestrantCombi_2l" />}
              label={t("ibrance.fulvestrantCombi_2l")}
            />

          </RadioGroup>
        </FormControl>


      </div>

      <div
        hidden={
          !(IbranceValues.default === true)}>

        <Box sx={{m: 1}}/>
        <Typography >{t("ibrance.details.other")}</Typography>
        <Box sx={{m: 1}}/>
        <TextField
          style={{width: "100%"}}
          InputLabelProps={{shrink: true}}
          data-cy="preTreatment"
          label={t("ibrance.defaultForm.preTreatment")}
          value={IbranceValues.defaultForm?.preTreatment}
          multiline
          maxRows={4}
          minRows={4}
          variant="outlined"
          onChange = {(event ) =>
            dispatch(onChangeIbranceValue(
                {
                  preTreatment: (event.target as HTMLInputElement).value,
                }),
            )
          }
        />
        <Box sx={{m: 1}}/>
        <TextField
          multiline
          style={{width: "100%"}}
          label={t("ibrance.defaultForm.patientCondition")}
          InputLabelProps={{shrink: true}}
          value={IbranceValues.defaultForm?.patientCondition }
          variant="outlined"
          maxRows={4}
          minRows={4}
          onChange = {(event ) =>
            dispatch(onChangeIbranceValue(
                {
                  patientCondition: (event.target as HTMLInputElement).value,
                }),
            )
          }
        />

        <Box sx={{m: 1}}/>
        <TextField
          style={{width: "100%"}}
          label={t("ibrance.defaultForm.reasonsAlternativeTherapiesFailed")}
          InputLabelProps={{shrink: true}}
          value={IbranceValues.defaultForm?.reasonsAlternativeTherapiesFailed }
          variant="outlined"
          maxRows={4}
          minRows={4}
          multiline
          onChange = {(event ) =>
            dispatch(onChangeIbranceValue(
                {
                  reasonsAlternativeTherapiesFailed: (event.target as HTMLInputElement).value,
                }),
            )
          }
        />
        <Box sx={{m: 1}}/>
        <TextField
          style={{width: "100%"}}
          label={t("ibrance.defaultForm.prognosis")}
          InputLabelProps={{shrink: true}}
          value={IbranceValues.defaultForm?.prognosis }
          variant="outlined"
          maxRows={4}
          minRows={4}
          multiline
          onChange = {(event ) =>
            dispatch(onChangeIbranceValue(
                {
                  prognosis: (event.target as HTMLInputElement).value,
                }),
            )
          }
        />

      </div>
    </Grid>
    <Grid item xs={12}>
      {
        IbranceValues.variant !=="" && IbranceValues.variant !=="  " && IbranceValues.default === false ?
          <FormLabel >{t("variante")}:&nbsp;{IbranceValues.variant}&nbsp;</FormLabel> :
        <></>
      }

    </Grid>
  </>;
};


const mapStateToProps=(state) => {
  return {
  };
};

const mapDispatchToProps=(dispatch) => ({

});

export default connect(mapStateToProps, mapDispatchToProps)(IbranceForm);


