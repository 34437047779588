import React, {useEffect} from "react";
import ChatListHeader from "./ChatListHeader";
import Backdrop from "components/Backdrop";
import makeStyles from "@mui/styles/makeStyles";
import {FaCapsules, FaClinicMedical} from "react-icons/fa";
import InsuranceStatusIcon from "./InsuranceStatusIcon";
import {EditOutlined} from "components/icons";
import MarkunreadMailboxIcon from "@mui/icons-material/MarkunreadMailbox";
import {
  Avatar,
  Box,
  Card,
  CardHeader,
  Grid,
  List,
  CardActionArea,
  CardActions,
  CardContent,
  Typography,
  Button,
} from "@mui/material";
import {useTranslation} from "react-i18next";
import {
  useParams,
  useHistory,
} from "react-router-dom";
import {
  useDispatch,
  connect,
} from "react-redux";
import {
  fetchCommunication,
} from "../../../store/middleware/communicationThunk";
import {
  communicationQueryChange, setSelectedCommunication,
} from "../../../store/features/communicationSlice";

import {
  fetchRequests,
} from "store/middleware/requestListThunk";


const useStyles = makeStyles((theme)=>({
  chatlist: {
    [theme.breakpoints.down("sm")]: {
    },
  },
  selectedItem: {
    backgroundColor: "navajowhite",
  },
  convesationList: {
    "overflowY": "auto",
    "margin": 0,
    "padding": 0,
    "listStyle": "none",
    "&::-webkit-scrollbar": {
      width: "0.2em",
    },
    "&::-webkit-scrollbar-track": {
      boxShadow: "inset 0 0 6px rgba(0,0,0,0.00)",
      webkitBoxShadow: "inset 0 0 6px rgba(0,0,0,0.00)",
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: "rgba(0,0,0,.1)",
      outline: "1px solid slategrey",
    },
  },
  columnSpace: {
    [theme.breakpoints.down("md")]: {
      "& > .MuiGrid-item": {
        padding: 0,
      },
    },
  },
  subColor: {
    "color": "#f86c6b",
    "margin-bottom": "0.5rem",
    "font-weight": "500",
    "line-height": "1.2",
  },
}));


const ChatList=(props) => {
  const communicationList = props.communicationList;
  const {selectedChatId} = useParams();
  const history = useHistory();
  const classes = useStyles();
  const {t} = useTranslation();
  const dispatch = useDispatch();


  useEffect(async () => {
    dispatch(fetchCommunication());
    dispatch(fetchRequests());
  }, []);

  const openConversation = async (conversation) =>{
    await Promise.all(
        [
          dispatch(setSelectedCommunication(conversation)),
        ],
    ).then(() => {
      history.push(`/communication/${conversation.conversation_id}`);
    });
  };

  const switchAvatar = (refrenceType, requestStatus) => {
    if (refrenceType === "DOCTOR_PHARMA") {
      return (<div style={{height: "40px"}}><Avatar aria-label="recipe" style={{
        color: "#63c2de",
        backgroundColor: "#f0f3f5",
      }}>
        <FaCapsules data-toggle="popover" data-content="Disabled popover" />
        {/* to be added in another component */}
      </Avatar>
      {requestStatus==="APPROVED"?
          <InsuranceStatusIcon status="APPROVED"/> :null}
      {requestStatus==="DECLINED" ?
          <InsuranceStatusIcon status="DECLINED"/> :null}
      </div>);
    } else if (refrenceType === "DOCTOR_INSURANCE") {
      return (<div style={{height: "40px"}}>
        <Avatar aria-label="recipe" style={{
          color: "#63c2de",
          backgroundColor: "#f0f3f5",
        }}>
          <FaClinicMedical data-toggle="popover" data-content="Disabled popover" />
        </Avatar>
        {requestStatus==="APPROVED"?
          <InsuranceStatusIcon status="APPROVED"/> :null}
        {requestStatus==="DECLINED" ?
          <InsuranceStatusIcon status="DECLINED"/> :null}
      </div>);
    }
  };

  const handleEditClick=(requestId)=>{
    const koguType=props?.requestList?.filter((e)=>parseInt(e.request_id)===parseInt(requestId))[0].kogu_type;
    if (koguType==="med") {
      history.push(`/wizard/${requestId}`);
    } else {
      history.push(`/edit_request/${requestId}`);
    }
  };
  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleDateString("DE-de");
  };
  return (
    <Grid container spacing={3} className={classes.columnSpace} justifyContent='center' >
      <Backdrop open={(props.isLoadingCommunication)}></Backdrop>

      <Box component={Grid} item xs={12} hidden={props.isLoadingCommunication === true}>
        <Box mb={1}>
          <ChatListHeader
            searchQuery={props.searchQuerry}
            onSearchChange={(input) => dispatch(communicationQueryChange(input))}
            label_search={t("communication.search")}
          />
        </Box>
        <List className={classes.convesationList} data-cy="convesation_list">
          {
            communicationList?.map((item, index) =>(
              <Box mb={2} key={"box_"+index}>
                <Card className={classes.chatlist} key={"card_"+index}>
                  <CardActionArea
                    onClick={()=>openConversation(item)} className={(item?.conversation_id === selectedChatId) ? classes.selectedItem : ""}
                    data-cy={item?.reference_id}
                  >
                    <CardHeader
                      avatar={switchAvatar(item?.conversation_type, item?.request_status)}
                      title={
                        <h2>
                          {item?.patient_name}
                        </h2>
                      }
                      subheader={<Typography className={classes.subColor}>
                        {item?.unread_messages > 0 &&
                          <span>
                            {t("communication.count_unread", {unread_messages: item?.unread_messages} )}&nbsp;
                            ({item?.last_message_date ? formatDate(item.last_message_date) : ""})
                          </span>

                        }
                      </Typography>}
                    />
                    <CardContent>
                      <Typography>
                        <span>
                          <span>{t("communication.diagnose")}:&nbsp;</span>
                          {item?.subtitle ? item?.subtitle + " / " : ""}{item?.title}
                        </span>
                      </Typography>
                      {item && item.participants && Array.isArray(item.participants) && item.participants[0].participant_name &&
                      <Typography variant="body1" color="textSecondary" component="span">
                        <span>
                          <span>{t("communication.participant")}:&nbsp;</span>
                          {
                            item?.participants?.map((participant, participant_index) => {
                              return (<span key={participant_index+1}>
                                {participant?.participant_name}{(participant_index!==item?.participants.length-1)&&" /"}&nbsp;
                              </span>);
                            })
                          }
                        </span>
                      </Typography>
                      }

                    </CardContent>
                  </CardActionArea>
                  <CardActions>
                    <Button
                      size="small"
                      color="primary"
                      onClick={()=>openConversation(item)}
                      startIcon={<MarkunreadMailboxIcon/>}
                    >
                      {t("communication.link.read")}
                    </Button>
                    <Button
                      size="small"
                      color="primary"
                      onClick={()=>handleEditClick(item?.reference_id)}
                      startIcon={<EditOutlined />}
                    >
                      {t("communication.link.editrequest")}
                    </Button>
                  </CardActions>
                </Card>
              </Box>
            ))
          }
        </List>
      </Box>
    </Grid>
  );
};


const mapStateToProps=(state) => {
  return {
    state,
    chatMessageList: state.communication.chatMessageList,
    selectedConversation: state.communication.selectedConversation,
    isLoadingMessagesOfCommunication: state.loading.messageOfCommunication,
    searchQuerry: state.communication.searchQueryCommunication,
    communicationList: state.communication.searchQueryCommunication ? state.communication.communicationList
        .filter((item) => {
          if (state.communication.searchQueryCommunication.length === 0) {
            return true;
          }
          try {
            const searchQueryCommunication = state.communication.searchQueryCommunication?.toLowerCase();
            return Object.keys(item).reduce((a, key) =>
              a || String(item[key])?.toLowerCase()?.includes(searchQueryCommunication), false);
          } catch (error) {
            console.error("communicationList", error);
          }
        }) : state.communication.communicationList,
    files: state.communication.files,
    filesStatus: state.communication.filesStatus,
    requestList: state.requestList?.itemList,
    isLoadingCommunication: state.loading.communication === true,
  };
};

export default connect(mapStateToProps)(ChatList);
