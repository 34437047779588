import React, {Suspense, useState} from "react";
import {
  FaUpload,
  FaTrash,
  ExpandMore as ExpandMoreIcon,
  AiOutlineEye,
  PostAdd,
  InsertLink,
} from "components/icons";
import InfoIcon from "@mui/icons-material/Info";

import {connect, useDispatch} from "react-redux";
import {
  uploadAndParseLocalFiles,
} from "store/middleware/newRequestThunk";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableFooter,
  TableRow,
  TableContainer,
  Button,
  Grid,
  Tab,
  Typography,
  Box,
  Card,
  CardActions,
  Collapse,
  CardContent,
  IconButton,
  Link,
  Alert,
  Tabs,
} from "@mui/material";
import {
  ButtonUnstyled,
} from "@mui/base";

import {useTranslation} from "react-i18next";
import Overlay from "react-overlay-component";
import PDFViewer from "components/PDFViewer";
import UploadDocument from "./UploadDocument";
import {
  fetchPubmedAbstract,
} from "store/middleware/masterdataThunk";
import CircularProgress from "@mui/material/CircularProgress";
import {ExpandMore} from "components/ExpandMore";
import {
  onAddOrRemoveStudy,
  markFilesForRemoval,
  markStudiesForRemoval,
  removeLocalFilesAction,
} from "store/features/newRequestSlice";
import PubMedComponent from "components/PubMed";
import {PubMedCard} from "components/PubMedCard";

const TabPanel=(props)=> {
  const {children, value, index} = props;
  return (
    <div
      hidden={value !== index}
    >
      {value === index && (
        <div>
          {children}
        </div>
      )}
    </div>
  );
};

const UploadForm=(props) => {
  const dispatch = useDispatch();
  const {
    files,
    warning,
    currentStudy,
    abstract,
    allFiles,
    allDownloadedFiles,
    downloadedFiles,
    filesMarkedForRemoval,
    page,
  } = props;
  const {t} = useTranslation();

  const [pubmedExpanded, setPubmedExpanded] = useState(true);
  const handlePubmedExpandClick = () => setPubmedExpanded(!pubmedExpanded);
  const [uploadExpanded, setUploadExpanded] = useState(false);
  const handleUploadExpandClick = () => setUploadExpanded(!uploadExpanded);

  const [inputRef] = useState(React.createRef(0));
  const handleFileRemoveLocal = (fileToRemove) => {
    dispatch(removeLocalFilesAction(allFiles.filter((file) => file !== fileToRemove), "REMOVE"));
    inputRef.current.value = "";
  };
  const handleFileRemoveAtSave = (fileToRemove) => {
    dispatch(markFilesForRemoval(fileToRemove));
    handleFileRemoveLocal(fileToRemove);
  };

  const handleFileAdd = (addedFiles) => {
    if (addedFiles && Array.isArray(addedFiles)) {
      const studyFiles=addedFiles.map((e)=> Object.assign(e, {uploadtype: "study"}));
      dispatch(uploadAndParseLocalFiles(Object.values(studyFiles)));
    }
  };

  const handleDropzoneClick = () => inputRef.current.click();


  const carouselView = (file) => {
    const pdfFile=file.fileURL?[file]:[
      {fileURL: window.URL.createObjectURL(
          new Blob(
              [file],
              {type: "application/pdf"},
          ),
      ),
      name: file.name},
    ];
    setCarouselPDFs(pdfFile);
    setOverlay(true);
  };
  const [isOpen, setOverlay] = useState(false);
  const [carouselPDFs, setCarouselPDFs] = useState([]);
  const [currentTab, setCurrentTab] = useState(0);
  const closeOverlay = () => setOverlay(false);
  const handleFetchAbstract = (pmid) => {
    dispatch(fetchPubmedAbstract(pmid));
  };
  const handleRemoveStudy = (rowData) => {
    Boolean(rowData.study_id)&&dispatch(markStudiesForRemoval(rowData.study_id));
    dispatch(onAddOrRemoveStudy(rowData));
  };
  const handleAddStudyLinkFromFile = (fileMetaDataToAddAsLink) => {
    // remove from stepper files
    // dispatch(removeFilesFromStoreAndDeleteIfExists());
    dispatch(removeLocalFilesAction([]));

    // add the link
    dispatch(onAddOrRemoveStudy({
      pmid: fileMetaDataToAddAsLink.pmid,
      doi: fileMetaDataToAddAsLink.doi,
      title: fileMetaDataToAddAsLink.title,
      link: fileMetaDataToAddAsLink.link,
      pubtype: fileMetaDataToAddAsLink.pubtype,
      authors: fileMetaDataToAddAsLink.authors,
    }));
  };
  const verifyIfDownloadedFileIsRemoved=(file)=>{
    return filesMarkedForRemoval.filter((markedFile)=>
      Object.values(markedFile)?.includes(file.fileURL)).length>0;
  };

  const patientDocumentsMarkedForRemoval=filesMarkedForRemoval?.filter((file)=>file.uploadtype!=="study").length;
  const patientDocumentsLength=allFiles?.filter(
      (file)=>file.uploadtype!=="study").length+
        allDownloadedFiles?.filter((file)=>file.uploadtype!=="study").length -
        patientDocumentsMarkedForRemoval;
  const isStudyRemoved=filesMarkedForRemoval?.filter((file)=>file.uploadtype==="study").length>0;

  const a11yProps=(index) =>({
    "id": `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  });
  return <div>
    <Overlay configs={{animate: true}} isOpen={isOpen} closeOverlay={closeOverlay}>
      {Array.isArray(carouselPDFs) &&
      carouselPDFs.map((file, idx) =><PDFViewer key={idx} src={file.fileURL}/>)}
    </Overlay>
    {warning &&<Alert variant="outlined"
      severity="warning">{t("upload_study_warning")}</Alert>}
    <Suspense fallback={null}>
      {page===3&&<Tabs value={currentTab}
        sx={{display: "block"}}
        onChange={(event, newValue) => setCurrentTab(newValue)}>
        <Tab
          sx={{display: "block"}}
          label=
            {`${t("upload.tab.study")} (${currentStudy?.pmid ||
                files.length > 0 ||
                downloadedFiles.length >0 &&
                !isStudyRemoved ? 1 :
                 0})`
            }
          data-cy="study_tab"
          {...a11yProps(0)}/>
        <Tab
          sx={{display: "block"}}
          label={
            `${t("upload.tab.other")} (${patientDocumentsLength})`
          }
          data-cy="others_tab"
          {...a11yProps(1)}
        />
      </Tabs>}
      <TabPanel value={currentTab} index={0} sx>
        {Boolean(currentStudy?.pmid)&&<PubMedCard
          title={t("upload.title.current")}
          rowData={currentStudy}
          onRemoveStudy={handleRemoveStudy}
          // addedStudyId={addedStudyId}
          handleFetchAbstract={handleFetchAbstract}
          abstractContent={abstract.content?.[currentStudy.pmid]}
          isLoading={abstract.isLoading}
        />}
        <Card sx={{my: 1}}>
          <CardActions>
            <Typography>{t("upload.pubmed.section.title")}</Typography>
            <ExpandMore
              data-cy="pubmed_search_dropdown"
              expand={pubmedExpanded}
              onClick={handlePubmedExpandClick}
            >
              <ExpandMoreIcon />
            </ExpandMore>
          </CardActions>
          <Collapse in={pubmedExpanded} timeout="auto" unmountOnExit>
            <CardContent>
              <PubMedComponent/>
            </CardContent>
          </Collapse>
        </Card>
        <Card sx={{my: 1}} hidden={currentStudy?.title?.length>0}>
          <CardActions>
            <Typography>{t("upload.upload.section.title")}</Typography>
            <ExpandMore
              data-cy="study_upload_dropdown"
              expand={uploadExpanded}
              onClick={handleUploadExpandClick}
            >
              <ExpandMoreIcon />
            </ExpandMore>
          </CardActions>
          <Collapse in={uploadExpanded} timeout="auto" unmountOnExit>
            <CardContent>
              <Box hidden={!props.documentparsing} justifyContent="center" alignItems="center" display="flex">
                <CircularProgress color="info" size={50} sx={{right: "-50%"}}/>
              </Box>
              <TableContainer>
                <Table sx={{tableLayout: "fixed"}} hidden={(files?.length === 0) || (!files?.[0]?.pmid) || (files?.[0]?.pmid?.length === 0) || props.documentparsing}>
                  <TableHead>
                    <TableRow>
                      <TableCell colSpan={2} align="left">
                        {t("pubmed.label.title")}
                      </TableCell>
                      <TableCell align="left">
                        {t("pubmed.label.type")}
                      </TableCell>
                      <TableCell align="left">
                        {t("pubmed.label.link")}
                      </TableCell>
                      <TableCell align="left">
                        {t("pubmed.label.use")}
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <TableRow className="pt-3 pb-3" key={1}>
                      <TableCell colSpan={2} align="left" className="col-9"><span>{files?.[0]?.title}</span></TableCell>
                      <TableCell align="left"><span>{files?.[0]?.pubtype?.toString()}</span></TableCell>
                      <TableCell align="left">
                        <Link color="#0288d1" href={`https://pubmed.ncbi.nlm.nih.gov/${files?.[0]?.pmid}`} target="_blank" underline="none">
                          <InsertLink/>
                        </Link>
                      </TableCell>
                      <TableCell align="left">
                        <IconButton
                          data-cy="btParseAddAsStudy"
                          color="info"
                          onClick={() => handleAddStudyLinkFromFile(files?.[0])}
                        >
                          <PostAdd />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                  <TableFooter>
                    <TableRow>
                      <TableCell colSpan={5} align="left">
                        <div style={{display: "flex"}}>
                          <InfoIcon color="warning"/>&nbsp;
                          <Typography sx={{FontWeight: "bold"}} >
                            {t("pubmed.label.foundstudy")}
                          </Typography>
                        </div>
                      </TableCell>
                    </TableRow>
                  </TableFooter>
                </Table>
              </TableContainer>
              <TableContainer >
                <Table sx={{tableLayout: "fixed"}} hidden={props.documentparsing}>
                  <TableHead hidden={!(Object.keys(files).length)}>
                    <TableRow>
                      <TableCell colSpan={2} align="left">{t("upload.filename")}</TableCell>
                      <TableCell align="left">{t("upload.filesize")}</TableCell>
                      <TableCell align="left">{t("upload.preview")}</TableCell>
                      <TableCell align="left">{t("upload.delete")}</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {(files?.[0] && files.length > 0) && files.map((file, idx) =>
                      <TableRow className="pt-3 pb-3" key={`temporary_${idx}`}>
                        <TableCell colSpan={2} align="left" className="col-9"><span className="Filename">{file.name}</span></TableCell>
                        <TableCell align="left"><span className="Filename">{((file.size) / 1000000).toFixed(2)} MB</span></TableCell>
                        <TableCell align="left"><Button color="info" onClick={() => carouselView(file)}><AiOutlineEye size={28} /></Button></TableCell>
                        <TableCell align="left" sx={{width: "20px"}}><Button color="info" onClick={() => handleFileRemoveLocal(file)} ><FaTrash /></Button>
                        </TableCell>
                      </TableRow>,
                    ) }
                    {/* List from BE (EDIT Case) */}
                    {props.downloadedFiles?.[0] && props.downloadedFiles.length > 0 &&
                    props.downloadedFiles.filter(
                        (file)=> !verifyIfDownloadedFileIsRemoved(file))
                        .map((file, idx) =>
                          <TableRow className="pt-3 pb-3" key={`external_${idx}`}>
                            <TableCell colSpan={2} align="left" className="col-9"><span className="Filename">{file.name}</span></TableCell>
                            <TableCell align="left"><span className="Filename">{((file.size) / 1000000).toFixed(2)} MB</span></TableCell>
                            <TableCell align="left"><Button color="info" onClick={() => carouselView(file)}><AiOutlineEye size={28} /></Button></TableCell>
                            <TableCell align="left" sx={{width: "20px"}}><Button color="info" onClick={() => handleFileRemoveAtSave(file)} ><FaTrash /></Button>
                            </TableCell>
                          </TableRow>,
                        ) }
                  </TableBody>
                </Table>
              </TableContainer>

              <div hidden={(files && files.length>0)||
                (downloadedFiles&&downloadedFiles.length>0&&!isStudyRemoved)}>
                <ButtonUnstyled
                  data-cy="dropzone"
                  style={{"borderWidth": "0px",
                    "backgroundColor": "white"}}
                  onClick={handleDropzoneClick}
                >
                  <input ref={inputRef} hidden={true}
                    type="file"
                    id="file"
                    data-cy="dropzone_input"
                    accept=".pdf"
                    name="file"
                    onChange={(e) => handleFileAdd([...e.target.files])}
                  />
                  <Grid container spacing={3} padding={3} justifyContent="center" sx={{
                    height: "200px",
                    width: "100%",
                    backgroundColor: "#c8ced3",
                    border: "2px dashed rgb(99, 194, 222, 1)",
                    borderRadius: "0.25rem",
                    marginTop: 0,
                    marginLeft: 0,
                    marginBottom: "20px",
                    fontSize: "16px",
                  }}>
                    <Grid item>
                      <span>{t("upload.warning")}</span>
                    </Grid>
                    <Grid item align="center">
                      {!props.documentparsing?<FaUpload />: <CircularProgress color="inherit" size={20} sx={{right: "-50%"}}/> }
                    </Grid>
                  </Grid>
                </ButtonUnstyled >
              </div>
            </CardContent>
          </Collapse>
        </Card>

      </TabPanel>
      <TabPanel value={currentTab} index={1}>
        <UploadDocument/>
      </TabPanel>

    </Suspense>
  </div>;
};


const mapStateToProps = (state) => ({
  currentRequestId: state.singleRequest?.editData?.request_id,
  currentStudy: state.singleRequest.pubmedStudy,
  files: state.singleRequest.localFiles?.filter((e)=>e.uploadtype==="study"),
  filesMarkedForRemoval: state.singleRequest.filesMarkedForRemoval,
  downloadedFiles: state.singleRequest.downloadedFiles?.filter((e)=>e.uploadtype==="study"),
  allDownloadedFiles: state.singleRequest.downloadedFiles,
  allFiles: state.singleRequest.localFiles,
  documentparsing: state.loading.documentparsing === true,
  abstract: {
    isLoading: Boolean(state.loading.pubmedAbstract),
    content: state.masterdata.pubMedAbstracts,
  },

});
const mapDispatchToProps = (dispatch) => ({

});


export default connect(mapStateToProps, {...mapDispatchToProps} )(UploadForm);
