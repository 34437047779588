
import React from "react";
import {IMaskInput} from "react-imask";

interface CustomProps {
    onChange: (event: { target: { name: string; value: string } }) => void;
    name: string;
  }
export const PhoneNoComponent = React.forwardRef<HTMLElement, CustomProps>(
    function PhoneNoComponent(props, ref:any) {
      const {onChange, ...other} = props;
      return (
        <IMaskInput
          {...other}
          mask="+00 00 000 00 00"
          definitions={{
            "#": /[1-9]/,
          }}
          inputRef={ref}
          onAccept={(value: any) => onChange({target: {name: props.name, value}})}
          overwrite
        />
      );
    },
);
