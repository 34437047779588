import {logoutOn401} from "config/msalProvider";
import {
  trackError,
  trackDependency,
} from "AppInsights";

import {displayToastError} from "store/features/errorSlice";
import i18n from "i18n";
import store from "store/store";


export default function(url, options, timeout = 60000, displayErrors = true ) {
  return Promise.race([
    fetch(
        url,
        options,
    ),
    new Promise((_, reject) =>
      setTimeout(() => reject(new Error("timeout")), timeout),
    ),
  ]).then((response) => {
    trackDependency({
      target: url,
      name: url,
      data: {},
      dependencyTypeName: "API",
    });
    if (response.ok) {
      return (response);
    } else {
      switch (response.status) {
        case 400:
          displayErrors && store.dispatch(displayToastError(i18n.t("api.error")));
          console.error("[400] ON ", url);
          trackError(response);
          break;
        case 401:
          if (url.indexOf("graph/users/me") > -1 ||
            url.indexOf("graph/users") > -1 ||
            url.indexOf("communications/count") > -1 ||
            url.indexOf("requests/history") > -1
          ) {
            // do nothing
          } else {
            console.error("[401] ON ", url);
            logoutOn401();
          }
          break;
        case 404:
          displayErrors && store.dispatch(displayToastError(i18n.t("api.error")));
          console.error("[404] ON ", url);
          trackError(response);
          break;
        case 500:
          displayErrors && store.dispatch(displayToastError(i18n.t("api.error")));
          console.error("[500] ON ", url);
          trackError(response);
          break;
        default:
          displayErrors && store.dispatch(displayToastError(i18n.t("api.error")));
          console.error("[" + response.status + "] ON ", url);
          trackError(response);
          break;
      }
      return (response);
    }
  }).catch((error) => {
    console.debug("There was an error", error); // Network Error
  }).catch((rejected) => {
    console.debug("There was an rejected error", rejected); // Network Error
  });
}
