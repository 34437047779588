import {get, post} from "../api";
import {RootState} from "../store";
import {ThunkAction} from "redux-thunk";
import {AnyAction} from "redux";
import {startLoading, endLoading} from "../features/loadingSlice";
import {trackEvent, trackError} from "../../AppInsights";
import {onReceivedUserData} from "../features/userSlice";
import {displayToastError} from "../features/errorSlice";


export const fetchUserInformation =
  (): ThunkAction<void, RootState, unknown, AnyAction> =>
    async (dispatch) => {
      try {
        trackEvent("graph/users/me");
        dispatch(startLoading("userGroups"));
        const userdata = await get("graph/users/me");
        dispatch(onReceivedUserData(userdata));
      } catch (error) {
        console.error(error);
        trackError(String(error));
        dispatch(displayToastError("USERT_DATA_ERROR"));
      } finally {
        dispatch(endLoading("userGroups"));
      }
    };

export const setSignaturePicture =
  (base64EncodedSignaturePicture: string): ThunkAction<void, RootState, unknown, AnyAction> =>
    async (dispatch) => {
      try {
        trackEvent("graph/users/signature/");
        dispatch(startLoading("signature"));
        const base64EncodedImage = {
          "Base64EncodedSignaturePicture": base64EncodedSignaturePicture,
        };
        await post("graph/users/signature/", base64EncodedImage);
        dispatch(startLoading("userGroups"));
        const userdata = await get("graph/users/me");
        dispatch(onReceivedUserData(userdata));
      } catch (error) {
        console.error(error);
        trackError(String(error));
        dispatch(displayToastError("USERT_DATA_ERROR"));
      } finally {
        dispatch(endLoading("signature"));
        dispatch(endLoading("userGroups"));
      }
    };
