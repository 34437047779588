import React from "react";

import {Paper, Grid, Typography, useMediaQuery, Button, Box, Stack} from "@mui/material";
import MedicineInfo from "pages/Wizard/forms/med/AdditionalInformation/MedicineInfo";
import {
  selectMedicineValues,
} from "pages/Wizard/forms/med/medicine/medicineSlice";
import {useTranslation} from "react-i18next";
import {connect, useDispatch} from "react-redux";
import {
  useHistory,
} from "react-router-dom";
import {UseAppSelector} from "store/hooks";
import MedicineForm from "pages/Wizard/forms/med/medicine/MedicineForm";
import {useTheme} from "@mui/material/styles";
import WarningIcon from "@mui/icons-material/Warning";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import TipsAndUpdatesIcon from "@mui/icons-material/TipsAndUpdates";
import {cleanStoreWithoutDoctorAndMedication} from "pages/Wizard/wizardSlice";


const Badge=(props)=>{
  const theme = useTheme();
  const {t}=useTranslation();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  if (props.color === undefined) return <></>;
  return (
    <Grid container spacing={3} marginBottom={props.marginBottom} >
      <Grid item textAlign={"center"}>
        {!props.success ?
          <CheckCircleIcon
            fontSize={isMobile ? "small" : "large"}
            style={{color: props?.color}}
            color={props?.color}
          /> :
          <WarningIcon
            fontSize={isMobile ? "small" : "large"}
            style={{color: props?.color}}
            color={props?.color}
          />}
      </Grid>
      <Grid item container xs={10} textAlign={isMobile?"start":"center"} alignItems='center'>
        <Typography>
          {t(`${props?.label}`)}
        </Typography>
      </Grid>
    </Grid>
  );
};

export const MedicineFinder = (props) => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const medForm=UseAppSelector(selectMedicineValues);
  const {
    t,
  }=useTranslation();

  const history=useHistory();

  return (
    <Paper sx={{marginBottom: "12px"}} >
      <Grid container direction="column" padding={2} spacing={3} >
        <Grid item xs={12}>
          <Typography variant="h4">{t("medicine.finder.title")}</Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography>{t("medicine.finder.text")}</Typography>
        </Grid>
        <Grid item>
          <MedicineForm standalone></MedicineForm>
        </Grid>
        {medForm.required_drug && medForm.substances?.length > 0 && <> <Grid
          item
          container
          xs={12}
          sx={{display: {xs: medForm?.required_drug?.length>0?"inherit":"none"}}}
        >
          <Grid item container sx={{backgroundColor: "#e8f4fc", borderRadius: "12px"}} padding='12px'>
            <Badge
              marginBottom="12px"
              color={medForm?.inSL === true?theme.palette.primary.main:theme.palette.warning.main}
              label={medForm?.inSL === true?"finder.flag.medIsInSlList":"finder.flag.medIsNotInSlList"}
              success={medForm?.inSL === false}
            ></Badge>
            <Badge
              marginBottom="12px"
              color={medForm?.medicationHasIndicationCodes === true?theme.palette.warning.main:theme.palette.primary.main}
              label={medForm?.medicationHasIndicationCodes === true?"finder.flag.medHasIndicationCodes":"finder.flag.medHasNotIndicationCodes"}
              success={medForm?.medicationHasIndicationCodes === true}
            ></Badge>
            <Badge
              marginBottom="12px"
              color={medForm?.medicationHasLimitations === true?theme.palette.warning.main:theme.palette.primary.main}
              label={medForm?.medicationHasLimitations === true?"finder.flag.medHasLimitations":"finder.flag.medHasNoLimitations"}
              success={medForm?.medicationHasLimitations === true}
            ></Badge>
            <Badge
              marginBottom="12px"
              color={medForm?.medicationHasProfessionalPatientInformation?theme.palette.warning.main:theme.palette.primary.main}
              label={medForm?.medicationHasProfessionalPatientInformation?"finder.flag.medHasSpecialistInformation":"finder.flag.medDoesNotHasSpecialistInformation"}
              success={medForm?.medicationHasProfessionalPatientInformation}
            ></Badge>
            { console.debug("medicationHasPricingModel", medForm?.medicationHasPricingModel)}
            <Badge
              marginBottom="12px"
              color={medForm?.medicationHasPricingModel === true?theme.palette.warning.main:theme.palette.primary.main}
              label={medForm?.medicationHasPricingModel === true?"finder.flag.medHasPricingModel":"finder.flag.medHasNoPricingModell"}
              success={medForm?.medicationHasPricingModel === true}
            ></Badge>
            <Badge
              marginBottom="12px"
              color={medForm?.medicationShortageWarning !== undefined?
                  theme.palette.warning.main:theme.palette.primary.main}
              label={medForm?.medicationShortageWarning === undefined?"finder.flag.medHasNoShortageWarnings":"finder.flag.medHasShortageWarnings"}
              success={medForm?.medicationShortageWarning !== undefined}
            ></Badge>

          </Grid>
        </Grid>
        <Grid item xs={12} alignSelf="left">
          {
            (!medForm?.inSL &&
              medForm?.medicationHasProfessionalPatientInformation) ||
              medForm?.medicationHasIndicationCodes ||
              medForm?.medicationHasPricingModel ||
              medForm?.medicationHasLimitations ?t("finder.help.text.kogu"):""
          }
          <Box sx={{m: 1}}/>
          <Stack
            direction="row"
            alignItems="left"
            gap={1}
          >
            <TipsAndUpdatesIcon color={"primary"}></TipsAndUpdatesIcon>
            <Typography hidden={medForm?.medicationNeedsKoGuRequest === false}>
              {t("finder.flag.medNeedsKoguRequest")}
            </Typography>
            <Typography hidden={medForm?.medicationNeedsKoGuRequest === true}>
              {t("finder.flag.medDoesNotNeedsKoguRequest")}
            </Typography>
          </Stack>


        </Grid></>}
        <Grid item xs={12} alignSelf="center" data-cy="create_request_from_medfinder">
          <Button variant="contained" onClick={

            ()=> {
              Promise.all([
                dispatch(cleanStoreWithoutDoctorAndMedication({})),
              ]).then(()=>{
                history.push({pathname: "/wizard"});
              });
            }

          }>
            {t("finder.button.create")}
          </Button>
        </Grid>
        <Grid item xs={12} sx={{display: medForm?.substances?.length>0?"inherit":"none"}}>
          <MedicineInfo/>
        </Grid>
      </Grid>
    </Paper>
  );
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(MedicineFinder);
