import React, {useState} from "react";
import makeStyles from "@mui/styles/makeStyles";
// import {formatDateDisplay} from "components/formatting";
import {statusIcons} from "./Icons";
import {useTranslation} from "react-i18next";
import {
  Card,
  CardContent,
  Typography,
  Collapse,
  IconButton,
  Tooltip,
  Grid,
} from "@mui/material";
import {
  useDispatch,
} from "react-redux";
import {
  fetchDetails,
} from "store/middleware/requestListThunk";
import {ExpandMore, FaCommentDots} from "components/icons";
import clsx from "clsx";
import NotificationsActiveIcon from "@mui/icons-material/NotificationsActive";
import EditIcon from "@mui/icons-material/Edit";
import PriorityHighIcon from "@mui/icons-material/PriorityHigh";
import i18n from "../../../i18n";
import RequestCollapseComponent from "./RequestCollapseComponent";

const useStyles=makeStyles((theme) => ({
  root: {
    minWidth: 275,
    margin: 5,
    padding: "0.5em",
  },
  rootUrgent: {
    borderLeft: "0.5em solid #f86c6b",
    minWidth: 275,
    margin: 5,
  },
  title: {
    fontSize: 16,
  },
  expand: {
    transform: "rotate(0deg)",
    marginLeft: "auto",
    transition: theme.transitions.create("transform", {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: "rotate(180deg)",
  },
  pos: {
    marginBottom: 12,
  },
}));
const RequestCard=(props) => {
  const classes=useStyles();
  const {t}=useTranslation();
  const {
    row,
    setSelectedRow,
    actions,
    verified,
  }=props;

  const dispatch = useDispatch();

  const [expanded, setExpanded]=useState(false);

  const handleClickStatus=(event) => {
    setSelectedRow({row: row, isOpenStateDropdownDialog: event.currentTarget});
  };

  const handleExpandClick=() => {
    dispatch(fetchDetails(row.request_id));
    setExpanded(!expanded);
  };

  const dottedSeparator={
    border: "none",
    marginTop: "initial",
    marginBottom: "initial",
    borderTop: "1px dotted grey",
    width: "100%",
  };

  const background={
    "backgroundColor": "#f0f3f5",
  };

  const patientName=row.patient_lastname || row.patient_surename ? row.patient_surename + " " + row.patient_lastname : <Typography className={classes.title} component={"span"} color="textSecondary" gutterBottom> {t("missing.patient.name")} </Typography>;
  return (
    <React.Fragment>
      <Card data-cy={"requestcardcard_" + row.request_id} variant="outlined" >
        <CardContent sx={{padding: "8px"}}>
          <Grid container alignContent='center' >
            <Grid container item alignItems="center" >
              <Grid item container xs={2} sm={1} alignItems="center" >
                <Grid item xs={3}>
                  {row.urgent?<PriorityHighIcon color="error" />:null}
                </Grid>
                <Grid item xs={9}>
                  <Tooltip title={i18n.t("requestlist.icons.set.reminder")}>
                    <IconButton
                      data-cy={"reminder_switch_"+row.request_id}
                      onClick={()=>setSelectedRow({row: row, isOpenReminderDialog: true})}
                      color={
                        (row.reminder_dates &&
                         Array.isArray(row.reminder_dates) &&
                         row.reminder_dates.length > 0) ? "green": "lightgray"}>
                      < NotificationsActiveIcon data-testid='set_reminder'/>
                    </IconButton>
                  </Tooltip>
                </Grid>
              </Grid>
              <Grid item xs={6} sm={3} >
                <Typography data-cy={row.request_id} component={"span"} fontSize='16px'>
                  {patientName}
                </Typography>
              </Grid>
              <Grid item xs={2} sx={{display: {xs: "none", sm: "flex"}}}>
                <Typography data-cy={row.request_id} component={"span"} fontSize='16px'>
                  {row.request_type}
                </Typography>
              </Grid>
              <Grid item container xs={4} alignItems="center" sx={{display: {xs: "none", sm: "flex"}}}>
                <Grid item container xs={4} justifyContent='center' >
                  {statusIcons(row.request_status, handleClickStatus, t)}
                </Grid>
                <Grid item xs={8}>
                  <Typography data-cy={row.request_id} component={"span"} fontSize='16px'>
                    {t(row.request_status)}
                  </Typography>
                </Grid>
              </Grid>

              <Grid item container xs={4} sm={2} alignItems="center" >
                <Grid item xs={4}>
                  <IconButton
                    data-cy={"edit_"+row.request_id}
                    onClick={()=>actions.edit(row.request_id, row.kogu_type)}>
                    < EditIcon sx={{fontSize: "16px"}} />
                  </IconButton>
                </Grid>
                <Grid item xs={4}>
                  <IconButton
                    disabled={(!verified)}
                    data-cy={"communication_navigation_"+row.request_id}
                    onClick={()=>actions.navCommunication(row.request_id)}>
                    < FaCommentDots fontSize='16px'/>
                  </IconButton>
                </Grid>

                <Grid item xs={4}>
                  <IconButton
                    id="expandButton"
                    className={clsx(classes.expand, {
                      [classes.expandOpen]: expanded,
                    })}
                    data-cy={"expandbutton"+row.request_id}
                    onClick={handleExpandClick}
                    aria-expanded={expanded}
                    aria-label="show more"
                    style={{paddingLeft: "0"}}
                  >
                    <ExpandMore />
                  </IconButton>
                </Grid>
              </Grid>


            </Grid>
            {/* for mobile view*/}
          </Grid>
        </CardContent>
        <Collapse in={expanded} timeout="auto" unmountOnExit style={{...dottedSeparator, ...background}} >
          <RequestCollapseComponent
            row={row}
            actions={actions}
            setSelectedRow={setSelectedRow}
            verified={verified}/>
        </Collapse>
      </Card>
    </React.Fragment>
  );
};

export default RequestCard;
