import React from "react";
import {Search} from "components/icons";
import {Box, Card, CardHeader} from "@mui/material";

const ChatListHeader = (props) => {
  return (
    <Card>
      <Box display="flex" alignItems="center">
        <Box flexGrow={1}>
          <CardHeader
            disableTypography
            title={
              <div>
                <Search fontSize="large" style={{color: "#c8ced3"}}/>
                <input
                  type="text"
                  data-cy='communication_search'
                  style={{border: "none", backgroundColor: "transparent", outlineWidth: 0}}
                  placeholder={props.label_search}
                  value={props.searchQuery}
                  onChange={(event) => props.onSearchChange(event.target.value)}
                />
              </div>
            }>
          </CardHeader>
        </Box>
      </Box>
    </Card>
  );
};

export default ChatListHeader;
