import React from "react";
import PhoneInput from "react-phone-input-2";
import {FormHelperText} from "@mui/material";
import {customComponentInputType} from "../../store/rootstate";
import {useTranslation} from "react-i18next";

type PhoneComponentPatientProps={
    input:any,
    placeholder:string,
    touched:boolean,
    patientCountry:string,
    id:string,
    isEdit:boolean,
    meta: {error:string,
        visited:boolean}
}


export const PhoneComponentPatient = ({
  input,
  placeholder,
  touched,
  patientCountry,
  id,
  isEdit,
  meta: {error,
    visited},
  ...other}:PhoneComponentPatientProps )=> {
  const {t}=useTranslation();

  const countryCode = patientCountry ?
    patientCountry.toLowerCase() : "ch";

  const inputProp:customComponentInputType={
    required: true,
    id: id,
  };

  if (!visited) {
    inputProp.value = input.value;
  }

  return <div>
    <FormHelperText>
      {placeholder + " *"}
    </FormHelperText>
    <PhoneInput
      {...input}
      onBlur={() => null}
      inputProps={{
        ...inputProp,
        "data-cy": input.name,
      }}
      placeholder={placeholder}
      inputStyle={{width: "100%"}}
      country={input.value ? "" : countryCode}
      regions={"europe"}
    />
    <label
      htmlFor="user-message"
      className="col-lg pl-0 text-danger position-absolute"
    >
      {(touched || visited) && error ? t(error) : null}
    </label>
  </div>
  ;
};
