/* eslint-disable max-len */
export const textForSepecialMedications = {
  IMFINZI_5_DE: `
  <p> Imfinzi ist in Kombination mit Gemcitabin und Cisplatin für die Erstlinienbehandlung von erwachsenen Patienten mit lokal fortgeschrittenem oder metastasiertem Gallengangskrebs indiziert.</p>
  <p> Die Swissmedic-Zulassung von Imfinzi in Kombination mit GemCis für erwachsene Patienten mit lokal fortgeschrittenem oder metastasiertem BTC basiert auf den Ergebnissender TOPAZ-1-Studie.</p>
  <p> Lokal fortgeschrittenes/metastasiertes BTC ist eine unheilbare Krankheit mit einem medianen Gesamtüberleben (OS) von weniger als einem Jahr und einer geschätzten 24-Monats-Überlebensratevon etwa 15%. Imfinzi in Kombination mit GemCis ist eine neue Immuntherapie-Behandlungsoption für lokal fortgeschrittenes/metastasiertes BTC </p>
  <p> TOPAZ-1 war die erste multizentrische, randomisierte Phase-III-Studie, in welcher die Wirksamkeit und Sicherheit von Imfinzi in Kombination mit GemCis im Vergleich zu GemCis als 1L-Behandlung bei Patienten mit lokal fortgeschrittenem oder metastasiertem BTC untersucht wurde. </p>
  <p> Der primäre Endpunkt der TOPAZ-1-Studie war das Gesamtüberleben und er wurde erreicht. Ineiner vordefinierten Zwischenanalyse zeigte die TOPAZ-1-Studie, dass Imfinzi in Kombination mit GemCis einen statistisch signifikanten und klinischbedeutsamen OS-Vorteil gegenüber GemCis allein als 1L-Behandlung für Patienten mit fortgeschrittenem BTC aufwies [HR 0,80 (95% KI, 0,66–0,97; p=0,021)]. Die Kombination von Imfinzi mit einer Chemotherapie stellt den ersten grossen globalen Behandlungsfortschritt bei der 1L- Behandlung eines fortgeschrittenen BTC seit übereinem Jahrzehnt dar. Die Überlebensrate nach 24 Monaten betrug 24,9% (95% KI, 17,9–32,5) für den Imfinzi + GemCis-Arm und 10,4% (95% KI, 4,7–18,8) für GemCis. Die Kombination zeigte auch eine Verbesserung des progressionsfreien Überlebens (PFS) und der objektiven Ansprechrate (ORR), zwei wichtigen sekundären Endpunkten. Das mediane PFS betrug 7,2 (95% KI, 6,7–7,4) Monate für den Durvalumab-Arm und 5,7 (95% KI, 5,6–6,7) Monate für Placebo. [HR 0,75 (95% KI, 0,63–0,89; p = 0,001)]. Die ORR betrug 26,7% für den Imfinzi + GemCis- Arm und 18,7 % für GemCis. </p>
  <p> Auf dem ESMO-Kongress 2022 wurde eine aktualisierte OS-Analyse nach 6,5 Monaten zusätzlicher Nachbeobachtung (Gesamtreife 76,9%) präsentiert. </p>
  <p> Der Vorteil beim Gesamtüberleben verbesserte sich durch die Zugabe von Imfinzi zu GemCis numerisch im Vergleich zur Zwischenanalyse [HR0,76 (95% KI, 0,64–0,91)]. Bei Patienten in der Imfinzi + GemCis-Gruppe wurde das Sterberisikoum 24% reduziert. Die Zwei-Jahres-Überlebensrate (95% KI) betrug23,6% (18,7–28,9) in der Imfinzi + GemCis-Gruppe und 11,5% (7,6–16,2) in der GemCis-Gruppe. Der Vorteil des Gesamtüberlebens verbesserte sich beständig zugunsten der Imfinzi + GemCis- Gruppe mit einem Unterschied in der OS-Rate von12,1% nach 24 Monaten (7,2% nach 12 Monaten und 10,7% nach 18 Monaten). Mehr als doppelt so viele Patienten waren in der Imfinzi + GemCis Gruppe nach 24 Monaten Behandlung noch am Leben. </p>
  <p> Das Sicherheits- und Verträglichkeitsprofil von Imfinzi + GemCis stimmte mit den bekannten Profilen dieser Arzneimittel überein Imfinzi fügte keine weiteren Toxizitäten zu den bereits aus den Chemotherapien bekannten hinzu. Die Inzidenz behandlungsbedingter unerwünschter Ereignisse (TRAEs) Grad 3/4 betrug 62,7% in der Imfinzi + GemCis-Gruppe und64,9% in der GemCis-Gruppe.</p>
  <p> Im November 2022 wurden die ESMO-Leitlinien aktualisiert und Imfinzi + GemCis wurde als bevorzugte 1L-Behandlung vom fortgeschrittenen BTC aufgenommen [I, A; ESMO-Magnitude of Clinical Benefit (MCBS) v1.1 score: 4]. Darüber hinaus wurden auch die NCCN-Leitlinienfür hepatobiliäre Tumore (v1.2023) aktualisiert und Imfinzi + GemCis wurde als bevorzugtes Therapie-Regime für die 1L-Behandlung vominoperablen und metastasierenden Gallengangskrebs aufgenommen (Kategorie 1). </p>
  `,
  IMFINZI_5_FR: `
  <p> Imfinzi en association avec la gemcitabine et le cisplatine est indiqué pour le traitement de première intention des patients adultes atteints d'un cancer des voies biliaires localement avancé ou métastatique</p>
  <p> L'approbation par Swissmedic d'Imfinzi en association avec GemCis pour les patients adultes atteints d'un cancer des voies biliaires localement avancé ou métastatique est basée sur les résultats de l'étude TOPAZ-1.</p>
  <p> Le cancer des voies biliaires localement avancé/métastatique est une maladie incurable avec une survie globale (SG) médiane inférieure à un an et un taux de survie 24 mois estimé à environ 15 %.2 Imfinzi en association avec GemCis est une nouvelle option de traitement par immunothérapie pour le cancer des voies biliaires localement avancé/métastatique.</p>
  <p> TOPAZ-1 était la première étude multicentrique randomisée de phase III évaluant l'efficacité et l'innocuité d'Imfinzi en association avec GemCis par rapport à GemCis en  1L chez des patients adultes atteints d'un cancer des voies biliaires localement avancé ou métastatique.</p>
  <p> L'étude TOPAZ-1 a atteint son critère principal de survie globale. Dans une analyse intermédiaire prédéfinie de TOPAZ-1, Imfinzi en association avec GemCis a démontré un bénéfice de SG statistiquement et cliniquement significatif par rapport à GemCis en monothérapie en tant que traitement de première intention pour les patients atteints d'un cancer des voies biliaires à un stade avancé [HR 0,80 (IC à 95 %, 0,66 -0,97 ; p=0,021)].2 La combinaison d'Imfinzi avec GemCis marque la première avancée thérapeutique mondiale majeure dans le traitement de première intention du cancer des voies biliaires à un stade avancé depuis plus d'une décennie</p>
  <p> Le taux de survie à 24 mois était de 24,9 % (IC à 95 %, 17,9 à 32,5) pour le groupe Imfinzi + GemCis et de 10,4 % (IC à 95 %, 4,7 à 18,8) pour GemCis</p>
  <p> L'association a également démontré une amélioration de la survie sans progression (SSP) et du taux de réponse objective (ORR), critères secondaires clés de l'étude.</p>
  <p> La SSP médiane était de 7,2 (IC à 95 %, 6,7 à 7,4) mois pour le groupe Imfinzi + GemCis et de 5,7 (IC à 95 %, 5,6 à 6,7) mois pour le groupe GemCis [HR 0,75 (IC à 95 %, 0,63 à 0,89 ; p =0,001)].</p>
  <p> L'ORR était de 26,7 % pour le groupe Imfinzi + GemCis et de 18,7 % pour GemCis</p>
  <p> Une analyse actualisée de la survie globale après un suivi supplémentaire de 6,5 mois (maturité globale 76,9 %) a été présentée au congrès de l'ESMO 2022.</p>
  <p> Le bénéfice de la SG avec l'ajout d'Imfinzi à GemCis s'est amélioré numériquement par rapport à l'analyse intermédiaire [HR 0,76 (IC à 95 %, 0,64 à 0,91)]. Les patients du groupe Imfinzi + GemCis avaient une réduction de 24 % du risque de décès.</p>
  <p> Le taux de survie à deux ans (IC à 95 %) était de 23,6 % (18,7–28,9) dans le groupe Imfinzi + GemCis et de 11,5 % (7,6–16,2) dans le groupe GemCis.</p>
  <p> La progression de la SG s'est améliorée de manière constante en faveur du groupe Imfinzi + GemCis avec une différence de taux de SG de 12,1 % après 24 mois (7,2 % après 12 mois et 10,7 % après 18 mois). Plus du double des patients traités par Imfinzi + GemCis étaient encore en vie après 24 mois de traitement.</p>
  <p> Le profil de sécurité et de tolérabilité d'Imfinzi + GemCis était cohérent avec les profils connus de ces médicaments, et Imfinzi n'a pas ajouté de toxicité supplémentaire à celle observée avec la chimiothérapie.</p>
  <p> L'incidence des événements indésirables liés au traitement de grade 3/4 était de 62,7 % dans le groupe Imfinzi + GemCis et de 64,9 % dans le groupe GemCis.</p>
  <p> En novembre 2022, les directives de l'ESMO ont été mises à jour pour inclure Imfinzi + GemCis comme traitement de première intention préféré du cancer des voies biliaires avancé [I, A; ESMO-Magnitude of Clinical Benefit (MCBS) v1.1 score: 4].</p>
  <p> De plus, les directives de la NCCN pour les tumeurs hépatobiliaires (v1.2023) ont également été mises à jour pour inclure Imfinzi + GemCis comme schéma thérapeutique préféré pour le traitement primaire des cancers des voies biliaires non résécables et métastatiques (catégorie 1).</p>
  `,
  IMFINZI_5_IT: `
  <p> Imfinzi in combinazione con gemcitabina e cisplatino è indicato per il trattamento di prima linea di pazienti adulti con tumore delle vie biliari localmente avanzato o metastatico1.
  L'omologazione di Swissmedic di Imfinzi in combinazione con GemCis per pazienti adulti con tumore delle vie biliari localmente avanzato o metastatico si basa sui risultati dello studio TOPAZ-1.</p>
  <p> Il tumore delle vie biliari localmente avanzato/metastatico è una malattia incurabile con una sopravvivenza globale (OS) mediana inferiore a un anno e un tasso di sopravvivenza stimato a 24 mesi di circa il 15%.2 Imfinzi in combinazione con GemCis è una nuova opzione di trattamento immunoterapico per il tumore delle vie biliari localmente avanzato/metastatico.
  TOPAZ-1 è stato il primo studio multicentrico, randomizzato di fase III a valutare l'efficacia e la sicurezza di Imfinzi in combinazione con GemCis rispetto a GemCis come 1L in pazienti con tumore delle vie biliari localmente avanzato o metastatico.
  </p>
  <p> Lo studio TOPAZ-1 ha raggiunto il suo endpoint primario di sopravvivenza globale. In un'analisi ad interim predefinita dello studio TOPAZ-1, Imfinzi in combinazione con GemCis ha dimostrato un beneficio di OS statisticamente e clinicamente significativo rispetto a GemCis in monoterapia come trattamento di prima linea per i pazienti con tumore delle vie biliari in stadio avanzato [HR 0,80 (95% CI, 0,66-0,97; p=0,021)]. La combinazione di Imfinzi con GemCis rappresenta il primo importante progresso terapeutico globale nel trattamento di prima linea del tumore delle vie biliari in stadio avanzato in oltre un decennio.</p>
  <p> Il tasso di sopravvivenza a 24 mesi è stato del 24,9% (95% CI, 17,9–32,5) per il gruppo Imfinzi + GemCis e del 10,4% (95% CI, 4,7–18,8) per GemCis.</p>
  <p> La combinazione ha anche dimostrato un miglioramento della sopravvivenza libera da progressione (PFS) e del tasso di risposta obiettiva (ORR), endpoint secondari chiave dello studio.</p>
  <p> La PFS mediana è stata di 7,2 (95% CI, 6,7–7,4) mesi per il gruppo Imfinzi + GemCis e 5,7 (95% CI, 5,6–6,7) mesi per il gruppo GemCis [HR 0,75 (95% CI, 0,63–0,89; p=0,001)].</p>
  <p> L'ORR è stato del 26,7% per il gruppo Imfinzi + GemCis e del 18,7% per GemCis.</p>
  <p> Al congresso ESMO 2022 è stata presentata un'analisi aggiornata della sopravvivenza globale  dopo un follow-up aggiuntivo di 6,5 mesi (maturità complessiva 76,9%).</p>
  <p> Il vantaggio in termini di OS con l'aggiunta di Imfinzi a GemCis è migliorato numericamente rispetto all'analisi ad interim [HR 0,76 (95% CI, 0,64–0,91)]. I pazienti nel gruppo di Imfinzi + GemCis hanno avuto una riduzione del rischio di morte del 24</p>
  <p> Il tasso di sopravvivenza a due anni (IC 95%) è stato del 23,6% (18,7–28,9) nel gruppo di Imfinzi + GemCis e dell'11,5% (7,6–16,2) nel gruppo di GemCis.</p>
  <p> Il vantaggio di OS è migliorato costantemente a favore del gruppo Imfinzi + GemCis con una differenza nel tasso di OS del 12,1% dopo 24 mesi (7,2% dopo 12 mesi e 10,7% dopo 18 mesi). Più del doppio dei pazienti trattati con Imfinzi + GemCis erano ancora in vita dopo 24 mesi di trattamento.</p>
  <p> Il profilo di sicurezza e tollerabilità di Imfinzi + GemCis era coerente con i profili noti di questi medicamenti e Imfinzi non ha aggiunto ulteriore tossicità a quella osservata con la chemioterapia.</p>
  <p> L'incidenza degli effetti indesiderati connessi al trattamento (TRAE) di  grado 3/4 era del 62.7% nel gruppo di Imfinzi + GemCis e 64.9% nel gruppo di GemCis.</p>
  <p> Nel novembre 2022, le linee guida ESMO sono state aggiornate e hanno incluso Imfinzi + GemCis come trattamento preferito di prima linea del tumore delle vie biliari avanzato [I, A; ESMO-Magnitude of Clinical Benefit (MCBS) v1.1 score: 4]. Inoltre, anche le linee guida NCCN per i tumori epatobiliari (v1.2023) sono state aggiornate per includere Imfinzi + GemCis come regime preferito per il trattamento primario dei tumori delle vie biliari non resecabili e metastatici (categoria 1).</p>
  `,
  CALQUENCE_TPYE_1_DE: `
  Acalabrutinib (Calquence®) ist ein neuartiger Bruton-Tyrosinkinase (BTK)-Inhibitor der zweiten Generation (Wen et al. 2021), der ein besseres Verträglichkeitsprofil als andere in der Schweiz zugelassene BTK-Inhibitoren aufweist, bei gleichzeitig vergleichbarer Wirksamkeit (Byrd et al. 2021, Seymour et al. 2021).
  <br>
  Swissmedic hat Acalabrutinib in Kombination mit Obinutuzumab (Gazyvaro®) zur Behandlung von erwachsenen Patienten mit bisher unbehandelter chronischer lymphatischer Leukämie (CLL), die 65 Jahre und älter sind oder Begleiterkrankungen haben, zugelassen (Calquence Fachinformation). In der Schweiz ist Acalabrutinib als Monotherapie auf der Spezialitätenliste bei CLL-Patient:innen vergütet, die entweder eine Vortherapie hatten oder unbehandelt sind und einen reduzierten Allgemeinzustand (≥ 65 Jahre oder mit Begleiterkrankungen) mit 17p-Deletion / TP53-Mutation oder mit unmutiertem IGHV Status aufweisen (Spezialitätenliste 2023). CLL ist eine weitgehend unheilbare Krankheit mit schwerer gesundheitlicher Beeinträchtigung und möglichem tödlichen Verlauf. Bei zuvor unbehandelten CLL-Patient:innen mit reduziertem Allgemeinzustand sind folgende Medikamente gemäss Spezialitätenliste vergütet
  <br>
  Chlorambucil (Leukeran®) in Kombination mit Obinutuzumab (O + Clb) für alle Patient:innen
  <br>
  Acalabrutinib als Monotherapie für Patient:innen mit 17p-Deletion / TP53-Mutation oder mit unmutiertem IGHV Status 
  <br
  Ibrutinib als Monotherapie für Patient:innen mit 17p-Deletion / TP53-Mutation und als Monotherapie oder in Kombination mit Rituximab bei umutiertem IGHV Status. 
  <br>
  Acalabrutinib zeigte in Kombination mit Obinutuzumab (A + O) in der Phase-III-Studie ELEVATE TN in dieser Population im Direktvergleich mit O + Clb und Acalabrutinib Monotherapie signifikant bessere Wirksamkeitsergebnisse bei vergleichbarem Verträglichkeitsprofil (Sharman et al. 2020; Sharman et al. 2022).
  <br>
  Für Patient(in) ist basierend auf den ELEVATE TN-Studienergebnissen Acalabrutinib in Kombination mit Obinutuzumab dringend angezeigt, um einen Progress möglichst lange hinauszuzögern und ein möglichst langes Gesamtüberleben zu erreichen:
  <br>
  <b>
  ELEVATE TN - Primäre Wirksamkeitsdaten von Acalabrutinib plus Obinutuzumab (A + O) im Vergleich zu Acalabrutinib Monotherapie oder Chlorambucil + Obinutuzumab (O + Clb) bei zuvor unbehandelten CLL-Patient:innen, die 65 Jahre oder älter waren oder Begleiterkrankungen aufwiesen (Sharman et al. 2020; Sharman et al. 2022-1, Sharman et al. 2022-2):
  </b>
  Studiendesign: Die Sicherheit und Wirksamkeit von Acalabrutinib wurde in der multizentrischen, randomisierten, unverblindeten Phase-III-Studie ELEVATE TN bei 535 Patienten mit zuvor unbehandelter CLL untersucht. Acalabrutinib oder A + O wurde in dieser Studie direkt mit dem historischen Behandlungsstandard O + Clb verglichen. 
  <br> 
  Wirksamkeit: Progressionsfreies Überleben (PFS): Das mediane PFS wurde für A +O und Acalabrutinib-Monotherapie nach einer medianen Nachbeobachtungszeit von 58,2 Monaten noch nicht erreicht und betrug für O + Clb 27,8 Monate (Sharman et al. 2022-2). Das relative Risiko der Patient:innen für Progress oder Tod wurde mit A +O gegenüber O + Clb signifikant um 89 % (Hazard Ratio (HR) = 0,11 [95 %-Konfidenzintervall (KI): 0,07–0,16], p < 0,0001; ) und mit A +O gegenüber Acalabrutinib-Monotherapie signifikant um 49 % (HR = 0,51 [95 %- KI: 0,32–0,81]; p = 0,0259; ) verringert (Sharman et al. 2022-2).
  <br>
  Wirksamkeit – Gesamtüberleben (OS): Das mediane OS wurde weder für die beiden Acalabrutinib-Arme, noch für den Vergleichsarm O + Clb nach einer medianen Nachbeobachtungszeit von 58,2 Monaten erreicht. Das mediane OS war aber signifikant länger unter A +O im Vergleich zu O + Clb (HR = 0,55; [95 %- KI]: 0,30–0,99, p = 0,0474; ) und ist ebenfalls länger unter A+O im Vergleicht mit der Acalabrutinib-Monotherapie, mit Tendenzen in Richtung Signifikanz (HR = 0,56; [95 %- KI]: 0,31–1,00, p = 0,0556;). Dabei ist zu beachten, dass insgesamt 72 (41 %) der Patient:innen aus dem O + Clb-Arm in den Acalabrutinib-Monotherapie-Arm hinübergewechselt waren (Sharman et al. 2022-2). Dies trug zur Verbesserung der O+Clb-Überlebenskurve bei.
  <br>
  Wirksamkeit in Subpopulationen: In allen zuvor festgelegten Subgruppenanalysen ergab sich ein konsistenter Vorteil zugunsten von A + O gegenüber O + Clb, mit Risikoquotienten zwischen 0,02 und 0,18 gegenüber dem O + Clb-Kontrollarm (Sharman et al. 2020). Diese eindrucksvollen Daten belegen die Überlegenheit von Acalabrutinib in Kombination mit Obinutuzumab gegenüber dem historischen Behandlungsstandard O + Clb für alle Subgruppen, inklusive Patient:innen mit unmutiertem oder mutiertem IGHV, komplexem Karyotyp, 11q-Deletion oder grosser Tumormasse. 
  <br>
  Verträglichkeit: Unter Acalabrutinib in Kombination mit Obinutuzumabtraten unerwünschte Wirkungen (UW) des Grades ≥ 3 mit einer ähnlichen Häufigkeit wie unter O + Clb (70 %) auf. A + O wies insgesamt ein vergleichbares Verträglichkeitsprofil auf wie O + Clb (Sharman et al. 2020).
  <br>
  Empfehlungen Internationaler Leitlinien und Fachgremien: Acalabrutinib +/- Obinutuzumab wurde bei zuvor unbehandelten CLL-Patient:innen mit reduziertem Allgemeinzustand von allen drei in der Schweiz anerkannten Leitlinien (Onkopedia, ESMO und NCCN) als bevorzugte Behandlungsoption empfohlen (Wendtner et al. 2023; Eichhorst et al. 2020; National Comprehensive Cancer Network 2023). 
  `,
  CALQUENCE_TPYE_1_FR: `
  Acalabrutinib (Calquence®) ist ein neuartiger Bruton-Tyrosinkinase (BTK)-Inhibitor der zweiten Generation (Wen et al. 2021), der ein besseres Verträglichkeitsprofil als andere in der Schweiz zugelassene BTK-Inhibitoren aufweist, bei gleichzeitig vergleichbarer Wirksamkeit (Byrd et al. 2021, Seymour et al. 2021).
  <br>
  Swissmedic hat Acalabrutinib in Kombination mit Obinutuzumab (Gazyvaro®) zur Behandlung von erwachsenen Patienten mit bisher unbehandelter chronischer lymphatischer Leukämie (CLL), die 65 Jahre und älter sind oder Begleiterkrankungen haben, zugelassen (Calquence Fachinformation). In der Schweiz ist Acalabrutinib als Monotherapie auf der Spezialitätenliste bei CLL-Patient:innen vergütet, die entweder eine Vortherapie hatten oder unbehandelt sind und einen reduzierten Allgemeinzustand (≥ 65 Jahre oder mit Begleiterkrankungen) mit 17p-Deletion / TP53-Mutation oder mit unmutiertem IGHV Status aufweisen (Spezialitätenliste 2023). CLL ist eine weitgehend unheilbare Krankheit mit schwerer gesundheitlicher Beeinträchtigung und möglichem tödlichen Verlauf. Bei zuvor unbehandelten CLL-Patient:innen mit reduziertem Allgemeinzustand sind folgende Medikamente gemäss Spezialitätenliste vergütet
  <br>
  Chlorambucil (Leukeran®) in Kombination mit Obinutuzumab (O + Clb) für alle Patient:innen
  <br>
  Acalabrutinib als Monotherapie für Patient:innen mit 17p-Deletion / TP53-Mutation oder mit unmutiertem IGHV Status 
  <br>
  Ibrutinib als Monotherapie für Patient:innen mit 17p-Deletion / TP53-Mutation und als Monotherapie oder in Kombination mit Rituximab bei umutiertem IGHV Status. 
  <br>
  Acalabrutinib zeigte in Kombination mit Obinutuzumab (A + O) in der Phase-III-Studie ELEVATE TN in dieser Population im Direktvergleich mit O + Clb und Acalabrutinib Monotherapie signifikant bessere Wirksamkeitsergebnisse bei vergleichbarem Verträglichkeitsprofil (Sharman et al. 2020; Sharman et al. 2022).
  <br>
  Für Patient(in) ist basierend auf den ELEVATE TN-Studienergebnissen Acalabrutinib in Kombination mit Obinutuzumab dringend angezeigt, um einen Progress möglichst lange hinauszuzögern und ein möglichst langes Gesamtüberleben zu erreichen:
  <br>
  <b>
  ELEVATE TN - Primäre Wirksamkeitsdaten von Acalabrutinib plus Obinutuzumab (A + O) im Vergleich zu Acalabrutinib Monotherapie oder Chlorambucil + Obinutuzumab (O + Clb) bei zuvor unbehandelten CLL-Patient:innen, die 65 Jahre oder älter waren oder Begleiterkrankungen aufwiesen (Sharman et al. 2020; Sharman et al. 2022-1, Sharman et al. 2022-2):
  </b>
  Studiendesign: Die Sicherheit und Wirksamkeit von Acalabrutinib wurde in der multizentrischen, randomisierten, unverblindeten Phase-III-Studie ELEVATE TN bei 535 Patienten mit zuvor unbehandelter CLL untersucht. Acalabrutinib oder A + O wurde in dieser Studie direkt mit dem historischen Behandlungsstandard O + Clb verglichen. 
  <br> 
  Wirksamkeit: Progressionsfreies Überleben (PFS): Das mediane PFS wurde für A +O und Acalabrutinib-Monotherapie nach einer medianen Nachbeobachtungszeit von 58,2 Monaten noch nicht erreicht und betrug für O + Clb 27,8 Monate (Sharman et al. 2022-2). Das relative Risiko der Patient:innen für Progress oder Tod wurde mit A +O gegenüber O + Clb signifikant um 89 % (Hazard Ratio (HR) = 0,11 [95 %-Konfidenzintervall (KI): 0,07–0,16], p < 0,0001; ) und mit A +O gegenüber Acalabrutinib-Monotherapie signifikant um 49 % (HR = 0,51 [95 %- KI: 0,32–0,81]; p = 0,0259; ) verringert (Sharman et al. 2022-2).
  <br>
  Wirksamkeit – Gesamtüberleben (OS): Das mediane OS wurde weder für die beiden Acalabrutinib-Arme, noch für den Vergleichsarm O + Clb nach einer medianen Nachbeobachtungszeit von 58,2 Monaten erreicht. Das mediane OS war aber signifikant länger unter A +O im Vergleich zu O + Clb (HR = 0,55; [95 %- KI]: 0,30–0,99, p = 0,0474; ) und ist ebenfalls länger unter A+O im Vergleicht mit der Acalabrutinib-Monotherapie, mit Tendenzen in Richtung Signifikanz (HR = 0,56; [95 %- KI]: 0,31–1,00, p = 0,0556; ). Dabei ist zu beachten, dass insgesamt 72 (41 %) der Patient:innen aus dem O + Clb-Arm in den Acalabrutinib-Monotherapie-Arm hinübergewechselt waren (Sharman et al. 2022-2). Dies trug zur Verbesserung der O+Clb-Überlebenskurve bei.
  <br>
  Wirksamkeit in Subpopulationen: In allen zuvor festgelegten Subgruppenanalysen ergab sich ein konsistenter Vorteil zugunsten von A + O gegenüber O + Clb , mit Risikoquotienten zwischen 0,02 und 0,18 gegenüber dem O + Clb-Kontrollarm (Sharman et al. 2020). Diese eindrucksvollen Daten belegen die Überlegenheit von Acalabrutinib in Kombination mit Obinutuzumab gegenüber dem historischen Behandlungsstandard O + Clb für alle Subgruppen, inklusive Patient:innen mit unmutiertem oder mutiertem IGHV, komplexem Karyotyp, 11q-Deletion oder grosser Tumormasse. 
  <br>
  Verträglichkeit: Unter Acalabrutinib in Kombination mit Obinutuzumabtraten unerwünschte Wirkungen (UW) des Grades ≥ 3 mit einer ähnlichen Häufigkeit wie unter O + Clb (70 %) auf. A + O wies insgesamt ein vergleichbares Verträglichkeitsprofil auf wie O + Clb (Sharman et al. 2020).
  <br>
  Empfehlungen Internationaler Leitlinien und Fachgremien: Acalabrutinib +/- Obinutuzumab wurde bei zuvor unbehandelten CLL-Patient:innen mit reduziertem Allgemeinzustand von allen drei in der Schweiz anerkannten Leitlinien (Onkopedia, ESMO und NCCN) als bevorzugte Behandlungsoption empfohlen (Wendtner et al. 2023; Eichhorst et al. 2020; National Comprehensive Cancer Network 2023). 
  `,
  CALQUENCE_TPYE_1_IT: `
  Acalabrutinib (Calquence®) ist ein neuartiger Bruton-Tyrosinkinase (BTK)-Inhibitor der zweiten Generation (Wen et al. 2021), der ein besseres Verträglichkeitsprofil als andere in der Schweiz zugelassene BTK-Inhibitoren aufweist, bei gleichzeitig vergleichbarer Wirksamkeit (Byrd et al. 2021, Seymour et al. 2021).
  <br>
  Swissmedic hat Acalabrutinib in Kombination mit Obinutuzumab (Gazyvaro®) zur Behandlung von erwachsenen Patienten mit bisher unbehandelter chronischer lymphatischer Leukämie (CLL), die 65 Jahre und älter sind oder Begleiterkrankungen haben, zugelassen (Calquence Fachinformation). In der Schweiz ist Acalabrutinib als Monotherapie auf der Spezialitätenliste bei CLL-Patient:innen vergütet, die entweder eine Vortherapie hatten oder unbehandelt sind und einen reduzierten Allgemeinzustand (≥ 65 Jahre oder mit Begleiterkrankungen) mit 17p-Deletion / TP53-Mutation oder mit unmutiertem IGHV Status aufweisen (Spezialitätenliste 2023). CLL ist eine weitgehend unheilbare Krankheit mit schwerer gesundheitlicher Beeinträchtigung und möglichem tödlichen Verlauf. Bei zuvor unbehandelten CLL-Patient:innen mit reduziertem Allgemeinzustand sind folgende Medikamente gemäss Spezialitätenliste vergütet
  <br>
  Chlorambucil (Leukeran®) in Kombination mit Obinutuzumab (O + Clb) für alle Patient:innen
  <br>
  Acalabrutinib als Monotherapie für Patient:innen mit 17p-Deletion / TP53-Mutation oder mit unmutiertem IGHV Status 
  <br>
  Ibrutinib als Monotherapie für Patient:innen mit 17p-Deletion / TP53-Mutation und als Monotherapie oder in Kombination mit Rituximab bei umutiertem IGHV Status. 
  <br>
  Acalabrutinib zeigte in Kombination mit Obinutuzumab (A + O) in der Phase-III-Studie ELEVATE TN in dieser Population im Direktvergleich mit O + Clb und Acalabrutinib Monotherapie signifikant bessere Wirksamkeitsergebnisse bei vergleichbarem Verträglichkeitsprofil (Sharman et al. 2020; Sharman et al. 2022).
  <br>
  Für Patient(in) ist basierend auf den ELEVATE TN-Studienergebnissen Acalabrutinib in Kombination mit Obinutuzumab dringend angezeigt, um einen Progress möglichst lange hinauszuzögern und ein möglichst langes Gesamtüberleben zu erreichen:
  <br>
  <b>
  ELEVATE TN - Primäre Wirksamkeitsdaten von Acalabrutinib plus Obinutuzumab (A + O) im Vergleich zu Acalabrutinib Monotherapie oder Chlorambucil + Obinutuzumab (O + Clb) bei zuvor unbehandelten CLL-Patient:innen, die 65 Jahre oder älter waren oder Begleiterkrankungen aufwiesen (Sharman et al. 2020; Sharman et al. 2022-1, Sharman et al. 2022-2):
  </b>
  Studiendesign: Die Sicherheit und Wirksamkeit von Acalabrutinib wurde in der multizentrischen, randomisierten, unverblindeten Phase-III-Studie ELEVATE TN bei 535 Patienten mit zuvor unbehandelter CLL untersucht. Acalabrutinib oder A + O wurde in dieser Studie direkt mit dem historischen Behandlungsstandard O + Clb verglichen. 
  <br> 
  Wirksamkeit: Progressionsfreies Überleben (PFS): Das mediane PFS wurde für A +O und Acalabrutinib-Monotherapie nach einer medianen Nachbeobachtungszeit von 58,2 Monaten noch nicht erreicht und betrug für O + Clb 27,8 Monate (Sharman et al. 2022-2). Das relative Risiko der Patient:innen für Progress oder Tod wurde mit A +O gegenüber O + Clb signifikant um 89 % (Hazard Ratio (HR) = 0,11 [95 %-Konfidenzintervall (KI): 0,07–0,16], p < 0,0001; ) und mit A +O gegenüber Acalabrutinib-Monotherapie signifikant um 49 % (HR = 0,51 [95 %- KI: 0,32–0,81]; p = 0,0259; ) verringert (Sharman et al. 2022-2).
  <br>
  Wirksamkeit – Gesamtüberleben (OS): Das mediane OS wurde weder für die beiden Acalabrutinib-Arme, noch für den Vergleichsarm O + Clb nach einer medianen Nachbeobachtungszeit von 58,2 Monaten erreicht. Das mediane OS war aber signifikant länger unter A +O im Vergleich zu O + Clb (HR = 0,55; [95 %- KI]: 0,30–0,99, p = 0,0474; ) und ist ebenfalls länger unter A+O im Vergleicht mit der Acalabrutinib-Monotherapie, mit Tendenzen in Richtung Signifikanz (HR = 0,56; [95 %- KI]: 0,31–1,00, p = 0,0556; ). Dabei ist zu beachten, dass insgesamt 72 (41 %) der Patient:innen aus dem O + Clb-Arm in den Acalabrutinib-Monotherapie-Arm hinübergewechselt waren (Sharman et al. 2022-2). Dies trug zur Verbesserung der O+Clb-Überlebenskurve bei.
  <br>
  Wirksamkeit in Subpopulationen: In allen zuvor festgelegten Subgruppenanalysen ergab sich ein konsistenter Vorteil zugunsten von A + O gegenüber O + Clb , mit Risikoquotienten zwischen 0,02 und 0,18 gegenüber dem O + Clb-Kontrollarm (Sharman et al. 2020). Diese eindrucksvollen Daten belegen die Überlegenheit von Acalabrutinib in Kombination mit Obinutuzumab gegenüber dem historischen Behandlungsstandard O + Clb für alle Subgruppen, inklusive Patient:innen mit unmutiertem oder mutiertem IGHV, komplexem Karyotyp, 11q-Deletion oder grosser Tumormasse. 
  <br>
  Verträglichkeit: Unter Acalabrutinib in Kombination mit Obinutuzumabtraten unerwünschte Wirkungen (UW) des Grades ≥ 3 mit einer ähnlichen Häufigkeit wie unter O + Clb (70 %) auf. A + O wies insgesamt ein vergleichbares Verträglichkeitsprofil auf wie O + Clb (Sharman et al. 2020).
  <br>
  Empfehlungen Internationaler Leitlinien und Fachgremien: Acalabrutinib +/- Obinutuzumab wurde bei zuvor unbehandelten CLL-Patient:innen mit reduziertem Allgemeinzustand von allen drei in der Schweiz anerkannten Leitlinien (Onkopedia, ESMO und NCCN) als bevorzugte Behandlungsoption empfohlen (Wendtner et al. 2023; Eichhorst et al. 2020; National Comprehensive Cancer Network 2023). 
  `,
  CALQUENCE_TPYE_2_DE: `
  Acalabrutinib (Calquence®) ist ein neuartiger Bruton-Tyrosinkinase (BTK)-Inhibitor der zweiten Generation (Wen et al. 2021), der ein besseres Verträglichkeitsprofil als andere in der Schweiz zugelassene BTK-Inhibitoren aufweist, bei gleichzeitig vergleichbarer Wirksamkeit (Byrd et al. 2021, Seymour et al. 2021).
  <br>
  Die Zulassungsbehörde Swissmedic hat Acalabrutinib als Monotherapie oder in Kombination mit Obinutuzumab (Gazyvaro®) bei erwachsenen CLL-Patient:innen mit positiver Beurteilung zugelassen (Swissmedic 2021). In der Schweiz ist Acalabrutinib auf der Spezialitätenliste bei CLL-Patient:innen vergütet, die entweder eine Vortherapie hatten oder unbehandelt sind und einen reduzierten Allgemeinzustand (≥ 65 Jahre oder mit Begleiterkrankungen) mit 17p-Deletion / TP53-Mutation oder mit einem unmutierten IGHV Status aufweisen(Spezialitätenliste Juli 2023).
  <br>
  CLL ist eine weitgehend unheilbare Krankheit mit schwerer gesundheitlicher Beeinträchtigung und möglichem tödlichen Verlauf. Bei zuvor unbehandelten CLL-Patient:innen mit reduziertem Allgemeinzustand ohne 17p-Deletion/TP53-Mutation und mit mutiertem IGHV Status ist Chlorambucil (Leukeran®) in Kombination mit Obinutuzumab (O + Clb) in der Schweiz aktuell die einzige auf der Spezialitätenliste vergütete Therapie. Acalabrutinib zeigte als Monotherapie oder in Kombination mit Obinutuzumab in der Phase III-Studie ELEVATE TN in dieser Population im Direktvergleich mit O + Clb signifikant bessere Wirksamkeitsergebnisse bei besserem bzw. vergleichbarem Verträglichkeitsprofil (Sharman et al. 2020; Sharman et al. 2022). 
  <br>
  Für die obengenannten Patientin/den obengenannten Patienten gibt es basierend auf den ELEVATE TN-Studienergebnissen ausser Acalabrutinib keine zumutbare therapeutische Alternative auf der Spezialitätenliste und Acalabrutinib ist als Monotherapie / in Kombination mit Obinutuzumab dringend angezeigt, um einen Progress möglichst lange hinauszuzögern:
  <br><b>
  ELEVATE TN - Primäre Wirksamkeitsdaten von Acalabrutinib-Monotherapie oder Acalabrutinib plus Obinutuzumab (A + O) im Vergleich zu Chlorambucil + Obinutuzumab (O + Clb) bei zuvor unbehandelten CLL-Patient:innen, die 65 Jahre oder älter waren oder Begleiterkrankungen aufwiesen (Sharman et al. 2020; Sharman et al. 2022-1, Sharman et al. 2022-2):  
  </b>
  <br>
  Studiendesign: Die Sicherheit und Wirksamkeit von Acalabrutinib wurde in der multizentrischen, randomisierten, unverblindeten Phase-III-Studie ELEVATE TN bei 535 Patienten mit zuvor unbehandelter CLL untersucht. Acalabrutinib oder A + O wurde in dieser Studie direkt mit der einzigen in der Schweiz für diese Population vergütete, sowie historischen Behandlungsstandard O + Clb verglichen.
  <br>
  Wirksamkeit - Progressionsfreies Überleben (PFS): Das mediane PFS wurde für A +O und Acalabrutinib-Monotherapie nach einer medianen Nachbeobachtungszeit von 58,2 Monaten noch nicht erreicht und betrug für O + Clb 27,8 Monate (Sharman et al. 2022-2). Das relative Risiko der Patient:innen für Progress oder Tod wurde mit A +O gegenüber O + Clb signifikant um 89 % (Hazard Ratio (HR) = 0,11 [95 %-Konfidenzintervall (KI): 0,07–0,16], p < 0,0001;) und mit Acalabrutinib-Monotherapie gegenüber O + Clb signifikant um 79 % (HR = 0,21 [95 %- KI: 0,15–0,30]; p < 0,0001;) verringert (Sharman et al. 2022-2).
  <br>
  Wirksamkeit – Gesamtüberleben (OS): Das mediane OS wurde weder für die beiden Acalabrutinib-Arme, noch für den Vergleichsarm O + Clb nach einer medianen Nachbeobachtungszeit von 58,2 Monaten erreicht. Das mediane OS war aber signifikant länger unter A +O im Vergleich zu O + Clb (HR = 0,55; [95 %- KI]: 0,30–0,99, p = 0,0474;). Dabei ist zu beachten, dass insgesamt 72 (41 %) der Patient:innen aus dem O + Clb-Arm in den Acalabrutinib-Monotherapie-Arm hinübergewechselt waren (Sharman et al. 2022-2). 
  <br>
  Wirksamkeit in Subpopulationen: In allen zuvor festgelegten Subgruppenanalysen ergab sich ein konsistenter Vorteil zugunsten von A + O oder Acalabrutinib-Monotherapie gegenüber O + Clb, mit Risikoquotienten zwischen 0,02 und 0,18 für A + O und zwischen 0,02 und 0,69 für Acalabrutinib-Monotherapie gegenüber dem O + Clb-Kontrollarm (Sharman et al. 2020). 
  Diese eindrucksvollen Daten belegen die Überlegenheit von Acalabrutinib als Monotherapie oder in Kombination mit Obinutuzumab gegenüber dem historischen Behandlungsstandard O + Clb für alle Subgruppen, inklusive Patient:innen mit unmutiertem oder mutiertem IGHV, komplexem Karyotyp, 11q-Deletion oder grosser Tumormasse.
  <br>
  Verträglichkeit<br>
  Bei mit Acalabrutinib behandelten Patient:innen konnte eine deutliche Reduktion der Rate an unerwünschten Arzneimittelwechselwirkungen (UAW) des Grades ≥ 3 um mehr als 20 % (Rate der UAWs Grad ≥ 3 = 50 %) im Vergleich zum Kontrollarm (Rate der UAWs Grad ≥ 3 = 70 %) beobachtet werden. Unter A + O und unter O + Clb traten diese Ereignisse mit einer ähnlichen Häufigkeit auf (70 %) auf. Acalabrutinib-Monotherapie weist ein deutlich besseres und A + O ein vergleichbares Verträglichkeitsprofil auf wie O + Clb (Sharman et al. 2020).
  Empfehlungen Internationaler Leitlinien und Fachgremien:Das deutsche und britische Gesundheitsinstitut G-BA und NICE haben Acalabrutinib bei allen zuvor unbehandelten CLL-Patient:innen mit reduziertem Allgemeinzustand (≥ 65 Jahre oder mit Begleiterkrankungen) zur Rückvergütung bewilligt (G-BA 2021; NICE 2021).
  Acalabrutinib wurde bei zuvor unbehandelten CLL-Patient:innen mit reduziertem Allgemeinzustand von allen drei in der Schweiz anerkannten Leitlinien (Onkopedia, ESMO und NCCN) als bevorzugte Behandlungsoption empfohlen (Wendtner et al. 2023; Eichhorst et al. 2020; National Comprehensive Cancer Network 2023).
  <br>
  Empfehlungen Internationaler Leitlinien und Fachgremien:
  <br>
  Das deutsche und britische Gesundheitsinstitut G-BA und NICE haben Acalabrutinib bei allen zuvor unbehandelten CLL-Patient:innen mit reduziertem Allgemeinzustand (≥ 65 Jahre oder mit Begleiterkrankungen) zur Rückvergütung bewilligt (G-BA 2021; NICE 2021).
  <br>
  Acalabrutinib wurde bei zuvor unbehandelten CLL-Patient:innen mit reduziertem Allgemeinzustand von allen drei in der Schweiz anerkannten Leitlinien (Onkopedia, ESMO und NCCN) als bevorzugte Behandlungsoption empfohlen (Wendtner et al. 2023; Eichhorst et al. 2020; National Comprehensive Cancer Network 2023)
  `,
  CALQUENCE_TPYE_2_FR: `
  L'acalabrutinib (Calquence®) est un nouvel inhibiteur de la tyrosine kinase de Bruton (BTK) de seconde génération (Wen et al. 2021) présentant un meilleur profil de tolérance que les autres inhibiteurs de la BTK autorisés en Suisse et faisant preuve d'une efficacité comparable (Byrd et al. 2021, Seymour et al. 2021).
  <br>
  L'autorité d'autorisation Swissmedic a autorisé l'acalabrutinib en monothérapie ou en association avec l'obinutuzumab (Gazyvaro®) chez les patients adultes atteints de LLC avec une évaluation positive (Swissmedic 2021). En Suisse, l'acalabrutinib est repris dans la liste des spécialités remboursées pour les patients atteints de LLC qui ont soit été traités préalablement ou sont naïfs de traitement et présentent un état général altéré (âgés de ≥65 ans ou présentant des comorbidités) avec délétion 17p/mutation de TP53 ou avec un statut IGHV non muté (liste des spécialités 2023).
  <br>
  La LLC est une maladie généralement incurable avec atteinte sévère de la santé et dont l'évolution est potentiellement mortelle. Chez les patients atteints de LLC naïfs de traitement dont l'état général est altéré sans délétion 17p / mutation de TP53 et avec statut IGHV non muté, le chlorambucil (Leukeran®) plus l'obinutuzumab (O + Clb) est actuellement la seule thérapie remboursée reprise dans la liste des spécialités en Suisse. L'acalabrutinib a montré en monothérapie ou en association avec l'obinutuzumab dans l'étude de phase III ELEVATE-TN dans cette population par comparaison directe avec O + Clb des résultats d'efficacité significativement meilleurs en présence d'un meilleur profil de tolérance, resp. d'un profil de tolérance comparable (Sharman et al. 2020; Sharman et al. 2022).
  <br>
  Sur la base des résultats de l'étude ELEVATE-TN, il n'existe pour le patient/la patiente susmentionné/e pas d'autre alternative thérapeutique acceptable dans la liste des spécialités à l'exception de l'acalabrutinib et l'acalabrutinib est indiqué de toute urgence en monothérapie/en association avec l'obinutuzumab afin de retarder une progression aussi longtemps que possible.
  <br><b>
  ELEVATE-TN – données d'efficacité primaires de la monothérapie par acalabrutinib ou acalabrutinib plus obinutuzumab (A + O) par comparaison avec chlorambucil + obinutuzumab (O + Clb) chez les patients atteints de LLC naïfs de traitement âgés d'au moins 65 ans ou présentant des comorbidités (Sharman et al. 2020; Sharman et al. 2022-1, Sharman et al. 2022-2):</b>
  <br>
  Schéma de l'étude : La sécurité et l'efficacité de l'acalabrutinib ont été examinées dans l'étude de phase III ELEVATE-TN multicentrique, randomisée, effectuée en ouvert chez 535 patients atteints de LLC naïfs de traitement. L'acalabrutinib ou A + O a été comparé directement dans cette étude avec le seul standard de traitement historique O + Clb remboursé en Suisse pour cette population.
  <br>
  Efficacité - Survie globale (SG) : La SG médiane n'a pas été atteinte pour les deux bras acalabrutinib, ni pour le bras comparateur O + Clb après un suivi médian de 58,2 mois. Cependant, la médiane de survie globale était significativement plus longue avec A + O qu'avec O + Clb (HR 0,55 ; [IC 95 %] : 0,30–0,99 p=0,0474. Il convient de noter qu'un total de 72 patients (41 %) sont passés du bras O + Clb au bras acalabrutinib en monothérapie. (Sharman et al. 2022-2)
  <br>
  Efficacité dans les sous-populations :  Dans toutes les analyses de sous-groupes fixées auparavant, un bénéfice cohérent a été constaté en faveur d'A + O ou d'acalabrutinib en monothérapie par rapport à O + Clb, avec des rapports de risque entre 0,02 et 0,18 pour A + O et entre 0,02 et 0,69 pour l'acalabrutinib en monothérapie par rapport au bras de contrôle O + Clb (Sharman et al. 2020). Ces données impressionnantes documentent la supériorité de l'acalabrutinib en monothérapie ou en association avec l'obinutuzumab par rapport au standard de traitement historique O + Clb pour tous les sous-groupes, y compris les patients porteurs d'IGHV non muté ou muté, avec caryotype complexe, délétion 11q ou masse tumorale de grande taille.
  <br>
  Tolérance: Chez les patients traités par acalabrutinib, une réduction considérable du taux d'interactions médicamenteuses indésirables (IMI) III° ou supérieur de plus de 20 % (taux des IMI ≥III° = 50%) a pu être observée par rapport au bras de contrôle (taux des IMI ≥III° = 70%). Dans le bras de contrôle A + O et dans le bras de contrôle O + Clb, ces événements sont survenus avec une fréquence similaire (70%). L'acalabrutinib en monothérapie présente un profil de tolérance considérablement meilleur que O + Clb et A + O un profil de tolérance comparable à celui de O + Clb (Sharman et al. 2020).
  <br>
  Recommandations des lignes directrices et instances spécialisées internationales.
  <br>
  L'institut allemand et britannique de la santé G-BA et NICE ont approuvé le remboursement de l'acalabrutinib chez tous les patients atteints de LLC naïfs de traitement présentant un état général altéré (âgés de ≥65 ans ou présentant des comorbidités) (G-BA 2021; NICE 2021).
  <br>
  L'acalabrutinib a été recommandé comme option thérapeutique préférentielle chez les patients atteints de LLC naïfs de traitement présentant un état général altéré par les trois lignes directrices d'Onkopedia, d'ESMO et de NCCN reconnues en Suisse (Wendtner et al. 2023; Eichhorst et al. 2020; National Comprehensive Cancer Network 2023).
  <br>
  `,
  CALQUENCE_TPYE_2_IT: `
  Acalabrutinib (Calquence®) è un nuovo inibitore della tirosin-chinasi di Bruton (BTK) di seconda generazione (Wen et al. 2021), che presenta un profilo di tollerabilità migliorato rispetto ad altri BTKis approvati in Svizzera con, al contempo, efficacia comparabile (Byrd et al. 2021, Seymour et al. 2021).
  <br>
  L'autorità regolatoria Swissmedic ha concesso l'omologazione con parere positivo per acalabrutinib in monoterapia o in combinazione con obinutuzumab (Gazyvaro®) in pazienti adulti con LLC (Swissmedic 2021). In Svizzera, è previsto il rimborso per acalabrutinib nell'Elenco delle specialità per i pazienti con LLC pre-trattati o non pre-trattati che presentano ridotte condizioni fisiche generali (età ≥65 anni o con comorbilità) con delezione 17p / mutazione TP53 o con stato IGHV non mutato ( Elenco delle specialità 2023).
  <br>
  La LLC è una patologia in larga parte incurabile che causa una grave compromissione di salute con possibile esito fatale. Nei pazienti con LLC non pre-trattati con ridotte condizioni fisiche generali senza delezione 17p / mutazione TP53 e con stato IGHV mutato, la combinazione clorambucile (Leukeran®) + obinutuzumab (O + Clb) è attualmente l'unica terapia rimborsata presente nell'Elenco delle specialità in Svizzera. In questa popolazione, nel confronto diretto con O + Clb nell'ambito dello studio di fase III ELEVATE-TN, acalabrutinib in monoterapia o in combinazione con obinutuzumab ha evidenziato risultati di efficacia significativamente superiori con profilo di tollerabilità migliore o comparabile (Sharman et al. 2020; Sharman et al. 2022).
  <br>
  Per i pazienti sopra menzionati, in base ai risultati di ELEVATE-TN, nell'Elenco delle specialità non sono presenti alternative terapeutiche ragionevolmente esigibili tranne acalabrutinib, e acalabrutinib è indicato con urgenza in monoterapia / in combinazione con obinutuzumab per ritardare quanto più a lungo possibile un'eventuale progressione:
  <br><b>
  ELEVATE-TN – Dati primari di efficacia per acalabrutinib in monoterapia o acalabrutinib più obinutuzumab (A + O) rispetto a clorambucile + obinutuzumab (O + Clb) in pazienti con LLC pre-trattati di età superiore a 65 anni o inferiore a 65 anni con comorbilità (Sharman et al. 2020; Sharman et al. 2022):  </b>
  <br>
  Disegno dello studio: La sicurezza e l'efficacia di acalabrutinib sono state esaminate in uno studio di fase III multicentrico, randomizzato, in aperto, denominato ELEVATE-TN su 535 pazienti con LLC non pre-trattata. In questo studio, acalabrutinib in monoterapia o A + O è stato confrontato direttamente con lo standard di cura storico O + Clb nonché unico standard di cura rimborsato in Svizzera per questa popolazione.
  <br>
  Efficacia – sopravvivenza libera da progressione (PFS): La PFS mediana valutata per A + O e acalabrutinib dopo un follow-up mediano di 58.2 mesi non era stata ancora raggiunta e per O + Clb è risultata di 27,8 mesi (Sharman et al. 2022-2). Il rischio relativo di progressione o morte per i pazienti con A + O vs O + Clb è diminuito significativamente dell'89% (Hazard Ratio (HR) 0,11 [intervallo di confidenza (IC) 95%]: 0,07–0,16, p<0,0001, Figura 1) e significativamente dell'79% con acalabrutinib in monoterapia vs O + Clb (HR 0.21 [IC 95%]: 0,15–0,30, p<0,0001, Figura 1) (Sharman et al. 2022-2).
  <br>
  Efficacia – Sopravvivenza Global (OS): La OS mediana non è stata raggiunta per i due bracci di acalabrutinib, né per il braccio di confronto O + Clb dopo un follow-up mediano di 58,2 mesi. Tuttavia, la sopravvivenza globale mediana è stata significativamente più lunga con A + O rispetto a O + Clb (HR 0,55; [IC 95%]: 0,30–0,99, p=0,0474, Figura 2). E' da notare che, in totale, 72 (41%) dei pazienti nel braccio di Acalabrutinib inizialmente ero stati trattati nel braccio di trattamento O + Clb e poi trattati con Acalabrutinib dopo progressioni, come da protocollo (Sharman et al. 2022-2).
  <br>
  Efficacia nelle sottopopolazioni In tutte le analisi pre-specificate per sottogruppi è emerso un vantaggio riproducibile a favore di A + O o di acalabrutinib in monoterapia vs O + Clb , con rapporti di rischio compresi tra 0,02 e 0,18 per A +O e tra 0,02 e 0,69 per acalabrutinib in monoterapia vs il braccio di controllo O + Clb (Sharman et al. 2020).Si tratta di dati imponenti che dimostrano la superiorità di acalabrutinib in monoterapia o in combinazione con obinutuzumab rispetto allo standard di cura storico O + Clb per tutti i sottogruppi, compresi i pazienti con IGHV non mutato o mutato, cariotipo complesso, delezione 11q o massa tumorale estesa.
  <br>
  Tollerabilità: Nei pazienti trattati con acalabrutinib è stata osservata una riduzione significativa del tasso di effetti indesiderati (EI) di grado ≥3 di oltre il 20% (tasso di EI di grado ≥2: 50%) rispetto al braccio di controllo (tasso di EI ≥3 = 70%). Nei bracci di controllo A + O e O + Clb, questi eventi si sono verificati con frequenza simile (70%). Acalabrutinib in monoterapia presenta un profilo di tollerabilità significativamente migliore e A + O un profilo di tollerabilità comparabile a O + Clb (Sharman et al. 2020).
  <br>
  Raccomandazioni delle linee guida e delle commissioni specialistiche internazionali
  <br>
  L'istituto tedesco per la salute G-BA e quello britannico NICE hanno approvato la rimborsabilità di acalabrutinib per tutti i pazienti con LLC non pre-trattati con condizioni fisiche  generali ridotte (età ≥65 anni o comorbilità) (G-BA 2021; NICE 2021).
  <br>
  Acalabrutinib è stato raccomandato come opzione di trattamento da preferire nei pazienti con LLC non pre-trattati con condizioni generali ridotte da tutte e tre le linee guida riconosciute in Svizzera: Onkopedia, ESMO ed NCCN (Wendtner et al. 2023; Eichhorst et al. 2020; National Comprehensive Cancer Network 2023).
  <br>
  `,
  IMBRUVICA_DE: `
  `,
  IMBRUVICA_FR: `
  `,
  IMBRUVICA_IT: `
  `,
  IMJUDO_7_DE: `
  <br>SWISSMEDIC ZULASSUNG
  <br>Imjudo in Kombination mit Durvalumab ist indiziert für die Behandlung von Patienten mit inoperablem hepatozellulärem Karzinom (uHCC), die noch keine systemische Vorbehandlung erhalten haben.
  <br>LEITLINIEN 
  <br>Imjudo in Kombination mit Durvalumab (ein Schema namens STRIDE, Single Tremelimumab Regular Interval Durvalumab) erfüllt die Kriterien von Grad 5 für ein erhebliches Ausmass des klinischen Nutzens gemäss dem Beurteilungsformular 2a des ESMO-Magnitude of Clinical Benefit (MCBS) v1.1-Score.
  <br>Die NCCN-Leitlinie für Leberkrebs (V1.2023) empfiehlt STRIDE als bevorzugtes Schema für die Behandlung von 1L uHCC (Kategorie 1).
  <br>Die BCLC-Verband (Barcelona Clinic Liver Cancer) empfiehlt STRIDE als alternativ bevorzugte Erstlinientherapie zu Atezolizumab/Bevacizumab.
  <br>OLU TOOL NUTZENBEWERTUNG 
  <br>Die Nutzenbewertung des OLU-Tools ergibt ein Studienrating A (4 Punkte) für die HIMALAYA-Studie:
  <br>Rubrik 2 (OS als primärer Endpunkt - medianes OS mit Standardtherapie/Best Supportive Care 1-2 Jahre) - ∆OS nach 36 Monaten ≥10% (30.7% vs 19.8% nach 36 Monaten und 25.2% vs 15.1% at 48 Monaten): +4 Punkte
  <br>Die von Patienten berichteten Ergebnisse (Patient Reported Outcomes, PROs) waren einer der sekundären Endpunkte der HIMALAYA Studie. Die Zeit bis zur Verschlechterung der globalen Lebensqualität (QoL) verbesserte sich signifikant, HR 0,76 (95 %-KI: 0,61-0,96): +1 Punkt
  <br>Langfristiges OS-Plateau ≥12 Monate: +1 Punkt
  <br>ORR [partielles Ansprechen (PR) + komplettes Ansprechen (CR)] - ORR 15-30% (ORR für STRIDE: 20.1%): -1 Punkt.
  <br>STRIDE WIRKUNGSMECHANISMUS
  <br>Imjudo in Kombination mit Durvalumab ist eine neuartige Immuntherapie-Behandlungsoption und die erste IO-IO Kombinationstherapie für die Erstlinientherapie bei uHCC. Eine einmalige Initialdosis des Anti-CTLA-4-Antikörpers Imjudo in Kombination mit dem Anti-PD-L1-Antikörper Imfinzi am Tag 1 (Zyklus 1) gefolgt von einer Imfinzi-Monotherapie alle vier Wochen, verbessert die Aktivierung und Funktion der T-Zellen des Tumors in mehreren Stadien der Immunantwort und maximiert die Immunität gegen den Tumor.
  <br>DIE HIMALAYA STUDIE
  <br>HIMALAYA war die erste globale, multizentrische, offene, randomisierte Phase-III-Studie zur Evaluierung der Wirksamkeit und Sicherheit von STRIDE im Vergleich zu Sorafenib, bei Patienten mit uHCC, die zuvor keine systemische Therapie erhielten. HIMALAYA ist die bislang einzige Phase-3-Studie, die über eine 3- und 4-jährige Nachbeobachtungszeit bei uHCC berichtet.
  <br>DIE HIMALAYA STUDIE – WIRKSAMKEITSDATEN
  <br>Die HIMALAYA-Studie erreichte ihren primären Endpunkt Gesamtüberleben (OS) mit STRIDE im Vergleich zu Sorafenib. Beim Datenschnitt zeigte das STRIDE-Schema einen statistisch signifikanten und klinisch bedeutsamen OS-Nutzen im Vergleich zu Sorafenib bei Patienten mit uHCC [HR 0,78 (96,02%-KI: 0,65-0,93; p = 0,0035)].
  <br>Die Überlebensraten für STRIDE vs. Sorafenib nach 36 Monaten betrugen 30,7% (95%-KI: 25,8-35,7) bzw. 20,2% (95%-KI: 15,8-25,1).
  <br>Die Kombination zeigte auch eine Verbesserung der objektiven Ansprechrate (ORR) pro Prüfarzt: 20,1% mit STRIDE vs. 5,1% mit Sorafenib.
  <br>Auf dem ESMO GI 2023 wurde eine aktualisierte OS-Analyse (78% Datenreife) vorgestellt. STRIDE wies eine beispiellose Überlebensrate nach 4 Jahren auf und bestätigte das langfristige OS-Plateau.
  <br>Die Hazard Ratio des OS im Vergleich zu Sorafenib [0,78 (95%-KI: 0,67-0,92; p = 0,0037)] und die geschätzte 36-monatige OS-Rate (30,7%) für STRIDE stimmten mit der primären Analyse überein.
  <br>Die 4-Jahres-Überlebensraten betrugen 25,2% im STRIDE-Arm und 15,1% im Sorafenib-Arm. 1 von 4 Patienten im STRIDE-Arm war nach 48 Monaten noch am Leben.
  <br>DIE HIMALAYA STUDIE – SICHERHEITSDATEN
  <br>Das Sicherheits- und Verträglichkeitsprofil der Kombination stimmte mit den bekannten Profilen von Imfinzi und Imjudo überein und es wurden keine neuen Sicherheitssignale beobachtet.7
  <br>Die Inzidenz der behandlungsbedingten unerwünschten Ereignisse (Treatment Related Adverse Events, TRAE) des Grads 3/4 betrug 25,8% für die STRIDE-Gruppe und 36,9% für den Sorafenib-Arm.
  <br>Darüber hinaus verlängerte STRIDE die Zeit bis zur Verschlechterung der Lebensqualität (Time To Deterioration, TTD) im Vergleich zu Sorafenib [HR 0,76 (95%-KI: 0,61-0,96)].
  `,
  IMJUDO_7_FR: `
  <br>INDICATION DE SWISSMEDIC
  <br>Imjudo (trémélimumab), en association avec le durvalumab (Imfinzi), est indiqué pour le traitement des patients atteints de carcinome hépatocellulaire non opérable (uHCC) n'ayant pas encore reçu de prétraitement systémique.
  <br>RECOMMANDATIONS DES DIRECTIVES
  <br>Imjudo en association avec durvalumab (appelé STRIDE, Single Tremelimumab Regular interval Durvalumab) répond aux critères de grade 5 pour une amplitude substantielle du bénéfice clinique conformément au formulaire d'évaluation 2a du score ESMO-Magnitude of Clinical Benefit Scale (MCBS) v1.1.2
  <br>De plus, les recommandations de la NCCN pour les cancers hépatobiliaires (v1.2023) incluent STRIDE comme régime privilégié pour le traitement du uHCC en 1L (catégorie 1).
  <br>Le groupe BCLC (Barcelona Clinic Liver Cancer) recommande l'utilisation de STRIDE comme traitement alternatif préférentiel de première intention à l'atezolizumab/bevacizumab.
  <br>ÉVALUATION DES OLU TOOL
  <br>L'évaluation du bénéfice de l'outil OLU aboutit à une note d'étude de A pour l'étude HIMALAYA (4 points) :
  <br>Catégorie 2 (SG comme critère d'évaluation principal - SG médiane avec traitement standard/meilleurs soins de soutien 1 à 2 ans) - ∆OS après 36 mois ≥10 % : (30, % vs 19,8 % à 36 mois et 25,2 % vs 15,1 % à 48 mois)5 +4 points.
  <br>Les PRO (résultats rapportés par les patients) étaient l'un des critères d'évaluation secondaires de l'étude HIMALAYA. Le délai de détérioration de la qualité de vie globale (QdV) a amélioré significativement le risque relatif, HR 0,76 (IC 95 % 0,61 ; 0,96)6 : +1 point.
  <br>Plateau de SG à long terme ≥ 12 mois : +1 point. 
  <br>ORR [réponse partielle (PR) + réponse complète (CR)] - ORR 15-30 % (ORR pour STRIDE : 20,1 %) : -1 point.
  <br>LE MÉCANISME D'ACTION DE STRIDE
  <br>Imjudo en association avec Imfinzi est une nouvelle option de traitement par immunothérapie et la première combinaison IO-IO pour le traitement de première ligne du uHCC. Une dose initiale unique d'Imjudo, anticorps anti-CTLA-4 en association avec l'anticorps Imfinzi anti-PD-L1, le jour 1, cycle 1, suivie d'Imfinzi en monothérapie toutes les quatre semaines, augmente l'activation cellulaire et la fonction antitumorale T dans plusieurs phases de la réponse immunitaire et maximise l'immunité antitumorale.
  <br>L'ÉTUDE HIMALAYA
  <br>HIMALAYA est la première étude de phase III, internationale, multicentrique, randomisée, ouverte, évaluant l'efficacité et la sécurité de STRIDE par rapport au sorafénib chez des patients atteints de uHCC n'ayant pas reçu de traitement systémique antérieur. HIMALAYA est la seule étude de phase 3 à ce jour à rapporter un suivi sur 3 et 4 ans dans le uHCC.
  <br>L'ÉTUDE HIMALAYA - DONNÉES D'EFFICACITÉ
  <br>L'étude HIMALAYA a atteint son critère principal de survie globale (SG) avec STRIDE par rapport au sorafénib. À la date de recueil des données, le régime STRIDE a montré un bénéfice de SG statistiquement significatif et cliniquement pertinent par rapport au sorafénib chez les patients atteints de carcinome hépatocellulaire non résécable [HR = 0,78 ; IC96,02% [0,65 ; 0,93] ; p = 0,0035)].
  <br>Les taux de survie pour STRIDE vs. sorafénib à 36 mois étaient de 30,7 % (IC95% [25,8 ; 35,7]) et de 20,2 % (IC95% [15,8 ; 25,1]), respectivement.
  <br>L'association a également montré une amélioration des taux de réponse objective (ORR) par investigateur : 20,1 % avec STRIDE vs. 5,1 % avec sorafénib.
  <br>Lors de l'ESMO GI 2023, une analyse actualisée de la SG (78 % de maturité des données) a été présentée. STRIDE a démontré un taux de survie sans précédent d'un sur quatre à 4 ans, confirmant le plateau à long terme de la SG.
  <br>Le hazard ratio de la SG par rapport au sorafénib [HR = 0,78 (IC95% [0,67 ; 0,92] ; p = 0,0037)] et le taux de SG estimé à 36 mois (30,7 %) pour STRIDE étaient cohérents avec l'analyse principale.
  <br>Les taux de survie à 4 ans étaient de 25,2 % dans le bras STRIDE et de 15,1 % dans le bras sorafénib. 1 patient sur 4 du bras STRIDE était encore en vie après 48 mois.
  <br>L'ÉTUDE HIMALAYA - DONNÉES DE SÉCURITÉ
  <br>Le profil de sécurité d'emploi et de tolérance de l'association était cohérent avec les profils connus d'Imfinzi et d'Imjudo et aucun nouveau signal de sécurité d'emploi n'a été observé.
  <br>L'incidence des effets indésirables liés au traitement de grade 3/4 était de 25,8 % pour le groupe STRIDE et de 36,9 % pour le bras sorafénib.
  <br>De plus, STRIDE a augmenté la durée avant détérioration de la qualité de vie (Time To Deterioration, TTD) par rapport au sorafénib [HR 0,76 (IC95% : 0,61 ; 0,96)].
  `,
  IMJUDO_7_IT: `
  <br>INDICAZIONE DI SWISSMEDIC
  <br>Imjudo (tremelimumab) in combinazione con durvalumab (Imfinzi) è indicato per il trattamento di pazienti con carcinoma epatocellulare inoperabile (uHCC), che non hanno ancora ricevuto un pretrattamento sistemico.
  <br>LINEE GUIDA
  <br>Imjudo in combinazione con durvalumab (un regime terapeutico chiamato STRIDE, Single Tremelimumab Regular interval Durvalumab) soddisfa i criteri per un'entità sostanziale del beneficio clinico di Grado 5 in conformità con il modulo di valutazione 2a nel punteggio ESMO- Magnitude of Clinical Benefit (MCBS) v1.1.2
  <br>Inoltre, le linee guida NCCN per i tumori epatobiliari (v1.2023) includono STRIDE come regime preferenziale per il trattamento di 1L dell' uHCC (categoria 1).
  <br>Il gruppo BCLC (Barcelona Clinic Liver Cancer) raccomanda l'utilizzo di STRIDE  come terapia preferenziale di prima linea alternativa ad atezolizumab/bevacizumab.
  <br>VALUTAZIONE MEDIANTE OLU TOOL
  <br>Sulla base della valutazione dei benefici tramite OLU tool, il punteggio dello studio HIMALAYA è A (4 punti): 
  <br>Rubrica 2 (OS endpoint primario – OS mediana con terapia standard/Best Supportive Care 1-2 anni) - ∆OS a 36 mesi ≥10% (30.7% vs 19.8% a 36 mesi e 25.2% vs 15.1% a 48 mesi): +4 punti.
  <br>I PROs (risultati riportati dal paziente) erano uno degli endpoint secondari dello studio HIMALAYA. Il tempo al deterioramento della qualità della vita (QoL) globale ha migliorato significativamente l'hazard ratio, HR 0,76 (95%CI 0,61-0,96): +1 punto.
  <br>Plateau a lungo termine di OS ≥12 mesi: +1 punto. 
  <br>ORR [Risposte parziali (RP) + Risposte complete (CR)] - ORR 15-30% (ORR per STRIDE: 20.1%): -1 punto.
  <br>MECCANISMO D'AZIONE DI STRIDE
  <br>Imjudo in combinazione con Imfinzi è una nuova opzione di trattamento immunoterapico e la prima terapia di combinazione IO-IO per il trattamento di prima di linea dell'uHCC. Una singola dose iniziale di Imjudo, anticorpo anti-CTLA-4 in combinazione con l'anticorpo anti-PD-L1 Imfinzi, al giorno 1, ciclo 1, seguita da monoterapia con Imfinzi ogni quattro settimane aumenta l'attivazione e la funzione delle cellule T antitumorali in più fasi della risposta immunitaria e massimizza l'immunità antitumorale.
  <br>LO STUDIO HIMALAYA
  <br>HIMALAYA è stato il primo studio globale, multicentrico, randomizzato, open label di fase III a valutare l'efficacia e la sicurezza di STRIDE rispetto a sorafenib in pazienti con uHCC che non avevano ricevuto una precedente terapia sistemica. HIMALAYA è l'unico studio di fase 3 finora condotto a mostrare un follow-up di 3 e 4 anni nell'HCC.
  <br>LO STUDIO HIMALAYA – DATI DI EFFICACIA
  <br>Lo studio HIMALAYA ha raggiunto l' endpoint primario di sopravvivenza globale (OS) di STRIDE rispetto a sorafenib. Al cut-off dei dati, STRIDE ha mostrato un beneficio statisticamente e clinicamente significativo rispetto a sorafenib nei pazienti con uHCC [HR 0,78 (IC 96,02%, 0,65-0,93; p=0,0035)].
  <br>I tassi di sopravvivenza per STRIDE vs. sorafenib a 36 mesi sono stati del 30,7% (IC 95%, 25,8-35,7) e del 20,2% (IC 95%, 15,8-25,1), rispettivamente.
  <br>La combinazione ha anche mostrato un miglioramento dei tassi di risposta obiettiva (Objective Response Rate, ORR): 20,1% con STRIDE vs 5,1% con sorafenib.
  <br>Al congresso mondiale ESMO GI del 2023 è stata presentata un'analisi aggiornata dell' OS (maturità dei dati del 78%). STRIDE ha dimostrato un tasso di sopravvivenza a 4 anni senza precedenti, confermando il plateau a lungo termine dell'OS.
  <br>L' hazard ratio per l'OS versus sorafenib [0.78 (95% IC, 0.67–0.92; p=0.0037)] e il tasso di OS stimato a 36 mesi per STRIDE (30,7%)  erano consistenti con l'analisi primaria.
  <br>I tassi di sopravvivenza a 4 anni sono stati del 25,2% nel braccio STRIDE e del 15,1% nel braccio sorafenib. 1 paziente su 4 nel braccio STRIDE era ancora in vita a 48 mesi.
  <br>LO STUDIO HIMALAYA – DATI DI SICUREZZA
  <br>Il profilo di sicurezza e tollerabilità della combinazione è risultato coerente con i profili noti di Imjudo e Imfinzi e non sono stati osservati nuovi segnali di sicurezza.
  <br>L'incidenza di eventi avversi correlati al trattamento (Treatment Related Adverse Events, TRAE) di grado 3/4 è stata del 25,8% per il gruppo STRIDE e del 36,9% per il braccio del sorafenib.
  <br>Inoltre, STRIDE ha prolungato il tempo al deterioramento (Time To Deterioration, TTD) della qualità della vita rispetto al sorafenib [HR 0,76 (95% IC: 0,61-0,96)].
  `,
  IMJUDO_8_DE: ` 
  <br>SWISSMEDIC ZULASSUNG
  <br>Imjudo in Kombination mit Durvalumab ist indiziert für die Behandlung von Patienten mit inoperablem hepatozellulärem Karzinom (uHCC), die noch keine systemische Vorbehandlung erhalten haben.
  <br>LEITLINIEN 
  <br>Imjudo in Kombination mit Durvalumab (ein Schema namens STRIDE, Single Tremelimumab Regular Interval Durvalumab) erfüllt die Kriterien von Grad 5 für ein erhebliches Ausmass des klinischen Nutzens gemäss dem Beurteilungsformular 2a des ESMO-Magnitude of Clinical Benefit (MCBS) v1.1-Score.
  <br>Die NCCN-Leitlinie für Leberkrebs (V1.2023) empfiehlt STRIDE als bevorzugtes Schema für die Behandlung von 1L uHCC (Kategorie 1).
  <br>Die BCLC-Verband (Barcelona Clinic Liver Cancer) empfiehlt STRIDE als alternativ bevorzugte Erstlinientherapie zu Atezolizumab/Bevacizumab.
  <br>OLU TOOL NUTZENBEWERTUNG 
  <br>Die Nutzenbewertung des OLU-Tools ergibt ein Studienrating A (4 Punkte) für die HIMALAYA-Studie: 
  <br>Rubrik 2 (OS als primärer Endpunkt - medianes OS mit Standardtherapie/Best Supportive Care 1-2 Jahre) - ∆OS nach 36 Monaten ≥10% (30.7% vs 19.8% nach 36 Monaten und 25.2% vs 15.1% at 48 Monaten): +4 Punkte
  <br>Die von Patienten berichteten Ergebnisse (Patient Reported Outcomes, PROs) waren einer der sekundären Endpunkte der HIMALAYA Studie. Die Zeit bis zur Verschlechterung der globalen Lebensqualität (QoL) verbesserte sich signifikant, HR 0,76 (95 %-KI: 0,61-0,96): +1 Punkt
  <br>Langfristiges OS-Plateau ≥12 Monate: +1 Punkt
  <br>ORR [partielles Ansprechen (PR) + komplettes Ansprechen (CR)] - ORR 15-30% (ORR für STRIDE: 20.1%): -1 Punkt.
  <br>BLUTUNGSRISIKO UND ANTI-VEGF/VEGFR KONTAINDIKATIONEN
  <br>Bei dem/der oben genannten Patient:in besteht ein Blutungsrisiko oder eine Kontraindikation für Anti-VEGF/VEGFR. Daher ist eine Therapie mit Atezolizumab/Bevacizumab nicht geeignet. Therapien mit antiangiogenetischem Wirkungsprinzip, wie Bevacizumab, sind mit einem signifikanten Anstieg des Blutungsrisikos assoziiert. Blutungen können durch den Hauptmechanismus von Angiogenese-Inhibitoren entstehen, die Tumorgefässe durch Hemmung der VEGF-Signalgebung zerstören und so zu Thrombosen oder Blutungen führen.Darüber hinaus sind weitere der derzeit zugelassenen Therapien für 1L uHCC, Lenvatinib und Sorafenib, die über den Mechanismus der  Blockrezeptor-Tyrosinkinase-Signalgebung (einschliesslich VEGFR) wirken, auch keine geeignete Behandlungsoption bei Patient:innen mit einem Blutungsrisiko oder einer Kontraindikation für Anti-VEGF/VEGFR.
  <br>STRIDE WIRKUNGSMECHANISMUS
  <br>Imjudo in Kombination mit Durvalumab ist eine neuartige Immuntherapie-Behandlungsoption und die erste IO-IO Kombinationstherapie für die Erstlinien Therapie im uHCC. Eine einmalige Initialdosis des Anti-CTLA-4-Antikörpers Imjudo in Kombination mit dem Anti-PD-L1-Antikörper Imfinzi am Tag 1, Zyklus 1, gefolgt von einer Imfinzi-Monotherapie alle vier Wochen, verbessert die Aktivierung und Funktion der T-Zellen des Tumors in mehreren Stadien der Immunantwort und maximiert die Immunität gegen den Tumor.
  <br>DIE HIMALAYA STUDIE
  <br>HIMALAYA war die erste globale, multizentrische, offene, randomisierte Phase-III-Studie zur Evaluierung der Wirksamkeit und Sicherheit von STRIDE im Vergleich zu Sorafenib bei Patienten mit uHCC, die zuvor keine systemische Therapie erhielten. HIMALAYA ist die bislang einzige Phase-3-Studie, die über eine 3- und 4-jährige Nachbeobachtungszeit bei uHCC berichtet.
  <br>DIE HIMALAYA STUDIE – WIRKSAMKEITSDATEN
  <br>Die HIMALAYA-Studie erreichte ihren primären Endpunkt Gesamtüberleben (OS) mit STRIDE im Vergleich zu Sorafenib. Beim Datenschnitt zeigte das STRIDE-Schema einen statistisch signifikanten und klinisch bedeutsamen OS-Nutzen im Vergleich zu Sorafenib bei Patienten mit uHCC [HR 0,78 (96,02%-KI: 0,65-0,93; p = 0,0035)].
  <br>Die Überlebensraten für STRIDE vs. Sorafenib nach 36 Monaten betrugen 30,7% (95%-KI: 25,8-35,7) bzw. 20,2% (95%-KI: 15,8-25,1).
  <br>Die Kombination zeigte auch eine Verbesserung der objektiven Ansprechrate (ORR) pro Prüfarzt: 20,1% mit STRIDE vs. 5,1% mit Sorafenib.
  <br>Auf dem ESMO GI 2023 wurde eine aktualisierte OS-Analyse (78% Datenreife) vorgestellt. STRIDE wies eine beispiellose Überlebensrate nach 4 Jahren auf und bestätigte das langfristige OS-Plateau.
  <br>Die Hazard Ratio des OS im Vergleich zu Sorafenib [0,78 (95%-KI: 0,67-0,92; p = 0,0037)] und die geschätzte 36-monatige OS-Rate (30,7%) für STRIDE stimmten mit der primären Analyse überein.
  <br>Die 4-Jahres-Überlebensraten betrugen 25,2% im STRIDE-Arm und 15,1% im Sorafenib-Arm. 1 von 4 Patienten im STRIDE-Arm waren nach 48 Monaten noch am Leben.
  <br>DIE HIMALAYA STUDIE – SICHERHEITSDATEN
  <br>Das Sicherheits- und Verträglichkeitsprofil der Kombination stimmte mit den bekannten Profilen von Imfinzi und Imjudo überein und es wurden keine neuen Sicherheitssignale beobachtet.
  <br>Die Inzidenz der behandlungsbedingten unerwünschten Ereignisse (Treatment Related Adverse Events, TRAE) des Grads 3/4 betrug 25,8% für die STRIDE-Gruppe und 36,9% für den Sorafenib-Arm.
  <br>Darüber hinaus verlängerte STRIDE die Zeit bis zur Verschlechterung der Lebensqualität (Time To Deterioration, TTD) im Vergleich zu Sorafenib [HR 0,76 (95%-KI: 0,61-0,96)].
  `,
  IMJUDO_8_FR: `
  <br>INDICATION DE SWISSMEDIC
  <br>Imjudo (trémélimumab), en association avec le durvalumab (Imfinzi), est indiqué pour le traitement des patients atteints de carcinome hépatocellulaire non opérable (uHCC) n'ayant pas encore reçu de prétraitement systémique.
  <br>RECOMMANDATIONS DES DIRECTIVES
  <br>Imjudo en association avec durvalumab (appelé STRIDE, Single Tremelimumab Regular interval Durvalumab) répond aux critères de grade 5 pour une amplitude substantielle du bénéfice clinique conformément au formulaire d'évaluation 2a du score ESMO-Magnitude of Clinical Benefit Scale (MCBS) v1.1.2
  <br>De plus, les recommandations du NCCN pour les cancers hépatobiliaires (v1.2023) incluent STRIDE comme régime privilégié pour le traitement du uHCC en 1L (catégorie 1).
  <br>Le groupe BCLC (Barcelona Clinic Liver Cancer) recommande l'utilisation de STRIDE comme traitement alternatif préférentiel de première intention à l'atezolizumab/bevacizumab.
  <br>ÉVALUATION DES OLU TOOL
  <br>L'évaluation du bénéfice de l'outil OLU aboutit à une note d'étude de A pour l'étude HIMALAYA (4 points) :
  <br>Catégorie 2 (SG comme critère d'évaluation principal - SG médiane avec traitement standard/meilleurs soins de soutien 1 à 2 ans) - ∆OS après 36 mois ≥10 % : (30,7 % vs 19,8 % à 36 mois et 25,2 % vs 15,1 % à 48 mois)5 +4 points.
  <br>Les PRO (résultats rapportés par les patients) étaient l'un des critères d'évaluation secondaires de l'étude HIMALAYA. Le délai de détérioration de la qualité de vie globale (QdV) a amélioré significativement le risque relatif, HR 0,76 (IC 95 % 0,61 ; 0,96)6 : +1 point.
  <br>Plateau de SG à long terme ≥ 12 mois : +1 point. 
  <br>ORR [réponse partielle (PR) + réponse complète (CR)] - ORR 15-30 % (ORR pour STRIDE : 20,1 %)7 : -1 point.
  <br>RISQUE DE SAIGNEMENT/CONTRE-INDICATIONS ANTI-VEGF/VEGFR
  <br>Le patient ci-dessus présente un risque de saignement ou des contre-indications aux inhibiteurs du VEGF/VEFGR. Par conséquent, le traitement par atézolizumab/bévacizumab n'est pas adapté. Les anticorps monoclonaux antiangiogéniques tel que le bévacizumab sont associés à une augmentation significative du risque de saignement. Un saignement peut survenir par le biais du mécanisme principal des inhibiteurs de l'angiogenèse, qui consiste à détruire le système vasculaire tumoral en inhibant la signalisation du VEGF, conduisant à une thrombose ou à un saignement.
  <br>Par conséquent, les autres traitements actuellement approuvés pour le traitement du uHCC en 1L, le lenvatinib et le sorafénib, qui agissent par le biais du mécanisme de blocage de la signalisation des récepteurs à activité tyrosine kinase (y compris le VEGFR), ne constituent pas non plus une option thérapeutique appropriée chez les patients présentant un risque hémorragique ou une contre-indication aux inhibiteurs du VEGF/VEGFR.
  <br>LE MÉCANISME D'ACTION DE STRIDE
  <br>Imjudo en association avec Imfinzi est une nouvelle option de traitement par immunothérapie et la première combinaison IO-IO pour le traitement de première ligne du uHCC. Une dose initiale unique d'Imjudo, anticorps anti-CTLA-4 en association avec l'anticorps Imfinzi anti-PD-L1, le jour 1, cycle 1, suivie d'Imfinzi en monothérapie toutes les quatre semaines, augmente l'activation cellulaire et la fonction antitumorale T dans plusieurs phases de la réponse immunitaire et maximise l'immunité antitumorale.
  <br>L'ÉTUDE HIMALAYA
  <br>HIMALAYA est la première étude de phase III, internationale, multicentrique, randomisée, ouverte, évaluant l'efficacité et la sécurité de STRIDE par rapport au sorafénib chez des patients atteints de uHCC n'ayant pas reçu de traitement systémique antérieur. HIMALAYA est la seule étude de phase 3 à ce jour à rapporter un suivi sur 3 et 4 ans dans le uHCC.
  <br>L'ÉTUDE HIMALAYA - DONNÉES D'EFFICACITÉ
  <br>L'étude HIMALAYA a atteint son critère principal de survie globale (SG) avec STRIDE par rapport au sorafénib. À la date de recueil des données, le régime STRIDE a montré un bénéfice de SG statistiquement significatif et cliniquement pertinent par rapport au sorafénib chez les patients atteints de carcinome hépatocellulaire non résécable [HR = 0,78 ; IC96,02% [0,65 ; 0,93] ; p = 0,0035)].
  <br>Les taux de survie pour STRIDE vs. sorafénib à 36 mois étaient de 30,7 % (IC95% [25,8 ; 35,7]) et de 20,2 % (IC95% [15,8 ; 25,1]), respectivement.
  <br>L'association a également montré une amélioration des taux de réponse objective (ORR) par investigateur : 20,1 % avec STRIDE vs. 5,1 % avec sorafénib.
  <br>Lors de l'ESMO GI 2023, une analyse actualisée de la SG (78 % de maturité des données) a été présentée. STRIDE a démontré un taux de survie sans précédent d'un sur quatre à 4 ans, confirmant le plateau à long terme de la SG.
  <br>Le hazard ratio de la SG par rapport au sorafénib [HR = 0,78 (IC95% [0,67 ; 0,92] ; p = 0,0037)] et le taux de SG estimé à 36 mois (30,7 %) pour STRIDE étaient cohérents avec l'analyse principale.
  <br>Les taux de survie à 4 ans étaient de 25,2 % dans le bras STRIDE et de 15,1 % dans le bras sorafénib. 1 patient sur 4 du bras STRIDE était encore en vie après 48 mois.
  <br>L'ÉTUDE HIMALAYA - DONNÉES DE SÉCURITÉ
  <br>Le profil de sécurité d'emploi et de tolérance de l'association était cohérent avec les profils connus d'Imfinzi et d'Imjudo et aucun nouveau signal de sécurité d'emploi n'a été observé.
  <br>L'incidence des effets indésirables liés au traitement de grade 3/4 était de 25,8 % pour le groupe STRIDE et de 36,9 % pour le bras sorafénib.
  <br>De plus, STRIDE a augmenté la durée avant détérioration (Time To Deterioration, TTD) de la qualité de vie par rapport au sorafénib [HR 0,76 (IC95% : 0,61 ; 0,96)].
  `,
  IMJUDO_8_IT: ` 
  <br>INDICAZIONE DI SWISSMEDIC
  <br>Imjudo (tremelimumab) in combinazione con durvalumab (Imfinzi) è indicato per il trattamento di pazienti con carcinoma epatocellulare inoperabile (uHCC), che non hanno ancora ricevuto un pretrattamento sistemico.
  <br>LINEE GUIDA
  <br>Imjudo in combinazione con durvalumab (un regime terapeutico chiamato STRIDE, Single Tremelimumab Regular interval Durvalumab) soddisfa i criteri per un'entità sostanziale del beneficio clinico di Grado 5 in conformità con il modulo di valutazione 2a nel punteggio ESMO- Magnitude of Clinical Benefit (MCBS) v1.1.2
  <br>Inoltre, le linee guida NCCN per i tumori epatobiliari (v1.2023) includono STRIDE come regime preferenziale per il trattamento di 1L dell' uHCC (categoria 1).
  <br>Il gruppo BCLC (Barcelona Clinic Liver Cancer) raccomanda l'utilizzo di STRIDE  come terapia preferenziale di prima linea alternativa ad atezolizumab/bevacizumab.
  <br>VALUTAZIONE MEDIANTE OLU TOOL
  <br>Sulla base della valutazione dei benefici tramite OLU tool, il punteggio dello studio HIMALAYA è A (4 punti): 
  <br>Rubrica 2 (OS endpoint primario – OS mediana con terapia standard/Best Supportive Care 1-2 anni) - ∆OS a 36 mesi ≥10% (30.7% vs 19.8% a 36 mesi e 25.2% vs 15.1% a 48 mesi): +4 punti.
  <br>I PROs (risultati riportati dal paziente) erano uno degli endpoint secondari. Il tempo al deterioramento della qualità della vita (QoL) globale ha migliorato significativamente l'hazard ratio, HR 0,76 (95%CI 0,61-0,96): +1 punto.
  <br>Plateau a lungo termine di OS ≥12 mesi: +1 punto. 
  <br>ORR [Risposte parziali (RP) + Risposte complete (CR)] - ORR 15-30% (ORR per STRIDE: 20.1%): -1 punto.
  <br>RISCHIO DI SANGUINAMENTO/CONTROINDICAZIONI ANTI-VEGF/VEGFR
  <br>Il paziente sopra menzionato ha un rischio di sanguinamento o controindicazioni per gli anti-VEGF/VEGFR. Pertanto una terapia con atezolizumab/bevacizumab non è appropriata. Gli anticorpi monoclonali antiangiogenici come bevacizumab sono associati ad un aumento significativo del rischio di sanguinamento. Il meccanismo principale di sanguinamento è che gli inibitori dell'angiogenesi interrompono la vascolarizzazione del tumore attraverso l'inibizione del segnale VEGF portando a trombosi o sanguinamento.Inoltre, le altre terapie attualmente approvate per il trattamento di prima linea dell' uHCC, lenvatinib e sorafenib, bloccano il segnale dei recettori tirosinchinasici (incluso VEGFR) e pertanto non rappresentano un'opzione di trattamento idonea in caso di pazienti con rischio di sanguinamento/controindicazioni per gli anti-VEGF/VEGFR.
  <br>MECCANISMO D'AZIONE DI STRIDE
  <br>Imjudo in combinazione con Imfinzi è una nuova opzione di trattamento immunoterapico e la prima terapia di combinazione IO-IO per il trattamento di prima di linea dell'uHCC. Una singola dose iniziale di Imjudo, anticorpo anti-CTLA-4 in combinazione con l'anticorpo anti-PD-L1 Imfinzi, al giorno 1, ciclo 1, seguita da monoterapia con Imfinzi ogni quattro settimane aumenta l'attivazione e la funzione delle cellule T antitumorali in più fasi della risposta immunitaria e massimizza l'immunità antitumorale
  <br>LO STUDIO HIMALAYA
  <br>HIMALAYA è stato il primo studio globale, multicentrico, randomizzato, open label di fase III a valutare l'efficacia e la sicurezza di STRIDE rispetto a sorafenib in pazienti con uHCC che non avevano ricevuto una precedente terapia sistemica. HIMALAYA è l'unico studio di fase 3 finora condotto a mostrare un follow-up di 3 e 4 anni nell'HCC
  <br>LO STUDIO HIMALAYA – DATI DI EFFICACIA
  <br>Lo studio HIMALAYA ha raggiunto l' endpoint primario di sopravvivenza globale (OS) di STRIDE rispetto a sorafenib. Al cut-off dei dati, STRIDE ha mostrato un beneficio statisticamente e clinicamente significativo rispetto a sorafenib nei pazienti con uHCC [HR 0,78 (IC 96,02%, 0,65-0,93; p=0,0035)].
  <br>I tassi di sopravvivenza per STRIDE vs. sorafenib a 36 mesi sono stati del 30,7% (IC 95%, 25,8-35,7) e del 20,2% (IC 95%, 15,8-25,1), rispettivamente.
  <br>La combinazione ha anche mostrato un miglioramento dei tassi di risposta obiettiva (Objective Response Rate, ORR): 20,1% con STRIDE vs 5,1% con sorafenib.
  <br>Al congresso mondiale ESMO GI del 2023 è stata presentata un'analisi aggiornata dell' OS (maturità dei dati del 78%). STRIDE ha dimostrato un tasso di sopravvivenza a 4 anni senza precedenti, confermando il plateau a lungo termine dell'OS.
  <br>L' hazard ratio per l'OS versus sorafenib [0.78 (95% IC, 0.67–0.92; p=0.0037)] e il tasso di OS stimato a 36 mesi per STRIDE (30,7%)  erano consistenti con l'analisi primaria.
  <br>I tassi di sopravvivenza a 4 anni sono stati del 25,2% nel braccio STRIDE e del 15,1% nel braccio sorafenib. 1 paziente su 4 nel braccio STRIDE era ancora in vita a 48 mesi.
  <br>LO STUDIO HIMALAYA – DATI DI SICUREZZA
  <br>Il profilo di sicurezza e tollerabilità della combinazione è risultato coerente con i profili noti di Imjudo e Imfinzi e non sono stati osservati nuovi segnali di sicurezza.
  <br>L'incidenza di eventi avversi correlati al trattamento (Treatment Related Adverse Events, TRAE) di grado 3/4 è stata del 25,8% per il gruppo STRIDE e del 36,9% per il braccio del sorafenib.
  <br>Inoltre, STRIDE ha prolungato il tempo al deterioramento (Time To Deterioration, TTD) della qualità della vita rispetto al sorafenib [HR 0,76 (95% IC: 0,61-0,96)].
  `,
};
