import React from "react";

import Substitution from "pages/Substitution/containers/Substitution";
const SubstitutionView = (props) => {
  return (
    <div className="animated fadeIn">
      <Substitution id={props.match.params.id} />
    </div>
  );
};
export default SubstitutionView;
