import {createSlice} from "@reduxjs/toolkit";

const SubstitutionReducerSlices = createSlice({
  name: "SubstitutionReducer",
  initialState: [],
  reducers: {
    receiveSubstitutions(state, action) {
      return {
        ...state,
        substitutions: action.payload,
        isError: false,
      };
    },
    receiveSubstitutionDoctors(state, action) {
      const validDoctors = action.payload.filter((doctor) => doctor.family_name);
      return {
        ...state,
        substitutionDoctors: validDoctors,
      };
    },

  },
});

export const {
  receiveSubstitutions,
  receiveSubstitutionDoctors,
} = SubstitutionReducerSlices.actions;

export default SubstitutionReducerSlices.reducer;
